import {
  GET_PRODUCT_GROUP,
  GET_LIST_PRODUCT_GROUP,
  GET_RESULT_PRODUCT_GROUP,
  GET_LIST_PRODUCT_GROUP_CODE,
  SET_PRODUCT_GROUP,
  CREATE_PRODUCT_GROUP,
  UPDATE_PRODUCT_GROUP,
  DELETE_PRODUCT_GROUP,
  SET_ALERT_PRODUCT_GROUP,
  GET_LIST_PRODUCT_TYPE,
  SET_PRODUCT_TYPE,
} from "redux/constants/ProductGroup"

export const getListProductGroup = (data) => {
  return {
    type: GET_LIST_PRODUCT_GROUP,
    payload: data,
  }
}

export const getListProductType = () => {
  return {
    type: GET_LIST_PRODUCT_TYPE,
  }
}

export const getListProductGroupCode = (data) => {
  return {
    type: GET_LIST_PRODUCT_GROUP_CODE,
    payload: data,
  }
}

export const getProductGroup = (data) => {
  return {
    type: GET_PRODUCT_GROUP,
    payload: data,
  }
}

export const getResultProductGroup = (data) => {
  return {
    type: GET_RESULT_PRODUCT_GROUP,
    payload: data,
  }
}

export const setProductGroup = (data) => {
  return {
    type: SET_PRODUCT_GROUP,
    payload: data,
  }
}

export const setProductType = (data) => {
  return {
    type: SET_PRODUCT_TYPE,
    payload: data,
  }
}

export const createProductGroup = (data) => {
  return {
    type: CREATE_PRODUCT_GROUP,
    payload: data,
  }
}

export const updateProductGroup = (data) => {
  return {
    type: UPDATE_PRODUCT_GROUP,
    payload: data,
  }
}

export const deleteProductGroup = (data) => {
  return {
    type: DELETE_PRODUCT_GROUP,
    payload: data,
  }
}

export const setAlertProductGroup = (data) => {
  return {
    type: SET_ALERT_PRODUCT_GROUP,
    payload: data,
  }
}
