import React, { useEffect } from "react"
import { Card, Typography, Badge, Row, Col, Table, Tag, Select } from "antd"
import { HistoryOutlined } from "@ant-design/icons"
import moment from "moment"
import TaskToDoModal from "./TaskToDoModal"
import "../index.css"

const { Title } = Typography
const { Option } = Select

const TasksToDo = ({
  data,
  outletList,
  updatedCustomerTask,
  updatedTaskProgressLog,
  setUpdatedCustomerTask,
  setUpdatedTaskProgressLog,
  setIsSaving,
  setIsModalVisible,
  isModalVisible,
  rowsPerPage,
  totalCount,
  newProgressLog,
  setNewProgressLog,
  validateNewProgressLog,
  setValidateNewProgressLog,
  isProgressLogModalVisible,
  setIsProgressLogModalVisible,
  setIsAddingNewProgressLog,
  selectedRecord,
  setSelectedRecord,
}) => {
  useEffect(() => {
    const record = data?.find((d) => d.TaskId === selectedRecord.TaskId)
    if (record) {
      setSelectedRecord(record)
    }
    //eslint-disable-next-line
  }, [data])

  const columns = [
    {
      dataIndex: ["Customer", "FullName"],
      key: "FullName",
    },
    {
      dataIndex: ["Task"]["Name"],
      key: "Name",
      render: ({ Status, Name, Description }) => (
        <>
          <Badge color={`${Status === "To Do" ? "blue" : "yellow"}`} />
          <span>{Name}</span>
          <span> — {Description}</span>
        </>
      ),
    },
    {
      dataIndex: "DueDate",
      key: "dueDate",
      width: 100,
      render: (dueDate) => (
        <span>
          {dueDate ? (
            <Tag color="#F5F5F5" className="gray-text">
              <HistoryOutlined className="mr-2" />
              {moment(dueDate).format("DD MMMM")}
            </Tag>
          ) : (
            ""
          )}
        </span>
      ),
    },
  ]

  const progressLogColumns = [
    {
      title: "Contact Type",
      dataIndex: "ContactType",
      render: (e) => e,
    },
    {
      title: "Description",
      dataIndex: "Description",
      render: (e) => e,
    },
    {
      title: "Next Follow Up Date",
      dataIndex: "FollowUpDate",
      render: (e) => moment(e).format("yyyy-MM-DD"),
    },
    {
      title: "Updated By",
      dataIndex: "UpdatedByStaffName",
    },
    {
      title: "Status",
      dataIndex: "Status",
      width: 150,
      render: (status, record) => {
        return (
          <Select
            defaultValue={status}
            onChange={(value) =>
              setUpdatedTaskProgressLog([
                ...updatedTaskProgressLog.filter(
                  (u) => u.ProgressLogId !== record.ProgressLogId
                ),
                { ...record, Status: value },
              ])
            }
            placeholder="Status"
            style={{ width: "100%" }}
          >
            <Option value="Active">Active</Option>
            <Option value="Inactive">Inactive</Option>
            <Option value="Completed">Completed</Option>
          </Select>
        )
      },
    },
  ]

  return (
    <Card>
      <Row>
        <Col xs={24} sm={14}>
          <Title level={4}>Tasks To Do</Title>
        </Col>
        <Col xs={24} sm={10}>
          <span className="mr-3">LABELS</span>
          <Badge color="blue" text="To Do" className="ml-3" />
          <Badge color="yellow" text="In Progress" className="ml-3" />
        </Col>
      </Row>
      <Table
        className="pt-2"
        rowClassName="pointer-cursor"
        showHeader={false}
        columns={columns}
        dataSource={data
          ?.sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))
          .map((item, index) => ({
            ...item,
            key: index,
          }))}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setIsModalVisible(true)
              setSelectedRecord(record)
              setUpdatedCustomerTask(record)
              setUpdatedTaskProgressLog(record?.TaskProgressLog)
            },
          }
        }}
        pagination={{
          showTotal: (total, range) =>
            `Displaying records ${range[0]}-${range[1]} of ${total} items`,
          pageSize: rowsPerPage,
          total: totalCount,
          showSizeChanger: false,
        }}
      />
      <TaskToDoModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setSelectedRecord={setSelectedRecord}
        outletList={outletList}
        data={selectedRecord}
        taskProgressLogColumns={progressLogColumns}
        taskProgressLogData={selectedRecord?.TaskProgressLog}
        updatedCustomerTask={updatedCustomerTask}
        setUpdatedCustomerTask={setUpdatedCustomerTask}
        updatedTaskProgressLog={updatedTaskProgressLog}
        setIsSaving={setIsSaving}
        newProgressLog={newProgressLog}
        setNewProgressLog={setNewProgressLog}
        validateNewProgressLog={validateNewProgressLog}
        setValidateNewProgressLog={setValidateNewProgressLog}
        isProgressLogModalVisible={isProgressLogModalVisible}
        setIsProgressLogModalVisible={setIsProgressLogModalVisible}
        setIsAddingNewProgressLog={setIsAddingNewProgressLog}
      ></TaskToDoModal>
    </Card>
  )
}

export default TasksToDo
