import React from "react"
import AppBreadcrumb from "components/layout-components/AppBreadcrumb"
import IntlMessage from "../util-components/IntlMessage"
import MemberCardCategoryAddButton from "../../views/app-views/setup/member-card-category/components/MemberCardCategoryAddButton"
import MemberGroupAddButton from "../../views/app-views/setup/member-card-group/components/MemberGroupAddButton"
import CustomerAddButton from "../../views/app-views/registration/customers/components/CustomerAddButton"
import MemberTaskGroupAddButton from "../../views/app-views/setup/task-group/components/TaskGroupAddButton"
import PointStructureAddButton from "../../views/app-views/points-management/point-structure/components/PointStructureAddButton"
import PointRateAddButton from "views/app-views/points-management/point-rate/components/buttons/PointRateAddButton"
import RewardsAddButton from "views/app-views/rewards/rewards/components/RewardsAddButton"
import { ActivityTypeAddButton } from "views/app-views/setup/activity-type/components"
import { ProductGroupAddButton } from "views/app-views/setup/product-group/components"
import CustomFieldAddButton from "views/app-views/setup/custom-field/components/CustomFieldAddButton"
import { FeedbackAddButton } from "views/app-views/feedback/feedback/components"
import FeedbackQuestionAddButton from "views/app-views/feedback/question/components/FeedbackQuestionAddButton"

export const PageHeader = ({ title, display }) => {
  return display ? (
    <div className="app-page-header">
      <div className="app-page-header-breadcrumb d-flex flex-lg-row flex-column align-items-md-start align-items-lg-center">
        <h3 className="mb-0 mr-3 font-weight-semibold">
          <IntlMessage id={title ? title : "home"} />
        </h3>
        <AppBreadcrumb />
      </div>
      <div className="app-page-header-btn">
        {window.location.pathname === "/setup/card-group" ? (
          <MemberGroupAddButton />
        ) : window.location.pathname === "/setup/card-category" ? (
          <MemberCardCategoryAddButton />
        ) : window.location.pathname === "/setup/task-group" ? (
          <MemberTaskGroupAddButton />
        ) : window.location.pathname === "/registration/customers" ? (
          <CustomerAddButton />
        ) : window.location.pathname ===
          "/points-management/point-structure" ? (
          <PointStructureAddButton />
        ) : window.location.pathname === "/points-management/point-rate" ? (
          <PointRateAddButton />
        ) : window.location.pathname === "/rewards/rewards" ? (
          <RewardsAddButton />
        ) : window.location.pathname === "/setup/activity-type" ? (
          <ActivityTypeAddButton />
        ) : window.location.pathname === "/setup/product-group" ? (
          <ProductGroupAddButton />
        ) : window.location.pathname === "/feedback/feedback" ? (
          <FeedbackAddButton />
        ) : window.location.pathname === "/feedback/question" ? (
          <FeedbackQuestionAddButton />
        ) : window.location.pathname === "/setup/custom-field" ? (
          <CustomFieldAddButton />
        ) : null}
      </div>
    </div>
  ) : null
}

export default PageHeader
