import {
  SET_AUTH_LOADING,
  SIGNIN,
  SSO_SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  UNAUTHENTICATED,
  CUSTOMER_SIGNIN,
  CUSTOMER_AUTHENTICATED,
  SYSTEM_SIGNIN,
  COMMON_SYSTEM_SIGNIN,
  SYSTEM_AUTHENTICATED,
  GET_ROLES_PRIVILEGES,
  SET_ROLES_PRIVILEGES,
  CHANGE_SSO_PASSWORD,
  REQUEST_RESET_PASSWORD,
  GET_LOGIN_ACTIVITIES,
  SET_LOGIN_ACTIVITIES,
  CREATE_LOGIN_ACTIVITY,
} from "../constants/Auth"

export const setAuthLoading = (payload) => {
  return {
    type: SET_AUTH_LOADING,
    payload,
  }
}

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user,
  }
}

export const ssoSignIn = (code) => {
  return {
    type: SSO_SIGNIN,
    payload: code,
  }
}

export const systemSignIn = () => {
  return {
    type: SYSTEM_SIGNIN,
  }
}

export const systemAuthenticated = (data) => {
  return {
    type: SYSTEM_AUTHENTICATED,
    data,
  }
}

export const commonSystemSignIn = () => {
  return {
    type: COMMON_SYSTEM_SIGNIN,
  }
}

export const customerSignIn = (user) => {
  return {
    type: CUSTOMER_SIGNIN,
    payload: user,
  }
}

export const authenticated = (data) => {
  return {
    type: AUTHENTICATED,
    data,
  }
}

export const customerAuthenticated = (data) => {
  return {
    type: CUSTOMER_AUTHENTICATED,
    data,
  }
}

export const unAuthenticated = () => {
  return {
    type: UNAUTHENTICATED,
  }
}

export const signOut = () => {
  return {
    type: SIGNOUT,
  }
}

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  }
}

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user,
  }
}

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token,
  }
}

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE,
  }
}

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token,
  }
}

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK,
  }
}

export const signInWithFacebookAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token,
  }
}

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  }
}

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  }
}

export const getRolesPrivileges = () => {
  return {
    type: GET_ROLES_PRIVILEGES,
  }
}

export const setRolesPrivileges = (payload) => {
  return {
    type: SET_ROLES_PRIVILEGES,
    payload,
  }
}

export const changeSsoPassword = (payload) => {
  return {
    type: CHANGE_SSO_PASSWORD,
    payload,
  }
}

export const requestResetPassword = (payload) => {
  return {
    type: REQUEST_RESET_PASSWORD,
    payload,
  }
}

export const getLoginActivities = (payload) => {
  return {
    type: GET_LOGIN_ACTIVITIES,
    payload,
  }
}

export const setLoginActivities = (payload) => {
  return {
    type: SET_LOGIN_ACTIVITIES,
    payload,
  }
}

export const createLoginActivity = (payload, isLogoutActivity = false) => {
  return {
    type: CREATE_LOGIN_ACTIVITY,
    payload,
    isLogoutActivity
  }
}
