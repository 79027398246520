import React, { useState } from "react"
import { Modal, Form, Input, Switch } from "antd"

const { TextArea } = Input

function TaskGroupModal(props) {
  const [validateInput, setValidateInput] = useState({})

  const onSave = () => {
    if (props.data.Name === "" || props.data.Description === "") {
      let error = {}
      if (!props.data.Description) {
        error.Description = true
      }
      if (!props.data.Name) {
        error.Name = true
      }
      setValidateInput(error)
    } else {
      // props.setShowModal(false)
      props.saveGroup()
      setValidateInput({})
    }
  }

  const onCancel = () => {
    props.setShowModal(false)
    props.setData({
      Code: "",
      Name: "",
      Description: "",
      Status: "Active",
    })
    setValidateInput({})
  }
  const submitHandler = (e) => {
    e.preventDefault()
  }
  const pullLeft = {
    labelAlign: "left",
    wrapperCol: { span: 20 },
    labelCol: { span: 6 },
    colon: false,
  }

  const styleFormLabel = {
    width: 100,
  }

  const errorStyeLabel = {
    color: "#ff6b72",
  }
  return (
    <div>
      <Modal
        title="Task Group"
        visible={props.visible}
        onOk={() => onSave()}
        okText="Save"
        onCancel={() => onCancel()}
        destroyOnClose={true}
      >
        <Form
          {...pullLeft}
          name="formGroup"
          initialValues={{ remember: true }}
          onSubmit={submitHandler}
        >
          <Form.Item
            label={<label style={styleFormLabel}>Code</label>}
            name="Code"
          >
            <Input
              type="text"
              defaultValue={props.data?.Code}
              required
              maxLength="10"
              style={{
                borderColor: validateInput.Code ? "#ff6b72" : "#699dff",
                textTransform: "uppercase",
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 32) {
                  e.preventDefault()
                }
              }}
              onChange={(e) =>
                props.setData({ ...props.data, Code: e.target.value })
              }
              value={props.data?.Code}
              disabled={props.data?.CreatedById ? true : false}
            />
            {validateInput.Code && (
              <span style={errorStyeLabel}>Please input Code.</span>
            )}
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Description</label>}
            name="Description"
            onChange={(e) =>
              props.setData({ ...props.data, Description: e.target.value })
            }
          >
            <TextArea
              rows={5}
              value={props.data?.Description}
              required
              maxLength="500"
              style={{
                borderColor: validateInput.Description ? "#ff6b72" : "#699dff",
              }}
            />
            {validateInput.Description && (
              <span style={errorStyeLabel}>Please input description.</span>
            )}
          </Form.Item>
          <Form.Item
            label={<label style={styleFormLabel}>Activate</label>}
            name="Status"
          >
            <Switch
              onChange={(e) =>
                props.setData({
                  ...props.data,
                  Status: e ? "Active" : "Inactive",
                })
              }
              checked={props.data?.Status === "Active"}
            />
          </Form.Item>
          <Form.Item
            label={<label style={styleFormLabel}>Remarks</label>}
            name="Remarks"
            onChange={(e) =>
              props.setData({ ...props.data, Remarks: e.target.value })
            }
          >
            <TextArea
              rows={4}
              value={props.data?.Remarks}
              required
              maxLength="1000"
              style={{
                borderColor: validateInput.Remarks ? "#ff6b72" : "#699dff",
              }}
            />
            {validateInput.Remarks && (
              <span style={errorStyeLabel}>Please input Remarks.</span>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default TaskGroupModal
