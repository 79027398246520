import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  CREATE_MEMBERSHIPS,
  GET_LIST_MEMBERSHIPS,
  GET_MEMBERSHIPS,
  UPDATE_MEMBERSHIPS,
  DELETE_MEMBERSHIPS,
  SEARCH_LIST_MEMBERSHIPS,
  GET_LIST_MEMBER_POINT_STATEMENT,
  GET_LIST_POINT_SUMMARY_REPORT,
} from "../constants/Memberships"
import {
  resultMemberships,
  alertMemberships,
  setIsMembershipLoading,
} from "../actions/Memberships"
import MembershipsService from "services/MembershipsService"
import { isArray } from "lodash"
import setNotification from "components/shared-components/Notification"

//generator
export function* createMembershipsAPI() {
  yield takeEvery(CREATE_MEMBERSHIPS, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(MembershipsService.createMemberships, payload)
      if (group.alert) {
        yield put(alertMemberships({ success: false, message: group.alert }))
      } else {
        group.type = "CREATE"
        yield put(resultMemberships(group))
        yield put(
          alertMemberships({
            success: true,
            message: "A new Memberships has been created",
          })
        )
      }
    } catch (err) {
      yield put(
        alertMemberships({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* listMembershipsAPI() {
  yield takeEvery(GET_LIST_MEMBERSHIPS, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(MembershipsService.getListMemberships, payload)
      if (group.alert) {
      } else {
        group.type = "LIST"
        yield put(resultMemberships(group))
      }
    } catch (err) {
      yield put(
        alertMemberships({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* listMemberPointStatementAPI() {
  yield takeEvery(GET_LIST_MEMBER_POINT_STATEMENT, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      yield put(setIsMembershipLoading(true))

      const group = yield call(
        MembershipsService.getListMemberPointStatement,
        payload
      )
      if (group.alert) {
        yield put(alertMemberships(group.alert))
      } else {
        group.type = "POINT_STATEMENT_LIST"
        yield put(resultMemberships(group))
      }
    } catch (err) {
      yield put(setIsMembershipLoading(false))
      yield put(
        alertMemberships({
          success: false,
          message: isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        })
      )
    }
  })
}

export function* listPointSummaryReportAPI() {
  yield takeEvery(GET_LIST_POINT_SUMMARY_REPORT, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      yield put(setIsMembershipLoading(true))

      const group = yield call(
        MembershipsService.getListPointSummaryReport,
        payload
      )

      if (group.alert) {
        yield put(alertMemberships(group.alert))
      } else {
        if (group?.data?.rows?.length < 1) {
          setNotification({
            type: "error",
            message: "Member Point Statement Not Found",
          })
          yield put(setIsMembershipLoading(false))
        } else {
          group.type = "POINT_SUMMARY_REPORT_LIST"
          yield put(resultMemberships(group))
        }
      }
    } catch (err) {
      yield put(setIsMembershipLoading(false))
      yield put(
        alertMemberships({
          success: false,
          message: isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        })
      )
    }
  })
}

export function* SearchMembershipsAPI() {
  yield takeEvery(SEARCH_LIST_MEMBERSHIPS, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(MembershipsService.getListMemberships, payload)

      if (group.alert) {
        yield put(alertMemberships(group.alert))
      } else {
        if (group?.data?.rows) {
          if (group?.data?.rows?.length > 0) {
            if (group?.data?.rows[0]?.Status === "Inactive") {
              setNotification({
                type: "error",
                message: "Something Wrong",
                description: "Member is inactive",
              })
            } else if (group?.data?.rows[0]?.Status === "Suspended") {
              setNotification({
                type: "error",
                message: "Something Wrong",
                description: "Member is suspended",
              })
            } else {
              payload.callback(group.data.rows)
            }
          } else {
            setNotification({
              type: "error",
              message: "Something Wrong",
              description: "Record Not Found",
            })
          }
        } else {
          payload.callback(group.data)
        }
      }
    } catch (err) {
      if (err?.response) {
        if (err.response.status === 400) {
          setNotification({
            type: "error",
            message: "Something Wrong",
            description: "Recommend Friend Not Found",
          })
        }
      } else {
        setNotification({
          type: "error",
          message: "Something Wrong",
          description: err.message,
        })
      }
    }
  })
}
export function* getMembershipsAPI() {
  yield takeEvery(GET_MEMBERSHIPS, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(MembershipsService.getMemberships, payload)
      if (group.alert) {
        yield put(alertMemberships(group.alert))
      } else {
        yield put(resultMemberships(group.data))
      }
    } catch (err) {
      yield put(alertMemberships(err))
    }
  })
}

export function* updateMembershipsAPI() {
  yield takeEvery(UPDATE_MEMBERSHIPS, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(MembershipsService.updateMemberships, payload)
      if (group.alert) {
        yield put(alertMemberships({ success: false, message: group.alert }))
      } else {
        group.type = "UPDATE"
        group.data = payload
        yield put(resultMemberships(group))
        yield put(
          alertMemberships({
            success: true,
            message: "Memberships successfully updated",
          })
        )
      }
    } catch (err) {
      yield put(
        alertMemberships({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* deleteMembershipsAPI() {
  yield takeEvery(DELETE_MEMBERSHIPS, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(MembershipsService.deleteMemberships, payload)
      if (group.alert) {
        yield put(alertMemberships({ success: false, message: group.alert }))
      } else {
        group.type = "DELETE"
        group.data = payload
        yield put(resultMemberships(group))
        yield put(
          alertMemberships({
            success: true,
            message: "Memberships has been deleted/inactivated successfully",
          })
        )
      }
    } catch (err) {
      yield put(alertMemberships({ success: false, message: err }))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listMembershipsAPI),
    fork(listMemberPointStatementAPI),
    fork(listPointSummaryReportAPI),
    fork(createMembershipsAPI),
    fork(getMembershipsAPI),
    fork(updateMembershipsAPI),
    fork(deleteMembershipsAPI),
    fork(SearchMembershipsAPI),
  ])
}
