import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"
import { addCommonCustomField } from "redux/actions/CustomField"
import CustomFieldModal from "./modal/CustomFieldModal"

export const CustomFieldAddButton = (props) => {
  const defaultFieldData = {
    ObjectType: "Customer",
    CustomFieldCode: "",
    CustomFieldName: "",
    FieldType: "",
    IsMandatoryYN: "Y",
    SequenceNo: "",
    FieldListId: "",
    AutoSyncYN: "Y",
    Status: "Active",
  }

  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState({ ...defaultFieldData })

  useEffect(() => {
    if (props.alert?.success === true) {
      resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.alert])

  const resetForm = async () => {
    setData({ ...defaultFieldData })
    setTimeout(() => {
      setShowModal(false)
    }, 1000)
  }

  const saveGroup = () => {
    props.addCommonCustomField(data)
    resetForm()
  }

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        type="primary"
        icon={<PlusCircleOutlined />}
        block
      >
        Add Custom Field
      </Button>
      <CustomFieldModal
        title="Create New Custom Field"
        visible={showModal}
        setShowModal={setShowModal}
        data={data}
        setData={setData}
        submitHandler={saveGroup}
      />
    </>
  )
}

const mapStateToProps = ({ customerActivityType }) => {
  const { group, alert } = customerActivityType
  return { group, alert }
}

const mapDispatchToProps = {
  addCommonCustomField,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFieldAddButton)
