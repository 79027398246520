import React from "react"
import { Collapse, Input, Select, Row, Col, Button } from "antd"
import { SearchOutlined, CloseOutlined } from "@ant-design/icons"
const { Option } = Select
const { Panel } = Collapse

const ActivityTypeSearchSection = ({
  search,
  setSearch,
  onSearch,
  onReset,
  ActivityCodeList,
}) => {
  return (
    <>
      <Collapse
        defaultActiveKey={["1"]}
        expandIconPosition={"right"}
        className="mb-5"
      >
        <Panel header="SEARCH SECTION" key="1" className="">
          <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
            <Col className="gutter-row mb-2" span={4} md={4} xs={24}>
              <div>
                <Select
                  style={{
                    width: "100%",
                    borderRadius: 1,
                  }}
                  value={search?.ActivityCode}
                  onChange={(value) =>
                    setSearch({ ...search, ActivityCode: value })
                  }
                  placeholder="Code"
                >
                  <Option value="All">All</Option>
                  {ActivityCodeList?.map((el, index) => (
                    <Option value={el.ActivityCode} key={index}>
                      {el.ActivityCode}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>

            <Col className="gutter-row mb-2" span={4} md={4} xs={24}>
              <div>
                <Input
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  placeholder="Description"
                  value={search?.Description}
                  onChange={(e) =>
                    setSearch({
                      ...search,
                      Description: e.target.value,
                    })
                  }
                  maxLength={1000}
                />
              </div>
            </Col>

            <Col className="gutter-row mb-2" span={4} md={4} xs={24}>
              <div>
                <Select
                  style={{
                    width: "100%",
                    borderRadius: 1,
                  }}
                  onChange={(value) =>
                    setSearch({ ...search, AutoSyncYN: value })
                  }
                  placeholder="Auto Synchronization"
                  value={search?.AutoSyncYN}
                >
                  <Option value="" disabled>
                    Auto Synchronization
                  </Option>
                  <Option value="Y">Yes</Option>
                  <Option value="N">No</Option>
                </Select>
              </div>
            </Col>

            <Col className="mb-2" lg={6} md={4} xs={24}>
              <div className="d-flex">
                <Select
                  style={{
                    width: "84%",
                    borderRadius: "10px",
                    marginRight: "1rem",
                  }}
                  placeholder="Status"
                  onChange={(value) => {
                    setSearch({ ...search, Status: value })
                  }}
                  value={search?.Status}
                >
                  <Option value="" disabled>
                    Status
                  </Option>
                  {/* <Option value="All">All</Option> */}
                  <Option value="Active">Active</Option>
                  <Option value="Inactive">Inactive</Option>
                </Select>

                <Button
                  className="mr-2"
                  shape="circle"
                  icon={<SearchOutlined />}
                  onClick={onSearch}
                />

                <Button
                  shape="circle"
                  icon={<CloseOutlined />}
                  onClick={onReset}
                />
              </div>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  )
}

export default ActivityTypeSearchSection
