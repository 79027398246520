export const GET_LIST_CUSTOMER_TAG = "GET_LIST_CUSTOMER_TAG"
export const CREATE_CUSTOMER_TAG = "CREATE_CUSTOMER_TAG"
export const GET_RESULT_CUSTOMER_TAG = "GET_RESULT_CUSTOMER_TAG"
export const GET_CUSTOMER_TAG = "GET_CUSTOMER_TAG"
export const UPDATE_CUSTOMER_TAG = "UPDATE_CUSTOMER_TAG"
export const DELETE_CUSTOMER_TAG = "DELETE_CUSTOMER_TAG"
export const RESULT_CUSTOMER_TAG = "RESULT_CUSTOMER_TAG"
export const ALERT_CUSTOMER_TAG = "ALERT_CUSTOMER_TAG"
export const REMOVE_CUSTOMER_TAG_ALERT = "REMOVE_CUSTOMER_TAG_ALERT"
export const UPDATE_CUSTOMER_TAG_AND_PROGRESS_LOGS =
  "UPDATE_CUSTOMER_TAG_AND_PROGRESS_LOGS"
