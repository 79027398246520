import React from "react"
import PropTypes from "prop-types"
import { Input, Modal, Form, Button, Row, Col, Alert } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { changeSsoPassword, hideAuthMessage } from "redux/actions/Auth"

const ChangePasswordModal = ({ visible, setVisible }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { showMessage, message } = useSelector((state) => state.auth)

  const onCloseModal = () => {
    setVisible(false)
    form.resetFields()
    dispatch(hideAuthMessage())
  }

  const onSubmitHandler = (values) => {
    dispatch(
      changeSsoPassword({
        ...values,
        callback: onCloseModal,
      })
    )
  }

  const pullLeft = {
    labelAlign: "left",
    wrapperCol: { span: 15 },
    labelCol: { span: 9 },
  }

  return (
    <Modal
      visible={visible}
      title={"Change Password"}
      centered
      footer={null}
      onCancel={onCloseModal}
    >
      <Form
        {...pullLeft}
        name="changePasswordForm"
        autoComplete={"off"}
        form={form}
        onFinish={onSubmitHandler}
      >
        <Form.Item
          name={"current_password"}
          label={<label>Current Password</label>}
          colon={false}
          required
          rules={[
            {
              required: true,
              message: "Please enter your current password",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name={"new_password"}
          label={<label>New Password</label>}
          colon={false}
          required
          rules={[
            {
              required: true,
              message: "Please enter your new password",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name={"confirm_new_password"}
          label={<label>Confirm New Password</label>}
          colon={false}
          required
          rules={[
            {
              required: true,
              message: "Please confirm your new password",
            },
            {
              validator: (_, value) => {
                const newPassword = form.getFieldValue("new_password")
                if (value !== newPassword) {
                  return Promise.reject(
                    "Password do not match the new password"
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        {showMessage && (
          <Alert message={message} type={"error"} showIcon></Alert>
        )}

        <Row gutter={{ md: 10 }} justify={"end"}>
          <Col>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Col>

          <Col>
            <Button onClick={onCloseModal}>Cancel</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

ChangePasswordModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
}

ChangePasswordModal.defaultProps = {
  visible: true,
  setVisible: () => {},
}

export default ChangePasswordModal
