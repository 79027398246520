import React from "react"
import { Modal, Form, Input, Switch, Select, Row, Col, Button } from "antd"

const { TextArea } = Input

function ActivityTypeModal(props) {
  const [form] = Form.useForm()

  const onCancel = () => {
    props.setShowModal(false)
    form.resetFields()
    props.setData({
      ActivityCode: "",
      Description: "",
      Status: "Active",
      AutoSyncYN: "N",
    })
  }

  const submitHandler = (e) => {
    props.submitHandler()
  }

  const pullLeft = {
    labelAlign: "left",
    wrapperCol: { span: 20 },
    labelCol: { span: 6 },
  }

  const styleFormLabel = {
    width: 100,
  }

  return (
    <div>
      <Modal
        title={props.title ? props.title : "Activity Type"}
        visible={props.visible}
        okText="Save"
        onCancel={() => onCancel()}
        destroyOnClose={true}
        footer={null}
      >
        <Form
          {...pullLeft}
          name="formGroup"
          initialValues={props.data}
          onFinish={submitHandler}
          autoComplete={"off"}
          form={form}
        >
          <Form.Item
            label={<label style={styleFormLabel}>Activity Code</label>}
            name="ActivityCode"
            onChange={(e) =>
              props.setData({ ...props.data, ActivityCode: e.target.value })
            }
            rules={
              !props.data?.CreatedById
                ? [
                    {
                      required: true,
                      message: "Please input your activity code!",
                    },
                    {
                      pattern: /^[A-Z0-9_]+$/,
                      message: "Please use capital letter without space",
                    },
                  ]
                : null
            }
          >
            <Input disabled={props.data?.CreatedById ? true : false} />
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Description</label>}
            name="Description"
            onChange={(e) =>
              props.setData({ ...props.data, Description: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Please input your description!",
              },
              {
                max: 200,
                message: "Description activity cannot more than 200 character",
              },
            ]}
          >
            <TextArea rows={5} maxLength="200" />
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Auto Sync</label>}
            name="AutoSync"
          >
            <Switch
              onChange={(e) =>
                props.setData({
                  ...props.data,
                  AutoSyncYN: e ? "Y" : "N",
                })
              }
              checked={props.data?.AutoSyncYN === "Y"}
            />
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Status</label>}
            name="Status"
          >
            <Select
              value={props.data.Status}
              onChange={(value) =>
                props.setData({ ...props.data, Status: value })
              }
            >
              <Select.Option value={"Active"}>Active</Select.Option>
              <Select.Option value={"Inactive"}>Inactive</Select.Option>
            </Select>
          </Form.Item>

          <Row gutter={{ md: 10 }} justify={"end"}>
            <Col>
              <Button onClick={onCancel}>Cancel</Button>
            </Col>

            <Col>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

export default ActivityTypeModal
