import {
  SET_PROFILE_UPDATED_HISTORY_LOADING,
  SET_PROFILE_UPDATED_HISTORY_LIST,
  CLEAR_PROFILE_UPDATED_HISTORY_LIST,
} from "redux/constants/ProfileUpdatedHistory"

const initialState = {
  isLoading: false,
  histories: {},
}

const ProfileUpdatedHistory = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE_UPDATED_HISTORY_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case SET_PROFILE_UPDATED_HISTORY_LIST:
      return {
        ...state,
        histories: action.payload.data,
        isLoading: false,
      }

    case CLEAR_PROFILE_UPDATED_HISTORY_LIST:
      return {
        ...state,
        histories: {},
        isLoading: false,
      }

    default:
      return state
  }
}

export default ProfileUpdatedHistory
