export const SEARCH_CUSTOMER_MEMBERSHIP = "SEARCH_CUSTOMER_MEMBERSHIP"
export const RESULT_SEARCH_CUSTOMER_MEMBERSHIP =
  "RESULT_SEARCH_CUSTOMER_MEMBERSHIP"

export const LOAD_REWARDS = "LOAD_REWARDS"
export const RESULT_LOAD_REWARDS = "RESULT_LOAD_REWARDS"

export const LOAD_IMAGE_REWARDS = "LOAD_IMAGE_REWARDS"
export const RESULT_LOAD_IMAGE_REWARDS = "RESULT_LOAD_IMAGE_REWARDS"

export const ON_REDEEM_REWARD = "ON_REDEEM_REWARD"
export const RESULT_ON_REDEEM_REWARD = "RESULT_ON_REDEEM_REWARD"
export const RESULT_ON_POINT_REDEEM = "RESULT_ON_POINT_REDEEM"

export const SET_IS_LOADING_REDEEM = "SET_IS_LOADING_REDEEM"
export const SET_RESET_REDEEM = "SET_RESET_REDEEM"
