import {
  CREATE_FEEDBACK_OPTION,
  DELETE_FEEDBACK_OPTION,
  GET_LIST_FEEDBACK_OPTION,
  SET_CREATE_FEEDBACK_OPTION,
  SET_DELETE_FEEDBACK_OPTION,
  SET_LIST_FEEDBACK_OPTION,
  SET_UPDATE_FEEDBACK_OPTION,
  UPDATE_FEEDBACK_OPTION,
  UNMOUNT_LIST_FEEDBACK_OPTION,
} from "redux/constants/FeedbackOption"

export const createFeedbackOption = (data) => {
  return {
    type: CREATE_FEEDBACK_OPTION,
    payload: data,
  }
}

export const setCreateFeedbackOption = (data) => {
  return {
    type: SET_CREATE_FEEDBACK_OPTION,
    payload: data,
  }
}

export const getListFeedbackOption = (data) => {
  return {
    type: GET_LIST_FEEDBACK_OPTION,
    payload: data,
  }
}

export const setListFeedbackOption = (data) => {
  return {
    type: SET_LIST_FEEDBACK_OPTION,
    payload: data,
  }
}

export const updateFeedbackOption = (data) => {
  return {
    type: UPDATE_FEEDBACK_OPTION,
    payload: data,
  }
}

export const setUpdateFeedbackOption = (data) => {
  return {
    type: SET_UPDATE_FEEDBACK_OPTION,
    payload: data,
  }
}

export const deleteFeedbackOption = (data) => {
  return {
    type: DELETE_FEEDBACK_OPTION,
    payload: data,
  }
}

export const setDeleteFeedbackOption = (data) => {
  return {
    type: SET_DELETE_FEEDBACK_OPTION,
    payload: data,
  }
}

export const unmountListFeedbackOption = () => {
  return {
    type: UNMOUNT_LIST_FEEDBACK_OPTION,
  }
}
