import {
  RESULT_POINT_ADJUSTMENT,
  ALERT_POINT_ADJUSTMENT,
  REMOVE_POINT_ADJUSTMENT_ALERT,
  SET_POINT_ADJUSTMENT,
} from "redux/constants/PointAdjustment"

const initialState = {
  point: {},
  alert: {},
}
const PointAdjustment = (state = initialState, action) => {
  switch (action.type) {
    case RESULT_POINT_ADJUSTMENT:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          point: action.payload.data,
        }
      } else if (action.payload.type === "CREATE") {
        return {
          ...state,
          point: {
            ...state.point,
            count: state.point.count + 1,
            rows: [action.payload.data, ...state.point.rows],
          },
        }
      }
      break
    case ALERT_POINT_ADJUSTMENT:
      return {
        ...state,
        alert: {
          status: true,
          success: action.payload.success,
          message: action.payload.message,
        },
      }
    case REMOVE_POINT_ADJUSTMENT_ALERT:
      return {
        ...state,
        alert: {
          status: false,
        },
      }
    case SET_POINT_ADJUSTMENT:
      return {
        ...state,
        point: action.payload,
      }
    default:
      return state
  }
}

export default PointAdjustment
