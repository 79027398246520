import {
  DELETE_COMMON_CUSTOM_FIELD,
  GET_CRM_CUSTOM_FIELD_LIST,
  GET_CUSTOM_FIELD_LIST,
  INSERT_COMMON_CUSTOM_FIELD,
  INSERT_CRM_CUSTOM_FIELD,
  SET_CRM_CUSTOM_FIELD_LIST,
  SET_CUSTOM_FIELD_LIST,
  UPDATE_COMMON_CUSTOM_FIELD,
  UPDATE_CRM_CUSTOM_FIELD,
} from "redux/constants/CustomField"

export const getCustomFieldList = (data) => {
  return {
    type: GET_CUSTOM_FIELD_LIST,
    payload: data,
  }
}

export const setCustomFieldList = (data) => {
  return {
    type: SET_CUSTOM_FIELD_LIST,
    payload: data,
  }
}

export const addCommonCustomField = (data) => {
  return {
    type: INSERT_COMMON_CUSTOM_FIELD,
    payload: data,
  }
}

export const updateCommonCustomField = (data) => {
  return {
    type: UPDATE_COMMON_CUSTOM_FIELD,
    payload: data,
  }
}

export const deleteCommonCustomField = (data) => {
  return {
    type: DELETE_COMMON_CUSTOM_FIELD,
    payload: data,
  }
}

export const addCrmCustomField = (data) => {
  return {
    type: INSERT_CRM_CUSTOM_FIELD,
    payload: data,
  }
}

export const updateCrmCustomField = (data) => {
  return {
    type: UPDATE_CRM_CUSTOM_FIELD,
    payload: data,
  }
}

export const getCrmCustomFieldList = (data) => {
  return {
    type: GET_CRM_CUSTOM_FIELD_LIST,
    payload: data,
  }
}

export const setCrmCustomFieldList = (data) => {
  return {
    type: SET_CRM_CUSTOM_FIELD_LIST,
    payload: data,
  }
}
