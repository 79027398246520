import {
  SET_CUSTOMER_REWARD_LOADING,
  CREATE_CUSTOMER_REWARD,
  DELETE_CUSTOMER_REWARD,
  GET_LIST_CUSTOMER_REWARD,
  GET_CUSTOMER_REWARD,
  RESET_CUSTOMER_REWARD_ALERT,
  RESULT_CUSTOMER_REWARD,
  SET_PAGE_CUSTOMER_REWARD,
  UPDATE_CUSTOMER_REWARD,
} from "redux/constants/CustomerReward"

export const setCustomerRewardLoading = (data) => {
  return {
    type: SET_CUSTOMER_REWARD_LOADING,
    payload: data,
  }
}

export const getListCustomerReward = (data) => {
  return {
    type: GET_LIST_CUSTOMER_REWARD,
    payload: data,
  }
}

export const getCustomerReward = (data) => {
  return {
    type: GET_CUSTOMER_REWARD,
    payload: data,
  }
}

export const deleteCustomerReward = (data) => {
  return {
    type: DELETE_CUSTOMER_REWARD,
    payload: data,
  }
}

export const createCustomerReward = (data) => {
  return {
    type: CREATE_CUSTOMER_REWARD,
    payload: data,
  }
}

export const updateCustomerReward = (data) => {
  return {
    type: UPDATE_CUSTOMER_REWARD,
    payload: data,
  }
}

export const setPageCustomerReward = (data) => {
  return {
    type: SET_PAGE_CUSTOMER_REWARD,
    payload: data,
  }
}

export const resetCustomerRewardAlert = () => {
  return {
    type: RESET_CUSTOMER_REWARD_ALERT,
  }
}

export const resultCustomerReward = (data) => {
  return {
    type: RESULT_CUSTOMER_REWARD,
    payload: data,
  }
}
