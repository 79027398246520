import {
  CREATE_CUSTOMER_SELF_SERVICE,
  GENERATE_PASSCODE,
  GET_CUSTOMER_PROFILE,
  SET_CUSTOMER_PROFILE,
  SET_PASSCODE_ERROR,
  SHOW_CREATE_CUSTOMER,
  SHOW_UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SELF_SERVICE,
  GET_LIST_PASSCODE,
  VALIDATE_PASSCODE,
  GET_CARD_LEVEL_DETAIL_SELF_SERVICE,
  VERIFY_REFERRER_SELF_SERVICE,
} from "redux/constants/SelfService"

export const createCustomerSelfService = (data) => {
  return {
    type: CREATE_CUSTOMER_SELF_SERVICE,
    payload: data,
  }
}

export const updateCustomerSelfService = (data) => {
  return {
    type: UPDATE_CUSTOMER_SELF_SERVICE,
    payload: data,
  }
}

export const getCustomerProfile = (data) => {
  return {
    type: GET_CUSTOMER_PROFILE,
    payload: data,
  }
}

export const createCustomer = (data) => {
  return {
    type: SHOW_CREATE_CUSTOMER,
    payload: data,
  }
}

export const updateCustomer = (data) => {
  return {
    type: SHOW_UPDATE_CUSTOMER,
    payload: data,
  }
}

export const setCustomerProfile = (data) => {
  return {
    type: SET_CUSTOMER_PROFILE,
    payload: data,
  }
}

export const getListPasscode = (data) => {
  return {
    type: GET_LIST_PASSCODE,
    payload: data,
  }
}

export const validatePasscode = (data) => {
  return {
    type: VALIDATE_PASSCODE,
    payload: data,
  }
}

export const setPasscodeError = (data) => {
  return {
    type: SET_PASSCODE_ERROR,
    payload: data,
  }
}

export const generatePasscode = (data) => {
  return {
    type: GENERATE_PASSCODE,
    payload: {
      ...data,
      callback: data.callback,
    },
  }
}

export const getCardLevelDetail = (data) => {
  return {
    type: GET_CARD_LEVEL_DETAIL_SELF_SERVICE,
    payload: data,
  }
}

export const verifyReferrer = (data) => {
  return {
    type: VERIFY_REFERRER_SELF_SERVICE,
    payload: data,
  }
}
