import setNotification from "components/shared-components/Notification"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  setCreateContactTemplate,
  setDeleteContactTemplate,
  setError,
  setGetContactTemplate,
  setLoading,
  setUpdateContactTemplate,
  setPageContactTemplate,
  setGetCustomerContactTemplate,
  setGetContactTemplates,
} from "redux/actions/ContactTemplate"
import {
  CREATE_CONTACT_TEMPLATE,
  GET_CONTACT_TEMPLATE,
  GET_CUSTOMER_CONTACT_TEMPLATE,
  DELETE_CONTACT_TEMPLATE,
  GET_CONTACT_TEMPLATES,
  SET_CREATE_CONTACT_TEMPLATE,
  UPDATE_CONTACT_TEMPLATE,
} from "redux/constants/ContactTemplate"
import ContactTemplateService from "services/ContactTemplateService"

const setErrorMessage = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* createContactTemplateAPI() {
  yield takeEvery(CREATE_CONTACT_TEMPLATE, function* ({ payload }) {
    try {
      yield put(setLoading(true))
      const data = yield call(
        ContactTemplateService.createContactTemplate,
        payload
      )
      yield put(
        createLoginActivity(`Add Contact Template in Rewards Contact Template. TemplateId - ${data?.data?.TemplateId}`)
      )
      data.type = SET_CREATE_CONTACT_TEMPLATE
      yield put(setCreateContactTemplate(data))
    } catch (err) {
      const message = err.response.data.message ?? "Something went wrong."
      yield put(setError({ message, action: "create" }))
      setErrorMessage("Create Contact Template Failed", message)
    }
  })
}

export function* updateContactTemplateAPI() {
  yield takeEvery(UPDATE_CONTACT_TEMPLATE, function* ({ payload }) {
    try {
      yield put(setLoading(true))
      const data = yield call(
        ContactTemplateService.updateContactTemplate,
        payload
      )
      data.id = payload.id
      yield put(
        createLoginActivity(`Update Contact Template in Rewards Contact Template. TemplateId - ${payload.id}`)
      )
      yield put(setUpdateContactTemplate(data))
    } catch (err) {
      const message = err.response.data.message ?? "Something went wrong."
      yield put(setError({ message, action: "update" }))
      setErrorMessage("Update Contact Template Failed", message)
    }
  })
}

export function* deleteContactTemplateAPI() {
  yield takeEvery(DELETE_CONTACT_TEMPLATE, function* ({ payload }) {
    try {
      const data = yield call(
        ContactTemplateService.deleteContactTemplate,
        payload
      )
      data.id = payload
      yield put(
        createLoginActivity(`Delete Contact Template in Rewards Contact Template. TemplateId - ${payload}`)
      )
      yield put(setDeleteContactTemplate(data))
      setNotification({
        type: "success",
        message: data?.data?.message ?? "Deleted.",
      })
    } catch (err) {
      setErrorMessage(
        "Delete Contact Template Failed",
        err.response.data.message ?? ""
      )
    }
  })
}

export function* getContactTemplateAPI() {
  yield takeEvery(GET_CONTACT_TEMPLATE, function* ({ payload }) {
    try {
      yield put(setLoading(true))
      const data = yield call(
        ContactTemplateService.getContactTemplate,
        payload
      )
      yield put(setGetContactTemplate(data))
    } catch (err) {
      const message = err.response.data.message ?? "Something went wrong."
      yield put(setError({ message, action: "get" }))
      setErrorMessage("Get Contact Template Failed", message)
    }
  })
}

export function* getCustomerContactTemplateAPI() {
  yield takeEvery(GET_CUSTOMER_CONTACT_TEMPLATE, function* ({ payload }) {
    try {
      yield put(setLoading(true))
      const data = yield call(
        ContactTemplateService.getCustomerContactTemplate,
        payload
      )

      yield put(setGetCustomerContactTemplate(data))
      if (data.data.rows.length) {
        if (payload.search.ContactType === "Email") {
          yield put(
            createLoginActivity(
              `Send Email for Customer Rewards in View Customer. ReferenceCode - ${payload.search.ReferenceCode}`
            )
          )
        } else {
          yield put(
            createLoginActivity(
              `Send SMS for Customer Rewards in View Customer. ReferenceCode - ${payload.search.ReferenceCode}`
            )
          )
        }

        setNotification({
          type: "success",
          message: `Success Sending ${payload.search.ContactType} Notification`,
        })
      } else {
        setNotification({
          type: "error",
          message: "No configuration found for this reward",
        })
      }
    } catch (err) {
      const message = err.response.data.message ?? "Something went wrong."
      yield put(setError({ message, action: "get" }))
      setErrorMessage("Get Customer Contact Template Failed", message)
    }
  })
}

export function* listContactTemplatesAPI() {
  yield takeEvery(GET_CONTACT_TEMPLATES, function* ({ payload }) {
    try {
      const data = yield call(
        ContactTemplateService.getContactTemplates,
        payload
      )

      yield put(setPageContactTemplate(payload.page))
      yield put(setGetContactTemplates(data))
    } catch (err) {
      const message = err.response.data.message ?? "Something went wrong."
      // yield put(setError(message))
      setErrorMessage("Listing Failed", message)
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(createContactTemplateAPI),
    fork(updateContactTemplateAPI),
    fork(getContactTemplateAPI),
    fork(getCustomerContactTemplateAPI),
    fork(deleteContactTemplateAPI),
    fork(listContactTemplatesAPI),
  ])
}
