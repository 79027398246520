import React from "react"
import { Collapse, Input, Select, Row, Col, Button } from "antd"
import { SearchOutlined, CloseOutlined } from "@ant-design/icons"
const { Option } = Select
const { Panel } = Collapse

const ProductGroupSearchSection = ({
  search,
  setSearch,
  productTypes,
  onSearch,
  onReset,
  // ProductGroupCodeList,
}) => {
  return (
    <>
      <Collapse
        defaultActiveKey={["1"]}
        expandIconPosition={"right"}
        className="mb-5"
      >
        <Panel header="SEARCH SECTION" key="1" className="">
          <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
            <Col className="gutter-row mb-2" span={4} md={4} xs={24}>
              <div>
                {/* <Select
                  style={{
                    width: "100%",
                    borderRadius: 1,
                  }}
                  value={search?.ProductCode}
                  onChange={(value) =>
                    setSearch({ ...search, ProductCode: value })
                  }
                  placeholder="Code"
                >
                  <Option value="All">All</Option>
                  {ProductGroupCodeList?.map((el, index) => (
                    <Option value={el.ProductCode} key={index}>
                      {el.ProductCode}
                    </Option>
                  ))}
                </Select> */}
                <Input
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  placeholder="Product Code"
                  value={search?.ProductCode}
                  onChange={(e) =>
                    setSearch({
                      ...search,
                      ProductCode: e.target.value,
                    })
                  }
                  maxLength={1000}
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </div>
            </Col>

            <Col className="gutter-row mb-2" span={4} md={4} xs={24}>
              <div>
                <Input
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  placeholder="Product Name"
                  value={search?.ProductName}
                  onChange={(e) =>
                    setSearch({
                      ...search,
                      ProductName: e.target.value,
                    })
                  }
                  maxLength={1000}
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </div>
            </Col>

            <Col className="gutter-row mb-2" span={4} md={4} xs={24}>
              <div>
                <Select
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  onChange={(value) =>
                    setSearch({
                      ...search,
                      ProductType: value,
                    })
                  }
                >
                  <Option value="All">All</Option>
                  {productTypes?.map((productType) => (
                    <Select.Option value={productType.Name}>
                      {productType.Name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>

            <Col className="mb-2" lg={6} md={4} xs={24}>
              <div className="d-flex">
                <Select
                  style={{
                    width: "84%",
                    borderRadius: "10px",
                    marginRight: "1rem",
                  }}
                  placeholder="Status"
                  onChange={(value) => {
                    setSearch({ ...search, Status: value })
                  }}
                  value={search?.Status}
                >
                  <Option value="" disabled>
                    Status
                  </Option>
                  <Option value="All">All</Option>
                  <Option value="Active">Active</Option>
                  <Option value="Inactive">Inactive</Option>
                </Select>

                <Button
                  className="mr-2"
                  shape="circle"
                  icon={<SearchOutlined />}
                  onClick={onSearch}
                />

                <Button
                  shape="circle"
                  icon={<CloseOutlined />}
                  onClick={onReset}
                />
              </div>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  )
}

export default ProductGroupSearchSection
