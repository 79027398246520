import setNotification from "components/shared-components/Notification"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { resultRewardObject } from "redux/actions/RewardObject"
import {
  GET_LIST_REWARD_OBJECT,
  SET_LIST_REWARD_OBJECT,
} from "redux/constants/RewardObject"
import RewardObjectService from "services/RewardObjectService"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* listRewardObjectAPI() {
  yield takeEvery(GET_LIST_REWARD_OBJECT, function* ({ payload }) {
    try {
      const data = yield call(RewardObjectService.getRewardObject, payload)
      data.type = SET_LIST_REWARD_OBJECT
      data.ObjectType = payload?.search?.ObjectType
      yield put(resultRewardObject(data))
    } catch (err) {
      setError("Listing Failed", err.response.data.message ?? "")
    }
  })
}

export default function* rootSaga() {
  yield all([fork(listRewardObjectAPI)])
}
