export const SET_IS_LOADING = "SET_IS_LOADING"

export const GET_TRANSACTION_SUMMARY = "GET_TRANSACTION_SUMMARY"
export const GET_TRANSACTION_SUMMARY_LIST = "GET_TRANSACTION_SUMMARY_LIST"
export const GET_CUSTOMER_TRANSACTION_REPORT_LIST =
  "GET_CUSTOMER_TRANSACTION_REPORT_LIST"
export const CREATE_TRANSACTION_SUMMARY = "CREATE_TRANSACTION_SUMMARY"
export const UPDATE_TRANSACTION_SUMMARY = "UPDATE_TRANSACTION_SUMMARY"
export const RESULT_TRANSACTION_SUMMARY = "RESULT_TRANSACTION_SUMMARY"
export const REMOVE_TRANSACTION_SUMMARY = "REMOVE_TRANSACTION_SUMMARY"
export const ALERT_TRANSACTION_SUMMARY = "ALERT_TRANSACTION_SUMMARY"
export const REMOVE_TRANSACTION_SUMMARY_ALERT =
  "REMOVE_TRANSACTION_SUMMARY_ALERT"
export const REMOVE_SELECTED_TRANSACTION_SUMMARY =
  "REMOVE_SELECTED_TRANSACTION_SUMMARY"

export const GET_TRANSACTION_DETAIL = "GET_TRANSACTION_DETAIL"
export const GET_TRANSACTION_DETAIL_LIST = "GET_TRANSACTION_DETAIL_LIST"
export const CREATE_TRANSACTION_DETAIL = "CREATE_TRANSACTION_DETAIL"
export const UPDATE_TRANSACTION_DETAIL = "UPDATE_TRANSACTION_DETAIL"
export const RESULT_TRANSACTION_DETAIL = "RESULT_TRANSACTION_DETAIL"
export const ALERT_TRANSACTION_DETAIL = "ALERT_TRANSACTION_DETAIL"
export const REMOVE_TRANSACTION_DETAIL_ALERT = "REMOVE_TRANSACTION_DETAIL_ALERT"
