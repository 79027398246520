import {
  GET_LIST_POINT_STRUCTURE,
  CREATE_POINT_STRUCTURE,
  GET_RESULT_POINT_STRUCTURE,
  GET_POINT_STRUCTURE,
  UPDATE_POINT_STRUCTURE,
  DELETE_POINT_STRUCTURE,
  RESULT_POINT_STRUCTURE,
  ALERT_POINT_STRUCTURE,
  REMOVE_POINT_STRUCTURE_ALERT,
} from "redux/constants/PointStructure"

export const createPointStructure = (data) => {
  return {
    type: CREATE_POINT_STRUCTURE,
    payload: data,
  }
}

export const getListPointStructure = (data) => {
  return {
    type: GET_LIST_POINT_STRUCTURE,
    payload: data,
  }
}

export const getResultPointStructure = (data) => {
  return {
    type: GET_RESULT_POINT_STRUCTURE,
    payload: data,
  }
}

export const getPointStructure = (data) => {
  return {
    type: GET_POINT_STRUCTURE,
    payload: data,
  }
}

export const updatePointStructure = (data) => {
  return {
    type: UPDATE_POINT_STRUCTURE,
    payload: data,
  }
}

export const deletePointStructure = (data) => {
  return {
    type: DELETE_POINT_STRUCTURE,
    payload: data,
  }
}

export const resultPointStructure = (data) => {
  return {
    type: RESULT_POINT_STRUCTURE,
    payload: data,
  }
}

export const alertPointStructure = (alert) => {
  return {
    type: ALERT_POINT_STRUCTURE,
    payload: alert,
  }
}

export const removePointStructureAlert = () => {
  return {
    type: REMOVE_POINT_STRUCTURE_ALERT,
  }
}
