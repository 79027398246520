import {
  GET_LIST_STOCK_GROUP,
  GET_SETTINGS,
  SET_LIST_STOCK_GROUP,
  SET_SETTINGS,
} from "redux/constants/Setting"

export const getListStockGroup = (data) => {
  return {
    type: GET_LIST_STOCK_GROUP,
    payload: data,
  }
}

export const setListStockGroup = (data) => {
  return {
    type: SET_LIST_STOCK_GROUP,
    payload: data,
  }
}

export const getSettings = (data) => {
  return {
    type: GET_SETTINGS,
    payload: data,
  }
}

export const setSettings = (data) => {
  return {
    type: SET_SETTINGS,
    payload: data,
  }
}
