import axios from "axios"
import { API_BASE_URL } from "configs/AppConfig"
import { AUTH_TOKEN } from "redux/constants/Auth"

const host = API_BASE_URL

const PointAdjustmentService = {}

PointAdjustmentService.createPointAdjustment = function (data) {
  return axios({
    url: host + "/point/adjustments/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

PointAdjustmentService.getPointAdjustments = function (data) {
  return axios({
    url: host + "/point/adjustments/find-all",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    method: "post",
    data: {
      Rows: data.rows ?? 20,
      Page: data.page ?? 1,
      Search: JSON.stringify(data.search ?? {}),
      SortDirection: "desc",
    },
  })
}

PointAdjustmentService.getPointAdjustment = function (data) {
  return axios({
    url: host + `/point/adjustments/find-one`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    method: "post",
    data: { HistoryId: data.HistoryId },
  })
}

PointAdjustmentService.updatePointAdjustment = function (data) {
  return axios({
    url: host + `/point/adjustments/update`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    method: "post",
    data,
  })
}

PointAdjustmentService.deletePointAdjustment = function (data) {
  return axios({
    url: host + `/point/adjustments/delete`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    method: "post",
    data: { HistoryId: data.HistoryId },
  })
}

export default PointAdjustmentService
