import {
  CREATE_FEEDBACK_FEEDBACK,
  DELETE_FEEDBACK_FEEDBACK,
  GET_LIST_FEEDBACK_FEEDBACK,
  SET_CREATE_FEEDBACK_FEEDBACK,
  SET_DELETE_FEEDBACK_FEEDBACK,
  SET_LIST_FEEDBACK_FEEDBACK,
  SET_UPDATE_FEEDBACK_FEEDBACK,
  UPDATE_FEEDBACK_FEEDBACK,
  GET_FEEDBACK_FEEDBACK_DETAIL,
  SET_FEEDBACK_FEEDBACK_DETAIL,
} from "redux/constants/Feedback"

export const createFeedbackFeedback = (data) => {
  return {
    type: CREATE_FEEDBACK_FEEDBACK,
    payload: data,
  }
}

export const setCreateFeedbackFeedback = (data) => {
  return {
    type: SET_CREATE_FEEDBACK_FEEDBACK,
    payload: data,
  }
}

export const getListFeedbackFeedback = (data) => {
  return {
    type: GET_LIST_FEEDBACK_FEEDBACK,
    payload: data,
  }
}

export const setListFeedbackFeedback = (data) => {
  return {
    type: SET_LIST_FEEDBACK_FEEDBACK,
    payload: data,
  }
}

export const updateFeedbackFeedback = (data) => {
  return {
    type: UPDATE_FEEDBACK_FEEDBACK,
    payload: data,
  }
}

export const setUpdateFeedbackFeedback = (data) => {
  return {
    type: SET_UPDATE_FEEDBACK_FEEDBACK,
    payload: data,
  }
}

export const deleteFeedbackFeedback = (data) => {
  return {
    type: DELETE_FEEDBACK_FEEDBACK,
    payload: data,
  }
}

export const setDeleteFeedbackFeedback = (data) => {
  return {
    type: SET_DELETE_FEEDBACK_FEEDBACK,
    payload: data,
  }
}

export const getFeedbackFeedbackDetail = (data) => {
  return {
    type: GET_FEEDBACK_FEEDBACK_DETAIL,
    payload: data,
  }
}

export const setFeedbackFeedbackDetail = (data) => {
  return {
    type: SET_FEEDBACK_FEEDBACK_DETAIL,
    payload: data,
  }
}
