import setNotification from "components/shared-components/Notification"
import { isArray } from "lodash"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import { resultReward, setPageReward } from "redux/actions/Reward"
import {
  CREATE_REWARD,
  DELETE_REWARD,
  GET_LIST_REWARD,
  GET_REWARD,
  SET_CREATE_REWARD,
  SET_DELETE_REWARD,
  SET_LIST_REWARD,
  SET_REWARD,
  UPDATE_REWARD,
} from "redux/constants/Reward"
import CommonService from "services/CommonService"
import RewardInventoryService from "services/RewardInventoryService"
import RewardObjectService from "services/RewardObjectService"
import RewardService from "services/RewardService"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* listRewardAPI() {
  yield takeEvery(GET_LIST_REWARD, function* ({ payload }) {
    try {
      const data = yield call(RewardService.getListReward, payload)
      data.type = SET_LIST_REWARD
      yield put(setPageReward(payload.page))
      yield put(resultReward(data))
    } catch (err) {
      setError("Listing Failed", err.response.data.message ?? "")
    }
  })
}

export function* getRewardAPI() {
  yield takeEvery(GET_REWARD, function* ({ payload }) {
    try {
      const data = yield call(RewardService.getReward, payload)
      data.type = SET_REWARD
      yield put(resultReward(data))
    } catch (err) {
      setError("Get Reward Failed", err.response.data.message ?? "")
    }
  })
}

export function* deleteRewardAPI() {
  yield takeEvery(DELETE_REWARD, function* ({ payload }) {
    try {
      const data = yield call(RewardService.deleteReward, payload)
      yield put(
        createLoginActivity(`Delete Reward in Rewards. RewardId - ${payload}`)
      )

      data.type = SET_DELETE_REWARD
      data.id = payload
      yield put(resultReward(data))
      setNotification({
        type: "success",
        message: data?.data?.message ?? "Deleted.",
      })
    } catch (err) {
      setError("Delete Reward Failed", err.response.data.message ?? "")
    }
  })
}

export function* createRewardAPI() {
  yield takeEvery(CREATE_REWARD, function* ({ payload }) {
    try {
      const {
        reward,
        rewardImages,
        rewardGroupList,
        rewardOutlets,
        rewardInventories,
      } = payload
      const data = yield call(RewardService.createReward, reward)

      for (let i = 0; i < rewardImages?.length; i++) {
        const imageFile = rewardImages[i]
        imageFile.append("ObjectId", data?.data?.RewardId)
        yield call(CommonService.uploadImageMediasInternal, imageFile)
      }

      for (let i = 0; i < rewardGroupList?.length; i++) {
        const { key } = rewardGroupList[i]
        yield call(RewardObjectService.createRewardObject, {
          RewardId: data?.data?.RewardId,
          ObjectType: "ProntoStockGroup",
          ObjectValue: key,
          Status: "Active",
        })
      }

      for (let i = 0; i < rewardOutlets?.length; i++) {
        const { key } = rewardOutlets[i]
        yield call(RewardObjectService.createRewardObject, {
          RewardId: data?.data?.RewardId,
          ObjectType: "Building",
          ObjectValue: key,
          Status: "Active",
        })
      }

      for (let i = 0; i < rewardInventories?.length; i++) {
        yield call(RewardInventoryService.createRewardInventory, {
          ...rewardInventories[i],
          RewardId: data?.data?.RewardId,
          Status: "Active",
        })
      }

      yield put(
        createLoginActivity(
          `Add Reward in Rewards. RewardId - ${data?.data?.RewardId}`
        )
      )

      data.type = SET_CREATE_REWARD
      yield put(resultReward(data))
    } catch (err) {
      setError(
        "Create Reward Failed",
        isArray(err?.response?.data?.message)
          ? err?.response?.data?.message[0]
          : err?.response?.data?.message ?? ""
      )
    }
  })
}

export function* updateRewardAPI() {
  yield takeEvery(UPDATE_REWARD, function* ({ payload }) {
    try {
      const {
        id,
        reward,
        rewardImages,
        rewardGroupList,
        rewardOutlets,
        rewardInventories,
      } = payload
      const data = yield call(RewardService.updateReward, { id, data: reward })

      for (let i = 0; i < rewardImages?.length; i++) {
        const imageFile = rewardImages[i]
        imageFile.append("ObjectId", data?.data?.RewardId)
        yield call(CommonService.uploadImageMediasInternal, imageFile)
      }

      for (let i = 0; i < rewardGroupList?.length; i++) {
        const { RewardObjectId, key, chosen, isDeleted } = rewardGroupList[i]
        if (isDeleted)
          yield call(RewardObjectService.updateRewardObject, {
            id: RewardObjectId,
            data: {
              RewardId: data?.data?.RewardId,
              ObjectType: "ProntoStockGroup",
              ObjectValue: key,
              Status: "Deleted",
            },
          })
        else if (!chosen)
          yield call(RewardObjectService.createRewardObject, {
            RewardId: data?.data?.RewardId,
            ObjectType: "ProntoStockGroup",
            ObjectValue: key,
            Status: "Active",
          })
      }

      for (let i = 0; i < rewardOutlets?.length; i++) {
        const { RewardObjectId, key, chosen, isDeleted } = rewardOutlets[i]
        if (isDeleted)
          yield call(RewardObjectService.updateRewardObject, {
            id: RewardObjectId,
            data: {
              RewardId: data?.data?.RewardId,
              ObjectType: "Building",
              ObjectValue: key,
              Status: "Deleted",
            },
          })
        else if (!chosen)
          yield call(RewardObjectService.createRewardObject, {
            RewardId: data?.data?.RewardId,
            ObjectType: "Building",
            ObjectValue: key,
            Status: "Active",
          })
      }

      for (let i = 0; i < rewardInventories?.length; i++) {
        if (rewardInventories[i]?.InventoryId) {
          yield call(
            RewardInventoryService.updateRewardInventory,
            rewardInventories[i]
          )
        } else {
          yield call(RewardInventoryService.createRewardInventory, {
            ...rewardInventories[i],
            RewardId: data?.data?.RewardId,
            Status: "Active",
          })
        }
      }

      yield put(
        createLoginActivity(
          `Update Reward in Rewards. RewardId - ${data?.data?.RewardId}`
        )
      )

      data.type = SET_CREATE_REWARD
      yield put(resultReward(data))
    } catch (err) {
      setError(
        "Create Reward Failed",
        isArray(err?.response?.data?.message)
          ? err?.response?.data?.message[0]
          : err?.response?.data.message ?? ""
      )
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listRewardAPI),
    fork(getRewardAPI),
    fork(deleteRewardAPI),
    fork(createRewardAPI),
    fork(updateRewardAPI),
  ])
}
