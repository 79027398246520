import setNotification from "components/shared-components/Notification"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { setListStockGroup, setSettings } from "redux/actions/Setting"
import { GET_LIST_STOCK_GROUP, GET_SETTINGS } from "redux/constants/Setting"
import SettingService from "services/SettingService"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* listStockGroupAPI() {
  yield takeEvery(GET_LIST_STOCK_GROUP, function* ({ payload }) {
    try {
      const data = yield call(SettingService.listStockGroups, payload)
      yield put(setListStockGroup(data))
    } catch (err) {
      setError("Listing Failed", err.response.data.message ?? "")
    }
  })
}

export function* getSettingsAPI() {
  yield takeEvery(GET_SETTINGS, function* ({ payload }) {
    try {
      const data = yield call(SettingService.getSettings, payload)
      yield put(setSettings(data))
    } catch (err) {
      setError("Listing Failed", err.response.data.message ?? "")
    }
  })
}

export default function* rootSaga() {
  yield all([fork(listStockGroupAPI), fork(getSettingsAPI)])
}
