import {
  CREATE_REWARD,
  DELETE_REWARD,
  GET_LIST_REWARD,
  GET_REWARD,
  RESET_REWARD_ALERT,
  RESULT_REWARD,
  SET_PAGE_REWARD,
  UPDATE_REWARD,
} from "redux/constants/Reward"

export const getListReward = (data) => {
  return {
    type: GET_LIST_REWARD,
    payload: data,
  }
}

export const getReward = (data) => {
  return {
    type: GET_REWARD,
    payload: data,
  }
}

export const deleteReward = (data) => {
  return {
    type: DELETE_REWARD,
    payload: data,
  }
}

export const createReward = (data) => {
  return {
    type: CREATE_REWARD,
    payload: data,
  }
}

export const updateReward = (data) => {
  return {
    type: UPDATE_REWARD,
    payload: data,
  }
}

export const setPageReward = (data) => {
  return {
    type: SET_PAGE_REWARD,
    payload: data,
  }
}

export const resetRewardAlert = (data) => {
  return {
    type: RESET_REWARD_ALERT,
    payload: data,
  }
}

export const resultReward = (data) => {
  return {
    type: RESULT_REWARD,
    payload: data,
  }
}
