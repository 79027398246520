import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  CREATE_POINT_ADJUSTMENT,
  GET_POINT_ADJUSTMENTS,
  GET_POINT_ADJUSTMENT,
  DELETE_POINT_ADJUSTMENT,
} from "../constants/PointAdjustment"
import {
  resultPointAdjustment,
  alertPointAdjustment,
} from "../actions/PointAdjustment"
import PointAdjustmentService from "services/PointAdjustmentService"
import { isArray } from "lodash"
import { createLoginActivity } from "redux/actions/Auth"

export function* createPointAdjustmentAPI() {
  yield takeEvery(CREATE_POINT_ADJUSTMENT, function* ({ payload }) {
    try {
      const point = yield call(
        PointAdjustmentService.createPointAdjustment,
        payload
      )
      if (point.alert) {
        yield put(
          alertPointAdjustment({ success: false, message: point.alert })
        )
      } else {
        yield put(
          createLoginActivity(
            `Adjust Point in Point Adjustment. HistoryId - ${point.data.HistoryId}`
          )
        )

        yield put(
          alertPointAdjustment({
            success: true,
            message: "Point Adjustment has been saved successfully.",
          })
        )
      }
    } catch (err) {
      yield put(
        alertPointAdjustment({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* getPointAdjustmentsAPI() {
  yield takeEvery(GET_POINT_ADJUSTMENTS, function* ({ payload }) {
    try {
      const point = yield call(
        PointAdjustmentService.getPointAdjustments,
        payload
      )
      if (point.alert) {
      } else {
        point.type = "LIST"
        yield put(resultPointAdjustment(point))
      }
    } catch (err) {}
  })
}

export function* getPointAdjustmentAPI() {
  yield takeEvery(GET_POINT_ADJUSTMENT, function* ({ payload }) {
    try {
      const point = yield call(
        PointAdjustmentService.getPointAdjustment,
        payload
      )
      if (point.alert) {
        yield put(alertPointAdjustment(point.alert))
      } else {
        yield put(resultPointAdjustment(point))
      }
    } catch (err) {
      yield put(alertPointAdjustment(err))
    }
  })
}

export function* deletePointAdjustmentAPI() {
  yield takeEvery(DELETE_POINT_ADJUSTMENT, function* ({ payload }) {
    try {
      const point = yield call(
        PointAdjustmentService.deletePointAdjustment,
        payload
      )
      if (point.alert) {
        yield put(
          alertPointAdjustment({ success: false, message: point.alert })
        )
      } else {
        point.type = "DELETE"
        point.data = payload
        yield put(resultPointAdjustment(point))
        yield put(
          alertPointAdjustment({
            success: true,
            message: "Point Adjustment has been deleted successfully.",
          })
        )
      }
    } catch (err) {
      yield put(alertPointAdjustment({ success: false, message: err }))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(createPointAdjustmentAPI),
    fork(getPointAdjustmentsAPI),
    fork(getPointAdjustmentAPI),
    fork(deletePointAdjustmentAPI),
  ])
}
