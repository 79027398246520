import setNotification from "components/shared-components/Notification"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  setCustomerActivityLoading,
  resultCustomerActivity,
} from "redux/actions/CustomerActivity"
import {
  GET_CUSTOMER_ACTIVITY_LIST,
  CREATE_CUSTOMER_ACTIVITY,
  UPDATE_CUSTOMER_ACTIVITY,
  DELETE_CUSTOMER_ACTIVITY,
} from "redux/constants/CustomerActivity"
import CustomerActivityService from "services/CustomerActivityService"

export function* getCustomerActivityListAPI() {
  yield takeEvery(GET_CUSTOMER_ACTIVITY_LIST, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      yield put(setCustomerActivityLoading(true))
      const group = yield call(
        CustomerActivityService.getCustomerActivityList,
        payload
      )
      if (group.alert) {
        // do nothing
      } else {
        group.type = "LIST"
        yield put(resultCustomerActivity(group))
      }
    } catch (err) {
      yield put(setCustomerActivityLoading(false))
    }
  })
}

export function* createCustomerActivityAPI() {
  yield takeEvery(CREATE_CUSTOMER_ACTIVITY, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(
        CustomerActivityService.createCustomerActivity,
        payload
      )
      if (group.alert) {
        setNotification({
          type: "error",
          message: "Create Activity Failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Add Customer Activity in View Customer. ActivityId - ${group.data.ActivityId}`
          )
        )

        payload.notification !== "hidden" &&
          setNotification({
            type: "success",
            message: "Create Activity Success",
          })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Create Activity Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Create Activity Failed",
          description: error.message,
        })
      }
    } finally {
      payload?.setIsFirstRender && payload.setIsFirstRender(true)
      payload?.handleClose && payload.handleClose()
    }
  })
}

export function* updateCustomerActivityAPI() {
  yield takeEvery(UPDATE_CUSTOMER_ACTIVITY, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(
        CustomerActivityService.updateCustomerActivity,
        payload
      )

      if (group.alert) {
        setNotification({
          type: "error",
          message: "Update Activity Failed",
          description: "An error occurred",
        })
      } else {
        yield put(
          createLoginActivity(
            `Update Customer Activity in View Customer. ActivityId - ${group.data.ActivityId}`
          )
        )

        setNotification({
          type: "success",
          message: "Activity Successfully Updated",
        })
      }
    } catch (err) {
      if (err?.response?.data) {
        const { message } = err.response.data
        setNotification({
          type: "error",
          message: "Update Activity Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Update Activity Failed",
          description: err.message,
        })
      }
    } finally {
      payload.setIsFirstRender(true)
      payload.handleClose()
    }
  })
}

export function* deleteCustomerActivityAPI() {
  yield takeEvery(DELETE_CUSTOMER_ACTIVITY, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(
        CustomerActivityService.deleteCustomerActivity,
        payload
      )
      if (group.alert) {
        setNotification({
          type: "error",
          message: "Create Activity Failed",
          description: "An error occurred",
        })
      } else {
        yield put(
          createLoginActivity(
            `Delete Customer Activity in View Customer. ActivityId - ${payload.ActivityId}`
          )
        )

        setNotification({
          type: "success",
          message: "Activity successfully deleted",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Delete Activity Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Delete Activity Failed",
          description: error.message,
        })
      }
    } finally {
      payload?.setIsFirstRender && payload.setIsFirstRender(true)
      payload?.setDeleteActivity && payload.setDeleteActivity({})
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getCustomerActivityListAPI),
    fork(createCustomerActivityAPI),
    fork(updateCustomerActivityAPI),
    fork(deleteCustomerActivityAPI),
  ])
}
