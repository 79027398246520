import {
  SET_IS_MEMBERSHIP_LOADING,
  REMOVE_MEMBERSHIPS_ALERT,
  RESULT_MEMBERSHIPS,
  ALERT_MEMBERSHIPS,
} from "redux/constants/Memberships"

const initialState = {
  isLoading: false,
  group: [],
  alert: {},
  pointStatement: {},
  pointSummary: [],
}

const Memberships = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_MEMBERSHIP_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case RESULT_MEMBERSHIPS:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          group: action.payload.data,
          isLoading: false,
        }
      } else if (action.payload.type === "POINT_STATEMENT_LIST") {
        return {
          ...state,
          pointStatement: action.payload,
          isLoading: false,
        }
      } else if (action.payload.type === "POINT_SUMMARY_REPORT_LIST") {
        return {
          ...state,
          pointSummary: action.payload.data.rows,
          isLoading: false,
        }
      } else if (action.payload.type === "CREATE") {
        return {
          ...state,
          group: {
            ...state.group,
            count: state.group.count + 1,
            rows: [action.payload.data, ...state.group.rows],
          },
        }
      } else if (action.payload.type === "UPDATE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: [
              ...state.group.rows.map((groupParam) => {
                if (groupParam.CardGroupId === action.payload.data.GroupId) {
                  return {
                    ...groupParam,
                    Name: action.payload.data.Name,
                    Description: action.payload.data.Description,
                    Status: action.payload.data.Status,
                  }
                } else {
                  return groupParam
                }
              }),
            ],
          },
        }
      } else if (action.payload.type === "DELETE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: [
              ...state.group.rows.map((groupParam) => {
                if (groupParam.CardGroupId === action.payload.data.GroupId) {
                  return {
                    ...groupParam,
                    Status: "Deleted",
                  }
                } else {
                  return groupParam
                }
              }),
            ],
          },
        }
      }
      break

    case ALERT_MEMBERSHIPS:
      return {
        ...state,
        alert: {
          status: true,
          success: action.payload.success,
          message: action.payload.message,
        },
        isLoading: false,
      }
    case REMOVE_MEMBERSHIPS_ALERT:
      return {
        ...state,
        alert: {
          status: false,
        },
      }
    default:
      return state
  }
}

export default Memberships
