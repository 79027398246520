import {
  RESULT_MEMBERSHIP_LEVEL,
  ALERT_MEMBERSHIP_LEVEL,
  REMOVE_MEMBERSHIP_LEVEL_ALERT,
} from "redux/constants/MembershipLevel"

const initialState = {
  level: [],
  alert: {},
}
const MembershipLevel = (state = initialState, action) => {
  switch (action.type) {
    case RESULT_MEMBERSHIP_LEVEL:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          level: action.payload.data,
        }
      } else if (action.payload.type === "CREATE") {
        return {
          ...state,
          level: {
            ...state.level,
            count: state.level.count + 1,
            rows: [action.payload.data, ...state.level.rows],
          },
        }
      }
      break

    case ALERT_MEMBERSHIP_LEVEL:
      return {
        ...state,
        alert: {
          status: true,
          success: action.payload.success,
          message: action.payload.message,
        },
      }

    case REMOVE_MEMBERSHIP_LEVEL_ALERT:
      return {
        ...state,
        alert: {
          status: false,
        },
      }

    default:
      return state
  }
}

export default MembershipLevel
