import {
  CHANGE_OTP_EMAIL,
  SEND_EMAIL_VERIFICATION,
  VALIDATE_OTP_CODE,
  SET_SEND_EMAIL_LOADING,
  SET_VALIDATE_OTP_LOADING,
  SET_UPDATE_EMAIL_LOADING,
} from "redux/constants/CustomerEmailVerification"

export const sendEmailVerification = (data) => {
  return {
    type: SEND_EMAIL_VERIFICATION,
    payload: data,
  }
}

export const validateOtpCode = (data) => {
  return {
    type: VALIDATE_OTP_CODE,
    payload: data,
  }
}

export const changeOtpEmail = (data) => {
  return {
    type: CHANGE_OTP_EMAIL,
    payload: data,
  }
}

export const setSendEmailLoading = (isLoading) => {
  return {
    type: SET_SEND_EMAIL_LOADING,
    payload: isLoading,
  }
}

export const setValidateOtpLoading = (isLoading) => {
  return {
    type: SET_VALIDATE_OTP_LOADING,
    payload: isLoading,
  }
}

export const setUpdateEmailLoading = (isLoading) => {
  return {
    type: SET_UPDATE_EMAIL_LOADING,
    payload: isLoading,
  }
}
