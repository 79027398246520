export const SET_CUSTOMER_TASK_LOADING = "SET_CUSTOMER_TASK_LOADING"
export const GET_LIST_CUSTOMER_TASK = "GET_LIST_CUSTOMER_TASK"
export const CREATE_CUSTOMER_TASK = "CREATE_CUSTOMER_TASK"
export const GET_RESULT_CUSTOMER_TASK = "GET_RESULT_CUSTOMER_TASK"
export const GET_CUSTOMER_TASK = "GET_CUSTOMER_TASK"
export const UPDATE_CUSTOMER_TASK = "UPDATE_CUSTOMER_TASK"
export const DELETE_CUSTOMER_TASK = "DELETE_CUSTOMER_TASK"
export const RESULT_CUSTOMER_TASK = "RESULT_CUSTOMER_TASK"
export const ALERT_CUSTOMER_TASK = "ALERT_CUSTOMER_TASK"
export const REMOVE_CUSTOMER_TASK_ALERT = "REMOVE_CUSTOMER_TASK_ALERT"
export const UPDATE_CUSTOMER_TASK_AND_PROGRESS_LOGS =
  "UPDATE_CUSTOMER_TASK_AND_PROGRESS_LOGS"
