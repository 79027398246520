export const GET_LIST_CAMPAIGN = "GET_LIST_CAMPAIGN"
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN"
export const GET_RESULT_CAMPAIGN = "GET_RESULT_CAMPAIGN"
export const GET_CAMPAIGN = "GET_CAMPAIGN"
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN"
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN"
export const RESULT_CAMPAIGN = "RESULT_CAMPAIGN"
export const SET_CAMPAIGN_LIST = "SET_CAMPAIGN_LIST"
export const SET_PAGE_ROW = "SET_PAGE_ROW"
export const SET_CAMPAIGN_DETAIL = "SET_CAMPAIGN_DETAIL"
