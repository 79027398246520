export const SET_TASK_PROGRESS_LOADING = "SET_TASK_PROGRESS_LOADING"
export const GET_LIST_TASK_PROGRESS = "GET_LIST_TASK_PROGRESS"
export const CREATE_TASK_PROGRESS = "CREATE_TASK_PROGRESS"
export const GET_RESULT_TASK_PROGRESS = "GET_RESULT_TASK_PROGRESS"
export const GET_TASK_PROGRESS = "GET_TASK_PROGRESS"
export const UPDATE_TASK_PROGRESS = "UPDATE_TASK_PROGRESS"
export const DELETE_TASK_PROGRESS = "DELETE_TASK_PROGRESS"
export const RESULT_TASK_PROGRESS = "RESULT_TASK_PROGRESS"
export const ALERT_TASK_PROGRESS = "ALERT_TASK_PROGRESS"
export const REMOVE_TASK_PROGRESS_ALERT = "REMOVE_TASK_PROGRESS_ALERT"
