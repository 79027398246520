import fetch from "auth/APIFetchInterceptor"

const CardCategoryService = {}

CardCategoryService.createCardCategory = async function (data) {
  const cardCategoryPayload = data.cardCategory
  const cardLevelPayload = data.cardLevel
  const CardCategoryId = sessionStorage.getItem("CardCategoryId")
  let cardCategory = null
  if (CardCategoryId) {
    cardCategory = await fetch({
      url: `/member/card-categories/update`,
      method: "post",
      headers: {
        Authorization: `Bearer ${data.access_token}`,
      },
      data: {
        ...cardCategoryPayload,
        LevelSequence: parseInt(cardCategoryPayload.LevelSequence),
        CardCategoryId,
      },
    })
  } else {
    cardCategory = await fetch({
      url: "/member/card-categories/add",
      method: "post",
      headers: {
        Authorization: `Bearer ${data.access_token}`,
      },
      data: {
        ...cardCategoryPayload,
        LevelSequence: parseInt(cardCategoryPayload.LevelSequence),
      },
    })
    sessionStorage.setItem("CardCategoryId", cardCategory.data.CardCategoryId)
  }

  const cardLevel = await fetch({
    url: "/member/card-levels/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: {
      ...cardLevelPayload,
      CardCategoryId: cardCategory.data.CardCategoryId,
    },
  })

  if (cardLevel) {
    sessionStorage.removeItem("CardCategoryId")
  }

  return cardCategory
}

CardCategoryService.getListCardCategory = function (data) {
  let searchquery = {}
  if (!(data.Status === "" || data.Status === "All"))
    searchquery.Status = data.Status
  if (!(data.Description === "" || data.Description === "All"))
    searchquery.Description = data.Description
  if (!(data.Code === "" || data.Code === "All")) searchquery.Code = data.Code
  if (!(data.Color === "" || data.Color === "All"))
    searchquery.Color = data.Color
  if (!(data.CardGroupId === "" || data.CardGroupId === "All"))
    searchquery.CardGroupId = data.CardGroupId

  return fetch({
    url: "/member/card-categories/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ?? 20,
      Page: data.page ?? 1,
      Search: JSON.stringify(searchquery ?? "{}"),
    },
  })
}

CardCategoryService.getCardCategory = function (data) {
  return fetch({
    url: `/member/card-categories/find-one`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { CardCategoryId: data.CardCategoryId },
  })
}

CardCategoryService.updateCardCategory = function (data) {
  return fetch({
    url: `/member/card-categories/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: {
      ...data,
      LevelSequence: parseInt(data.LevelSequence),
      CardCategoryId: data.CardCategoryId,
    },
    method: "post",
  })
}

CardCategoryService.deleteCardCategory = function (data) {
  return fetch({
    url: `/member/card-categories/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { CardCategoryId: data.CardCategoryId },
  })
}

CardCategoryService.createCardLevel = function (data) {
  return fetch({
    url: `/member/card-levels/add`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

CardCategoryService.updateCardLevel = function (data) {
  return fetch({
    url: `/member/card-levels/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: {
      ...data,
      MaxRedemptionLimit: parseFloat(data.MaxRedemptionLimit).toFixed(2),
      AccumulateFrom: parseFloat(data.AccumulateFrom).toFixed(2),
      AccumulateTo: parseFloat(data.AccumulateTo).toFixed(2),
      CardLevelId: data.CardLevelId,
    },
    method: "post",
  })
}

CardCategoryService.deleteCardCardLevel = function (data) {
  return fetch({
    url: `/member/card-levels/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { CardLevelId: data.CardLevelId },
  })
}

export default CardCategoryService
