export const SET_CUSTOMER_ADDRESS_LOADING =
  "SET_CUSTOMER_ADDRESS_LOADING"

export const CREATE_CUSTOMER_ADDRESS = "CREATE_CUSTOMER_ADDRESS"
export const SET_CREATE_CUSTOMER_ADDRESS = "SET_CREATE_CUSTOMER_ADDRESS"

export const GET_CUSTOMER_ADDRESS_LIST =
  "GET_CUSTOMER_ADDRESS_LIST"
export const SET_CUSTOMER_ADDRESS_LIST =
  "SET_CUSTOMER_ADDRESS_LIST"

export const GET_CUSTOMER_ADDRESS_DETAIL = "GET_CUSTOMER_ADDRESS_DETAIL"
export const SET_CUSTOMER_ADDRESS_DETAIL = "SET_CUSTOMER_ADDRESS_DETAIL"

export const UPDATE_CUSTOMER_ADDRESS = "UPDATE_CUSTOMER_ADDRESS"
export const SET_UPDATE_CUSTOMER_ADDRESS = "SET_UPDATE_CUSTOMER_ADDRESS"

export const DELETE_CUSTOMER_ADDRESS = "DELETE_CUSTOMER_ADDRESS"
export const SET_DELETE_CUSTOMER_ADDRESS = "SET_DELETE_CUSTOMER_ADDRESS"

export const UNMOUNT_CUSTOMER_ADDRESS_DETAIL =
  "UNMOUNT_CUSTOMER_ADDRESS_DETAIL"