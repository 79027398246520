export const GET_REWARD = "GET_REWARD_CANCEL_COLLECT_REWARD"
export const SET_REWARD = "SET_REWARD_CANCEL_COLLECT_REWARD"
export const SET_REWARD_IMAGES = "SET_REWARD_IMAGES_CANCEL_COLLECT_REWARD"
export const SET_IS_CANCELLED = "SET_IS_CANCELLED_CANCEL_COLLECT_REWARD"

export const SET_IS_LOADING = "SET_IS_LOADING_CANCEL_COLLECT_REWARD"

export const ON_CANCEL_COLLECTED_REWARD = "ON_CANCEL_COLLECTED_REWARD"

export const ON_UPDATE_COLLECTED_REWARD = "ON_UPDATE_COLLECTED_REWARD"

export const RESET_CANCEL_COLLECT_REWARD = "RESET_CANCEL_COLLECT_REWARD"
