import React, { Fragment, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Menu, Grid, Tooltip } from "antd"
import IntlMessage from "../util-components/IntlMessage"
import Icon from "../util-components/Icon"
import navigationConfig from "configs/NavigationConfig"
import { connect, useSelector } from "react-redux"
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant"
import utils from "utils"
import { onMobileNavToggle } from "redux/actions/Theme"
import { FaSignOutAlt } from "react-icons/fa"

const { SubMenu } = Menu
const { useBreakpoint } = Grid

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString()

const setDefaultOpen = (key) => {
  let keyList = []
  let keyString = ""
  if (key) {
    const arr = key.split("-")
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index]
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`)
      keyList.push(keyString)
    }
  }
  return keyList
}

const setMenuItem = (navContent, roles, privileges) => {
  // reset navContent
  navContent[0].submenu.forEach((item) => {
    item.isEnabled = false
    item.submenu.forEach((subItem) => {
      subItem.isEnabled = false
    })
  })

  const mainMenu = navContent.find((menu) => menu.key === "Main Menu").submenu

  //check if mainMenu.subMenu.key is included in roles if yes set mainMenu.subMenu.isEnabled = true
  mainMenu.forEach((menu) => {
    if (menu.submenu.length > 0) {
      let isAtleastOneActive = false
      for (let i = 0; i < menu.submenu.length; i++) {
        if (roles.includes(menu.submenu[i].key)) {
          menu.submenu[i].isEnabled = true
          isAtleastOneActive = true
        }
      }
      if (isAtleastOneActive) {
        menu.isEnabled = true
      }
    } else {
      if (roles.includes(menu.key)) {
        menu.isEnabled = true
      }
    }
  })

  return navContent
}

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props
  const history = useHistory()
  const { roles, privileges } = useSelector((state) => state.auth)
  const [content, setContent] = useState([...navigationConfig])
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg")
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false)
    }
  }

  const tooltips = {
    "Contact Template": "Create Template",
    "Claim Rewards":
      "Redeem Voucher for New Member, Birthday, Top Spender & other",
    "Collect Rewards": "Collect Gift Premium",
    "Member Point Statement": "Point Summary",
  }

  useEffect(() => {
    let navContent = [...navigationConfig]
    setContent(setMenuItem(navContent, roles, privileges))
  }, [roles, privileges])

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {content.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={setLocale(localization, menu.title)}
          >
            {/* eslint-disable-next-line */}
            {menu.submenu.map((subMenuFirst) => {
              if (subMenuFirst.isEnabled) {
                return subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={setLocale(localization, subMenuFirst.title)}
                  >
                    {/* eslint-disable-next-line */}
                    {subMenuFirst.submenu.map((subMenuSecond) => {
                      const isWithTooltip = Object.keys(tooltips).includes(
                        subMenuSecond?.key
                      )

                      if (subMenuSecond.isEnabled) {
                        return (
                          <Menu.Item key={subMenuSecond.key}>
                            {isWithTooltip ? (
                              <Tooltip title={tooltips[subMenuSecond?.key]}>
                                {subMenuSecond.icon ? (
                                  <Icon type={subMenuSecond?.icon} />
                                ) : null}
                                <span>
                                  {setLocale(localization, subMenuSecond.title)}
                                </span>
                                <Link
                                  onClick={() => closeMobileNav()}
                                  to={subMenuSecond.path}
                                />
                              </Tooltip>
                            ) : (
                              <Fragment>
                                {subMenuSecond.icon ? (
                                  <Icon type={subMenuSecond?.icon} />
                                ) : null}
                                <span>
                                  {setLocale(localization, subMenuSecond.title)}
                                </span>
                                <Link
                                  onClick={() => closeMobileNav()}
                                  to={subMenuSecond.path}
                                />
                              </Fragment>
                            )}
                          </Menu.Item>
                        )
                      }
                    })}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : null}
                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                )
              }
            })}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        )
      )}
      <Menu.Item
        key={"Logout"}
        onClick={() => {
          closeMobileNav()
          history.push("/logout")
        }}
      >
        <Icon type={FaSignOutAlt} />
        <span>{setLocale(localization, "sidenav.main-menu.logout")}</span>
      </Menu.Item>
    </Menu>
  )
}

const TopNavContent = (props) => {
  const history = useHistory()
  const { topNavColor, localization } = props
  const { roles, privileges } = useSelector((state) => state.auth)
  const [content, setContent] = useState([...navigationConfig])

  useEffect(() => {
    let navContent = [...navigationConfig]
    setContent(setMenuItem(navContent, roles, privileges))
  }, [roles, privileges])

  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {/* eslint-disable-next-line */}
      {content.map((menu) => {
        if (menu.isEnabled === true) {
          return menu.submenu.length > 0 ? (
            <SubMenu
              key={menu.key}
              popupClassName="top-nav-menu"
              title={
                <span>
                  {menu.icon ? <Icon type={menu?.icon} /> : null}
                  <span>{setLocale(localization, menu.title)}</span>
                </span>
              }
            >
              {/* eslint-disable-next-line */}
              {menu.submenu.map((subMenuFirst) => {
                if (subMenuFirst.isEnabled === true) {
                  return subMenuFirst.submenu.length > 0 ? (
                    <SubMenu
                      key={subMenuFirst.key}
                      icon={
                        subMenuFirst.icon ? (
                          <Icon type={subMenuFirst?.icon} />
                        ) : null
                      }
                      title={setLocale(localization, subMenuFirst.title)}
                    >
                      {subMenuFirst.submenu.map((subMenuSecond) => (
                        <Menu.Item key={subMenuSecond.key}>
                          <span>
                            {setLocale(localization, subMenuSecond.title)}
                          </span>
                          <Link to={subMenuSecond.path} />
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item key={subMenuFirst.key}>
                      {subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null}
                      <span>{setLocale(localization, subMenuFirst.title)}</span>
                      <Link to={subMenuFirst.path} />
                    </Menu.Item>
                  )
                }
              })}
            </SubMenu>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? <Link to={menu.path} /> : null}
            </Menu.Item>
          )
        }
      })}
      <Menu.Item
        key={"Logout"}
        onClick={() => {
          history.push("/logout")
        }}
      >
        <Icon type={FaSignOutAlt} />
        <span>{setLocale(localization, "sidenav.main-menu.logout")}</span>
      </Menu.Item>
    </Menu>
  )
}

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  )
}

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme
  return { sideNavTheme, topNavColor }
}

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent)
