import {
  GET_COLLECTED_REWARD,
  ON_COLLECT_REWARD,
  ON_UPDATE_COLLECTED_REWARD,
  SET_COLLECTED_REWARD,
  SET_COLLECTED_REWARD_IMAGES,
  SET_IS_COLLECTED_REWARD,
  SET_IS_LOADING_COLLECTED_REWARD,
} from "redux/constants/CollectReward"

export const getCollectedReward = (data) => {
  return {
    type: GET_COLLECTED_REWARD,
    payload: data,
  }
}

export const setCollectedReward = (data) => {
  return {
    type: SET_COLLECTED_REWARD,
    payload: data,
  }
}

export const setIsLoading = (isLoading) => {
  return {
    type: SET_IS_LOADING_COLLECTED_REWARD,
    payload: isLoading,
  }
}

export const setIsCollected = (isCollected) => {
  return {
    type: SET_IS_COLLECTED_REWARD,
    payload: isCollected,
  }
}

export const setCollectedRewardImages = (data) => {
  return {
    type: SET_COLLECTED_REWARD_IMAGES,
    payload: data,
  }
}

export const onCollectReward = (data) => {
  return {
    type: ON_COLLECT_REWARD,
    payload: data,
  }
}

export const onUpdateCollectedReward = (data) => {
  return {
    type: ON_UPDATE_COLLECTED_REWARD,
    payload: data,
  }
}
