import axios from "axios"
import { API_BASE_URL, COMMON_API_BASE_URL } from "configs/AppConfig"

const fetch = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
})

const commonFetch = axios.create({
  baseURL: COMMON_API_BASE_URL,
  timeout: 60000,
})

const CustomerEmailService = {}

CustomerEmailService.sendEmailVerificationCode = function (data) {
  return fetch({
    url: "/customer/customers/email-verification/send",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data,
  })
}

CustomerEmailService.validateOtpCode = function (data) {
  return commonFetch({
    url: "/passcodes/validate",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    data,
  })
}

CustomerEmailService.updateEmailSetting = function (data) {
  return fetch({
    url: "/customer/email/update",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data,
  })
}

export default CustomerEmailService
