export const GET_CLAIM_REWARD = "GET_CLAIM_REWARD"
export const SET_CLAIM_REWARD = "SET_CLAIM_REWARD"

export const ON_CLAIM_REWARD = "ON_CLAIM_REWARD"
export const SET_ON_CLAIM_REWARD = "SET_ON_CLAIM_REWARD"

export const SET_IS_LOADING = "SET_IS_LOADING"
export const RESULT_CLAIM_REWARD = "RESULT_CLAIM_REWARD"
export const RESET_CLAIM_REWARD_ALERT = "RESET_CLAIM_REWARD_ALERT"
export const RESET_CLAIM_REWARD = "RESET_CLAIM_REWARD"
