import {
  IS_VOUCHER_LOADING,
  ALERT_VOUCHERS,
  CREATE_VOUCHERS,
  GET_LIST_VOUCHERS,
  GET_RESULT_VOUCHERS,
  DELETE_VOUCHER,
  UPDATE_VOUCHER,
  UPDATE_VOUCHER_BATCH,
  REMOVE_VOUCHERS_ALERT,
  RESULT_VOUCHERS,
  SET_LIST_VOUCHERS,
  SET_DELETE_VOUCHER,
  SET_UPDATE_VOUCHER,
} from "redux/constants/Voucher"

export const setIsVoucherLoading = (payload) => {
  return {
    type: IS_VOUCHER_LOADING,
    payload,
  }
}

export const getListVouchers = (data) => {
  return {
    type: GET_LIST_VOUCHERS,
    payload: data,
  }
}

export const setListVouchers = (data) => {
  return {
    type: SET_LIST_VOUCHERS,
    payload: data,
  }
}

export const getResultVouchers = (data) => {
  return {
    type: GET_RESULT_VOUCHERS,
    payload: data,
  }
}

export const updateVoucher = (data) => {
  return {
    type: UPDATE_VOUCHER,
    payload: data,
  }
}

export const updateVoucherBatch = (data) => {
  return {
    type: UPDATE_VOUCHER_BATCH,
    payload: data,
  }
}

export const setUpdateVoucher = (data) => {
  return {
    type: SET_UPDATE_VOUCHER,
    payload: data,
  }
}

export const deleteVoucher = (data) => {
  return {
    type: DELETE_VOUCHER,
    payload: data,
  }
}

export const setDeleteVoucher = (data) => {
  return {
    type: SET_DELETE_VOUCHER,
    payload: data,
  }
}

export const resultVouchers = (data) => {
  return {
    type: RESULT_VOUCHERS,
    payload: data,
  }
}

export const createVouchers = (data) => {
  return {
    type: CREATE_VOUCHERS,
    payload: data,
  }
}

export const alertVouchers = (alert) => {
  return {
    type: ALERT_VOUCHERS,
    payload: alert,
  }
}

export const removeVouchersAlert = () => {
  return {
    type: REMOVE_VOUCHERS_ALERT,
  }
}
