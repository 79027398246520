import {
  SET_LIST_POINT_ADJUSTMENT_LOADING,
  GET_LIST_POINT_ADJUSTMENT,
  GET_MEMBER_POINT_EXPIRY,
  GET_MEMBER_POINT_EXPIRY_DETAIL,
  GET_MEMBER_GROUP_DETAIL,
  GET_MEMBER_DETAIL,
  RESULT_POINT_ADJUSTMENT,
  RESULT_MEMBER_DETAIL,
  GET_MEMBERSHIP_LEVEL_DETAIL,
  GET_MEMBER_CARD_LEVEL_DETAIL,
  GET_CUSTOMER_ELIGIBLE_REWARD,
} from "../constants/PointSummary"

export const setListPointAdjustmentLoading = (data) => {
  return {
    type: SET_LIST_POINT_ADJUSTMENT_LOADING,
    payload: data,
  }
}

export const getListPointAdjustment = (data) => {
  return {
    type: GET_LIST_POINT_ADJUSTMENT,
    payload: data,
  }
}

export const getMemberPointExpiry = (data) => {
  return {
    type: GET_MEMBER_POINT_EXPIRY,
    payload: data,
  }
}

export const getMemberPointExpiryDetail = (data) => {
  return {
    type: GET_MEMBER_POINT_EXPIRY_DETAIL,
    payload: data,
  }
}

export const getResultPointAdjustment = (data) => {
  return {
    type: RESULT_POINT_ADJUSTMENT,
    payload: data,
  }
}

export const getResultMemberDetail = (data) => {
  return {
    type: RESULT_MEMBER_DETAIL,
    payload: data,
  }
}

export const getMemberCardGroup = (data) => {
  return {
    type: GET_MEMBER_GROUP_DETAIL,
    payload: data,
  }
}

export const getMemberDetail = (data) => {
  return {
    type: GET_MEMBER_DETAIL,
    payload: data,
  }
}

export const getMembershipLevelDetail = (data) => {
  return {
    type: GET_MEMBERSHIP_LEVEL_DETAIL,
    payload: data,
  }
}

export const getMemberCardLevelDetail = (data) => {
  return {
    type: GET_MEMBER_CARD_LEVEL_DETAIL,
    payload: data,
  }
}

export const getCustomerEligibleReward = (data) => {
  return {
    type: GET_CUSTOMER_ELIGIBLE_REWARD,
    payload: data,
  }
}
