export const IS_VOUCHER_LOADING = "IS_VOUCHER_LOADING"
export const GET_LIST_VOUCHERS = "GET_LIST_VOUCHERS"
export const SET_VOUCHERS = "SET_VOUCHERS"
export const CREATE_VOUCHERS = "CREATE_VOUCHERS"
export const GET_RESULT_VOUCHERS = "GET_RESULT_VOUCHERS"
export const RESULT_VOUCHERS = "RESULT_VOUCHERS"
export const ALERT_VOUCHERS = "ALERT_VOUCHERS"
export const REMOVE_VOUCHERS_ALERT = "REMOVE_VOUCHERS_ALERT"
export const SET_LIST_VOUCHERS = "SET_LIST_VOUCHERS"
export const UPDATE_VOUCHER = "UPDATE_VOUCHER"
export const UPDATE_VOUCHER_BATCH = "UPDATE_VOUCHER_BATCH"
export const SET_UPDATE_VOUCHER = "SET_UPDATE_VOUCHER"
export const DELETE_VOUCHER = "DELETE_VOUCHER"
export const SET_DELETE_VOUCHER = "SET_DELETE_VOUCHER"
