import {
  SET_CUSTOMER_ACTIVITY_LOADING,
  CREATE_CUSTOMER_ACTIVITY,
  GET_CUSTOMER_ACTIVITY_LIST,
  GET_RESULT_CUSTOMER_ACTIVITY,
  RESULT_CUSTOMER_ACTIVITY,
  SET_CUSTOMER_ACTIVITY_LIST,
  UPDATE_CUSTOMER_ACTIVITY,
  DELETE_CUSTOMER_ACTIVITY,
} from "redux/constants/CustomerActivity"

export const setCustomerActivityLoading = (data) => {
  return {
    type: SET_CUSTOMER_ACTIVITY_LOADING,
    payload: data,
  }
}

export const getCustomerActivityList = (data) => {
  return {
    type: GET_CUSTOMER_ACTIVITY_LIST,
    payload: data,
  }
}

export const resultCustomerActivity = (data) => {
  return {
    type: RESULT_CUSTOMER_ACTIVITY,
    payload: data,
  }
}

export const getResultCustomerActivity = (data) => {
  return {
    type: GET_RESULT_CUSTOMER_ACTIVITY,
    payload: data,
  }
}

export const createCustomerActivity = (data) => {
  return {
    type: CREATE_CUSTOMER_ACTIVITY,
    payload: data,
  }
}

export const setCustomerActivityList = (data) => {
  return { type: SET_CUSTOMER_ACTIVITY_LIST, payload: data }
}

export const updateCustomerActivity = (data) => {
  return {
    type: UPDATE_CUSTOMER_ACTIVITY,
    payload: data,
  }
}

export const deleteCustomerActivity = (data) => {
  return {
    type: DELETE_CUSTOMER_ACTIVITY,
    payload: data,
  }
}
