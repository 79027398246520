export const GET_LIST_CARD_CATEGORY = "GET_LIST_CARD_CATEGORY"
export const CREATE_CARD_CATEGORY = "CREATE_CARD_CATEGORY"
export const GET_RESULT_CARD_CATEGORY = "GET_RESULT_CARD_CATEGORY"
export const GET_CARD_CATEGORY = "GET_CARD_CATEGORY"
export const UPDATE_CARD_CATEGORY = "UPDATE_CARD_CATEGORY"
export const DELETE_CARD_CATEGORY = "DELETE_CARD_CATEGORY"
export const RESULT_CARD_CATEGORY = "RESULT_CARD_CATEGORY"
export const ALERT_CARD_CATEGORY = "ALERT_CARD_CATEGORY"
export const REMOVE_CARD_CATEGORY_ALERT = "REMOVE_CARD_CATEGORY_ALERT"
export const SET_CARD_CATEGORY = "SET_CARD_CATEGORY"
export const CREATE_CARD_LEVEL = "CREATE_CARD_LEVEL"
export const UPDATE_CARD_LEVEL = "UPDATE_CARD_LEVEL"
export const DELETE_CARD_LEVEL = "DELETE_CARD_LEVEL"
