import {
  SET_IS_POINT_EXPIRY_REWARD_LOADING,
  SET_CREATE_POINT_EXPIRY_REWARD,
  SET_CREATE_MEMBER_REWARD_TO_POINT_EXPIRY,
  SET_LIST_POINT_EXPIRY_REWARD_ELIGIBLE,
} from "redux/constants/PointExpiryReward"

const initialState = {
  isLoading: false,
  pointExpiryRewardList: {
    count: 0,
    rows: [],
  },
  pointExpiryRewardEligibleList: {
    count: 0,
    rows: [],
  },
}

const pointExpiryReward = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_POINT_EXPIRY_REWARD_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case SET_CREATE_POINT_EXPIRY_REWARD:
      return {
        ...state,
        pointExpiryRewardList: {
          count: state.pointExpiryRewardList.count + 1,
          rows: [action.payload, ...state.pointExpiryRewardList.rows],
        },
      }

    case SET_CREATE_MEMBER_REWARD_TO_POINT_EXPIRY:
      return {
        ...state,
        pointExpiryRewardList: {
          count: state.pointExpiryRewardList.count + 1,
          rows: [action.payload, ...state.pointExpiryRewardList.rows],
        },
      }

    case SET_LIST_POINT_EXPIRY_REWARD_ELIGIBLE:
      return {
        ...state,
        pointExpiryRewardEligibleList: {
          count: action.payload.length,
          rows: action.payload,
        },
        isLoading: false,
      }

    default:
      return state
  }
}

export default pointExpiryReward
