import { all, fork, takeEvery, call, put } from "@redux-saga/core/effects"
import setNotification from "../../components/shared-components/Notification"
import {
  CREATE_PROFILE_UPDATED_HISTORY,
  GET_PROFILE_UPDATED_HISTORY_LIST,
} from "redux/constants/ProfileUpdatedHistory"
import {
  setProfileUpdatedHistoryLoading,
  setProfileUpdatedHistoryList,
} from "redux/actions/ProfileUpdatedHistory"
import ProfileUpdatedHistoryService from "services/ProfileUpdatedHistoryService"

export function* createProfileUpdatedHistoryAPI() {
  yield takeEvery(CREATE_PROFILE_UPDATED_HISTORY, function* ({ payload }) {
    try {
      yield put(setProfileUpdatedHistoryLoading(true))
      yield call(
        ProfileUpdatedHistoryService.createProfileUpdatedHistory,
        payload
      )
      // if (histories) {
      //   yield put(setProfileUpdatedHistoryList(histories))
      // }
    } catch (err) {
      yield put(setProfileUpdatedHistoryLoading(false))
      setNotification({
        type: "error",
        message: "List Failed",
        description: "Profile updated histories failed to be listed.",
      })
    }
  })
}

export function* listProfileUpdatedHistoriesAPI() {
  yield takeEvery(GET_PROFILE_UPDATED_HISTORY_LIST, function* ({ payload }) {
    try {
      yield put(setProfileUpdatedHistoryLoading(true))
      const histories = yield call(
        ProfileUpdatedHistoryService.listMemberProfileAmendment,
        payload
      )
      if (histories) {
        yield put(setProfileUpdatedHistoryList(histories))
      }
    } catch (err) {
      yield put(setProfileUpdatedHistoryLoading(false))
      setNotification({
        type: "error",
        message: "List Failed",
        description: "Profile updated histories failed to be listed.",
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listProfileUpdatedHistoriesAPI),
    fork(createProfileUpdatedHistoryAPI),
  ])
}
