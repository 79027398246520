export const CREATE_FEEDBACK_FEEDBACK = "CREATE_FEEDBACK_FEEDBACK"
export const SET_CREATE_FEEDBACK_FEEDBACK = "SET_CREATE_FEEDBACK_FEEDBACK"
export const GET_LIST_FEEDBACK_FEEDBACK = "GET_LIST_FEEDBACK_FEEDBACK"
export const SET_LIST_FEEDBACK_FEEDBACK = "SET_LIST_FEEDBACK_FEEDBACK"
export const GET_FEEDBACK_FEEDBACK = "GET_FEEDBACK_FEEDBACK"
export const UPDATE_FEEDBACK_FEEDBACK = "UPDATE_FEEDBACK_FEEDBACK"
export const SET_UPDATE_FEEDBACK_FEEDBACK = "SET_UPDATE_FEEDBACK_FEEDBACK"
export const DELETE_FEEDBACK_FEEDBACK = "DELETE_FEEDBACK_FEEDBACK"
export const SET_DELETE_FEEDBACK_FEEDBACK = "SET_DELETE_FEEDBACK_FEEDBACK"
export const GET_FEEDBACK_FEEDBACK_DETAIL = "GET_FEEDBACK_FEEDBACK_DETAIL"
export const SET_FEEDBACK_FEEDBACK_DETAIL = "SET_FEEDBACK_FEEDBACK_DETAIL"
export const UNMOUNT_FEEDBACK_FEEDBACK_DETAIL =
  "UNMOUNT_FEEDBACK_FEEDBACK_DETAIL"