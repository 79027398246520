import {
  SET_IS_CUSTOMER_ANALYSIS_REPORT_LOADING,
  SET_CUSTOMER_ANALYSIS_REPORT_DATA,
  RESET_CUSTOMER_ANALYSIS_REPORT_DATA,
} from "redux/constants/CustomerAnalysisReport"

const initialState = {
  isLoading: false,
  customerAnalysisReportData: [],
  totalCustomerAnalysisReportData: 0,
}

const CustomerAnalysisReport = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_CUSTOMER_ANALYSIS_REPORT_LOADING:
      return {
        ...state,
        isLoading: payload,
      }

    case SET_CUSTOMER_ANALYSIS_REPORT_DATA:
      return {
        ...state,
        customerAnalysisReportData: payload.rows,
        totalCustomerAnalysisReportData: payload.count,
        isLoading: false,
      }

    case RESET_CUSTOMER_ANALYSIS_REPORT_DATA:
      return {
        ...state,
        customerAnalysisReportData: [],
        totalCustomerAnalysisReportData: 0,
        isLoading: false,
      }

    default:
      return state
  }
}

export default CustomerAnalysisReport
