import {
  SET_FEEDBACK_QUESTION_FETCH,
  SET_FEEDBACK_QUESTION_LOADING,
  CREATE_FEEDBACK_QUESTION,
  DELETE_FEEDBACK_QUESTION,
  GET_LIST_FEEDBACK_QUESTION,
  SET_CREATE_FEEDBACK_QUESTION,
  SET_DELETE_FEEDBACK_QUESTION,
  SET_LIST_FEEDBACK_QUESTION,
  SET_UPDATE_FEEDBACK_QUESTION,
  UPDATE_FEEDBACK_QUESTION,
  GET_FEEDBACK_QUESTION_DETAIL,
  SET_FEEDBACK_QUESTION_DETAIL,
  UNMOUNT_FEEDBACK_QUESTION_DETAIL,
} from "redux/constants/FeedbackQuestion"

export const setFeedbackQuestionFetch = (data) => {
  return {
    type: SET_FEEDBACK_QUESTION_FETCH,
    payload: data
  }
}

export const setFeedbackQuestionLoading = (data) => {
  return {
    type: SET_FEEDBACK_QUESTION_LOADING,
    payload: data
  }
}

export const createFeedbackQuestion = (data) => {
  return {
    type: CREATE_FEEDBACK_QUESTION,
    payload: data,
  }
}

export const setCreateFeedbackQuestion = (data) => {
  return {
    type: SET_CREATE_FEEDBACK_QUESTION,
    payload: data,
  }
}

export const getListFeedbackQuestion = (data) => {
  return {
    type: GET_LIST_FEEDBACK_QUESTION,
    payload: data,
  }
}

export const setListFeedbackQuestion = (data) => {
  return {
    type: SET_LIST_FEEDBACK_QUESTION,
    payload: data,
  }
}

export const updateFeedbackQuestion = (data) => {
  return {
    type: UPDATE_FEEDBACK_QUESTION,
    payload: data,
  }
}

export const setUpdateFeedbackQuestion = (data) => {
  return {
    type: SET_UPDATE_FEEDBACK_QUESTION,
    payload: data,
  }
}

export const deleteFeedbackQuestion = (data) => {
  return {
    type: DELETE_FEEDBACK_QUESTION,
    payload: data,
  }
}

export const setDeleteFeedbackQuestion = (data) => {
  return {
    type: SET_DELETE_FEEDBACK_QUESTION,
    payload: data,
  }
}

export const getFeedbackQuestionDetail = (data) => {
  return {
    type: GET_FEEDBACK_QUESTION_DETAIL,
    payload: data,
  }
}

export const setFeedbackQuestionDetail = (data) => {
  return {
    type: SET_FEEDBACK_QUESTION_DETAIL,
    payload: data,
  }
}

export const unmountFeedbackQuestionDetail = () => {
  return {
    type: UNMOUNT_FEEDBACK_QUESTION_DETAIL,
  }
}
