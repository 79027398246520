import {
  RESULT_LOAD_IMAGE_REWARDS,
  RESULT_LOAD_REWARDS,
  RESULT_ON_REDEEM_REWARD,
  RESULT_SEARCH_CUSTOMER_MEMBERSHIP,
  SET_IS_LOADING_REDEEM,
  SET_RESET_REDEEM,
} from "redux/constants/RedeemReward"

const initialState = {
  customerMembership: null,
  rewards: [],
  rewardImages: [],

  isLoadingCustomer: false,
  isLoadingRewards: false,
  isLoadingImages: false,

  isLoadingRedeem: false,

  isSucceed: false,
}

const RedeemReward = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESULT_SEARCH_CUSTOMER_MEMBERSHIP:
      return {
        ...state,
        customerMembership: payload,
        isLoadingCustomer: false,
      }

    case RESULT_LOAD_REWARDS:
      return {
        ...state,
        rewards: payload,
        isLoadingRewards: false,
      }

    case RESULT_LOAD_IMAGE_REWARDS:
      return {
        ...state,
        rewardImages: payload,
        isLoadingImages: false,
      }

    case RESULT_ON_REDEEM_REWARD:
      return {
        ...state,
        isLoadingRedeem: false,
        isSucceed: true,
      }

    case SET_IS_LOADING_REDEEM:
      return { ...state, ...payload }

    case SET_RESET_REDEEM:
      return {
        ...state,
        isSucceed: false,
        rewardImages: [],
        isLoadingImages: false,
        customerMembership: null,
        isLoadingCustomer: false,
      }

    default:
      return state
  }
}

export default RedeemReward
