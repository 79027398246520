import axios from "axios"
import { COMMON_API_BASE_URL, SSO_API_BASE_URL } from "configs/AppConfig"
import { AUTH_TOKEN, SSO_TOKEN } from "redux/constants/Auth"
import AuthService from "./AuthService"
import fetch from "auth/APIFetchInterceptor"

const host = SSO_API_BASE_URL

const commonApiService = axios.create({
  baseURL: COMMON_API_BASE_URL,
  timemout: 60000,
})

async function generateSSOFetch() {
  const ssoToken = sessionStorage.getItem(SSO_TOKEN)

  if (!ssoToken) {
    const { data } = await AuthService.ssoLogin()

    return axios.create({
      baseURL: host,
      timeout: 60000,
      headers: {
        Authorization: `Bearer ${data?.data?.access_token}`,
      },
    })
  } else {
    return axios.create({
      baseURL: host,
      timeout: 60000,
      headers: {
        Authorization: `Bearer ${ssoToken}`,
      },
    })
  }
}

const SSOService = {}

SSOService.getOutLetList = async function (data) {
  return fetch({
    url: "/buildings/find-all",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      search: JSON.stringify(data.search),
    },
  })
}

SSOService.getSsoBuildings = async function (data) {
  const ssoFetch = await generateSSOFetch()

  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      data[key] = data[key].join(",")
    }
  })

  return ssoFetch({
    url: "/buildings",
    params: data,
    method: "get",
  })
}

SSOService.getStateList = async function (data) {
  return commonApiService({
    url: "/lists/items",
    method: "get",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    params: { ...data, ListName: "Malaysian State" },
  })
}

SSOService.getCountryList = async function (data) {
  return commonApiService({
    url: "/lists/items",
    method: "get",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    params: { ...data, ListName: "Country" },
  })
}

SSOService.getStaffList = async function (data) {
  return fetch({
    url: "/staffs/find-all",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

SSOService.getUserList = async function (data) {
  const ssoFetch = await generateSSOFetch()

  return ssoFetch({
    url: "/users",
    params: data,
    method: "get",
  })
}

export default SSOService
