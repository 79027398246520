import {
  SET_IS_INACTIVE_MEMBERS_LOADING,
  GET_LIST_INACTIVE_MEMBERS,
  GET_RESULT_INACTIVE_MEMBERS,
  GET_LIST_MEMBER_ID_TYPE,
  SET_LIST_INACTIVE_MEMBERS,
  UPDATE_INACTIVE_MEMBER,
} from "redux/constants/MemberArchiveInactiveMember"

export const setIsInactiveMembersLoading = (payload) => {
  return {
    type: SET_IS_INACTIVE_MEMBERS_LOADING,
    payload,
  }
}

export const getListInactiveMembers = (data) => {
  return {
    type: GET_LIST_INACTIVE_MEMBERS,
    payload: data,
  }
}

export const getListMemberIdType = (data) => {
  return {
    type: GET_LIST_MEMBER_ID_TYPE,
    payload: data,
  }
}

export const getResultInactiveMembers = (data) => {
  return {
    type: GET_RESULT_INACTIVE_MEMBERS,
    payload: data,
  }
}

export const updateInactiveMember = (data) => {
  return {
    type: UPDATE_INACTIVE_MEMBER,
    payload: data,
  }
}

export const setListInactiveMembers = (data) => {
  return {
    type: SET_LIST_INACTIVE_MEMBERS,
    payload: data,
  }
}
