import {
  RESULT_POINT_RATE,
  ALERT_POINT_RATE,
  REMOVE_POINT_RATE_ALERT,
  SET_POINT_RATE,
} from "redux/constants/PointRates"

const initialState = {
  rates: [],
  alert: {},
  rate: null,
}

const PointRate = (state = initialState, action) => {
  switch (action.type) {
    case SET_POINT_RATE:
      return {
        ...state,
        rate: action.payload,
      }
    case RESULT_POINT_RATE:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          rates: action.payload.data,
        }
      } else if (action.payload.type === "CREATE") {
        let newRows = null
        if (state.rates?.rows) {
          newRows = [action.payload.data, ...state.rates.rows]
        } else {
          newRows = [action.payload.data]
        }
        return {
          ...state,
          rates: {
            ...state.rates,
            count: state.rates.count + 1,
            rows: newRows,
          },
          rate: action.payload.data,
        }
      } else if (action.payload.type === "UPDATE") {
        return {
          ...state,
          rates: {
            ...state.rates,
            rows: [
              ...state.rates.rows.map((ratesParam) => {
                if (ratesParam.CardratesId === action.payload.data.ratesId) {
                  return {
                    ...ratesParam,
                    Name: action.payload.data.Name,
                    Description: action.payload.data.Description,
                    Status: action.payload.data.Status,
                  }
                } else {
                  return ratesParam
                }
              }),
            ],
          },
          rate: action.payload.data,
        }
      } else if (action.payload.type === "DELETE") {
        return {
          ...state,
          rates: {
            ...state.rates,
            rows: [
              ...state.rates.rows.map((ratesParam) => {
                if (ratesParam.CardratesId === action.payload.data.ratesId) {
                  return {
                    ...ratesParam,
                    Status: "Deleted",
                  }
                } else {
                  return ratesParam
                }
              }),
            ],
          },
          rate: action.payload.data,
        }
      }
      break
    case ALERT_POINT_RATE:
      return {
        ...state,
        alert: {
          status: true,
          success: action.payload.success,
          message: action.payload.message,
        },
      }
    case REMOVE_POINT_RATE_ALERT:
      return {
        ...state,
        alert: {
          status: false,
        },
      }
    default:
      return state
  }
}

export default PointRate
