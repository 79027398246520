import React from "react"
import { Switch, Route } from "react-router-dom"
import CustomerFeedbackView from "views/customer-feedback-views"

export const CustomerFeedbackLayout = () => {
  return (
    <div className="auth-container">
      <Switch>
        <Route path="/customer/feedback" component={CustomerFeedbackView} />
      </Switch>
    </div>
  )
}

export default CustomerFeedbackLayout
