import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const CustomerTransactionService = {}

CustomerTransactionService.createTransactionSummary = function (data) {
  return fetch({
    method: "post",
    url: `/customer/transaction-summaries/add`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

CustomerTransactionService.createTransactionDetail = function (data) {
  return fetch({
    method: "post",
    url: `/customer/transaction-details/add`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

CustomerTransactionService.getCustomerMembership = function (params) {
  return fetch({
    url: `/customer/memberships/find-all`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: 10,
      Page: 1,
      Search: JSON.stringify(params ?? {}),
    },
  })
}

export default CustomerTransactionService
