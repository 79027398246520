import {
  RESULT_CAMPAIGN,
  SET_CAMPAIGN_DETAIL,
  SET_CAMPAIGN_LIST,
  SET_PAGE_ROW,
} from "redux/constants/Campaign"

const initialState = {
  group: [],
  groupDetail: null,
  alert: {},
  page: 1,
  rows: 10,
  count: 0,
}

const campaign = (state = initialState, action) => {
  switch (action.type) {
    case RESULT_CAMPAIGN:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          group: action.payload.data,
        }
      } else if (action.payload.type === "CREATE") {
        return {
          ...state,
          group: {
            ...state.group,
            count: state.group.count + 1,
            rows: [action.payload.data, ...state.group.rows],
          },
        }
      } else if (action.payload.type === "UPDATE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: [
              ...state.group.rows.map((groupParam) => {
                if (
                  groupParam.CampaignCode === action.payload.data.CampaignCode
                ) {
                  return action.payload.data
                } else {
                  return groupParam
                }
              }),
            ],
          },
          groupDetail: action.payload.data,
        }
      } else if (action.payload.type === "DELETE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: [
              ...state.group.rows.map((groupParam) => {
                if (
                  groupParam.CampaignCode ===
                  action?.payload?.data?.CampaignCode
                ) {
                  return {
                    ...groupParam,
                    Status: "Deleted",
                  }
                } else {
                  return groupParam
                }
              }),
            ],
          },
        }
      } else if (action.payload.type === "DETAIL") {
        return {
          ...state,
          groupDetail: action.payload?.data,
        }
      }
      break
    case SET_CAMPAIGN_LIST:
      return {
        ...state,
        group: action.payload,
      }
    case SET_PAGE_ROW:
      if (action.payload.type === "rows") {
        return {
          ...state,
          rows: action.payload.rows,
        }
      } else if (action.payload.type === "page") {
        return {
          ...state,
          page: action.payload.page,
        }
      } else {
        return {
          ...state,
          page: action.payload[0],
          rows: action.payload[1],
        }
      }
    case SET_CAMPAIGN_DETAIL:
      return {
        ...state,
        groupDetail: action.payload,
      }
    default:
      return state
  }
}

export default campaign
