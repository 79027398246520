export const SET_COMMON_LOADING = "SET_COMMON_LOADING"

export const GET_COMMON_DATA = "GET_COMMON_DATA"
export const GET_COMMON_RESULT = "GET_COMMON_RESULT"
export const UPLOAD_IMAGE_MEDIAS_INTERNAL = "UPLOAD_IMAGE_MEDIAS_INTERNAL"
export const GET_LIST_MEDIA = "GET_LIST_MEDIA"
export const SET_LIST_MEDIA = "SET_LIST_MEDIA"
export const DELETE_MEDIA = "DELETE_MEDIA"
export const CREATE_FIELD_TRANSLATION = "CREATE_FIELD_TRANSLATION"
export const GET_CONTACT_LOG = "GET_CONTACT_LOG"
export const SET_CONTACT_LOG = "SET_CONTACT_LOG"
export const GET_POSTCODE = "GET_POSTCODE"
export const SET_POSTCODE = "SET_POSTCODE"
