import "../index.css"
import React from "react"
import { Card, Typography, Row, Col, Tag } from "antd"
import {
  UserSwitchOutlined,
  UserAddOutlined,
  // FundOutlined,
  // CreditCardOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons"
// import Utils from "utils"

const { Title, Text } = Typography

const TotalCount = ({
  totalActiveMember,
  totalNewMember,
  // totalSalesYesterday,
  // totalTransaction,
  totalSelfRegistered,
  totalManualRegistered,
}) => {
  return (
    <div>
      <Row gutter={[16, { xs: 8, sm: 8, md: 8, lg: 8 }]}>
        <Col xs={24} sm={12}>
          <Card className="total-count-card">
            <Tag color="cyan" className="total-count-icon">
              <UserSwitchOutlined />
            </Tag>
            <Title level={4}>{totalActiveMember ?? "-"}</Title>
            <Text className="gray-text">Total Active</Text>
            <br />
            <Text className="gray-text">Member</Text>
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card className="total-count-card">
            <Tag color="geekblue" className="total-count-icon">
              <UserAddOutlined />
            </Tag>
            <Title level={4}>{totalNewMember ?? "-"}</Title>
            <Text className="gray-text">Total New Member</Text>
            <br />
            <Text className="gray-text">(As at Yesterday)</Text>
          </Card>
        </Col>
      </Row>
      {/* {Utils.checkUserPrivilege("View Sales Information") && (
        <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col xs={24} sm={12}>
            <Card className="total-count-card">
              <Tag color="gold" className="total-count-icon">
                <FundOutlined />
              </Tag>
              <Title level={4}>{totalSalesYesterday ?? "-"}</Title>
              <Text className="gray-text">Total Sales</Text>
              <br />
              <Text className="gray-text">(As at Yesterday)</Text>
            </Card>
          </Col>
          <Col xs={24} sm={12}>
            <Card className="total-count-card">
              <Tag color="volcano" className="total-count-icon">
                <CreditCardOutlined />
              </Tag>
              <Title level={4}>{totalTransaction ?? "-"}</Title>
              <Text className="gray-text">Total Transaction (As at Yesterday)</Text>
            </Card>
          </Col>
        </Row>
      )} */}
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xs={24} sm={12}>
          <Card className="total-count-card">
            <Tag color="gold" className="total-count-icon">
              <UsergroupAddOutlined />
            </Tag>
            <Title level={4}>{totalSelfRegistered ?? "-"}</Title>
            <Text className="gray-text">Total Self-Registered</Text>
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card className="total-count-card">
            <Tag color="volcano" className="total-count-icon">
              <UsergroupAddOutlined />
            </Tag>
            <Title level={4}>{totalManualRegistered ?? "-"}</Title>
            <Text className="gray-text">Total Manual Registered</Text>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default TotalCount
