import {
  SET_IS_INACTIVE_MEMBERS_LOADING,
  GET_RESULT_INACTIVE_MEMBERS,
  SET_LIST_INACTIVE_MEMBERS,
} from "redux/constants/MemberArchiveInactiveMember"

const initialState = {
  isLoading: false,
  group: [],
  alert: {},
  count: null,
  category: null,
  memberIdType: null,
}

const inactiveMember = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_INACTIVE_MEMBERS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case GET_RESULT_INACTIVE_MEMBERS:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          count: action?.payload?.data?.count,
          group: action?.payload?.data?.rows,
          isLoading: false,
        }
      } else if (action.payload.type === "UPDATE") {
        const updateData = []
        state?.group?.forEach((member) => {
          if (member?.MembershipNo !== action?.payload?.MembershipNo) {
            updateData.push(member)
          }
        })
        return {
          ...state,
          count: state.count - 1,
          group: updateData,
          isLoading: false,
        }
      } else if (action.payload.type === "LIST_MEMBER_ID_TYPE") {
        return {
          ...state,
          memberIdType: action?.payload?.data,
          isLoading: false,
        }
      }
      break
    case SET_LIST_INACTIVE_MEMBERS:
      return {
        ...state,
        count: 0,
        group: [],
        isLoading: false,
      }
    default:
      return state
  }
}

export default inactiveMember
