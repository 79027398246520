export const SET_CUSTOMER_REWARD_LOADING = "GET_CUSTOMER_REWARD_LOADING"
export const GET_LIST_CUSTOMER_REWARD = "GET_LIST_CUSTOMER_REWARD"
export const GET_CUSTOMER_REWARD = "GET_CUSTOMER_REWARD"
export const DELETE_CUSTOMER_REWARD = "DELETE_CUSTOMER_REWARD"
export const CREATE_CUSTOMER_REWARD = "CREATE_CUSTOMER_REWARD"
export const UPDATE_CUSTOMER_REWARD = "UPDATE_CUSTOMER_REWARD"
export const RESULT_CUSTOMER_REWARD = "RESULT_CUSTOMER_REWARD"

export const SET_LIST_CUSTOMER_REWARD = "SET_LIST_CUSTOMER_REWARD"
export const SET_CUSTOMER_REWARD = "SET_CUSTOMER_REWARD"
export const SET_DELETE_CUSTOMER_REWARD = "SET_DELETE_CUSTOMER_REWARD"
export const SET_CREATE_CUSTOMER_REWARD = "SET_CREATE_CUSTOMER_REWARD"
export const SET_UPDATE_CUSTOMER_REWARD = "SET_UPDATE_CUSTOMER_REWARD"

export const SET_PAGE_CUSTOMER_REWARD = "SET_PAGE_CUSTOMER_REWARD"
export const RESET_CUSTOMER_REWARD_ALERT = "RESET_CUSTOMER_REWARD_ALERT"
