import {
  CLEAR_REWARD_OBJECT,
  GET_LIST_REWARD_OBJECT,
  RESULT_REWARD_OBJECT,
} from "redux/constants/RewardObject"

export const getListRewardObject = (data) => {
  return {
    type: GET_LIST_REWARD_OBJECT,
    payload: data,
  }
}

export const resultRewardObject = (data) => {
  return {
    type: RESULT_REWARD_OBJECT,
    payload: data,
  }
}

export const clearRewardObject = (data) => {
  return {
    type: CLEAR_REWARD_OBJECT,
    payload: data,
  }
}
