import React, { useState, useEffect } from "react"
import { Row, Col, Button, Select, DatePicker } from "antd"
import { CloseOutlined, SearchOutlined } from "@ant-design/icons"
import moment from "moment"

const { Option } = Select
const { RangePicker } = DatePicker

const Greetings = ({
  departmentCode,
  buildingCode,
  preferredName,
  isOutletStaff,
  outletList,
  search,
  setSearch,
  setIsSearching,
}) => {
  const [initSearch, setInitSearch] = useState({
    OutletCode: null,
    UpdatedAt: [moment().subtract(1, "w"), moment()],
  })

  useEffect(() => {
    if (isOutletStaff && buildingCode) {
      setInitSearch({ ...initSearch, OutletCode: buildingCode })
    }
    //eslint-disable-next-line
  }, [buildingCode, isOutletStaff])

  useEffect(() => {
    setSearch(initSearch)
    //eslint-disable-next-line
  }, [initSearch])

  const handleSearch = () => {
    setIsSearching(true)
  }

  return (
    <Row>
      <Col xs={24} sm={12}>
        Hello, <b>{preferredName}</b>
        <br></br>
        Department/Outlet:{" "}
        <b>
          {departmentCode}/{buildingCode}
        </b>
      </Col>
      <Col xs={24} sm={12}>
        <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col className="gutter-row mb-2" xs={24} sm={8}>
            <div>
              <Select
                onChange={(value) =>
                  setSearch({
                    ...search,
                    OutletCode: value,
                  })
                }
                placeholder="Outlet Code"
                style={{
                  width: "100%",
                  borderRadius: 1,
                }}
                value={search?.OutletCode}
                disabled={isOutletStaff}
              >
                {outletList.map((item) => (
                  <Option value={item.code} key={item.code}>
                    {item.code} - {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col className="gutter-row mb-2" xs={24} sm={11}>
            <div>
              <RangePicker
                // disabled={isOutletStaff}
                style={{
                  width: "100%",
                }}
                placeholder={["Date From", "Date To"]}
                ranges={{
                  Today: [moment(), moment()],
                  "This Week": [
                    moment().startOf("week"),
                    moment().endOf("week"),
                  ],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                value={search?.UpdatedAt}
                onChange={(value) =>
                  setSearch({
                    ...search,
                    UpdatedAt: value,
                  })
                }
              />
            </div>
          </Col>
          <Col className="gutter-row mb-2" xs={24} sm={5}>
            <Row className="d-flex">
              <Button
                className="mr-2"
                shape="circle"
                icon={<SearchOutlined />}
                onClick={() => handleSearch()}
              />
              <Button
                className="mr-2"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => setSearch(initSearch)}
              />
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Greetings
