import axios from "axios"
import { API_BASE_URL } from "configs/AppConfig"

const host = API_BASE_URL

const CustomerActivityService = {}

CustomerActivityService.getCustomerActivityList = function (data) {
  return axios({
    url: host + "/customer/activities/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ?? 10,
      Page: data.page ?? 1,
      Search: JSON.stringify(data.queries ?? "{}"),
      Sort: data.sort ?? "ASC",
    },
  })
}

CustomerActivityService.createCustomerActivity = function (data) {
  return axios({
    url: host + "/customer/activities/add",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

CustomerActivityService.updateCustomerActivity = function (data) {
  return axios({
    url: host + `/customer/activities/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { ...data, ActivityId: data?.ActivityId },
  })
}

CustomerActivityService.deleteCustomerActivity = function (data) {
  return axios({
    url: host + `/customer/activities/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { ActivityId: data?.ActivityId },
  })
}

export default CustomerActivityService
