import setNotification from "components/shared-components/Notification"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import { createCustomerActivity } from "redux/actions/CustomerActivity"
import {
  AWARD_REFERRER,
  REJECT_REFERRER,
} from "redux/constants/ReferrerListing"
import CustomerService from "services/CustomerService"
import ReferrerListingService from "services/ReferrerListingService"

export function* awardReferrerAPI() {
  yield takeEvery(AWARD_REFERRER, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token

    try {
      yield call(ReferrerListingService.awardReferrer, payload.data)

      let userInfo = JSON.parse(sessionStorage.getItem("userinfo"))

      yield put(
        createCustomerActivity({
          CustomerId: payload.data.ReferrerId,
          ActivityCode: "FGF",
          ActivityLog: payload.data.PointDescription + " APPROVED",
          Status: "Active",
          SSOBuildingId: String(userInfo.building?.id),
          notification: "hidden",
        })
      )

      yield put(
        createLoginActivity(
          "Award Friend-Get-Friend reward to referrer in Referrer Listing"
        )
      )

      setNotification({
        type: "success",
        message: "Success Award Point",
        description: "Successfully award point to referrer",
      })

      yield call(payload.callback)
    } catch (err) {
      setNotification({
        type: "error",
        message: "Award Point Failed",
        description: err.response.data.message ?? "",
      })
    }
  })
}

export function* rejectReferrerAPI() {
  yield takeEvery(REJECT_REFERRER, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token

    try {
      const response = yield call(CustomerService.getCustomer, {
        CustomerId: payload.data.CustomerId,
        access_token: auth_token,
      })

      yield call(CustomerService.updateCustomer, {
        ...response.data,
        RecommendMembershipNo: null,
        access_token: auth_token,
      })

      let userInfo = JSON.parse(sessionStorage.getItem("userinfo"))

      yield put(
        createCustomerActivity({
          CustomerId: payload.data.ReferrerId,
          ActivityCode: "FGF",
          ActivityLog: `FGF - ${payload.data.MembershipNo} ${payload.data.FullName} REJECTED WITH REASON - ${payload.data.Reason}`,
          Status: "Active",
          SSOBuildingId: String(userInfo.building?.id),
          notification: "hidden",
        })
      )

      yield put(
        createLoginActivity(
          "Reject Friend-Get-Friend reward to referrer in Referrer Listing"
        )
      )

      setNotification({
        type: "success",
        message: "Success Reject Point",
        description: "Successfully Reject point to referrer",
      })

      yield call(payload.callback)
    } catch (err) {
      setNotification({
        type: "error",
        message: "Reject Point Failed",
        description: err.response.data.message ?? "",
      })
    }
  })
}

export default function* rootSaga() {
  yield all([fork(awardReferrerAPI), fork(rejectReferrerAPI)])
}
