import React, { useState, useEffect } from "react"
import { Table, Menu, Dropdown, Badge, Card } from "antd"
import { MoreOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"

import ProductGroupModal from "./modal/ProductGroupModal"
import ProductGroupDeleteModal from "./modal/ProductGroupDeleteModal"

const ProductGroupTable = ({
  group,
  count,
  productTypes,
  onDelete,
  onUpdate,
  page,
  setPage,
  getListProduct,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [data, setData] = useState({})

  useEffect(() => {
    if (alert.success) {
      setShowModal(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert])

  const [deleteData, setDeleteData] = useState({
    ProductCode: "",
  })

  const onUpdateProductGroup = () => {
    setShowModal(false)
    onUpdate(data)
  }

  const deleteProductGroupUpdate = () => {
    onDelete(deleteData)
  }

  const insertRecord = async (record) => {
    setData(
      {
        CreatedById: record.CreatedById,
        ProductCode: record.ProductCode,
        ProductName: record.ProductName,
        ProductType: record.ProductType,
        Status: record?.Status,
      },
      setShowModal(true)
    )
  }

  function hideMenu(e) {
    e.target.style.display = "none"
  }

  const showConfirm = (record) => {
    setDeleteData({
      ProductCode: record.ProductCode,
    })
    setShowDeleteModal(true)
  }

  const menu = (record) => {
    return (
      <div>
        <Menu
          onChange={hideMenu}
          style={{
            width: "10.5rem",
            borderRadius: "10px",
            boxShadow: "-1px 3px 20px -2px rgba(0,0,0,0.1)",
          }}
        >
          <Menu.Item key="0" onClick={() => insertRecord(record)}>
            <EditOutlined /> Edit
          </Menu.Item>
          <Menu.Item key="1" onClick={() => showConfirm(record)}>
            <DeleteOutlined /> Delete
          </Menu.Item>
        </Menu>
      </div>
    )
  }

  const columns = [
    {
      title: "Code",
      dataIndex: "ProductCode",
      sorter: (a, b) => {
        return a.ProductCode.localeCompare(b.Code)
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Name",
      dataIndex: "ProductName",
      sorter: (a, b) => {
        return a.ProductName.localeCompare(b.Name)
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Type",
      dataIndex: "ProductType",
      sorter: (a, b) => {
        return a.ProductType.localeCompare(b.Type)
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Status",
      dataIndex: "Status",
      sorter: (a, b) => {
        return a?.Status.localeCompare(b?.Status)
      },
      sortDirections: ["ascend", "descend"],
      render: (status) => {
        return (
          <div style={{ minWidth: "95px" }}>
            <Badge status={status === "Active" ? "success" : "error"} />{" "}
            <span>{status}</span>{" "}
          </div>
        )
      },
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => {
        return (
          record?.Status !== "Deleted" && (
            <div>
              <Dropdown overlay={menu(record)} trigger={["click"]}>
                <span className="ellipsis-dropdown">
                  <MoreOutlined
                    style={{
                      cursor: "pointer",
                      transform: "rotate(-90deg)",
                    }}
                  />
                </span>
              </Dropdown>
            </div>
          )
        )
      },
    },
  ]

  // const getGroups = (e) => {
  //   if (e === "prev") {
  //     if (page - 1 >= 1) {
  //       setPage(page - 1)
  //       getListProduct({ page: page - 1 })
  //     }
  //   } else {
  //     if (page + 1 <= Math.ceil(count / 20)) {
  //       setPage(page + 1)
  //       getListProduct({ page: page + 1 })
  //     }
  //   }
  // }

  // const paginationHandler = () => {
  // const prevPage = document.querySelector(".ant-pagination-prev")
  // const nextPage = document.querySelector(".ant-pagination-next")
  // if (page > 1) {
  //   prevPage.classList.remove("ant-pagination-disabled")
  //   prevPage.querySelector("button").disabled = false
  //   prevPage.addEventListener("click", () => getGroups("prev"))
  // }
  // nextPage.classList.remove("ant-pagination-disabled")
  // nextPage.querySelector("button").disabled = false
  // nextPage.addEventListener("click", () => getGroups("next"))
  // }

  // useEffect(() => {
  //   if (group && group.length > 1) {
  //     paginationHandler()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [group])

  return (
    <div className="table-responsive">
      <Card>
        <Table columns={columns} dataSource={group} />

        <ProductGroupModal
          visible={showModal}
          title="Edit"
          data={data}
          productTypes={productTypes}
          setData={setData}
          setShowModal={setShowModal}
          submitHandler={onUpdateProductGroup}
        />

        <ProductGroupDeleteModal
          visible={showDeleteModal}
          deleteProductGroup={deleteProductGroupUpdate}
          setShowDeleteModal={setShowDeleteModal}
          setDeleteData={setDeleteData}
        />
      </Card>
    </div>
  )
}

export default ProductGroupTable
