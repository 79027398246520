export const GET_REWARD = "GET_REWARD_CANCEL_REDEEM_REWARD"
export const SET_REWARD = "SET_REWARD_CANCEL_REDEEM_REWARD"
export const SET_REWARD_IMAGES = "SET_REWARD_IMAGES_CANCEL_REDEEM_REWARD"
export const SET_IS_CANCELLED = "SET_IS_CANCELLED_CANCEL_REDEEM_REWARD"

export const SET_IS_LOADING = "SET_IS_LOADING_CANCEL_REDEEM_REWARD"

export const ON_CANCEL_REDEEMED_REWARD = "ON_CANCEL_REDEEMED_REWARD"

export const RESET_CANCEL_REDEEM_REWARD = "RESET_CANCEL_REDEEM_REWARD"
