import React from "react"
import {
  Card,
  Table,
  Form,
  Modal,
  Row,
  Col,
  Select,
  Button,
  DatePicker,
} from "antd"
import { PlusOutlined } from "@ant-design/icons"
import moment from "moment"
import ProgressLogModal from "../../registration/customer-details/components/customer-task/components/modal/ProgressLogModal"

const { Item } = Form
const { Option } = Select

const TaskToDoModal = ({
  isModalVisible,
  setIsModalVisible,
  setSelectedRecord,
  outletList,
  data,
  taskProgressLogColumns,
  taskProgressLogData,
  updatedCustomerTask,
  setUpdatedCustomerTask,
  setIsSaving,
  newProgressLog,
  setNewProgressLog,
  validateNewProgressLog,
  setValidateNewProgressLog,
  isProgressLogModalVisible,
  setIsProgressLogModalVisible,
  setIsAddingNewProgressLog,
}) => {
  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"))

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  }

  const onCancel = () => {
    setIsModalVisible(false)
    setSelectedRecord({})
    setUpdatedCustomerTask({})
  }

  const onSave = () => {
    setIsSaving(true)
  }

  const onValidateProgressLog = () => {
    if (newProgressLog.Description) {
      setValidateNewProgressLog({})
      setIsAddingNewProgressLog(true)
    } else {
      setValidateNewProgressLog({ Description: "Description is required!" })
    }
  }

  return (
    <Modal
      title={`Task To Do`}
      visible={isModalVisible}
      onCancel={onCancel}
      onOk={onCancel}
      destroyOnClose={true}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={onSave}>
          Save
        </Button>,
      ]}
      width={1000}
      style={{ top: 20 }}
    >
      <Card className="px-3">
        <Form {...formItemLayout}>
          <Row>
            <Col md={12}>
              <Item
                labelCol={{ span: 8 }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 0,
                }}
                label="Membership No"
              >
                <span>{data?.Customer?.RecommendMembershipNo ?? ""}</span>
              </Item>
            </Col>
            <Col md={12}>
              <Item
                labelCol={{ span: 8 }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 0,
                }}
                label="ID No."
              >
                <span>{data?.Customer?.IdNo ?? ""}</span>
              </Item>
            </Col>
          </Row>
          <Item
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 0,
            }}
            label="Customer Name"
          >
            <span>{data?.Customer?.FullName ?? ""}</span>
          </Item>
          <Row>
            <Col md={12}>
              <Item
                labelCol={{ span: 8 }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 0,
                }}
                label="Mobile No."
              >
                <span>{data?.Customer?.ContactNo ?? ""}</span>
              </Item>
            </Col>
            <Col md={12}>
              <Item
                labelCol={{ span: 8 }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 0,
                }}
                label="Email."
              >
                <span>{data?.Customer?.Email ?? ""}</span>
              </Item>
            </Col>
          </Row>
          <Item
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 0,
            }}
            label="Task Group"
          >
            <span>{data?.GroupCode}</span>
          </Item>
          <Item
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 0,
            }}
            label="Task Name"
          >
            <span>{data?.Name}</span>
          </Item>
          <Item
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 0,
            }}
            label="Task Description"
          >
            <span>{data?.Description}</span>
          </Item>
          <Row>
            <Col md={12}>
              <Item
                labelCol={{ span: 8 }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 0,
                }}
                label="Outlet"
              >
                <Select
                  defaultValue={data.building?.id}
                  onChange={(value) => {
                    setUpdatedCustomerTask({
                      ...updatedCustomerTask,
                      SSOBuildingId: String(value),
                    })
                  }}
                  disabled={userInfo.building.code !== "HQ"}
                >
                  {outletList?.map((item, index) => (
                    <Option value={item.Id} key={index}>
                      {item.code} - {item.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col md={12}>
              <Item
                labelCol={{ span: 8 }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 0,
                }}
                label="Due Date"
              >
                <DatePicker
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  placeholder="Due Date"
                  defaultValue={moment(data?.DueDate)}
                  onChange={(value) => {
                    setUpdatedCustomerTask({
                      ...updatedCustomerTask,
                      DueDate: value
                        .add(8, "hours")
                        .format("YYYY-MM-DDTHH:mm:ss"),
                    })
                  }}
                  disabled={userInfo.building.code !== "HQ"}
                />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Item
                labelCol={{ span: 8 }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 0,
                }}
                label="Status"
              >
                <Select
                  defaultValue={data.Status}
                  onChange={(value) =>
                    setUpdatedCustomerTask({
                      ...updatedCustomerTask,
                      Status: value,
                    })
                  }
                  placeholder="Status"
                >
                  <Option value="To Do">To Do</Option>
                  <Option value="In Progress">In Progress</Option>
                  {userInfo.building.code === "HQ" && (
                    <Option value="Completed">Completed</Option>
                  )}
                </Select>
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card
        title="Progress Log"
        extra={
          <Button
            className="mt-2"
            icon={<PlusOutlined />}
            onClick={() => setIsProgressLogModalVisible(true)}
          >
            Add Log
          </Button>
        }
      >
        <Table
          columns={taskProgressLogColumns}
          dataSource={taskProgressLogData?.map(
            (taskProgressLogItem, index) => ({
              ...taskProgressLogItem,
              key: index,
            })
          )}
          onChange={(pagination, filters, sorter) =>
            console.log(pagination, filters, sorter)
          }
          pagination={{
            showTotal: (total, range) =>
              `Displaying records ${range[0]}-${range[1]} of ${total} items`,
            pageSize: 5,
            total: taskProgressLogData?.length,
            showSizeChanger: false,
          }}
        />
      </Card>
      <ProgressLogModal
        visible={isProgressLogModalVisible}
        setVisible={setIsProgressLogModalVisible}
        setData={setNewProgressLog}
        data={newProgressLog}
        onSave={onValidateProgressLog}
        validate={validateNewProgressLog}
        setValidate={setValidateNewProgressLog}
      />
    </Modal>
  )
}

export default TaskToDoModal
