export const CREATE_FEEDBACK_RESPONSE = "CREATE_FEEDBACK_RESPONSE"
export const SET_CREATE_FEEDBACK_RESPONSE = "SET_CREATE_FEEDBACK_RESPONSE"
export const GET_LIST_FEEDBACK_RESPONSE = "GET_LIST_FEEDBACK_RESPONSE"
export const SET_LIST_FEEDBACK_RESPONSE = "SET_LIST_FEEDBACK_RESPONSE"
export const GET_FEEDBACK_RESPONSE = "GET_FEEDBACK_RESPONSE"
export const UPDATE_FEEDBACK_RESPONSE = "UPDATE_FEEDBACK_RESPONSE"
export const SET_UPDATE_FEEDBACK_RESPONSE = "SET_UPDATE_FEEDBACK_RESPONSE"
export const DELETE_FEEDBACK_RESPONSE = "DELETE_FEEDBACK_RESPONSE"
export const SET_DELETE_FEEDBACK_RESPONSE = "SET_DELETE_FEEDBACK_RESPONSE"
export const GET_FEEDBACK_RESPONSE_DETAIL = "GET_FEEDBACK_RESPONSE_DETAIL"
export const SET_FEEDBACK_RESPONSE_DETAIL = "SET_FEEDBACK_RESPONSE_DETAIL"
export const UNMOUNT_FEEDBACK_RESPONSE_DETAIL =
  "UNMOUNT_FEEDBACK_RESPONSE_DETAIL"
