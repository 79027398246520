import React, { lazy, Suspense, useEffect } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import Loading from "components/shared-components/Loading"
import { APP_PREFIX_PATH } from "configs/AppConfig"
import Dashboard from "./dashboard"
import { useDispatch, useSelector } from "react-redux"
import { getRolesPrivileges } from "redux/actions/Auth"
import Utils from "utils"

export const AppViews = () => {
  const dispatch = useDispatch()
  const { roles, privileges } = useSelector((state) => state.auth)
  useEffect(() => {
    if (roles.length < 1) {
      dispatch(getRolesPrivileges())
    }
  }, [roles, privileges, dispatch])
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/settings`}
          component={lazy(() => import(`views/app-views/settings`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/setup`}
          component={lazy(() => import(`views/app-views/setup`))}
        />
        <Route path={`${APP_PREFIX_PATH}/dashboard`} component={Dashboard} />
        {Utils.checkUserRole("Customer") && (
          <Route
            path={`${APP_PREFIX_PATH}/registration`}
            component={lazy(() => import(`views/app-views/registration`))}
          />
        )}
        <Route
          path={`${APP_PREFIX_PATH}/points-management`}
          component={lazy(() => import(`views/app-views/points-management`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/member`}
          component={lazy(() => import(`views/app-views/member`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/rewards`}
          component={lazy(() => import(`views/app-views/rewards`))}
        />
        {Utils.checkUserRole("Campaign") && (
          <Route
            path={`${APP_PREFIX_PATH}/campaign`}
            component={lazy(() => import(`views/app-views/campaign`))}
          />
        )}
        <Route
          path={`${APP_PREFIX_PATH}/reporting`}
          component={lazy(() => import(`views/app-views/reporting`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/feedback`}
          component={lazy(() => import(`views/app-views/feedback`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/member-list/view`}
          component={lazy(() =>
            import(`views/app-views/reporting/member-listing/view`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/customer-reward-status-report/view`}
          component={lazy(() =>
            import(
              `views/app-views/reporting/customer-reward-status-report/view`
            )
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/customer-performance-report/view`}
          component={lazy(() =>
            import(`views/app-views/reporting/customer-performance-report/view`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/member-profile-amendment-report/view`}
          component={lazy(() =>
            import(
              `views/app-views/reporting/member-profile-amendment-report/view`
            )
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/member-point-statement/view`}
          component={lazy(() =>
            import(`views/app-views/reporting/member-point-statement/view`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/member-point-statement/view-summary`}
          component={lazy(() =>
            import(
              `views/app-views/reporting/member-point-statement/view-summary`
            )
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/customer-analysis-report/view`}
          component={lazy(() =>
            import(`views/app-views/reporting/customer-analysis-report/view`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/product-sales-analysis-report/view`}
          component={lazy(() =>
            import(
              `views/app-views/reporting/product-sales-analysis-report/view`
            )
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/member-sales-analysis-report/view`}
          component={lazy(() =>
            import(
              `views/app-views/reporting/member-sales-analysis-report/view`
            )
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/customer-transaction-report/view`}
          component={lazy(() =>
            import(`views/app-views/reporting/customer-transaction-report/view`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/non-member-transaction-report/view`}
          component={lazy(() =>
            import(
              `views/app-views/reporting/non-member-transaction-report/view`
            )
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/repeat-customer-analysis-report/view`}
          component={lazy(() =>
            import(
              `views/app-views/reporting/repeat-customer-analysis-report/view`
            )
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/point-expiry-report/view`}
          component={lazy(() =>
            import(`views/app-views/reporting/point-expiry-report/view`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/premium-gift-redemption-listing/view`}
          component={lazy(() =>
            import(
              `views/app-views/reporting/premium-gift-redemption-listing/view`
            )
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/inactive-reactive-member-listing/view`}
          component={lazy(() =>
            import(
              `views/app-views/reporting/inactive-reactive-member-listing/view`
            )
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/member-category-analysis-report/view`}
          component={lazy(() =>
            import(
              `views/app-views/reporting/member-category-analysis-report/view`
            )
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/audit-trail-report/view`}
          component={lazy(() =>
            import(`views/app-views/reporting/audit-trail-report/view`)
          )}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews)
