import {
  GET_POINT_ADJUSTMENTS,
  GET_POINT_ADJUSTMENT,
  CREATE_POINT_ADJUSTMENT,
  DELETE_POINT_ADJUSTMENT,
  RESULT_POINT_ADJUSTMENT,
  ALERT_POINT_ADJUSTMENT,
  REMOVE_POINT_ADJUSTMENT_ALERT,
  SET_POINT_ADJUSTMENT,
} from "redux/constants/PointAdjustment"

export const getPointAdjustments = (data) => {
  return {
    type: GET_POINT_ADJUSTMENTS,
    payload: data,
  }
}

export const getPointAdjustment = (data) => {
  return {
    type: GET_POINT_ADJUSTMENT,
    payload: data,
  }
}

export const createPointAdjustment = (data) => {
  return {
    type: CREATE_POINT_ADJUSTMENT,
    payload: data,
  }
}

export const deletePointAdjustment = (data) => {
  return {
    type: DELETE_POINT_ADJUSTMENT,
    payload: data,
  }
}

export const resultPointAdjustment = (data) => {
  return {
    type: RESULT_POINT_ADJUSTMENT,
    payload: data,
  }
}

export const alertPointAdjustment = (alert) => {
  return {
    type: ALERT_POINT_ADJUSTMENT,
    payload: alert,
  }
}

export const removePointAdjustmentAlert = () => {
  return {
    type: REMOVE_POINT_ADJUSTMENT_ALERT,
  }
}

export const setPointAdjustment = (data) => {
  return {
    type: SET_POINT_ADJUSTMENT,
    payload: data,
  }
}
