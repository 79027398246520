import {
  SET_COMMON_LOADING,
  GET_COMMON_RESULT,
  SET_LIST_MEDIA,
  SET_CONTACT_LOG,
  SET_POSTCODE,
} from "../constants/Common"

const initialState = {
  isLoading: false,
  Brand: [],
  Salutation: [],
  IDType: [],
  Gender: [],
  Race: [],
  Language: [],
  SpokenLanguage: [],
  ProfessionLevel: [],
  MonthlyIncomeRange: [],
  WhereYouKnowUs: [],
  Currency: [],
  Country: [],
  MalaysianState: [],
  media: {
    count: 0,
    rows: [],
  },
  contactLog: {
    count: 0,
    rows: [],
  },
  postcode: {
    count: 0,
    rows: [],
  },
}

const common = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case SET_COMMON_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case GET_COMMON_RESULT:
      return {
        ...state,
        [action.payload.type]: action.payload.data.rows,
        isLoading: false,
      }
    case SET_LIST_MEDIA:
      return {
        ...state,
        media: {
          count: payload.count,
          rows: payload.rows,
        },
      }
    case SET_CONTACT_LOG:
      return {
        ...state,
        contactLog: {
          count: payload.count,
          rows: payload.rows,
        },
        isLoading: false,
      }
    case SET_POSTCODE:
      return {
        ...state,
        postcode: {
          count: payload.count,
          rows: payload.rows,
        },
        isLoading: false,
      }
    default:
      return state
  }
}

export default common
