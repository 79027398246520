import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"
import { createMemberGroup } from "redux/actions/MemberGroup"
import MemberGroupModal from "../modals/MemberGroupModal"

export const MemberGroupAddButton = (props) => {
  const [showModal, setShowModal] = useState(false)

  const { createMemberGroup, alert } = props

  useEffect(() => {
    if (alert?.success === true) {
      setShowModal(false)
      setData({
        Name: "",
        Description: "",
        Status: "Active",
      })
    }
  }, [alert])

  const [data, setData] = useState({
    Name: "",
    Description: "",
    Status: "Active",
  })

  const saveGroup = () => {
    createMemberGroup(data)
  }

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        type="primary"
        icon={<PlusCircleOutlined />}
        block
      >
        Add Card Group
      </Button>
      <MemberGroupModal
        visible={showModal}
        setShowModal={setShowModal}
        data={data}
        setData={setData}
        saveGroup={saveGroup}
      />
    </>
  )
}

const mapStateToProps = ({ memberGroup }) => {
  const { group, alert } = memberGroup
  return { group, alert }
}

const mapDispatchToProps = {
  createMemberGroup,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberGroupAddButton)
