export const CREATE_CONTACT_TEMPLATE = "CREATE_CONTACT_TEMPLATE"
export const UPDATE_CONTACT_TEMPLATE = "UPDATE_CONTACT_TEMPLATE"
export const DELETE_CONTACT_TEMPLATE = "DELETE_CONTACT_TEMPLATE"
export const GET_CONTACT_TEMPLATE = "GET_CONTACT_TEMPLATE"
export const GET_CUSTOMER_CONTACT_TEMPLATE = "GET_CUSTOMER_CONTACT_TEMPLATE"
export const GET_CONTACT_TEMPLATES = "GET_CONTACT_TEMPLATES"

export const SET_CREATE_CONTACT_TEMPLATE = "SET_CREATE_CONTACT_TEMPLATE"
export const SET_UPDATE_CONTACT_TEMPLATE = "SET_UPDATE_CONTACT_TEMPLATE"
export const SET_DELETE_CONTACT_TEMPLATE = "SET_DELETE_CONTACT_TEMPLATE"
export const SET_GET_CONTACT_TEMPLATE = "SET_GET_CONTACT_TEMPLATE"
export const SET_GET_CUSTOMER_CONTACT_TEMPLATE =
  "SET_GET_CUSTOMER_CONTACT_TEMPLATE"
export const SET_GET_CONTACT_TEMPLATES = "SET_GET_CONTACT_TEMPLATES"

export const SET_PAGE_CONTACT_TEMPLATE = "SET_PAGE_CONTACT_TEMPLATE"
export const SET_ERROR_CONTACT_TEMPLATE = "SET_ERROR_CONTACT_TEMPLATE"
export const SET_LOADING_CONTACT_TEMPLATE = "SET_LOADING_CONTACT_TEMPLATE"
export const RESET_CONTACT_TEMPLATE = "RESET_CONTACT_TEMPLATE"
