import {
  SET_IS_POINT_EXPIRY_LOADING,
  GET_LIST_POINT_EXPIRY,
  GET_LIST_CUSTOMER_POINT_EXPIRIES,
  RESULT_POINT_EXPIRY,
  UPDATE_CUSTOMER_POINT_EXPIRY,
  SET_LIST_POINT_EXPIRY,
  UNMOUNT_POINT_EXPIRY,
} from "../constants/PointExpiry"

export const setIsPointExpiryLoading = (payload) => {
  return {
    type: SET_IS_POINT_EXPIRY_LOADING,
    payload,
  }
}

export const getListPointExpiry = (data) => {
  return {
    type: GET_LIST_POINT_EXPIRY,
    payload: data,
  }
}

export const getListCustomerPointExpiries = (data) => {
  return {
    type: GET_LIST_CUSTOMER_POINT_EXPIRIES,
    payload: data,
  }
}

export const resultPointExpiry = (data) => {
  return {
    type: RESULT_POINT_EXPIRY,
    payload: data,
  }
}

export const updateCustomerPointExpiry = (data) => {
  return {
    type: UPDATE_CUSTOMER_POINT_EXPIRY,
    payload: data,
  }
}

export const setPointExpiry = () => {
  return {
    type: SET_LIST_POINT_EXPIRY,
  }
}

export const unmountPointExpiry = () => {
  return {
    type: UNMOUNT_POINT_EXPIRY,
  }
}
