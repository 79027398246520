import {
  RESET_CANCEL_REDEEM_REWARD,
  SET_IS_CANCELLED,
  SET_IS_LOADING,
  SET_REWARD,
  SET_REWARD_IMAGES,
} from "redux/constants/CancelRedeemReward"

const initialState = {
  reward: null,
  rewardImages: [],

  isLoading: false,
  isCancelled: false,
}

const CancelRedeemReward = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_REWARD:
      return {
        ...state,
        reward: payload,
      }

    case SET_REWARD_IMAGES:
      return {
        ...state,
        rewardImages: payload,
      }

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }

    case SET_IS_CANCELLED:
      return {
        ...state,
        isCancelled: payload,
      }

    case RESET_CANCEL_REDEEM_REWARD:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default CancelRedeemReward
