import {
  SET_IS_CUSTOMER_ANALYSIS_REPORT_LOADING,
  GET_CUSTOMER_ANALYSIS_REPORT_DATA,
  RESET_CUSTOMER_ANALYSIS_REPORT_DATA,
  SET_CUSTOMER_ANALYSIS_REPORT_DATA,
} from "redux/constants/CustomerAnalysisReport"

export const setIsCustomerAnalysisReportLoading = (payload) => {
  return {
    type: SET_IS_CUSTOMER_ANALYSIS_REPORT_LOADING,
    payload,
  }
}

export const getCustomerAnalysisReport = (data) => {
  return {
    type: GET_CUSTOMER_ANALYSIS_REPORT_DATA,
    payload: data,
  }
}

export const setCustomerAnalysisReport = (data) => {
  return {
    type: SET_CUSTOMER_ANALYSIS_REPORT_DATA,
    payload: data,
  }
}

export const resetCustomerAnalysisReport = (data) => {
  return {
    type: RESET_CUSTOMER_ANALYSIS_REPORT_DATA,
    payload: data,
  }
}
