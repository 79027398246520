import React, { useEffect } from "react"
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom"
import { connect } from "react-redux"
import AppLayout from "layouts/app-layout"
import AuthLayout from "layouts/auth-layout"
import AppLocale from "lang"
import { IntlProvider } from "react-intl"
import { ConfigProvider } from "antd"
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig"
import useBodyClass from "hooks/useBodyClass"
import SelfServiceLayout from "layouts/self-service-layout"
import { ssoSignIn } from "redux/actions/Auth"
import CustomerFeedbackLayout from "layouts/customer-feedback-layout"
import LogoutLayout from "layouts/logout-layout"

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const Views = (props) => {
  const { locale, location, direction, token, ssoSignIn } = props
  const query = useQuery()
  const isRedirectedFromSso = query.get("redirectedFromSso")
  const redirectedUserId = query.get("redirectedUserId")
  const authCode = query.get("code")

  useEffect(() => {
    if (isRedirectedFromSso && redirectedUserId && authCode) {
      ssoSignIn(authCode)
    }
  }, [isRedirectedFromSso, authCode, redirectedUserId, ssoSignIn])

  const currentAppLocale = AppLocale[locale]
  useBodyClass(`dir-${direction}`)
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route path="/customer/feedback">
            <CustomerFeedbackLayout />
          </Route>
          <Route path="/self-service">
            <SelfServiceLayout
              direction={direction}
              location={location}
              isAuthenticated={token}
            />
          </Route>
          <Route path="/logout">
            <LogoutLayout />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme
  const { token } = auth
  return { locale, token, direction }
}

const mapDispatchToProps = {
  ssoSignIn,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Views))
