const dev = {
  API_ENDPOINT_URL: process.env.REACT_APP_CRM_API_URL,
  API2_ENDPOINT_URL: process.env.REACT_APP_CRM_API2_URL,
  SSO_API_BASE_URL: process.env.REACT_APP_SSO_API_URL,
  COMMON_API_BASE_URL: process.env.REACT_APP_COMMON_API_URL,
  CRM_CLIENT_ID: process.env.REACT_APP_CRM_CLIENT_ID,
  COMMON_CLIENT_ID: process.env.REACT_APP_COMMON_CLIENT_ID,
  AZURE_BLOB_STORAGE_URL: process.env.REACT_APP_AZURE_BLOB_STORAGE_URL,
  SYSTEM_EMAIL: process.env.REACT_APP_SYSTEM_EMAIL,
  SYSTEM_PASSWORD: process.env.REACT_APP_SYSTEM_PASSWORD,
  MJC_BASE_URL: process.env.REACT_APP_MJC_URL,
  CRM_BASE_URL: process.env.REACT_APP_CRM_URL,
}
const prod = {
  API_ENDPOINT_URL: process.env.REACT_APP_CRM_API_URL,
  API2_ENDPOINT_URL: process.env.REACT_APP_CRM_API2_URL,
  SSO_API_BASE_URL: process.env.REACT_APP_SSO_API_URL,
  COMMON_API_BASE_URL: process.env.REACT_APP_COMMON_API_URL,
  CRM_CLIENT_ID: process.env.REACT_APP_CRM_CLIENT_ID,
  COMMON_CLIENT_ID: process.env.REACT_APP_COMMON_CLIENT_ID,
  AZURE_BLOB_STORAGE_URL: process.env.REACT_APP_AZURE_BLOB_STORAGE_URL,
  SYSTEM_EMAIL: process.env.REACT_APP_SYSTEM_EMAIL,
  SYSTEM_PASSWORD: process.env.REACT_APP_SYSTEM_PASSWORD,
  MJC_BASE_URL: process.env.REACT_APP_MJC_URL,
  CRM_BASE_URL: process.env.REACT_APP_CRM_URL,
}
const test = {
  API_ENDPOINT_URL: "https://api.test.com",
  API2_ENDPOINT_URL: process.env.REACT_APP_CRM_API2_URL,
}

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev
    case "production":
      return prod
    case "test":
      return test
    default:
      break
  }
}

export const env = getEnv()
