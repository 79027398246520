export const CREATE_FEEDBACK_OPTION = "CREATE_FEEDBACK_OPTION"
export const SET_CREATE_FEEDBACK_OPTION = "SET_CREATE_FEEDBACK_OPTION"
export const GET_LIST_FEEDBACK_OPTION = "GET_LIST_FEEDBACK_OPTION"
export const SET_LIST_FEEDBACK_OPTION = "SET_LIST_FEEDBACK_OPTION"
export const GET_FEEDBACK_OPTION = "GET_FEEDBACK_OPTION"
export const UPDATE_FEEDBACK_OPTION = "UPDATE_FEEDBACK_OPTION"
export const SET_UPDATE_FEEDBACK_OPTION = "SET_UPDATE_FEEDBACK_OPTION"
export const DELETE_FEEDBACK_OPTION = "DELETE_FEEDBACK_OPTION"
export const SET_DELETE_FEEDBACK_OPTION = "SET_DELETE_FEEDBACK_OPTION"
export const UNMOUNT_LIST_FEEDBACK_OPTION = "UNMOUNT_LIST_FEEDBACK_OPTION"
