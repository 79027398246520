export const SET_LIST_POINT_ADJUSTMENT_LOADING =
  "SET_LIST_POINT_ADJUSTMENT_LOADING"
export const GET_LIST_POINT_ADJUSTMENT = "GET_LIST_POINT_ADJUSTMENT"
export const GET_MEMBER_POINT_EXPIRY = "GET_MEMBER_POINT_EXPIRY"
export const GET_MEMBER_DETAIL = "GET_MEMBER_DETAIL"
export const RESULT_POINT_ADJUSTMENT = "RESULT_POINT_ADJUSTMENT"
export const RESULT_MEMBER_DETAIL = "RESULT_MEMBER_DETAIL"
export const GET_MEMBER_GROUP_DETAIL = "GET_MEMBER_GROUP_DETAIL"
export const GET_MEMBER_POINT_EXPIRY_DETAIL = "GET_MEMBER_POINT_EXPIRY_DETAIL"
export const GET_MEMBERSHIP_LEVEL_DETAIL = "GET_MEMBERSHIP_LEVEL_DETAIL"
export const GET_MEMBER_CARD_LEVEL_DETAIL = "GET_MEMBER_CARD_LEVEL_DETAIL"
export const GET_CUSTOMER_ELIGIBLE_REWARD = "GET_CUSTOMER_ELIGIBLE_REWARD"
