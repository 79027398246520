import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  CREATE_CUSTOMER,
  GET_LIST_CUSTOMER,
  GET_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  GET_CURRENT_CUSTOMER,
  CREATE_CUSTOMER_SELF_SERVICE,
  GET_CUSTOMER_MEMBERSHIP_DETAILS,
  GET_CUSTOMER_MEMBERSHIP_MERGE_DETAILS,
  UPDATE_CUSTOMER_LOGIN,
  UPGRADE_TO_PREMIUM,
  GET_CARD_LEVEL_DETAIL,
  VERIFY_REFERRER,
  MERGE_PREMIUM_CUSTOMER_MEMBERSHIP,
} from "../constants/Customer"
import {
  resultCustomer,
  setCurrentCustomer,
  alertCustomer,
  setCustomerMembershipDetails,
  setCustomerMembershipMergeDetails,
  setConfirmUpgrade,
  initializeCustomerLoading,
} from "../actions/Customer"
import CustomerService from "services/CustomerService"
import { isArray } from "lodash"
import CommonService from "services/CommonService"
import CustomerAddressService from "services/CustomerAddressService"
import setNotification from "components/shared-components/Notification"
import {
  addCrmCustomField,
  updateCrmCustomField,
} from "redux/actions/CustomField"
import CustomFieldService from "services/CustomFieldService"
import {
  AUTH_TOKEN,
  CLIENT_IP_ADDRESS,
  UNIQUE_SESSION_ID,
} from "redux/constants/Auth"
import { createCustomerActivity } from "redux/actions/CustomerActivity"
import { createProfileUpdatedHistory } from "redux/actions/ProfileUpdatedHistory"
import { getAllMemberList } from "redux/actions/Reporting"
import AuthService from "services/AuthService"

const getFileType = (fileType) => {
  if (fileType.indexOf("image") !== -1) {
    return "Photo"
  } else if (fileType.indexOf("video") !== -1) {
    return "Video"
  } else {
    return "Document"
  }
}

export function* createCustomerAPI() {
  yield takeEvery(CREATE_CUSTOMER, function* ({ payload }) {
    yield put(initializeCustomerLoading())
    const sessionId = sessionStorage.getItem(UNIQUE_SESSION_ID)
    const userIP = sessionStorage.getItem(CLIENT_IP_ADDRESS)

    let auth_token = sessionStorage.getItem(AUTH_TOKEN)
    let userInfo = JSON.parse(sessionStorage.getItem("userinfo"))

    payload.access_token = auth_token
    try {
      let filesToUpload = payload.fileList
      let AddressData = payload.AddressData
      const CustomFieldData = payload.CustomField
      const BlockedReasonData = payload.BlockedReason

      delete payload.file
      // delete payload.addressData
      delete payload.CustomField

      const group = yield call(CustomerService.createCustomer, {
        ...payload.CustomerData,
        access_token: payload.access_token,
      })
      if (group.alert) {
        yield put(alertCustomer({ success: false, message: group.alert }))
      } else {
        if (filesToUpload.length) {
          for (const file of filesToUpload) {
            let files = file?.name.split(".")
            let fileName = files[0]
            let fileExtension = files.pop()

            let fdata = new FormData()
            fdata.append("ObjectId", group.data.CustomerId)
            fdata.append("ObjectType", "CustomerId")
            fdata.append("Type", getFileType(file.type))
            fdata.append("FileStream", file.originFileObj)
            fdata.append("FileName", fileName)
            fdata.append("FileExtension", fileExtension)
            fdata.append("Title", "ApplicationForm")
            fdata.append("Description", group.data.FullName)

            yield call(CommonService.uploadImageMediasInternal, fdata)
          }
        }

        AddressData.CustomerId = group.data.CustomerId

        if (
          Object.keys(AddressData).length &&
          payload.CustomerData.MemberType !== "Premium Member"
        ) {
          yield call(CustomerAddressService.createCustomerAddress, {
            ...AddressData,
            access_token: payload.access_token,
          })
        }

        /** Add Custom Field */
        for (let i = 0; i < CustomFieldData?.length; i++) {
          const { CustomFieldCode, CustomFieldValue } = CustomFieldData[i]
          yield put(
            addCrmCustomField({
              ObjectType: "Customer",
              ObjectId: group.data.CustomerId,
              Status: "Active",
              CustomFieldCode,
              CustomFieldValue,
            })
          )
        }

        /** Add Customer Activity */
        for (const key in BlockedReasonData) {
          const reason = BlockedReasonData[key]
          if (reason) {
            let activityCode = ""

            switch (key) {
              case "AllowedToCall":
                activityCode = "X_CALL"
                break
              case "AllowedToSMS":
                activityCode = "X_SMS"
                break
              case "AllowedToEmail":
                activityCode = "X_EMAIL"
                break
              case "AllowNotification":
                activityCode = "X_ADDRESS"
                break
              default:
                break
            }

            yield put(
              createCustomerActivity({
                CustomerId: group.data.CustomerId,
                ActivityCode: activityCode,
                ActivityLog: reason,
                Status: "Active",
                SSOBuildingId: String(userInfo.building?.id),
                notification: "hidden",
              })
            )
          }
        }

        /** Add Login Activity */
        yield call(AuthService.createLoginActivity, {
          SessionId: sessionId,
          SourceIP: userIP,
          ObjectType: "UserId",
          ObjectId: String(userInfo?.id),
          ActivityDateTime: new Date(),
          ActivityDescription: `Add New Customer in Add Customer. CustomerId - ${group.data.CustomerId}`,
        })

        group.type = "CREATE"
        yield put(resultCustomer(group))
        yield put(
          alertCustomer({
            success: true,
            message: "A new customer has been created",
          })
        )
        payload.history.goBack()
      }
    } catch (err) {
      console.error(err)

      yield put(
        alertCustomer({
          success: false,
          message:
            (isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message) ?? "Something went wrong.",
        })
      )
    }
  })
}

// TODO: Move functions to SelfService
export function* createCustomerSelfServiceAPI() {
  yield takeEvery(CREATE_CUSTOMER_SELF_SERVICE, function* ({ payload }) {
    yield put(initializeCustomerLoading())
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      let Signature = payload.Signature
      // let AddressData = payload.AddressData
      const CustomFieldData = payload.CustomField

      delete payload.Signature
      delete payload.addressData
      delete payload.CustomField

      /** Validation */
      const customerFound = yield call(CustomerService.getListCustomer, {
        Email: payload.CustomerData.Email,
        access_token: payload.access_token,
      })

      const customer = customerFound.data?.rows[0]

      if (customer) {
        if (customer.MemberType === "Premium Member") {
          return yield put(
            alertCustomer({
              success: false,
              message: "Customer Email exists",
            })
          )
        } else {
          return yield put(
            setConfirmUpgrade({
              isConfirmUpgrade: true,
              upgradeData: {
                ...customer,
                Signature,
              },
            })
          )
        }
      }
      /** End Validation */

      const group = yield call(CustomerService.createCustomerSelfService, {
        ...payload.CustomerData,
        access_token: payload.access_token,
      })
      if (group.alert) {
        yield put(alertCustomer({ success: false, message: group.alert }))
      } else {
        // if (payload.CustomerData.MemberType === "Online Member") {
        //   let LoginData = {
        //     CustomerId: group.data.CustomerId,
        //     Password: "Tomei@" + payload.CustomerData.ContactNo.slice(-4),
        //     ForceChangePasswordYN: "Y",
        //   }
        //   yield call(
        //     CustomerService.customerLogin,
        //     LoginData,
        //     payload.access_token
        //   )
        // }

        if (Signature) {
          Signature.append("FileName", `${group.data.CustomerId}_Signature`)
          Signature.append("FileExtension", "png")
          Signature.append("ObjectId", group.data.CustomerId)
          yield call(CommonService.uploadImageMediasInternal, Signature)
        }

        // AddressData.CustomerId = group.data.CustomerId

        // if (payload.CustomerData.MemberType !== "Premium Member") {
        //   if (AddressData.Address) {
        //     yield call(CustomerAddressService.createCustomerAddress, {
        //       ...AddressData,
        //       access_token: payload.access_token,
        //     })
        //   }
        // }

        /** Add Custom Field */
        for (let i = 0; i < CustomFieldData?.length; i++) {
          const { CustomFieldCode, CustomFieldValue } = CustomFieldData[i]
          const dataCustomField = {
            ObjectType: "Customer",
            ObjectId: group.data.CustomerId,
            Status: "Active",
            CustomFieldCode,
            CustomFieldValue,
          }

          yield call(CustomFieldService.addCrmCustomField, dataCustomField)
        }
        /** End Add Custom Field */

        group.type = "DETAIL"
        yield put(resultCustomer(group))
        yield put(
          alertCustomer({
            success: true,
            message: "A new customer has been created",
          })
        )
      }

      // Validate Customer Self-Service Passcode
      payload.callback()
    } catch (err) {
      yield put(
        alertCustomer({
          success: false,
          message:
            (isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message) ?? "Something went wrong.",
        })
      )
    }
  })
}

export function* listCustomerAPI() {
  yield takeEvery(GET_LIST_CUSTOMER, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(CustomerService.getListCustomer, payload)
      if (group.alert) {
      } else {
        group.type = "LIST"
        yield put(resultCustomer(group))
      }
    } catch (err) {}
  })
}

export function* getCustomerAPI() {
  yield takeEvery(GET_CUSTOMER, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(CustomerService.getCustomer, payload)
      if (group.alert) {
        yield put(alertCustomer(group.alert))
      } else {
        group.type = "DETAIL"
        yield put(resultCustomer(group))
      }
    } catch (err) {
      yield put(alertCustomer(err))
    }
  })
}

export function* updateCustomerAPI() {
  yield takeEvery(UPDATE_CUSTOMER, function* ({ payload }) {
    const sessionId = sessionStorage.getItem(UNIQUE_SESSION_ID)
    const userIP = sessionStorage.getItem(CLIENT_IP_ADDRESS)
    let auth_token = sessionStorage.getItem(AUTH_TOKEN)

    payload.access_token = auth_token
    const CustomFieldData = payload.CustomField

    delete payload.addressData
    delete payload.CustomField

    try {
      const group = yield call(CustomerService.updateCustomer, payload)
      if (group.alert) {
        yield put(alertCustomer({ success: false, message: group.alert }))
      } else {
        const { oldFileList, fileList, CustomerId, FullName } = payload

        let filesToUpload = fileList

        if ((oldFileList ?? []).length > 0) {
          const filesToDelete = oldFileList.filter(
            (oldFile) =>
              !fileList?.map((file) => file.uid)?.includes(oldFile.uid)
          )
          for (let file of filesToDelete) {
            yield call(CommonService.deleteMedia, file.uid)
          }

          filesToUpload = fileList.filter(
            (file) =>
              !oldFileList?.map((oldFile) => oldFile.uid)?.includes(file.uid)
          )
        }

        for (let file of filesToUpload) {
          let files = file?.name.split(".")
          let fileName = files[0]
          let fileExtension = files.pop()

          let fdata = new FormData()
          fdata.append("ObjectId", CustomerId)
          fdata.append("ObjectType", "CustomerId")
          fdata.append("Type", getFileType(file.type))
          fdata.append("FileStream", file.originFileObj)
          fdata.append("FileName", fileName)
          fdata.append("FileExtension", fileExtension)
          fdata.append("Title", "ApplicationForm")
          fdata.append("Description", FullName)

          yield call(CommonService.uploadImageMediasInternal, fdata)
        }

        /** Add Custom Field */
        for (let i = 0; i < CustomFieldData?.length; i++) {
          const { CustomFieldCode, CustomFieldValue, CustomFieldId } =
            CustomFieldData[i]

          if (!CustomFieldId) {
            yield put(
              addCrmCustomField({
                ObjectType: "Customer",
                ObjectId: group.data.CustomerId,
                Status: "Active",
                CustomFieldCode,
                CustomFieldValue,
              })
            )
          } else {
            yield put(
              updateCrmCustomField({
                CustomFieldId,
                CustomFieldCode,
                CustomFieldValue,
              })
            )
          }
        }

        let userInfo = JSON.parse(sessionStorage.getItem("userinfo"))

        /** Add Customer Activity */
        yield put(
          createCustomerActivity({
            CustomerId: group.data.CustomerId,
            ActivityCode: "PROFILE",
            ActivityLog: "PROFILE UPDATED BY HQ",
            Status: "Active",
            SSOBuildingId: String(userInfo.building?.id),
            notification: "hidden",
          })
        )

        /** Add Login Activity */
        yield call(AuthService.createLoginActivity, {
          SessionId: sessionId,
          SourceIP: userIP,
          ObjectType: "UserId",
          ObjectId: String(userInfo?.id),
          ActivityDateTime: new Date(),
          ActivityDescription: `Update Customer in Update Customer. CustomerId - ${group.data.CustomerId}`,
        })

        group.type = "UPDATE"
        yield put(resultCustomer(group))
        yield put(
          alertCustomer({
            success: true,
            message: "Customer details have been saved successfully.",
          })
        )
      }

      payload?.redirect && payload.redirect("/registration/customers")
    } catch (err) {
      yield put(
        alertCustomer({
          success: false,
          message: isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message || "",
        })
      )
    }
  })
}

export function* deleteCustomerAPI() {
  yield takeEvery(DELETE_CUSTOMER, function* ({ payload }) {
    const sessionId = sessionStorage.getItem(UNIQUE_SESSION_ID)
    const userIP = sessionStorage.getItem(CLIENT_IP_ADDRESS)
    let auth_token = sessionStorage.getItem(AUTH_TOKEN)

    payload.access_token = auth_token
    try {
      const group = yield call(CustomerService.deleteCustomer, payload)

      let userInfo = JSON.parse(sessionStorage.getItem("userinfo"))

      /** Add Customer Activity */
      yield put(
        createCustomerActivity({
          CustomerId: payload.CustomerId,
          ActivityCode: "PROFILE",
          ActivityLog: payload.Reason,
          Status: "Active",
          SSOBuildingId: String(userInfo.building?.id),
          notification: "hidden",
        })
      )

      /** Add Login Activity */
      yield call(AuthService.createLoginActivity, {
        SessionId: sessionId,
        SourceIP: userIP,
        ObjectType: "UserId",
        ObjectId: String(userInfo?.id),
        ActivityDateTime: new Date(),
        ActivityDescription: `Delete Customer in Customer Listing. CustomerId - ${payload.CustomerId}`,
      })

      if (group.alert) {
        yield put(alertCustomer({ success: false, message: group.alert }))
      } else {
        // group.type = "DELETE"
        // group.data = payload
        // yield put(resultCustomer(group))

        yield put(
          getAllMemberList({
            page: 1,
            rows: 10,
            TotalRecordCountYN: "Y",
            WithAddressYN: "N",
            WithCategoryYN: "Y",
            WithPointYN: "N",
          })
        )

        setNotification({
          type: "success",
          message: "Customer has been deleted successfully",
        })
      }
    } catch (err) {
      if (err?.response?.data) {
        setNotification({
          type: "error",
          message: err.response.data.error,
          description: err.response.data.message?.toString(),
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* getCurrentCustomerAPI() {
  yield takeEvery(GET_CURRENT_CUSTOMER, function* ({ payload }) {
    try {
      const customer = yield call(CustomerService.getCurrentCustomer, payload)
      if (customer) {
        yield put(setCurrentCustomer(customer.data))
      }
    } catch (err) {
      if (err?.response?.data) {
        setNotification({
          type: "error",
          message: err.response.data.error,
          description: err.response.data.message?.toString(),
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* getCustomerMembershipDetailsAPI() {
  yield takeEvery(GET_CUSTOMER_MEMBERSHIP_DETAILS, function* ({ payload }) {
    try {
      const memberDetails = yield call(
        CustomerService.getCustomerMembershipDetails,
        payload
      )
      if (memberDetails) {
        if (memberDetails?.Status === "Active") {
          yield put(setCustomerMembershipDetails(memberDetails))
        } else {
          let message = `Member is ${memberDetails.Status}`
          if (!memberDetails.Status) {
            message = "Record not found"
          }
          setNotification({
            type: "error",
            message,
          })
        }
      }
    } catch (err) {
      yield put(alertCustomer(err))
    }
  })
}

export function* getCustomerMembershipMergeDetailsAPI() {
  yield takeEvery(
    GET_CUSTOMER_MEMBERSHIP_MERGE_DETAILS,
    function* ({ payload }) {
      try {
        const customerMembership = yield call(
          CustomerService.getCustomerMembershipMergeDetails,
          payload
        )

        const result = customerMembership.data?.rows?.[0]

        if (result) {
          yield put(setCustomerMembershipMergeDetails(result))
        } else {
          setNotification({
            type: "error",
            message: "Customer Membership Not Found",
          })
        }
      } catch (err) {
        yield put(alertCustomer(err))
      }
    }
  )
}

export function* updateCustomerLogin() {
  yield takeEvery(UPDATE_CUSTOMER_LOGIN, function* ({ payload }) {
    try {
      const sessionId = sessionStorage.getItem(UNIQUE_SESSION_ID)
      const userIP = sessionStorage.getItem(CLIENT_IP_ADDRESS)

      let auth_token = sessionStorage.getItem(AUTH_TOKEN)
      payload.access_token = auth_token
      const result = yield call(CustomerService.updateCustomerLogin, payload)

      if (result.alert) {
        setNotification({
          type: "error",
          message: "Update password was failed!",
        })
      } else {
        setNotification({
          type: "success",
          message: result?.data?.message,
        })

        /** Add Customer Activity */
        let userInfo = JSON.parse(sessionStorage.getItem("userinfo"))

        yield put(
          createCustomerActivity({
            CustomerId: payload.id,
            ActivityCode: "RESET_PWD",
            ActivityLog: "MEMBER PASSWORD RESET BY HQ",
            Status: "Active",
            SSOBuildingId: String(userInfo.building?.id),
            notification: "hidden",
          })
        )

        yield put(
          createProfileUpdatedHistory({
            CustomerId: payload.id,
            ActionType: "Update",
            UpdatedFieldName: "Reset Password Reason",
            PreviousInfo: "",
            UpdatedInfo: payload.Reason,
          })
        )

        /** Add Login Activity */
        yield call(AuthService.createLoginActivity, {
          SessionId: sessionId,
          SourceIP: userIP,
          ObjectType: "UserId",
          ObjectId: String(userInfo?.id),
          ActivityDateTime: new Date(),
          ActivityDescription: `Reset Customer Password in Customer Listing. CustomerId - ${payload.id}`,
        })
      }
    } catch (err) {
      if (err?.response?.data) {
        setNotification({
          type: "error",
          message: err.response.data.error,
          description: err.response.data.message?.toString(),
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* upgradeToPremiumAPI() {
  yield takeEvery(UPGRADE_TO_PREMIUM, function* ({ payload }) {
    let auth_token = sessionStorage.getItem(AUTH_TOKEN)
    payload.access_token = auth_token

    try {
      let Signature = payload.Signature

      const CustomFieldData = payload.CustomField
      delete payload.CustomField

      const group = yield call(CustomerService.upgradeToPremium, {
        ...payload,
        access_token: payload.access_token,
        ValidateDataYN: "Y",
      })

      if (Signature) {
        Signature.append("FileName", `${group.data.CustomerId}_Signature`)
        Signature.append("FileExtension", "png")
        Signature.append("ObjectId", group.data.CustomerId)
        yield call(CommonService.uploadImageMediasInternal, Signature)
      }

      /** Create or update custom fields */
      for (let i = 0; i < CustomFieldData?.length; i++) {
        const { CustomFieldCode, CustomFieldValue, CustomFieldId } =
          CustomFieldData[i]

        if (!CustomFieldId)
          yield put(
            addCrmCustomField({
              ObjectType: "Customer",
              ObjectId: payload.CustomerId,
              Status: "Active",
              CustomFieldCode,
              CustomFieldValue,
            })
          )
        else
          yield put(
            updateCrmCustomField({
              CustomFieldId,
              CustomFieldCode,
              CustomFieldValue,
            })
          )
      }
      /** End Create or update custom fields */

      yield put(
        setConfirmUpgrade({
          isConfirmUpgrade: false,
          upgradeData: null,
        })
      )

      group.type = "DETAIL"
      yield put(resultCustomer(group))
      yield put(
        alertCustomer({
          success: true,
          message: "Customer has been upgrade to premium member successfully",
        })
      )
    } catch (err) {
      yield put(
        alertCustomer({
          success: false,
          message:
            (isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message) ?? "Something went wrong.",
        })
      )
    }
  })
}

export function* getCardLevelDetailAPI() {
  yield takeEvery(GET_CARD_LEVEL_DETAIL, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token

    try {
      const response = yield call(CustomerService.getCardLevelDetail, payload)

      if (!response.data.count) {
        return setNotification({
          type: "error",
          message: "Failed to get card level detail",
        })
      }

      const cardLevelDetail = response.data.rows[0]

      if (cardLevelDetail) {
        payload.callback(cardLevelDetail)
      }
    } catch (err) {
      setNotification({
        type: "error",
        message: "Failed to get card level detail",
      })
    }
  })
}

export function* verifyReferrerAPI() {
  yield takeEvery(VERIFY_REFERRER, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token

    try {
      const response = yield call(CustomerService.verifyReferrer, payload)

      if (!response.data.count) {
        return setNotification({
          type: "error",
          message: "Referrer has no purchase",
        })
      }

      // When there is a purchase by referrer, it will search for memberships and assign them as referrer
      yield call(payload.callback)
    } catch (err) {
      setNotification({
        type: "error",
        message: "Verify Referrer Membership No failed",
      })
    }
  })
}

export function* mergePremiumCustomerMembershipAPI() {
  yield takeEvery(MERGE_PREMIUM_CUSTOMER_MEMBERSHIP, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token

    try {
      yield call(CustomerService.mergePremiumCustomerMembership, payload)

      yield put(
        createLoginActivity(
          `Merge Customer in Customer Listing. CustomerId - ${payload?.CustomerId}`
        )
      )

      if (payload.callback) {
        yield call(payload.callback)
      }

      return setNotification({
        type: "success",
        message: "Merge Premium Member successful.",
      })
    } catch (err) {
      setNotification({
        type: "error",
        message: err.response?.data?.message ?? "An Error Occured",
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listCustomerAPI),
    fork(createCustomerAPI),
    fork(getCustomerAPI),
    fork(updateCustomerAPI),
    fork(deleteCustomerAPI),
    fork(getCurrentCustomerAPI),
    fork(createCustomerSelfServiceAPI),
    fork(getCustomerMembershipDetailsAPI),
    fork(getCustomerMembershipMergeDetailsAPI),
    fork(updateCustomerLogin),
    fork(upgradeToPremiumAPI),
    fork(getCardLevelDetailAPI),
    fork(verifyReferrerAPI),
    fork(mergePremiumCustomerMembershipAPI),
  ])
}
