import {
  CREATE_CONTACT_TEMPLATE,
  DELETE_CONTACT_TEMPLATE,
  UPDATE_CONTACT_TEMPLATE,
  GET_CONTACT_TEMPLATE,
  GET_CUSTOMER_CONTACT_TEMPLATE,
  GET_CONTACT_TEMPLATES,
  SET_CREATE_CONTACT_TEMPLATE,
  SET_DELETE_CONTACT_TEMPLATE,
  SET_UPDATE_CONTACT_TEMPLATE,
  SET_GET_CONTACT_TEMPLATE,
  SET_GET_CUSTOMER_CONTACT_TEMPLATE,
  SET_GET_CONTACT_TEMPLATES,
  SET_ERROR_CONTACT_TEMPLATE,
  SET_LOADING_CONTACT_TEMPLATE,
  SET_PAGE_CONTACT_TEMPLATE,
  RESET_CONTACT_TEMPLATE,
} from "redux/constants/ContactTemplate"

export const createContactTemplate = (data) => {
  return {
    type: CREATE_CONTACT_TEMPLATE,
    payload: data,
  }
}

export const setCreateContactTemplate = (data) => {
  return {
    type: SET_CREATE_CONTACT_TEMPLATE,
    payload: data,
  }
}

export const updateContactTemplate = (data) => {
  return {
    type: UPDATE_CONTACT_TEMPLATE,
    payload: data,
  }
}

export const setUpdateContactTemplate = (data) => {
  return {
    type: SET_UPDATE_CONTACT_TEMPLATE,
    payload: data,
  }
}

export const deleteContactTemplate = (data) => {
  return {
    type: DELETE_CONTACT_TEMPLATE,
    payload: data,
  }
}

export const setDeleteContactTemplate = (data) => {
  return {
    type: SET_DELETE_CONTACT_TEMPLATE,
    payload: data,
  }
}

export const getContactTemplate = (id) => {
  return {
    type: GET_CONTACT_TEMPLATE,
    payload: id,
  }
}

export const setGetContactTemplate = (id) => {
  return {
    type: SET_GET_CONTACT_TEMPLATE,
    payload: id,
  }
}

export const getCustomerContactTemplate = (data) => {
  return {
    type: GET_CUSTOMER_CONTACT_TEMPLATE,
    payload: data,
  }
}

export const setGetCustomerContactTemplate = (data) => {
  return {
    type: SET_GET_CUSTOMER_CONTACT_TEMPLATE,
    payload: data,
  }
}

export const getContactTemplates = (data) => {
  return {
    type: GET_CONTACT_TEMPLATES,
    payload: data,
  }
}

export const setGetContactTemplates = (data) => {
  return {
    type: SET_GET_CONTACT_TEMPLATES,
    payload: data,
  }
}

export const setPageContactTemplate = (data) => {
  return {
    type: SET_PAGE_CONTACT_TEMPLATE,
    payload: data,
  }
}

export const setError = (data) => {
  return {
    type: SET_ERROR_CONTACT_TEMPLATE,
    payload: data,
  }
}

export const setLoading = (data) => {
  return {
    type: SET_LOADING_CONTACT_TEMPLATE,
    payload: data,
  }
}

export const reset = (data) => {
  return {
    type: RESET_CONTACT_TEMPLATE,
    payload: data,
  }
}
