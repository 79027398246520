import {
  CLEAR_REWARD_INVENTORY,
  RESULT_REWARD_INVENTORY,
  REMOVE_REWARD_INVENTORY_ALERT,
  REWARD_INVENTORY_ALERT,
} from "redux/constants/RewardInventory"

const initialState = {
  rewardInventories: [],
  rewardInventory: {},
  alert: {},
}

const Reward = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESULT_REWARD_INVENTORY:
      if (payload.type === REWARD_INVENTORY_ALERT) {
        return {
          ...state,
          alert: { status: true, success: true },
        }
      }
      break
    case CLEAR_REWARD_INVENTORY:
      return {
        ...state,
        rewardInventory: {},
      }
    case REMOVE_REWARD_INVENTORY_ALERT:
      return {
        ...state,
        alert: {},
      }

    default:
      return state
  }
}

export default Reward
