import { notification } from "antd"

const setNotification = (data) => {
  notification[data.type]({
    message: data.message,
    description: data.description,
  })
}

export default setNotification
