import {
  SET_IS_LOADING,
  RESULT_TRANSACTION_SUMMARY,
  REMOVE_TRANSACTION_SUMMARY,
  ALERT_TRANSACTION_SUMMARY,
  REMOVE_TRANSACTION_SUMMARY_ALERT,
  RESULT_TRANSACTION_DETAIL,
  ALERT_TRANSACTION_DETAIL,
  REMOVE_TRANSACTION_DETAIL_ALERT,
  REMOVE_SELECTED_TRANSACTION_SUMMARY,
} from "redux/constants/TransactionHistory"

const initialState = {
  isLoading: {},
  summary: {
    count: 0,
    rows: [],
  },
  customerTransaction: {
    count: 0,
    rows: [],
  },
  detail: {},
  summaryAlert: {},
  detailAlert: {},
  selectedSummary: {},
  selectedDetail: {},
}
const TransactionHistory = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: { ...state.isLoading, ...action.payload },
      }

    case RESULT_TRANSACTION_SUMMARY:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          summary: action.payload.data,
          selectedSummary: {},
          selectedDetail: {},
          isLoading: {},
        }
      } else if (action.payload.type === "CUSTOMER_TRANSACTION_LIST") {
        return {
          ...state,
          customerTransaction: action.payload.data,
          selectedSummary: {},
          selectedDetail: {},
          isLoading: {},
        }
      } else if (action.payload.type === "RECORD") {
        return {
          ...state,
          selectedSummary: action.payload.data,
          selectedDetail: {},
          isLoading: {},
        }
      } else if (action.payload.type === "CREATE") {
        return {
          ...state,
          summary: {
            ...state.summary,
            count: state.summary.count + 1,
            rows: [action.payload.data, ...state.summary.rows],
          },
          selectedSummary: {},
          selectedDetail: {},
          isLoading: {},
        }
      } else if (action.payload.type === "SEARCH") {
        return {
          ...state,
          selectedSummary: {
            ...state.selectedSummary,
            MembershipNo: action.payload.data?.MembershipNo,
            CustomerIDNo: action.payload.data?.Customer?.IdNo,
            CustomerName: action.payload.data?.Customer?.FullName,
          },
        }
      }
      break

    case REMOVE_TRANSACTION_SUMMARY:
      return initialState

    case ALERT_TRANSACTION_SUMMARY:
      return {
        ...state,
        summaryAlert: {
          status: true,
          success: action.payload.success,
          message: action.payload.message,
        },
      }

    case REMOVE_TRANSACTION_SUMMARY_ALERT:
      return {
        ...state,
        summaryAlert: {
          status: false,
        },
      }

    case REMOVE_SELECTED_TRANSACTION_SUMMARY:
      return {
        ...state,
        selectedSummary: {},
      }

    case RESULT_TRANSACTION_DETAIL:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          detailAlert: {
            status: false,
          },
          detail: action.payload.data,
          selectedDetail: {},
          isLoading: {},
        }
      } else if (action.payload.type === "RECORD") {
        return {
          ...state,
          detailAlert: {
            status: false,
          },
          selectedDetail: action.payload.data,
          isLoading: {},
        }
      } else if (action.payload.type === "CREATE") {
        return {
          ...state,
          detail: {
            ...state.detail,
            count: state.detail.count + 1,
            rows: [action.payload.data, ...state.detail.rows],
          },
        }
      }
      break
    case ALERT_TRANSACTION_DETAIL:
      return {
        ...state,
        detailAlert: {
          status: true,
          success: action.payload.success,
          message: action.payload.message,
        },
        isLoading: {},
      }

    case REMOVE_TRANSACTION_DETAIL_ALERT:
      return {
        ...state,
        detailAlert: {
          status: false,
        },
      }

    default:
      return state
  }
}

export default TransactionHistory
