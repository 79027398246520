import axios from "axios"
import { API_BASE_URL } from "configs/AppConfig"

const host = API_BASE_URL
const CustomerActivityTypeService = {}

CustomerActivityTypeService.getListCustomerActivityType = function (data) {
  const searchquery = {}
  if (!(data.Status === "" || data.Status === "All"))
    searchquery.Status = data.Status
  if (!(data.ActivityCode === "" || data.ActivityCode === "All"))
    searchquery.ActivityCode = data.ActivityCode
  if (!(data.Description === "" || data.Description === "All"))
    searchquery.Description = data.Description
  if (!(data.AutoSyncYN === "")) searchquery.AutoSyncYN = data.AutoSyncYN

  const payload = {
    Rows: data.rows ? data.rows : 20,
    Page: data.page ? data.page : 1,
    Search: JSON.stringify(searchquery),
  }

  return axios({
    url: host + "/customer/activity-types/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: payload,
  })
}

CustomerActivityTypeService.getCustomerActivityType = function (data) {
  return axios({
    url: host + `/customer/activity-types/find-one`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { ActivityCode: data.AddressId },
  })
}

CustomerActivityTypeService.createCustomerActivityType = function (data) {
  return axios({
    url: host + `/customer/activity-types/add`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

CustomerActivityTypeService.updateCustomerActivityType = function (data) {
  const payload = { ...data, ActivityCode: data?.ActivityCode }

  return axios({
    url: host + `/customer/activity-types/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: payload,
  })
}

CustomerActivityTypeService.deleteCustomerActivityType = function (data) {
  return axios({
    url: host + `/customer/activity-types/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { ActivityCode: data?.ActivityCode },
  })
}
export default CustomerActivityTypeService
