import {
  RESULT_CAMPAIGN_OBJECT,
  SET_CAMPAIGN_OBJECT,
} from "redux/constants/CampaignObject"

const initialState = {
  campaignObjectData: [],
  alert: {},
}

const campaign = (state = initialState, action) => {
  switch (action.type) {
    case RESULT_CAMPAIGN_OBJECT:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          campaignObjectData: action.payload.data,
        }
      } else if (action.payload.type === "DELETE") {
        return {
          ...state,
          campaignObjectData: state.campaignObjectData?.rows?.filter(
            (campaign) => campaign?.ObjectValue !== action.payload?.ObjectCode
          ),
        }
      }
      break
    case SET_CAMPAIGN_OBJECT:
      return {
        ...state,
        campaignObjectData: action.payload,
      }
    default:
      return state
  }
}

export default campaign
