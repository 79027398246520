import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"
import { createTaskGroup } from "redux/actions/TaskGroup"
import TaskGroupModal from "../modals/TaskGroupModal"

export const TaskGroupAddButton = (props) => {
  const [showModal, setShowModal] = useState(false)

  const { createTaskGroup, alert } = props

  useEffect(() => {
    if (alert?.success === true) {
      setShowModal(false)
      setData({
        Code: "",
        Description: "",
        Remarks: "",
        Status: "Active",
      })
    }
  }, [alert])

  const [data, setData] = useState({
    Code: "",
    Description: "",
    Remarks: "",
    Status: "Active",
  })

  const saveGroup = () => {
    createTaskGroup(data)
  }

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        type="primary"
        icon={<PlusCircleOutlined />}
        block
      >
        Add Task Group
      </Button>
      <TaskGroupModal
        visible={showModal}
        setShowModal={setShowModal}
        data={data}
        setData={setData}
        saveGroup={saveGroup}
      />
    </>
  )
}

const mapStateToProps = ({ taskGroup }) => {
  const { group, alert } = taskGroup
  return { group, alert }
}

const mapDispatchToProps = {
  createTaskGroup,
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskGroupAddButton)
