import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const RewardService = {}

RewardService.getListReward = function (params) {
  const data = { ...params }

  if (params.page) {
    delete data.page
    data.Page = params.page
  }

  if (params.rows) {
    delete data.rows
    data.Rows = params.rows
  }

  if (params.search) {
    delete data.search
    data.Search = JSON.stringify(params.search ?? {})
  }

  return fetch({
    url: "/reward/rewards/find-all",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

RewardService.getReward = function (id) {
  return fetch({
    url: `/reward/rewards/find-one`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { RewardId: id },
  })
}

RewardService.deleteReward = function (id) {
  return fetch({
    url: `/reward/rewards/delete`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { RewardId: id },
  })
}

RewardService.createReward = function (data) {
  return fetch({
    url: `/reward/rewards/add`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

RewardService.updateReward = function ({ id, data }) {
  return fetch({
    url: `/reward/rewards/update`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { ...data, RewardId: id },
  })
}

export default RewardService
