import {
  GET_RESULT_CUSTOMER_ACTIVITY_TYPE,
  SET_ALERT_ACTIVITY_TYPE,
  SET_CUSTOMER_ACTIVITY_TYPE,
} from "redux/constants/CustomerActivityType"

const initialState = {
  group: [],
  codeList: [],
  alert: {},
}
const customerActivityType = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESULT_CUSTOMER_ACTIVITY_TYPE:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          group: action.payload.data,
        }
      } else if (action.payload.type === "CODE LIST") {
        return {
          ...state,
          codeList: action.payload.data,
        }
      } else if (action.payload.type === "DETAIL") {
        return {
          ...state,
          detail: action.payload.data,
        }
      } else if (action.payload.type === "CREATE") {
        if (state.codeList?.rows?.length > 0) {
          return {
            ...state,
            codeList: {
              count: state.codeList.count + 1,
              rows: [action.payload.data, ...state.codeList.rows],
            },
          }
        } else {
          return {
            ...state,
            codeList: {
              count: 1,
              rows: [action.payload.data],
            },
          }
        }
      } else if (action.payload.type === "UPDATE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: state.group.rows.map((groupParam) => {
              if (
                groupParam.ActivityCode === action.payload.data.ActivityCode
              ) {
                return {
                  ...groupParam,
                  ActivityCode: action.payload.data.ActivityCode,
                  Description: action.payload.data.Description,
                  Status: action.payload.data.Status,
                  AutoSyncYN: action.payload.data.AutoSyncYN,
                }
              } else {
                return groupParam
              }
            }),
          },
        }
      } else if (action.payload.type === "DELETE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: state.group.rows.filter(
              (groupParam) =>
                groupParam.ActivityCode !== action.payload.data.ActivityCode
            ),
          },
        }
      }
      break
    case SET_CUSTOMER_ACTIVITY_TYPE:
      return {
        ...state,
        group: action.payload,
      }
    case SET_ALERT_ACTIVITY_TYPE:
      return {
        ...state,
        alert: action.payload,
      }
    default:
      return state
  }
}

export default customerActivityType
