import {
  SET_COLLECTED_REWARD,
  SET_COLLECTED_REWARD_IMAGES,
  SET_IS_COLLECTED_REWARD,
  SET_IS_LOADING_COLLECTED_REWARD,
} from "redux/constants/CollectReward"

const initialState = {
  reward: null,
  rewardImages: [],

  isLoading: false,
  isCollected: false,
}

const CollectReward = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COLLECTED_REWARD:
      return {
        ...state,
        reward: payload,
      }

    case SET_IS_LOADING_COLLECTED_REWARD:
      return {
        ...state,
        isLoading: payload,
      }

    case SET_IS_COLLECTED_REWARD:
      return {
        ...state,
        isCollected: payload,
      }

    case SET_COLLECTED_REWARD_IMAGES:
      return {
        ...state,
        rewardImages: payload,
      }

    default:
      return state
  }
}

export default CollectReward
