export const CREATE_PROFILE_UPDATED_HISTORY = "CREATE_PROFILE_UPDATED_HISTORY"

export const SET_PROFILE_UPDATED_HISTORY_LOADING =
  "SET_PROFILE_UPDATED_HISTORY_LOADING"
export const GET_PROFILE_UPDATED_HISTORY_LIST =
  "GET_PROFILE_UPDATED_HISTORY_LIST"
export const SET_PROFILE_UPDATED_HISTORY_LIST =
  "SET_PROFILE_UPDATED_HISTORY_LIST"
export const CLEAR_PROFILE_UPDATED_HISTORY_LIST =
  "CLEAR_PROFILE_UPDATED_HISTORY_LIST"
