import React from "react"
import { Button } from "antd"
import { Link } from "react-router-dom"
import { PlusCircleOutlined } from "@ant-design/icons"

export const FeedbackQuestionAddButton = () => {
  return (
    <>
      <Link to="/feedback/question/add">
        <Button type="primary" icon={<PlusCircleOutlined />} block>
          Add Question
        </Button>
      </Link>
    </>
  )
}

export default FeedbackQuestionAddButton
