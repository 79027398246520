import setNotification from "components/shared-components/Notification"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  setCustomerRewardLoading,
  resultCustomerReward,
  setPageCustomerReward,
} from "redux/actions/CustomerReward"
import {
  GET_LIST_CUSTOMER_REWARD,
  SET_LIST_CUSTOMER_REWARD,
  CREATE_CUSTOMER_REWARD,
  SET_CREATE_CUSTOMER_REWARD,
  UPDATE_CUSTOMER_REWARD,
  SET_UPDATE_CUSTOMER_REWARD,
} from "redux/constants/CustomerReward"
import CustomerRewardService from "services/CustomerRewardService"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* listCustomerRewardAPI() {
  yield takeEvery(GET_LIST_CUSTOMER_REWARD, function* ({ payload }) {
    try {
      yield put(setCustomerRewardLoading(true))
      const data = yield call(
        CustomerRewardService.getListCustomerReward,
        payload
      )
      data.type = SET_LIST_CUSTOMER_REWARD
      yield put(setPageCustomerReward(payload.page))
      yield put(resultCustomerReward(data))
    } catch (err) {
      yield put(setCustomerRewardLoading(false))
      setError(
        "Listing Failed",
        err?.response?.data?.message ?? "Something went wrong"
      )
    }
  })
}

export function* createCustomerRewardAPI() {
  yield takeEvery(CREATE_CUSTOMER_REWARD, function* ({ payload }) {
    try {
      yield put(setCustomerRewardLoading(true))

      setNotification({
        type: "warn",
        message: "Creating Customer Reward",
        description: "Please wait until it's finished",
      })

      const customerRewardPayload = { ...payload }
      delete customerRewardPayload.Reward

      const customerReward = yield call(
        CustomerRewardService.createCustomerReward,
        customerRewardPayload
      )

      customerReward.data = {
        ...customerReward.data,
        Reward: payload.Reward,
      }
      customerReward.type = SET_CREATE_CUSTOMER_REWARD
      yield put(resultCustomerReward(customerReward))

      yield put(
        createLoginActivity(
          `Add Rewards in View Customer. ReferenceCode - ${customerReward.data.ReferenceCode}`
        )
      )

      // Close Create Reward Modal
      payload.callback()

      setNotification({
        type: "success",
        message: "Create Reward Success",
      })
    } catch (err) {
      yield put(setCustomerRewardLoading(false))
      setError(
        "Create Customer Reward Failed",
        err.response.data.message ?? "An error occured."
      )
    }
  })
}

export function* updateCustomerRewardAPI() {
  yield takeEvery(UPDATE_CUSTOMER_REWARD, function* ({ payload }) {
    try {
      yield put(setCustomerRewardLoading(true))
      const { ReferenceCode, Remarks } = payload

      const dataToUpdate = {
        InvoiceNo: payload.InvoiceNo,
        RedeemedById: payload.RedeemedById,
        RedeemedOutletCode: payload.RedeemedOutletCode,
        RedeemedDate: payload.RedeemedDate,
        ValidityFrom: payload.ValidityFrom,
        ValidityTo: payload.ValidityTo,
        Status: payload.Status,
        Remarks,
      }

      const updatedReward = yield call(
        CustomerRewardService.updateCustomerReward,
        {
          ref_code: ReferenceCode,
          data: dataToUpdate,
        }
      )

      updatedReward.type = SET_UPDATE_CUSTOMER_REWARD
      yield put(resultCustomerReward(updatedReward))

      setNotification({
        type: "success",
        message: "Update Customer Reward Success",
      })

      if (payload.Status === "Active") {
        yield put(
          createLoginActivity(
            `Update Rewards Expiry Date in View Customer. ReferenceCode - ${ReferenceCode}`
          )
        )
      } else {
        yield put(
          createLoginActivity(
            `Redeemed Rewards in View Customer. ReferenceCode - ${ReferenceCode}`
          )
        )
      }

      // Close Update Customer Reward Modal
      payload.callback()
    } catch (err) {
      setError(
        "Update Customer Reward Failed",
        err.response.data.message ?? "An error occured."
      )
      yield put(setCustomerRewardLoading(false))
    }
  })
}

// export function* getCustomerRewardAPI() {
//   yield takeEvery(GET_CUSTOMER_REWARD, function* ({ payload }) {
//     try {
//       const data = yield call(CustomerRewardService.getCustomerReward, payload)
//       data.type = SET_CUSTOMER_REWARD
//       yield put(resultCustomerReward(data))
//     } catch (err) {
//       setError("Get CustomerReward Failed", err.response.data.message ?? "")
//     }
//   })
// }

// export function* deleteCustomerRewardAPI() {
//   yield takeEvery(DELETE_CUSTOMER_REWARD, function* ({ payload }) {
//     try {
//       const data = yield call(CustomerRewardService.deleteCustomerReward, payload)
//       data.type = SET_DELETE_CUSTOMER_REWARD
//       data.id = payload
//       yield put(resultCustomerReward(data))
//       setNotification({
//         type: "success",
//         message: data?.data?.message ?? "Deleted.",
//       })
//     } catch (err) {
//       setError("Delete CustomerReward Failed", err.response.data.message ?? "")
//     }
//   })
// }

// export function* createCustomerRewardAPI() {
//   yield takeEvery(CREATE_CUSTOMER_REWARD, function* ({ payload }) {
//     try {
//       const { reward, rewardImages, rewardGroupList, rewardOutlets } = payload
//       const data = yield call(CustomerRewardService.createCustomerReward, reward)

//       for (let i = 0; i < rewardImages?.length; i++) {
//         const imageFile = rewardImages[i]
//         imageFile.append("ObjectId", data?.data?.RewardId)
//         yield call(CommonService.uploadImageMediasInternal, imageFile)
//       }

//       for (let i = 0; i < rewardGroupList?.length; i++) {
//         const { key } = rewardGroupList[i]
//         yield call(CustomerRewardObjectService.createCustomerRewardObject, {
//           RewardId: data?.data?.RewardId,
//           ObjectType: "ProntoStockGroup",
//           ObjectValue: key,
//           Status: "Active",
//         })
//       }

//       for (let i = 0; i < rewardOutlets?.length; i++) {
//         const { key } = rewardOutlets[i]
//         yield call(CustomerRewardObjectService.createCustomerRewardObject, {
//           RewardId: data?.data?.RewardId,
//           ObjectType: "Building",
//           ObjectValue: key,
//           Status: "Active",
//         })
//       }

//       data.type = SET_CREATE_CUSTOMER_REWARD
//       yield put(resultCustomerReward(data))
//     } catch (err) {
//       setError(
//         "Create CustomerReward Failed",
//         isArray(err.response.data?.message)
//           ? err.response.data?.message[0]
//           : err.response.data.message ?? ""
//       )
//     }
//   })
// }

// export function* updateCustomerRewardAPI() {
//   yield takeEvery(UPDATE_CUSTOMER_REWARD, function* ({ payload }) {
//     try {
//       const { id, reward, rewardImages, rewardGroupList, rewardOutlets } =
//         payload
//       const data = yield call(CustomerRewardService.updateCustomerReward, { id, data: reward })

//       for (let i = 0; i < rewardImages?.length; i++) {
//         const imageFile = rewardImages[i]
//         imageFile.append("ObjectId", data?.data?.RewardId)
//         yield call(CommonService.uploadImageMediasInternal, imageFile)
//       }

//       for (let i = 0; i < rewardGroupList?.length; i++) {
//         const { CustomerRewardObjectId, key, chosen, isDeleted } = rewardGroupList[i]
//         if (isDeleted)
//           yield call(CustomerRewardObjectService.updateCustomerRewardObject, {
//             id: CustomerRewardObjectId,
//             data: {
//               RewardId: data?.data?.RewardId,
//               ObjectType: "ProntoStockGroup",
//               ObjectValue: key,
//               Status: "Deleted",
//             },
//           })
//         else if (!chosen)
//           yield call(CustomerRewardObjectService.createCustomerRewardObject, {
//             RewardId: data?.data?.RewardId,
//             ObjectType: "ProntoStockGroup",
//             ObjectValue: key,
//             Status: "Active",
//           })
//       }

//       for (let i = 0; i < rewardOutlets?.length; i++) {
//         const { CustomerRewardObjectId, key, chosen, isDeleted } = rewardOutlets[i]
//         if (isDeleted)
//           yield call(CustomerRewardObjectService.updateCustomerRewardObject, {
//             id: CustomerRewardObjectId,
//             data: {
//               RewardId: data?.data?.RewardId,
//               ObjectType: "Building",
//               ObjectValue: key,
//               Status: "Deleted",
//             },
//           })
//         else if (!chosen)
//           yield call(CustomerRewardObjectService.createCustomerRewardObject, {
//             RewardId: data?.data?.RewardId,
//             ObjectType: "Building",
//             ObjectValue: key,
//             Status: "Active",
//           })
//       }

//       data.type = SET_CREATE_CUSTOMER_REWARD
//       yield put(resultCustomerReward(data))
//     } catch (err) {
//       setError(
//         "Create CustomerReward Failed",
//         isArray(err.response.data?.message)
//           ? err.response.data?.message[0]
//           : err.response.data.message ?? ""
//       )
//     }
//   })
// }

export default function* rootSaga() {
  yield all([
    fork(listCustomerRewardAPI),
    fork(createCustomerRewardAPI),
    fork(updateCustomerRewardAPI),
    // fork(getCustomerRewardAPI),
    // fork(deleteCustomerRewardAPI),
  ])
}
