import {
  RESET_CONTACT_TEMPLATE,
  SET_CREATE_CONTACT_TEMPLATE,
  SET_UPDATE_CONTACT_TEMPLATE,
  SET_DELETE_CONTACT_TEMPLATE,
  SET_GET_CONTACT_TEMPLATE,
  SET_GET_CUSTOMER_CONTACT_TEMPLATE,
  SET_GET_CONTACT_TEMPLATES,
  SET_LOADING_CONTACT_TEMPLATE,
  SET_ERROR_CONTACT_TEMPLATE,
  SET_PAGE_CONTACT_TEMPLATE,
} from "redux/constants/ContactTemplate"

const initialState = {
  rows: 10, // constant
  page: 1,
  count: 0,
  contactTemplates: [],
  contactTemplate: null,
  customerContactTemplate: null,
  isLoading: false,
  error: "",
  success: null,
  action: "",
}

const ContactTemplate = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CREATE_CONTACT_TEMPLATE:
      return {
        ...state,
        error: "",
        success: true,
        isLoading: false,
        action: "create",
      }

    case SET_UPDATE_CONTACT_TEMPLATE:
      return {
        ...state,
        error: "",
        success: true,
        isLoading: false,
        action: "update",
      }

    case SET_GET_CONTACT_TEMPLATE:
      return {
        ...state,
        contactTemplate: payload.data,
        error: "",
        success: true,
        isLoading: false,
        action: "get",
      }

    case SET_GET_CUSTOMER_CONTACT_TEMPLATE:
      return {
        ...state,
        customerContactTemplate: payload.data.rows[0],
        error: "",
        success: true,
        isLoading: false,
        action: "get",
      }

    case SET_DELETE_CONTACT_TEMPLATE:
      const updateContactTemplate = state.contactTemplates.filter(
        (contact) => contact.TemplateId !== payload.id
      )
      return {
        ...state,
        contactTemplates: updateContactTemplate,
      }

    case SET_GET_CONTACT_TEMPLATES:
      return {
        ...state,
        contactTemplates: payload.data.rows,
        count: payload.data.count,
      }

    case SET_ERROR_CONTACT_TEMPLATE:
      return {
        ...state,
        error: payload.message,
        success: false,
        isLoading: false,
        action: payload.action,
      }

    case SET_LOADING_CONTACT_TEMPLATE:
      return {
        ...state,
        isLoading: payload,
      }

    case SET_PAGE_CONTACT_TEMPLATE:
      return { ...state, page: payload }

    case RESET_CONTACT_TEMPLATE:
      return {
        ...state,
        isLoading: false,
        error: "",
        success: null,
        action: "",
      }

    default:
      return state
  }
}

export default ContactTemplate
