import {
  GET_LIST_TASK_GROUP,
  CREATE_TASK_GROUP,
  GET_RESULT_TASK_GROUP,
  GET_TASK_GROUP,
  UPDATE_TASK_GROUP,
  DELETE_TASK_GROUP,
  RESULT_TASK_GROUP,
  ALERT_TASK_GROUP,
  REMOVE_GROUP_TASK_ALERT,
} from "redux/constants/TaskGroup"

export const createTaskGroup = (data) => {
  return {
    type: CREATE_TASK_GROUP,
    payload: data,
  }
}

export const getListTaskGroup = (data) => {
  return {
    type: GET_LIST_TASK_GROUP,
    payload: data,
  }
}

export const getResultTaskGroup = (data) => {
  return {
    type: GET_RESULT_TASK_GROUP,
    payload: data,
  }
}

export const getTaskGroup = (data) => {
  return {
    type: GET_TASK_GROUP,
    payload: data,
  }
}

export const updateTaskGroup = (data) => {
  const payload = {
    Code: data.Code,
    Remarks: data.Remarks,
    Description: data.Description,
    Status: data.Status,
  }
  return {
    type: UPDATE_TASK_GROUP,
    payload: payload,
  }
}

export const deleteTaskGroup = (data) => {
  return {
    type: DELETE_TASK_GROUP,
    payload: data,
  }
}

export const resultTaskGroup = (data) => {
  return {
    type: RESULT_TASK_GROUP,
    payload: data,
  }
}

export const alertTaskGroup = (alert) => {
  return {
    type: ALERT_TASK_GROUP,
    payload: alert,
  }
}

export const removeGroupTaskAlert = () => {
  return {
    type: REMOVE_GROUP_TASK_ALERT,
  }
}
