import {
  SET_IS_REPORTING_LOADING,
  SET_MEMBER_LIST,
  SET_CUSTOMER_ANALYSIS_REPORT,
  SET_CUSTOMER_REWARD_STATUS_REPORT,
  SET_CUSTOMER_PERFORMANCE_REPORT,
  SET_MEMBER_LIST_PREMIUM,
  SET_PRODUCT_SALES_ANALYSIS_REPORT,
  SET_MEMBER_SALES_ANALYSIS_REPORT,
  SET_MEMBER_PROFILE_AMENDMENT_REPORT,
  SET_CUSTOMER_TRANSACTION_REPORT,
  SET_NON_MEMBER_TRANSACTION_REPORT,
  SET_REPEAT_CUSTOMER_ANALYSIS_REPORT,
  SET_PREMIUM_GIFT_REDEMPTION_LISTING,
  SET_INACTIVE_REACTIVE_MEMBER_LISTING,
  SET_MEMBER_CATEGORY_ANALYSIS_REPORT,
  SET_AUDIT_TRAIL_REPORT,
} from "redux/constants/Reporting"

const initialState = {
  isLoading: false,
  memberList: { count: 0, rows: [] },
  memberListPremium: { count: 0, rows: [] },
  alert: {},
  totalActiveMember: 0,
  totalNewMember: 0,
  totalSalesYesterday: 0,
  totalTransaction: 0,
  totalSalesByGender: { count: 0, rows: [] },
  totalSalesByRace: { count: 0, rows: [] },
  totalSalesByAge: { count: 0, rows: [] },
  totalSales: { count: 0, rows: [] },
  reportStatus: { count: 0, rows: [] },
  performanceReport: { count: 0, rows: [] },
  productSalesAnalysisReport: { count: 0, rows: [] },
  memberSalesAnalysisReport: { count: 0, rows: [] },
  memberProfileAmendmentReport: { count: 0, rows: [] },
  customerTransactionReport: { count: 0, rows: [] },
  nonMemberTransactionReport: { count: 0, rows: [] },
  repeatCustomerAnalysisReport: { count: 0, rows: [] },
  premiumGiftRedemptionListing: { count: 0, rows: [] },
  inactiveReactiveMemberListing: { count: 0, rows: [] },
  memberCategoryAnalysisReport: { count: 0, rows: [] },
  auditTrailReport: { count: 0, rows: [] },
}

const reporting = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_REPORTING_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case SET_MEMBER_LIST:
      return {
        ...state,
        memberList: {
          count: action?.payload?.data?.count ?? 0,
          rows:
            action.payload?.isGeneratingReport &&
            !action.payload?.isGeneratingExcelReport
              ? [...state.memberList.rows, ...action?.payload?.data?.rows] ?? []
              : action?.payload?.data?.rows,
        },
        isLoading: false,
      }
    case SET_MEMBER_LIST_PREMIUM:
      return {
        ...state,
        memberListPremium: {
          count: action?.payload?.data?.count ?? 0,
          rows:
            action.payload?.isGeneratingReport &&
            !action.payload?.isGeneratingExcelReport
              ? [
                  ...state.memberListPremium.rows,
                  ...action?.payload?.data?.rows,
                ] ?? []
              : action?.payload?.data?.rows,
        },
        isLoading: false,
      }
    case SET_CUSTOMER_ANALYSIS_REPORT:
      return {
        ...state,
        totalActiveMember: action.payload.totalActiveMember ?? 0,
        totalNewMember: action.payload.totalNewMember ?? 0,
        totalSalesYesterday: action.payload.totalSalesYesterday ?? 0,
        totalTransaction: action.payload.totalTransaction ?? 0,
        totalSelfRegistered: action.payload.totalSelfRegistered ?? 0,
        totalManualRegistered: action.payload.totalManualRegistered ?? 0,
        totalSalesByGender: action.payload.totalSalesByGender ?? {
          count: 0,
          rows: [],
        },
        totalSalesByRace: action.payload.totalSalesByRace ?? {
          count: 0,
          rows: [],
        },
        totalSalesByAge: action.payload.totalSalesByAge ?? {
          count: 0,
          rows: [],
        },
        totalSales: action.payload.totalSales ?? { count: 0, rows: [] },
        isLoading: false,
      }
    case SET_CUSTOMER_REWARD_STATUS_REPORT:
      return {
        ...state,
        reportStatus: {
          count: action?.payload?.data?.count ?? 0,
          rows: action?.payload?.data?.rows ?? [],
        },
        isLoading: false,
      }
    case SET_CUSTOMER_PERFORMANCE_REPORT:
      return {
        ...state,
        performanceReport: {
          count: action?.payload?.data?.count ?? 0,
          rows: action?.payload?.data?.rows ?? [],
        },
        isLoading: false,
      }
    case SET_PRODUCT_SALES_ANALYSIS_REPORT:
      return {
        ...state,
        productSalesAnalysisReport: {
          count: action?.payload?.data?.count ?? 0,
          rows: action?.payload?.data?.rows ?? [],
        },
        isLoading: false,
      }
    case SET_MEMBER_SALES_ANALYSIS_REPORT:
      return {
        ...state,
        memberSalesAnalysisReport: {
          count: action?.payload?.data?.count ?? 0,
          rows: action?.payload?.data?.rows ?? [],
        },
        isLoading: false,
      }
    case SET_MEMBER_PROFILE_AMENDMENT_REPORT:
      return {
        ...state,
        memberProfileAmendmentReport: {
          count: action?.payload?.data?.count ?? 0,
          rows: action?.payload?.data?.rows ?? [],
        },
        isLoading: false,
      }
    case SET_CUSTOMER_TRANSACTION_REPORT:
      return {
        ...state,
        customerTransactionReport: {
          count: action?.payload?.data?.count ?? 0,
          rows: action?.payload?.data?.rows ?? [],
        },
        isLoading: false,
      }
    case SET_NON_MEMBER_TRANSACTION_REPORT:
      return {
        ...state,
        nonMemberTransactionReport: {
          count: action?.payload?.data?.count ?? 0,
          rows: action?.payload?.data?.rows ?? [],
        },
        isLoading: false,
      }
    case SET_REPEAT_CUSTOMER_ANALYSIS_REPORT:
      return {
        ...state,
        repeatCustomerAnalysisReport: {
          count: action?.payload?.data?.count ?? 0,
          rows: action?.payload?.data?.rows ?? [],
        },
        isLoading: false,
      }
    case SET_PREMIUM_GIFT_REDEMPTION_LISTING:
      return {
        ...state,
        premiumGiftRedemptionListing: {
          count: action?.payload?.data?.count ?? 0,
          rows: action?.payload?.data?.rows ?? [],
        },
        isLoading: false,
      }
    case SET_INACTIVE_REACTIVE_MEMBER_LISTING:
      return {
        ...state,
        inactiveReactiveMemberListing: {
          count: action?.payload?.data?.count ?? 0,
          rows: action.payload?.isGeneratingExcelReport
            ? action?.payload?.data?.rows
            : [
                ...state.inactiveReactiveMemberListing.rows,
                ...action?.payload?.data?.rows,
              ] ?? [],
        },
        isLoading: false,
      }
    case SET_MEMBER_CATEGORY_ANALYSIS_REPORT:
      return {
        ...state,
        memberCategoryAnalysisReport: {
          count: action?.payload?.data?.count ?? 0,
          rows: action?.payload?.data?.rows ?? [],
        },
        isLoading: false,
      }
    case SET_AUDIT_TRAIL_REPORT:
      return {
        ...state,
        auditTrailReport: {
          count: action?.payload?.data?.count ?? 0,
          rows: action?.payload?.data?.rows ?? [],
        },
        isLoading: false,
      }
    default:
      return state
  }
}

export default reporting
