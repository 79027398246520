import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  CREATE_POINT_EXPIRY_REWARD,
  CREATE_MEMBER_REWARD_TO_POINT_EXPIRY,
  GET_LIST_POINT_EXPIRY_REWARD_ELIGIBLE,
} from "redux/constants/PointExpiryReward"
import PointExpiryRewardService from "services/PointExpiryRewardService"
import CustomerRewardService from "services/CustomerRewardService"
import setNotification from "components/shared-components/Notification"
import {
  setIsPointExpiryRewardLoading,
  setCreatePointExpiryReward,
  setCreateMemberPointToPointExpiry,
  setListPointExpiryRewardEligible,
} from "redux/actions/PointExpiryReward"

export function* createPointExpiryReward() {
  yield takeEvery(CREATE_POINT_EXPIRY_REWARD, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const pointExpiryReward = yield call(
        PointExpiryRewardService.createPointExpiryReward,
        payload
      )
      if (pointExpiryReward.alert) {
        setNotification({
          type: "error",
          message: "Create Point Expiry Reward Failed",
          description: "An error occured",
        })
      } else {
        yield put(setCreatePointExpiryReward(pointExpiryReward.data))
        setNotification({
          type: "success",
          message: "Create Point Expiry Reward Success",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Create Point Expiry Reward Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Create Point Expiry Reward Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* createMemberRewardToPointExpiry() {
  yield takeEvery(
    CREATE_MEMBER_REWARD_TO_POINT_EXPIRY,
    function* ({ payload }) {
      let auth_token = sessionStorage.getItem("auth_token")
      try {
        for (const eligibleMemberReward of payload.selectedEligibleMemberReward) {
          const customerRewardPayload = {
            CustomerId: eligibleMemberReward.CustomerId,
            RewardId: eligibleMemberReward.RewardId,
            DeductPointInClaimYN: "Y",
            access_token: auth_token,
          }

          const customerReward = yield call(
            CustomerRewardService.createCustomerReward,
            customerRewardPayload
          )

          const pointExpiryRewardPayload = {
            MembershipNo: eligibleMemberReward.MembershipNo,
            ExpiryYear: payload.ExpiryYear,
            ExpiryMonth: payload.ExpiryMonth,
            PointExpiry: String(eligibleMemberReward.PointExpiry),
            PointBalance: String(eligibleMemberReward.CurrentBalance),
            ReferenceCode: customerReward.data.ReferenceCode,
            access_token: auth_token,
          }

          const pointExpiryReward = yield call(
            PointExpiryRewardService.createPointExpiryReward,
            pointExpiryRewardPayload
          )

          yield put(setCreateMemberPointToPointExpiry(pointExpiryReward.data))
        }

        setNotification({
          type: "success",
          message: "Create Member Reward Point Expiry Success",
        })
      } catch (error) {
        if (error?.response?.data) {
          const { message } = error.response.data
          setNotification({
            type: "error",
            message: "Create Member Reward Point Expiry Failed",
            description: message,
          })
        } else {
          setNotification({
            type: "error",
            message: "Create Member Reward Point Expiry Failed",
            description: error.message,
          })
        }
      }
    }
  )
}

export function* getPointExpiryRewardEligible() {
  yield takeEvery(
    GET_LIST_POINT_EXPIRY_REWARD_ELIGIBLE,
    function* ({ payload }) {
      let auth_token = sessionStorage.getItem("auth_token")
      payload.access_token = auth_token
      try {
        yield put(setIsPointExpiryRewardLoading(true))

        const pointExpiryRewardEligible = yield call(
          PointExpiryRewardService.getListPointExpiryRewardEligible,
          payload
        )
        if (pointExpiryRewardEligible.alert) {
          setNotification({
            type: "error",
            message: "Get Point Expiry Reward Eligible Failed",
            description: "An error occured",
          })
        } else {
          yield put(
            setListPointExpiryRewardEligible(pointExpiryRewardEligible.data)
          )
        }
      } catch (error) {
        setNotification({
          type: "error",
          message: "Get Point Expiry Reward Eligible Failed",
          description: "An error occured",
        })
      }
    }
  )
}

export default function* rootSaga() {
  yield all([
    fork(createPointExpiryReward),
    fork(createMemberRewardToPointExpiry),
    fork(getPointExpiryRewardEligible),
  ])
}
