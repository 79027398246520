import React from 'react'
import { COMPANY_NAME, COMPANY_NO } from 'configs/AppConfig';

export default function Footer() {
	return (
		<footer className="footer">
			<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${COMPANY_NAME} ${COMPANY_NO}`}</span> All rights reserved.</span>
		</footer>
	)
}

