import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from "constants/ThemeConstant"
import { env } from "./EnvironmentConfig"

export const APP_NAME = "Tomei CRM"
export const COMPANY_NAME = "Tomei Gold & Jewellery Holdings (M) Sdn.Bhd."
export const COMPANY_NO = "(197701002534)(33551-H)."
export const API_BASE_URL = env.API_ENDPOINT_URL
export const API2_BASE_URL = env.API2_ENDPOINT_URL
export const CRM_CLIENT_ID = env.CRM_CLIENT_ID
export const COMMON_CLIENT_ID = env.COMMON_CLIENT_ID
export const SSO_API_BASE_URL = env.SSO_API_BASE_URL
export const COMMON_API_BASE_URL = env.COMMON_API_BASE_URL
export const AZURE_BLOB_STORAGE_URL = env.AZURE_BLOB_STORAGE_URL
export const MJC_BASE_URL = env.MJC_BASE_URL
export const CRM_BASE_URL = env.CRM_BASE_URL
export const APP_PREFIX_PATH = ""
export const AUTH_PREFIX_PATH = "/auth"

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
}
