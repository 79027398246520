import React, { lazy, Suspense } from "react"
import { Switch, Route } from "react-router-dom"
import Loading from "components/shared-components/Loading"

export const SelfServiceView = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          exact
          path="/self-service"
          component={lazy(() => import(`./authentication/self-service`))}
        />
        <Route
          exact
          path="/self-service/customer/add"
          component={lazy(() => import(`./authentication/add-customer`))}
        />
        <Route
          exact
          path="/self-service/customer/:id/edit"
          component={lazy(() => import(`./authentication/update-customer`))}
        />
      </Switch>
    </Suspense>
  )
}

export default SelfServiceView
