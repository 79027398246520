import React from "react"
import { Modal, Form, Input, Select, DatePicker } from "antd"
import moment from "moment"

function ProgressLogModal(props) {
  const { onSave, data, setData, visible, setVisible, validate, setValidate } =
    props

  const { TextArea } = Input
  const { Option } = Select

  const ContactTypeList = ["Email", "SMS", "WhatsApp", "Call", "Mail", "N/A"]
  const pullLeft = {
    labelAlign: "left",
    wrapperCol: { span: 20 },
    labelCol: { span: 6 },
    colon: false,
  }
  const onCancel = () => {
    setVisible(false)
    setValidate({})
  }

  return (
    <div>
      <Modal
        title={`Add Progress Log`}
        visible={visible}
        onOk={() => onSave()}
        okText="Save"
        onCancel={() => onCancel()}
        destroyOnClose={true}
      >
        <Form {...pullLeft} name="formGroup" initialValues={{ remember: true }}>
          <Form.Item
            label={<label style={{ width: 100 }}>Contact Type</label>}
            name="ContactType"
          >
            <Select
              onChange={(e) => setData({ ...data, ContactType: e })}
              defaultValue={data.ContactType}
              value={data.ContactType}
            >
              {ContactTypeList.map((item, i) => (
                <Option key={i} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={<label style={{ width: 100 }}>Description</label>}
            name="Description"
          >
            <TextArea
              onChange={(e) =>
                setData({ ...data, Description: e.target.value })
              }
              rows={4}
              value={data.Description}
              required
              maxLength="300"
              style={{
                border: validate.Description
                  ? "1px solid #ff6b72"
                  : "1px solid #699dff",
                borderRadius: 10,
              }}
              onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
            />
            {validate.Description && (
              <span style={{ color: "#ff6b72" }}>{validate.Description}</span>
            )}
          </Form.Item>
          <Form.Item
            label={<label style={{ width: 100 }}>Follow Up Date</label>}
            name="FollowUpDate"
          >
            <DatePicker
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
              value={moment(data.FollowUpDate)}
              defaultValue={moment()}
              onChange={(e) => setData({ ...data, FollowUpDate: e })}
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            label={<label style={{ width: 100 }}>Status</label>}
            name="Status"
          >
            <Select
              defaultValue={data.Status}
              onChange={(value) => setData({ ...data, Status: value })}
            >
              <Option value="Active">Active</Option>
              <Option value="Inactive">Inactive</Option>
              <Option value="Completed">Completed</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default ProgressLogModal
