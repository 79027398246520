import React, { Component } from "react"

export class Icon extends Component {
  render() {
    const { type, className } = this.props
    return (
      <>
        {React.createElement(type, {
          className: className,
          style: {
            marginRight: 10,
            fontSize: "15px",
          },
        })}
      </>
    )
  }
}

export default Icon
