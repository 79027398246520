import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  CREATE_FEEDBACK_RESPONSE,
  DELETE_FEEDBACK_RESPONSE,
  GET_LIST_FEEDBACK_RESPONSE,
  GET_FEEDBACK_RESPONSE_DETAIL,
  UPDATE_FEEDBACK_RESPONSE,
} from "redux/constants/FeedbackResponse"
import FeedbackResponseService from "services/FeedbackResponseService"
import setNotification from "components/shared-components/Notification"
import {
  setListFeedbackResponse,
  setFeedbackResponseDetail,
  setCreateFeedbackResponse,
  setUpdateFeedbackResponse,
  setDeleteFeedbackResponse,
} from "redux/actions/FeedbackResponse"
import FeedbackOptionService from "services/FeedbackOptionService"

export function* createFeedbackResponse() {
  yield takeEvery(CREATE_FEEDBACK_RESPONSE, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      let feedbackResponse

      if (payload.isBatchCreate) {
        payload.responseData.map(
          (response) => (response.access_token = auth_token)
        )
        yield all(
          payload.responseData.map((response) =>
            call(FeedbackResponseService.createFeedbackResponse, {
              ...response,
              CustomerId: payload.CustomerId,
            })
          )
        )
      } else {
        feedbackResponse = yield call(
          FeedbackResponseService.createFeedbackResponse,
          payload
        )
      }

      if (feedbackResponse?.alert) {
        setNotification({
          type: "error",
          message: "Create Response Failed",
          description: "An error occured",
        })
      } else {
        yield put(setCreateFeedbackResponse(feedbackResponse?.data))
        setNotification({
          type: "success",
          message: "Create Response Success",
        })

        payload.callback()
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Create Response Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Create Response Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* getAllFeedbackResponse() {
  yield takeEvery(GET_LIST_FEEDBACK_RESPONSE, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedbackResponse = yield call(
        FeedbackResponseService.getListFeedbackResponse,
        payload
      )
      if (feedbackResponse.alert) {
        setNotification({
          type: "error",
          message: "Get Response Failed",
          description: "An error occured",
        })
      } else {
        yield put(setListFeedbackResponse(feedbackResponse.data))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Get Response Failed",
        description: "An error occured",
      })
    }
  })
}

export function* getOneFeedbackResponse() {
  yield takeEvery(GET_FEEDBACK_RESPONSE_DETAIL, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedbackResponse = yield call(
        FeedbackResponseService.getOneFeedbackResponse,
        payload
      )

      if (feedbackResponse.alert) {
        setNotification({
          type: "error",
          message: "Get Response Failed",
          description: "An error occured",
        })
      } else {
        yield put(setFeedbackResponseDetail(feedbackResponse.data))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Get Response Failed",
        description: "An error occured",
      })
    }
  })
}

export function* updateFeedbackResponse() {
  yield takeEvery(UPDATE_FEEDBACK_RESPONSE, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedbackResponse = yield call(
        FeedbackResponseService.updateFeedbackResponse,
        payload
      )
      if (feedbackResponse.alert) {
        setNotification({
          type: "error",
          message: "Update Question failed",
          description: "An error occured",
        })
      } else {
        yield put(setUpdateFeedbackResponse(feedbackResponse.data))
        setNotification({
          type: "success",
          message: "Question successfully updated",
        })

        /* Update and Manage Feedback Option  */
        // Attach authentication token to each option in payload and existing options
        payload.optionsData.map((option) => (option.access_token = auth_token))
        feedbackResponse.data.FeedbackOptions.map(
          (option) => (option.access_token = auth_token)
        )

        // Step 1: Update or Create Feedback Options
        yield all(
          payload.optionsData.map((option) => {
            if (option.OptionId) {
              // If OptionId exists, update the Feedback Option
              return call(FeedbackOptionService.updateFeedbackOption, option)
            } else {
              // If no OptionId, create a new Feedback Option
              return call(FeedbackOptionService.createFeedbackOption, {
                ...option,
                Status: "Active",
                QuestionId: feedbackResponse.data.QuestionId,
              })
            }
          })
        )

        // Step 2: Identify Feedback Options to Delete
        const optionsToDelete = feedbackResponse.data.FeedbackOptions.filter(
          (existingOption) =>
            !payload.optionsData.some(
              (option) => option.OptionId === existingOption.OptionId
            )
        )

        // Step 3: Delete Feedback Options that are no longer needed
        for (const optionToDelete of optionsToDelete) {
          yield call(FeedbackOptionService.deleteFeedbackOption, optionToDelete)
        }

        // Step 4: Redirect to Feedback Question listing after finished
        payload.callback()
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Update Response Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Update Response Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* deleteFeedbackResponse() {
  yield takeEvery(DELETE_FEEDBACK_RESPONSE, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedbackResponse = yield call(
        FeedbackResponseService.deleteFeedbackResponse,
        payload
      )
      if (feedbackResponse.alert) {
        setNotification({
          type: "error",
          message: "Delete Response failed",
          description: "An error occured",
        })
      } else {
        yield put(setDeleteFeedbackResponse(payload))
        setNotification({
          type: "success",
          message: "Response successfully deleted",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Delete Response Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Delete Response Failed",
          description: error.message,
        })
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(createFeedbackResponse),
    fork(getAllFeedbackResponse),
    fork(getOneFeedbackResponse),
    fork(updateFeedbackResponse),
    fork(deleteFeedbackResponse),
  ])
}
