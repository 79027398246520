import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button, Form } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"
import { createProductGroup } from "redux/actions/ProductGroup"
import { CreateUpdateProductGroupModal } from "../components"

export const ProductGroupAddButton = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState({
    ProductCode: "",
    ProductName: "",
    ProductType: "",
    Status: "Active",
  })

  const [form] = Form.useForm()

  useEffect(() => {
    if (props.alert?.success === true) {
      resetForm()
    }
  }, [props.alert])

  const resetForm = async () => {
    setData({
      ProductCode: "",
      ProductName: "",
      ProductType: "",
      Status: "Active",
    })
    setTimeout(() => {
      setShowModal(false)
    }, 1000)
  }

  const saveGroup = () => {
    props.createProductGroup(data)
  }

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        type="primary"
        icon={<PlusCircleOutlined />}
        block
      >
        Add Product Group
      </Button>
      <CreateUpdateProductGroupModal
        form={form}
        title="Create New Product Group"
        visible={showModal}
        setShowModal={setShowModal}
        data={data}
        productTypes={props.productTypes}
        setData={setData}
        submitHandler={saveGroup}
      />
    </>
  )
}

const mapStateToProps = ({ productGroup }) => {
  const { group, alert, productTypes } = productGroup
  return { group, alert, productTypes }
}

const mapDispatchToProps = {
  createProductGroup,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductGroupAddButton)
