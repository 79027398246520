import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  CREATE_MANUAL_CUSTOMER_TRANSACTION,
  GET_CUSTOMER_MEMBERSHIP,
  GET_MANUAL_CUSTOMER_MEMBERSHIP,
} from "redux/constants/CustomerTransaction"
import setNotification from "components/shared-components/Notification"
import {
  setIsLoading,
  resultTransactionSummary,
} from "../actions/TransactionHistory"
import CustomerTransactionService from "services/CustomerTransactionService"
import { createLoginActivity } from "redux/actions/Auth"

export function* createManualCustomerTransactionAPI() {
  yield takeEvery(CREATE_MANUAL_CUSTOMER_TRANSACTION, function* ({ payload }) {
    try {
      yield put(setIsLoading({ createCustomerTransaction: true }))

      const createdTransactionSummary = yield call(
        CustomerTransactionService.createTransactionSummary,
        payload.transactionSummary
      )

      if (payload.transactionDetails.length) {
        yield all(
          payload.transactionDetails.map((detail) =>
            call(CustomerTransactionService.createTransactionDetail, detail)
          )
        )
      }

      yield put(
        createLoginActivity(
          `Add Transaction in Customer Transaction. SalesOrder - ${createdTransactionSummary.data.SalesOrder}`
        )
      )

      createdTransactionSummary.type = "CREATE"
      yield put(resultTransactionSummary(createdTransactionSummary))

      setNotification({
        type: "success",
        message: "Create Customer Transaction Success",
      })

      // Redirect back to listing page
      payload.callback()
    } catch (err) {
      yield put(setIsLoading({ createCustomerTransaction: false }))
      const { message } = err.response.data
      setNotification({
        type: "error",
        message: "Create Customer Transaction Failed",
        description: message,
      })
    }
  })
}

export function* getCustomerMembershipAPI() {
  yield takeEvery(GET_CUSTOMER_MEMBERSHIP, function* ({ payload }) {
    try {
      const customerMembership = yield call(
        CustomerTransactionService.getCustomerMembership,
        payload
      )

      customerMembership.data = customerMembership.data?.rows?.[0]

      if (customerMembership.data) {
        customerMembership.type = "SEARCH"
        yield put(resultTransactionSummary(customerMembership))
      }
    } catch (err) {}
  })
}

export function* getManualCustomerMembershipAPI() {
  yield takeEvery(GET_MANUAL_CUSTOMER_MEMBERSHIP, function* ({ payload }) {
    try {
      const customerMembership = yield call(
        CustomerTransactionService.getCustomerMembership,
        payload.data
      )

      customerMembership.data = customerMembership.data?.rows?.[0]

      if (customerMembership.data) {
        payload.callback(customerMembership.data)
      }
    } catch (err) {}
  })
}

export default function* rootSaga() {
  yield all([
    fork(createManualCustomerTransactionAPI),
    fork(getCustomerMembershipAPI),
    fork(getManualCustomerMembershipAPI),
  ])
}
