import React, { useEffect, useState } from "react"
import { Modal, Form, Input, Switch, Select, Row, Col, Button } from "antd"
import { isEmpty } from "lodash"

function CustomFieldModal(props) {
  const [disabledListId, setDisabledListId] = useState(true)
  const [initialValues, setInitialValues] = useState({})

  const [form] = Form.useForm()

  useEffect(() => {
    if (isEmpty(initialValues) && !isEmpty(props.data) && props.visible) {
      setInitialValues(props.data)
    }
  }, [props.data, props.visible, initialValues])

  useEffect(() => {
    if (!isEmpty(initialValues)) form.resetFields()
  }, [initialValues, form])

  useEffect(() => {
    if (!props.visible) setInitialValues({})
  }, [props.visible])

  const onCancel = () => {
    props.setShowModal(false)
    form.resetFields()
    props.setData({
      ObjectType: "Customer",
      CustomFieldCode: "",
      CustomFieldName: "",
      FieldType: "",
      IsMandatoryYN: "Y",
      SequenceNo: "",
      FieldListId: "",
      AutoSyncYN: "Y",
      Status: "Active",
    })
    setInitialValues({})
  }

  const submitHandler = (e) => {
    props.submitHandler()
  }

  const pullLeft = {
    labelAlign: "left",
    wrapperCol: { span: 20 },
    labelCol: { span: 10 },
  }

  const styleFormLabel = {
    width: 160,
  }

  return (
    <div>
      <Modal
        title={props.title ? props.title : "Activity Type"}
        visible={props.visible}
        okText="Save"
        onCancel={() => onCancel()}
        destroyOnClose={true}
        footer={null}
      >
        <Form
          {...pullLeft}
          name="formGroup"
          initialValues={initialValues}
          onFinish={submitHandler}
          autoComplete={"off"}
          form={form}
        >
          <Form.Item
            label={<label style={styleFormLabel}>Object Type</label>}
            name="ObjectType"
            rules={[
              {
                required: true,
                message: "Please input object type!",
              },
            ]}
          >
            <Select
              value={props.data.ObjectType}
              onChange={(value) =>
                props.setData({ ...props.data, ObjectType: value })
              }
            >
              <Select.Option value={"Customer"}>Customer</Select.Option>
              <Select.Option value={"Task"}>Task</Select.Option>
              <Select.Option value={"Rewards"}>Rewards</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Code</label>}
            name="CustomFieldCode"
            onChange={(e) =>
              props.setData({ ...props.data, CustomFieldCode: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Please input your code!",
              },
              {
                pattern: /^[A-Z0-9_]+$/,
                message: "Please use capital letter without space",
              },
              { max: 20, message: "The maximum length is 20" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Field Name</label>}
            name="CustomFieldName"
            onChange={(e) =>
              props.setData({ ...props.data, CustomFieldName: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Please input your field name!",
              },
              { max: 50, message: "The maximum length is 50" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Field Type</label>}
            name="FieldType"
            rules={[
              {
                required: true,
                message: "Please input field type!",
              },
            ]}
          >
            <Select
              value={props.data.FieldType}
              onChange={(value) => {
                props.setData({ ...props.data, FieldType: value })
                if (["Selection", "Multiple Selection"].includes(value))
                  return setDisabledListId(false)

                setDisabledListId(true)
              }}
            >
              <Select.Option value={"Text"}>Text</Select.Option>
              <Select.Option value={"Selection"}>Selection</Select.Option>
              <Select.Option value={"Multiple Selection"}>
                Multiple Selection
              </Select.Option>
              <Select.Option value={"Multiline Text"}>
                Multiline Text
              </Select.Option>
              <Select.Option value={"Email"}>Email</Select.Option>
              <Select.Option value={"Number"}>Number</Select.Option>
              <Select.Option value={"Date"}>Date</Select.Option>
              <Select.Option value={"Datetime"}>Datetime</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Mandatory Field</label>}
            name="IsMandatoryYN"
            rules={[
              {
                required: true,
                message: "Please select mandatory field!",
              },
            ]}
          >
            <Switch
              onChange={(e) =>
                props.setData({
                  ...props.data,
                  IsMandatoryYN: e ? "Y" : "N",
                })
              }
              checked={props.data?.IsMandatoryYN === "Y"}
            />
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Sequence No</label>}
            name="SequenceNo"
            onChange={(e) =>
              props.setData({ ...props.data, SequenceNo: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Please input your sequence number!",
              },
              { pattern: /^[0-9]*$/, message: "Please only use number" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={<label style={{ width: 170 }}>List ID</label>}
            name="FieldListId"
            onChange={(e) =>
              props.setData({ ...props.data, FieldListId: e.target.value })
            }
            rules={[{ max: 30, message: "The maximum length is 30" }]}
          >
            <Input disabled={disabledListId} />
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Auto Synchronization</label>}
            name="AutoSyncYN"
            rules={[
              {
                required: true,
                message: "Please select auto synchronization!",
              },
            ]}
          >
            <Switch
              onChange={(e) =>
                props.setData({
                  ...props.data,
                  AutoSyncYN: e ? "Y" : "N",
                })
              }
              checked={props.data?.AutoSyncYN === "Y"}
            />
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Status</label>}
            name="Status"
            rules={[
              {
                required: true,
                message: "Please select status!",
              },
            ]}
          >
            <Select
              value={props.data.Status}
              onChange={(value) =>
                props.setData({ ...props.data, Status: value })
              }
            >
              <Select.Option value={"Active"}>Active</Select.Option>
              <Select.Option value={"Inactive"}>Inactive</Select.Option>
            </Select>
          </Form.Item>

          <Row gutter={{ md: 10 }} justify={"end"}>
            <Col>
              <Button onClick={onCancel}>Cancel</Button>
            </Col>

            <Col>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

export default CustomFieldModal
