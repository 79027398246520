import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  GET_LIST_POINT_ADJUSTMENT,
  GET_MEMBER_POINT_EXPIRY,
  GET_MEMBER_POINT_EXPIRY_DETAIL,
  GET_MEMBER_DETAIL,
  GET_MEMBERSHIP_LEVEL_DETAIL,
  GET_MEMBER_CARD_LEVEL_DETAIL,
  GET_CUSTOMER_ELIGIBLE_REWARD,
} from "../constants/PointSummary"
import {
  setListPointAdjustmentLoading,
  getResultPointAdjustment,
  getResultMemberDetail,
} from "../actions/PointSummary"
import PointSummaryService from "services/PointSummaryService"

export function* listPointSummaryAPI() {
  yield takeEvery(GET_LIST_POINT_ADJUSTMENT, function* ({ payload }) {
    try {
      yield put(setListPointAdjustmentLoading(true))
      const group = yield call(
        PointSummaryService.getListPointAdjustments,
        payload
      )
      if (group.alert) {
        // do nothing
      } else {
        group.type = "LIST"
        yield put(getResultPointAdjustment(group))
      }
    } catch (error) {
      yield put(setListPointAdjustmentLoading(false))
    }
  })
}

export function* getMemberPointExpiryAPI() {
  yield takeEvery(GET_MEMBER_POINT_EXPIRY, function* ({ payload }) {
    try {
      const group = yield call(PointSummaryService.getPointExpiry, payload)
      if (group.alert) {
      } else {
        group.type = "POINT"
        yield put(getResultMemberDetail(group))
      }
    } catch (error) {}
  })
}

export function* getMemberPointExpiryDetailAPI() {
  yield takeEvery(GET_MEMBER_POINT_EXPIRY_DETAIL, function* ({ payload }) {
    try {
      const group = yield call(
        PointSummaryService.getPointExpiryDetail,
        payload
      )
      if (group.alert) {
      } else {
        group.type = "POINT EXPIRY DETAIL"
        yield put(getResultMemberDetail(group))
      }
    } catch (error) {}
  })
}

export function* getMemberDetailAPI() {
  yield takeEvery(GET_MEMBER_DETAIL, function* ({ payload }) {
    try {
      const group = yield call(PointSummaryService.getMemberBalance, payload)
      if (group.alert) {
      } else {
        group.type = "MEMBER"
        yield put(getResultMemberDetail(group))
      }
    } catch (error) {}
  })
}

export function* getMembershipLevelDetailAPI() {
  yield takeEvery(GET_MEMBERSHIP_LEVEL_DETAIL, function* ({ payload }) {
    try {
      const group = yield call(
        PointSummaryService.getMembershipLevelDetail,
        payload
      )
      if (group.alert) {
      } else {
        group.type = "MEMBERSHIP LEVEL"
        yield put(getResultMemberDetail(group))
      }
    } catch (error) {}
  })
}

export function* getMemberCardLevelDetailAPI() {
  yield takeEvery(GET_MEMBER_CARD_LEVEL_DETAIL, function* ({ payload }) {
    try {
      const group = yield call(
        PointSummaryService.getMemberCardLevelDetail,
        payload
      )
      if (group.alert) {
      } else {
        group.type = "MEMBER CARD LEVEL"
        yield put(getResultMemberDetail(group))
      }
    } catch (error) {}
  })
}

export function* getCustomerEligibleRewardAPI() {
  yield takeEvery(GET_CUSTOMER_ELIGIBLE_REWARD, function* ({ payload }) {
    try {
      const group = yield call(
        PointSummaryService.getCustomerEligibleReward,
        payload
      )
      if (group.alert) {
      } else {
        group.type = "ELIGIBLE REWARD"
        yield put(getResultMemberDetail(group))
      }
    } catch (error) {}
  })
}

export default function* rootSaga() {
  yield all([
    fork(listPointSummaryAPI),
    fork(getMemberPointExpiryAPI),
    fork(getMemberPointExpiryDetailAPI),
    fork(getMemberDetailAPI),
    fork(getMembershipLevelDetailAPI),
    fork(getMemberCardLevelDetailAPI),
    fork(getCustomerEligibleRewardAPI),
  ])
}
