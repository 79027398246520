import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const ContactTemplateService = {}

ContactTemplateService.createContactTemplate = function (data) {
  return fetch({
    url: "/reward/contact-templates/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

ContactTemplateService.updateContactTemplate = function ({ id, data }) {
  return fetch({
    url: "/reward/contact-templates/update",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { ...data, TemplateId: id },
  })
}

ContactTemplateService.deleteContactTemplate = function (id) {
  return fetch({
    url: "/reward/contact-templates/delete",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { TemplateId: id },
  })
}

ContactTemplateService.getContactTemplate = function (id) {
  return fetch({
    url: "/reward/contact-templates/find-one",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { TemplateId: id },
  })
}

ContactTemplateService.getCustomerContactTemplate = function ({
  rows,
  page,
  search,
  option,
}) {
  return fetch({
    url: "/reward/contact-templates/customer/find-all",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: rows ?? 10,
      Page: page ?? 1,
      Search: JSON.stringify(search ?? {}),
      Option: option ?? "View Only",
    },
  })
}

ContactTemplateService.getContactTemplates = function ({
  rows,
  page,
  search,
  campaign,
}) {
  return fetch({
    url: "/reward/contact-templates/find-all",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: rows,
      Page: page,
      Search: JSON.stringify(search ?? {}),
      isCampaign: campaign,
    },
  })
}

export default ContactTemplateService
