import {
  SET_CUSTOMER_REWARD_LOADING,
  RESET_CUSTOMER_REWARD_ALERT,
  RESULT_CUSTOMER_REWARD,
  SET_CREATE_CUSTOMER_REWARD,
  SET_UPDATE_CUSTOMER_REWARD,
  SET_DELETE_CUSTOMER_REWARD,
  SET_LIST_CUSTOMER_REWARD,
  SET_PAGE_CUSTOMER_REWARD,
  SET_CUSTOMER_REWARD,
} from "redux/constants/CustomerReward"

const initialState = {
  isLoading: false,
  rows: 10, // constant
  page: 1,
  rewards: [],
  count: 0,
  reward: {},
  alert: {},
}

const CustomerReward = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CUSTOMER_REWARD_LOADING:
      return {
        ...state,
        isLoading: payload,
      }

    case RESULT_CUSTOMER_REWARD:
      if (payload.type === SET_LIST_CUSTOMER_REWARD) {
        return {
          ...state,
          rewards: payload.data.rows,
          count: payload.data.count,
          alert: {},
          isLoading: false,
        }
      } else if (payload.type === SET_CREATE_CUSTOMER_REWARD) {
        return {
          ...state,
          rewards: [payload.data, ...state.rewards],
          count: state.count + 1,
          isLoading: false,
        }
      } else if (payload.type === SET_UPDATE_CUSTOMER_REWARD) {
        return {
          ...state,
          rewards: state.rewards.map((reward) =>
            reward.ReferenceCode === payload.data.ReferenceCode
              ? payload.data
              : reward
          ),
          isLoading: false,
        }
      } else if (payload.type === SET_CUSTOMER_REWARD) {
        return {
          ...state,
          reward: payload.data,
          isLoading: false,
        }
      } else if (payload.type === SET_DELETE_CUSTOMER_REWARD) {
        const updatedCustomerRewards = state.rewards.filter(
          (reward) => reward.RewardId !== payload.id
        )
        return {
          ...state,
          rewards: updatedCustomerRewards,
        }
      }
      break

    case SET_PAGE_CUSTOMER_REWARD:
      return { ...state, page: payload }

    case RESET_CUSTOMER_REWARD_ALERT:
      return {
        ...state,
        alert: {},
      }

    default:
      return state
  }
}

export default CustomerReward
