import React, { useState } from "react"
import { connect } from "react-redux"
import { Button } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"
import { createFeedbackFeedback } from "redux/actions/Feedback"
import FeedbackModal from "./modal/FeedbackModal"

const FeedbackAddButton = ({ createFeedbackFeedback }) => {
  const [showModal, setShowModal] = useState(false)
  const initialValues = {
    Name: "",
    Code: "",
    Description: "",
    Type: "",
    DisplayOption: "",
    Status: "Active",
  }
  const [data, setData] = useState(initialValues)

  const saveFeedback = () => {
    createFeedbackFeedback({
      ...data,
      Code: data.Code?.toUpperCase(),
    })
  }

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        type="primary"
        icon={<PlusCircleOutlined />}
        block
      >
        Add Feedback
      </Button>
      <FeedbackModal
        title={"Create Feedback"}
        visible={showModal}
        setShowModal={setShowModal}
        data={data}
        setData={setData}
        onSubmit={saveFeedback}
      />
    </>
  )
}

const mapStateToProps = ({ feedback }) => {
  const { feedbackList } = feedback
  return { feedbackList }
}

const mapDispatchToProps = {
  createFeedbackFeedback,
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackAddButton)
