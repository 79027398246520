import {
  SET_TASK_PROGRESS_LOADING,
  GET_LIST_TASK_PROGRESS,
  CREATE_TASK_PROGRESS,
  GET_RESULT_TASK_PROGRESS,
  GET_TASK_PROGRESS,
  UPDATE_TASK_PROGRESS,
  DELETE_TASK_PROGRESS,
  RESULT_TASK_PROGRESS,
  ALERT_TASK_PROGRESS,
  REMOVE_TASK_PROGRESS_ALERT,
} from "redux/constants/TaskProgress"

export const setTaskProgressLoading = (data) => {
  return {
    type: SET_TASK_PROGRESS_LOADING,
    payload: data,
  }
}

export const createTaskProgress = (data, shouldUpdateList = true) => {
  return {
    type: CREATE_TASK_PROGRESS,
    payload: data,
    shouldUpdateList,
  }
}

export const getListTaskProgress = (data) => {
  return {
    type: GET_LIST_TASK_PROGRESS,
    payload: data,
  }
}

export const getResultTaskProgress = (data) => {
  return {
    type: GET_RESULT_TASK_PROGRESS,
    payload: data,
  }
}

export const getTaskProgress = (data) => {
  return {
    type: GET_TASK_PROGRESS,
    payload: data,
  }
}

export const updateTaskProgress = (data, shouldUpdateList = true) => {
  return {
    type: UPDATE_TASK_PROGRESS,
    payload: data,
    shouldUpdateList,
  }
}

export const deleteTaskProgress = (data) => {
  return {
    type: DELETE_TASK_PROGRESS,
    payload: data,
  }
}

export const resultTaskProgress = (data) => {
  return {
    type: RESULT_TASK_PROGRESS,
    payload: data,
  }
}

export const alertTaskProgress = (alert) => {
  return {
    type: ALERT_TASK_PROGRESS,
    payload: alert,
  }
}

export const removeTaskProgressAlert = () => {
  return {
    type: REMOVE_TASK_PROGRESS_ALERT,
  }
}
