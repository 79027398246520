import {
  RESULT_POINT_STRUCTURE,
  ALERT_POINT_STRUCTURE,
  REMOVE_POINT_STRUCTURE_ALERT,
} from "redux/constants/PointStructure"

const initialState = {
  group: [],
  alert: {},
}
const PointStructure = (state = initialState, action) => {
  switch (action.type) {
    case RESULT_POINT_STRUCTURE:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          group: action.payload.data,
        }
      } else if (action.payload.type === "CREATE") {
        return {
          ...state,
          group: {
            ...state.group,
            count: state.group.count + 1,
            rows: [action.payload.data, ...state.group.rows],
          },
        }
      } else if (action.payload.type === "UPDATE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: [
              ...state.group.rows.map((groupParam) => {
                if (groupParam.CardGroupId === action.payload.data.GroupId) {
                  return {
                    ...groupParam,
                    Name: action.payload.data.Name,
                    Description: action.payload.data.Description,
                    Status: action.payload.data.Status,
                  }
                } else {
                  return groupParam
                }
              }),
            ],
          },
        }
      } else if (action.payload.type === "DELETE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: [
              ...state.group.rows.map((groupParam) => {
                if (
                  groupParam.StructureId === action.payload.data.StructureId
                ) {
                  return {
                    ...groupParam,
                    Status: "Deleted",
                  }
                } else {
                  return groupParam
                }
              }),
            ],
          },
        }
      }
      break

    case ALERT_POINT_STRUCTURE:
      return {
        ...state,
        alert: {
          status: true,
          success: action.payload.success,
          message: action.payload.message,
        },
      }

    case REMOVE_POINT_STRUCTURE_ALERT:
      return {
        ...state,
        alert: {
          status: false,
        },
      }

    default:
      return state
  }
}

export default PointStructure
