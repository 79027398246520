import {
  SET_IS_MEMBERSHIP_LOADING,
  GET_LIST_MEMBERSHIPS,
  GET_LIST_MEMBER_POINT_STATEMENT,
  GET_LIST_POINT_SUMMARY_REPORT,
  CREATE_MEMBERSHIPS,
  GET_RESULT_MEMBERSHIPS,
  GET_MEMBERSHIPS,
  UPDATE_MEMBERSHIPS,
  DELETE_MEMBERSHIPS,
  RESULT_MEMBERSHIPS,
  ALERT_MEMBERSHIPS,
  REMOVE_MEMBERSHIPS_ALERT,
  SEARCH_LIST_MEMBERSHIPS,
} from "redux/constants/Memberships"

export const setIsMembershipLoading = (data) => {
  return {
    type: SET_IS_MEMBERSHIP_LOADING,
    payload: data,
  }
}

export const createMemberships = (data) => {
  return {
    type: CREATE_MEMBERSHIPS,
    payload: data,
  }
}

export const getListMemberships = (data) => {
  return {
    type: GET_LIST_MEMBERSHIPS,
    payload: data,
  }
}

export const getListMemberPointStatement = (data) => {
  return {
    type: GET_LIST_MEMBER_POINT_STATEMENT,
    payload: data,
  }
}

export const getListPointSummaryReport = (data) => {
  return {
    type: GET_LIST_POINT_SUMMARY_REPORT,
    payload: data,
  }
}

export const getSearchMemberships = (data) => {
  return {
    type: SEARCH_LIST_MEMBERSHIPS,
    payload: data,
  }
}
export const getResultMemberships = (data) => {
  return {
    type: GET_RESULT_MEMBERSHIPS,
    payload: data,
  }
}

export const getMemberships = (data) => {
  return {
    type: GET_MEMBERSHIPS,
    payload: data,
  }
}

export const updateMemberships = (data) => {
  const payload = {
    GroupId: data.GroupId,
    Name: data.Name,
    Description: data.Description,
    Status: data.Status,
  }
  return {
    type: UPDATE_MEMBERSHIPS,
    payload: payload,
  }
}

export const deleteMemberships = (data) => {
  return {
    type: DELETE_MEMBERSHIPS,
    payload: data,
  }
}

export const resultMemberships = (data) => {
  return {
    type: RESULT_MEMBERSHIPS,
    payload: data,
  }
}

export const alertMemberships = (alert) => {
  return {
    type: ALERT_MEMBERSHIPS,
    payload: alert,
  }
}

export const removeCustomerAlert = () => {
  return {
    type: REMOVE_MEMBERSHIPS_ALERT,
  }
}
