import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  CREATE_FEEDBACK_FEEDBACK,
  DELETE_FEEDBACK_FEEDBACK,
  GET_LIST_FEEDBACK_FEEDBACK,
  GET_FEEDBACK_FEEDBACK_DETAIL,
  UPDATE_FEEDBACK_FEEDBACK,
} from "redux/constants/Feedback"
import setNotification from "components/shared-components/Notification"
import FeedbackService from "services/FeedbackService"
import {
  setListFeedbackFeedback,
  setCreateFeedbackFeedback,
  setUpdateFeedbackFeedback,
  setDeleteFeedbackFeedback,
  setFeedbackFeedbackDetail,
} from "redux/actions/Feedback"

export function* createFeedbackFeedback() {
  yield takeEvery(CREATE_FEEDBACK_FEEDBACK, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedback = yield call(
        FeedbackService.createFeedbackFeedback,
        payload
      )
      if (feedback.alert) {
        setNotification({
          type: "error",
          message: "Create Feedback Failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Add Feedback in Feedback. FeedbackId - ${feedback.data?.FeedbackId}`
          )
        )

        yield put(setCreateFeedbackFeedback(feedback.data))
        setNotification({
          type: "success",
          message: "Create Feedback Success",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Create Feedback Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Create Feedback Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* getAllFeedbackFeedback() {
  yield takeEvery(GET_LIST_FEEDBACK_FEEDBACK, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedback = yield call(
        FeedbackService.getListFeedbackFeedback,
        payload
      )
      if (feedback.alert) {
        setNotification({
          type: "error",
          message: "Get Feedback Failed",
          description: "An error occured",
        })
      } else {
        yield put(setListFeedbackFeedback(feedback.data))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Get Feedback Failed",
        description: "An error occured",
      })
    }
  })
}

export function* getOneFeedbackFeedback() {
  yield takeEvery(GET_FEEDBACK_FEEDBACK_DETAIL, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedback = yield call(
        FeedbackService.getOneFeedbackFeedback,
        payload
      )

      if (feedback.alert) {
        setNotification({
          type: "error",
          message: "Get Feedback Failed",
          description: "An error occured",
        })
      } else {
        yield put(setFeedbackFeedbackDetail(feedback.data))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Get Feedback Failed",
        description: "An error occured",
      })
    }
  })
}

export function* updateFeedbackFeedback() {
  yield takeEvery(UPDATE_FEEDBACK_FEEDBACK, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedback = yield call(
        FeedbackService.updateFeedbackFeedback,
        payload
      )
      if (feedback.alert) {
        setNotification({
          type: "error",
          message: "Update Feedback failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Update Feedback in Feedback. FeedbackId - ${feedback.data?.FeedbackId}`
          )
        )

        yield put(setUpdateFeedbackFeedback(feedback.data))
        setNotification({
          type: "success",
          message: "Feedback successfully updated",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Update Feedback Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Update Feedback Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* deleteFeedbackFeedback() {
  yield takeEvery(DELETE_FEEDBACK_FEEDBACK, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedback = yield call(
        FeedbackService.deleteFeedbackFeedback,
        payload
      )
      if (feedback.alert) {
        setNotification({
          type: "error",
          message: "Delete Feedback failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Delete Feedback in Feedback. FeedbackId - ${payload.FeedbackId}`
          )
        )

        yield put(setDeleteFeedbackFeedback(payload))
        setNotification({
          type: "success",
          message: "Feedback successfully deleted",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Delete Feedback Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Delete Feedback Failed",
          description: error.message,
        })
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(createFeedbackFeedback),
    fork(getAllFeedbackFeedback),
    fork(getOneFeedbackFeedback),
    fork(updateFeedbackFeedback),
    fork(deleteFeedbackFeedback),
  ])
}
