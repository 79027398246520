export const SET_IS_POINT_EXPIRY_REWARD_LOADING =
  "SET_IS_POINT_EXPIRY_REWARD_LOADING"

export const CREATE_POINT_EXPIRY_REWARD = "CREATE_POINT_EXPIRY_REWARD"
export const SET_CREATE_POINT_EXPIRY_REWARD = "SET_CREATE_POINT_EXPIRY_REWARD"
export const CREATE_MEMBER_REWARD_TO_POINT_EXPIRY =
  "CREATE_MEMBER_REWARD_TO_POINT_EXPIRY"
export const SET_CREATE_MEMBER_REWARD_TO_POINT_EXPIRY =
  "SET_CREATE_MEMBER_REWARD_TO_POINT_EXPIRY"
export const GET_LIST_POINT_EXPIRY_REWARD_ELIGIBLE =
  "GET_LIST_POINT_EXPIRY_REWARD_ELIGIBLE"
export const SET_LIST_POINT_EXPIRY_REWARD_ELIGIBLE =
  "SET_LIST_POINT_EXPIRY_REWARD_ELIGIBLE"
