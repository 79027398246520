import {
  IS_VOUCHER_LOADING,
  RESULT_VOUCHERS,
  ALERT_VOUCHERS,
  REMOVE_VOUCHERS_ALERT,
  SET_LIST_VOUCHERS,
  SET_DELETE_VOUCHER,
  SET_UPDATE_VOUCHER,
} from "redux/constants/Voucher"

const initialState = {
  isLoading: false,
  vouchers: [],
  count: 0,
  alert: {},
}

const voucher = (state = initialState, action) => {
  switch (action.type) {
    case IS_VOUCHER_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case RESULT_VOUCHERS:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          vouchers: action.payload.data.rows,
          count: action.payload.data.count,
        }
      } else if (action.payload === "CREATE") {
        return {
          ...state,
        }
      }
      break

    case ALERT_VOUCHERS:
      return {
        ...state,
        alert: {
          status: true,
          success: action.payload.success,
          message: action.payload.message,
        },
      }

    case REMOVE_VOUCHERS_ALERT:
      return {
        ...state,
        alert: {
          status: false,
        },
      }

    case SET_LIST_VOUCHERS:
      return {
        ...state,
        vouchers: action.payload.vouchers,
        count: action.payload.count,
      }

    case SET_UPDATE_VOUCHER:
      return {
        ...state,
        vouchers: state.vouchers.map((voucher) =>
          voucher.VoucherNo === action.payload.VoucherNo
            ? action.payload
            : voucher
        ),
      }

    case SET_DELETE_VOUCHER:
      return {
        ...state,
        vouchers: state.vouchers.filter(
          (voucher) => voucher.VoucherNo !== action.payload.VoucherNo
        ),
        count: state.count - 1,
      }

    default:
      return state
  }
}

export default voucher
