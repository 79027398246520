import {
  SEARCH_CUSTOMER_MEMBERSHIP,
  LOAD_REWARDS,
  ON_REDEEM_REWARD,
  RESULT_LOAD_REWARDS,
  RESULT_ON_REDEEM_REWARD,
  RESULT_SEARCH_CUSTOMER_MEMBERSHIP,
  LOAD_IMAGE_REWARDS,
  RESULT_LOAD_IMAGE_REWARDS,
  RESULT_ON_POINT_REDEEM,
  SET_IS_LOADING_REDEEM,
  SET_RESET_REDEEM,
} from "redux/constants/RedeemReward"

export const searchCustomerMembership = (data) => {
  return {
    type: SEARCH_CUSTOMER_MEMBERSHIP,
    payload: data,
  }
}

export const setCustomerMembership = (data) => {
  return {
    type: RESULT_SEARCH_CUSTOMER_MEMBERSHIP,
    payload: data,
  }
}

export const loadRewards = (data) => {
  return {
    type: LOAD_REWARDS,
    payload: data,
  }
}

export const setResultLoadRewards = (data) => {
  return {
    type: RESULT_LOAD_REWARDS,
    payload: data,
  }
}

export const loadImageRewards = (data) => {
  return {
    type: LOAD_IMAGE_REWARDS,
    payload: data,
  }
}

export const setResultLoadImageRewards = (data) => {
  return {
    type: RESULT_LOAD_IMAGE_REWARDS,
    payload: data,
  }
}

export const onRedeemReward = (data) => {
  return {
    type: ON_REDEEM_REWARD,
    payload: data,
  }
}

export const setResultOnRedeemReward = (data) => {
  return {
    type: RESULT_ON_REDEEM_REWARD,
    payload: data,
  }
}

export const setResultOnPointRedeem = (data) => {
  return {
    type: RESULT_ON_POINT_REDEEM,
    payload: data,
  }
}

export const setLoading = (data) => {
  return {
    type: SET_IS_LOADING_REDEEM,
    payload: data,
  }
}

export const reset = (data) => {
  return {
    type: SET_RESET_REDEEM,
    payload: data,
  }
}
