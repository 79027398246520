import { combineReducers } from "redux"
import Auth from "./Auth"
import Theme from "./Theme"
import MemberGroup from "./MemberGroup"
import cardCategory from "./MemberCardCategory"
import referrer from "./MemberReferrerListing"
import customer from "./Customer"
import taskGroup from "./TaskGroup"
import pointStructure from "./PointStructure"
import common from "./Common"
import SSOData from "./SSOData"
import Memberships from "./Memberships"
import PointRates from "./PointRate"
import PointSummary from "./PointSummary"
import ProfileUpdatedHistory from "./ProfileUpdatedHistory"
import PointAdjustment from "./PointAdjustment"
import PointExpiry from "./PointExpiry"
import MembershipLevel from "./MembershipLevel"
import CustomerAddress from "./CustomerAddress"
import TransactionHistory from "./TransactionHistory"
import customerTask from "./CustomerTask"
import customerType from "./CustomerType"
import taskProgress from "./TaskProgress"
import selfService from "./SelfService"
import InactiveMember from "./MemberArchiveInactiveMember"
import Reward from "./Reward"
import Setting from "./Setting"
import RewardObject from "./RewardObject"
import ContactTemplate from "./ContactTemplate"
import Campaign from "./Campaign"
import CampaignObject from "./CampaignObject"
import CampaignTarget from "./CampaignTarget"
import CustomerReward from "./CustomerReward"
import Reporting from "./Reporting"
import RewardInventory from "./RewardInventory"
import ClaimReward from "./ClaimReward"
import RedeemReward from "./RedeemReward"
import CustomerActivityType from "./CustomerActivityType"
import ProductGroup from "./ProductGroup"
import CollectReward from "./CollectReward"
import CustomField from "./CustomField"
import CustomerTag from "./CustomerTag"
import customerActivity from "./CustomerActivity"
import CustomerAnalysisReport from "./CustomerAnalysisReport"
import Voucher from "./Voucher"
import CancelRedeemReward from "./CancelRedeemReward"
import CancelCollectReward from "./CancelCollectReward"
import CustomerEmailVerification from "./CustomerEmailVerification"
import Feedback from "./Feedback"
import PointExpiryReward from "./PointExpiryReward"

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  memberGroup: MemberGroup,
  cardCategory: cardCategory,
  referrer: referrer,
  customer: customer,
  taskGroup: taskGroup,
  pointStructure: pointStructure,
  common: common,
  SSOData: SSOData,
  Memberships: Memberships,
  pointRate: PointRates,
  profileUpdatedHistory: ProfileUpdatedHistory,
  pointAdjustment: PointAdjustment,
  membershipLevel: MembershipLevel,
  customerAddress: CustomerAddress,
  transactionHistory: TransactionHistory,
  customerTask: customerTask,
  customerType: customerType,
  taskProgress: taskProgress,
  selfService: selfService,
  inactiveMember: InactiveMember,
  pointExpiry: PointExpiry,
  pointSummary: PointSummary,
  reward: Reward,
  setting: Setting,
  rewardObject: RewardObject,
  contactTemplate: ContactTemplate,
  campaign: Campaign,
  campaignObject: CampaignObject,
  campaignTarget: CampaignTarget,
  customerReward: CustomerReward,
  reporting: Reporting,
  rewardInventory: RewardInventory,
  claimReward: ClaimReward,
  redeemReward: RedeemReward,
  customerActivityType: CustomerActivityType,
  collectReward: CollectReward,
  customField: CustomField,
  customerTag: CustomerTag,
  customerActivity: customerActivity,
  customerAnalysisReport: CustomerAnalysisReport,
  voucher: Voucher,
  productGroup: ProductGroup,
  cancelRedeemReward: CancelRedeemReward,
  cancelCollectReward: CancelCollectReward,
  customerEmailVerification: CustomerEmailVerification,
  feedback: Feedback,
  pointExpiryReward: PointExpiryReward,
})

export default reducers
