import { RESULT_CUSTOMER_TYPE } from "redux/constants/CustomerType"

const initialState = {
  group: [],
  alert: {},
}

const customerType = (state = initialState, action) => {
  switch (action.type) {
    case RESULT_CUSTOMER_TYPE:
      return {
        ...state,
        group: action.payload.data.rows,
      }

    default:
      return state
  }
}

export default customerType
