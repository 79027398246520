import {
  GET_OUTLET_LIST,
  GET_SSO_BULDING,
  RESULT_SSO_DATA,
  GET_STATE_LIST,
  GET_COUNTRY_LIST,
  GET_STAFF_LIST,
  GET_USER_LIST,
} from "redux/constants/SSOData"

export const getOutletList = (data) => {
  return {
    type: GET_OUTLET_LIST,
    payload: data,
  }
}

export const getSsoBuilding = (data) => {
  return {
    type: GET_SSO_BULDING,
    payload: data,
  }
}

export const getStateList = () => {
  return {
    type: GET_STATE_LIST,
  }
}

export const getCountryList = () => {
  return {
    type: GET_COUNTRY_LIST,
  }
}

export const getStaffList = () => {
  return {
    type: GET_STAFF_LIST,
  }
}

export const getUserList = () => {
  return {
    type: GET_USER_LIST,
  }
}

export const ResultSSOData = (data) => {
  return {
    type: RESULT_SSO_DATA,
    payload: data,
  }
}
