import React, { lazy, Suspense } from "react"
import { Switch, Route } from "react-router-dom"
import Loading from "components/shared-components/Loading"

export const CustomerFeedbackView = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          exact
          path="/customer/feedback/:FeedbackId"
          component={lazy(() => import(`./customer-feedback-form`))}
        />
      </Switch>
    </Suspense>
  )
}

export default CustomerFeedbackView
