import axios from "axios"
import { API_BASE_URL } from "configs/AppConfig"

const host = API_BASE_URL
const FeedbackService = {}

FeedbackService.createFeedbackFeedback = function (data) {
  return axios({
    url: host + `/feedback/feedback/add`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

FeedbackService.getListFeedbackFeedback = function (data) {
  const searchQuery = {}
  if (!(data.Code === "")) {
    searchQuery.Code = data.Code?.toUpperCase()
  }
  if (!(data.Type === "")) {
    searchQuery.Type = data.Type
  }
  if (!(data.Status === "" || data.Status === "All")) {
    searchQuery.Status = data.Status
  }

  return axios({
    url: host + `/feedback/feedback/find-all`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ? data.rows : 1000,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchQuery ?? {}),
    },
  })
}

FeedbackService.getOneFeedbackFeedback = function (data) {
  return axios({
    url: host + "/feedback/feedback/find-one",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      FeedbackId: data?.FeedbackId,
    },
  })
}

FeedbackService.updateFeedbackFeedback = function (data) {
  return axios({
    url: host + `/feedback/feedback/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

FeedbackService.deleteFeedbackFeedback = function (data) {
  return axios({
    url: host + `/feedback/feedback/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      FeedbackId: data?.FeedbackId,
    },
  })
}

export default FeedbackService
