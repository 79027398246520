import {
  GET_LIST_CUSTOMER_TAG,
  CREATE_CUSTOMER_TAG,
  GET_RESULT_CUSTOMER_TAG,
  GET_CUSTOMER_TAG,
  UPDATE_CUSTOMER_TAG,
  DELETE_CUSTOMER_TAG,
  RESULT_CUSTOMER_TAG,
  ALERT_CUSTOMER_TAG,
  REMOVE_CUSTOMER_TAG_ALERT,
  UPDATE_CUSTOMER_TAG_AND_PROGRESS_LOGS,
} from "redux/constants/CustomerTag"

export const createCustomerTag = (data) => {
  return {
    type: CREATE_CUSTOMER_TAG,
    payload: data,
  }
}

export const getListCustomerTag = (data) => {
  return {
    type: GET_LIST_CUSTOMER_TAG,
    payload: data,
  }
}

export const getResultCustomerTag = (data) => {
  return {
    type: GET_RESULT_CUSTOMER_TAG,
    payload: data,
  }
}

export const getCustomerTag = (data) => {
  return {
    type: GET_CUSTOMER_TAG,
    payload: data,
  }
}

export const updateCustomerTag = (data) => {
  return {
    type: UPDATE_CUSTOMER_TAG,
    payload: data,
  }
}

export const updateCustomerTagAndProgressLogs = (data) => {
  return {
    type: UPDATE_CUSTOMER_TAG_AND_PROGRESS_LOGS,
    payload: data,
  }
}

export const deleteCustomerTag = (data) => {
  return {
    type: DELETE_CUSTOMER_TAG,
    payload: data,
  }
}

export const resultCustomerTag = (data) => {
  return {
    type: RESULT_CUSTOMER_TAG,
    payload: data,
  }
}

export const alertCustomerTag = (alert) => {
  return {
    type: ALERT_CUSTOMER_TAG,
    payload: alert,
  }
}

export const removeCustomerTagAlert = () => {
  return {
    type: REMOVE_CUSTOMER_TAG_ALERT,
  }
}
