import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  CREATE_MEMBER_GROUP,
  GET_LIST_MEMBER_GROUP,
  GET_MEMBER_GROUP,
  UPDATE_MEMBER_GROUP,
  DELETE_MEMBER_GROUP,
} from "../constants/MemberGroup"
import { resultMemberGroup, alertMemberGroup } from "../actions/MemberGroup"
import MemberGroupService from "services/MemberGroupService"
import { isArray } from "lodash"
import { createLoginActivity } from "redux/actions/Auth"

//generator
export function* createMemberGroupAPI() {
  yield takeEvery(CREATE_MEMBER_GROUP, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(MemberGroupService.createMemberGroup, payload)
      if (group.alert) {
        yield put(alertMemberGroup({ success: false, message: group.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Add Member Card Group in Member Card Group. CardGroupId - ${group.data.CardGroupId}`
          )
        )

        group.type = "CREATE"
        yield put(resultMemberGroup(group))
        yield put(
          alertMemberGroup({
            success: true,
            message: "A new group has been created",
          })
        )
      }
    } catch (err) {
      yield put(
        alertMemberGroup({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* listMemberGroupAPI() {
  yield takeEvery(GET_LIST_MEMBER_GROUP, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(MemberGroupService.getListMemberGroup, payload)
      if (group.alert) {
      } else {
        group.type = "LIST"
        yield put(resultMemberGroup(group))
      }
    } catch (err) {}
  })
}

export function* getMemberGroupAPI() {
  yield takeEvery(GET_MEMBER_GROUP, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(MemberGroupService.getMemberGroup, payload)
      if (group.alert) {
        yield put(alertMemberGroup(group.alert))
      } else {
        group.type = "LIST"
        yield put(resultMemberGroup(group))
      }
    } catch (err) {
      yield put(alertMemberGroup(err))
    }
  })
}

export function* updateMemberGroupAPI() {
  yield takeEvery(UPDATE_MEMBER_GROUP, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(MemberGroupService.updateMemberGroup, payload)
      if (group.alert) {
        yield put(alertMemberGroup({ success: false, message: group.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Update Member Card Group in Member Card Group. CardGroupId - ${payload.GroupId}`
          )
        )

        group.type = "UPDATE"
        group.data = payload
        yield put(resultMemberGroup(group))
        yield put(
          alertMemberGroup({
            success: true,
            message: "Group successfully updated",
          })
        )
      }
    } catch (err) {
      yield put(
        alertMemberGroup({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* deleteMemberGroupAPI() {
  yield takeEvery(DELETE_MEMBER_GROUP, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(MemberGroupService.deleteMemberGroup, payload)
      if (group.alert) {
        yield put(alertMemberGroup({ success: false, message: group.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Delete Member Card Group in Member Card Group. CardGroupId - ${payload.GroupId}`
          )
        )

        group.type = "DELETE"
        group.data = payload
        yield put(resultMemberGroup(group))
        yield put(
          alertMemberGroup({
            success: true,
            message: "Group has been deleted/inactivated successfully",
          })
        )
      }
    } catch (err) {
      yield put(alertMemberGroup({ success: false, message: err }))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listMemberGroupAPI),
    fork(createMemberGroupAPI),
    fork(getMemberGroupAPI),
    fork(updateMemberGroupAPI),
    fork(deleteMemberGroupAPI),
  ])
}
