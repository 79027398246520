import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const CustomerRewardService = {}

CustomerRewardService.getListCustomerReward = function (params) {
  const payload = { ...params, Page: params.page ?? 1, Rows: params.rows ?? 10 }

  if (params.search) payload.Search = JSON.stringify(params.search ?? {})
  if (params.Type) payload.Type = [params.Type]

  return fetch({
    url: "/reward/customer-rewards/find-all",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

CustomerRewardService.getCustomerReward = function (ref_code) {
  return fetch({
    url: `/reward/customer-rewards/find-one`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { ReferenceCode: ref_code },
  })
}

CustomerRewardService.deleteCustomerReward = function (ref_code) {
  return fetch({
    url: `/reward/customer-rewards/delete`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { ReferenceCode: ref_code },
  })
}

CustomerRewardService.createCustomerReward = function (data) {
  return fetch({
    url: `/reward/customer-rewards/add`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

CustomerRewardService.updateCustomerReward = function ({ ref_code, data }) {
  return fetch({
    url: `/reward/customer-rewards/update`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { ...data, ReferenceCode: ref_code },
  })
}

export default CustomerRewardService
