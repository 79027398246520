import setNotification from "components/shared-components/Notification"
import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import { resultCampaignTarget } from "redux/actions/CampaignTarget"
import {
  GET_LIST_CAMPAIGN_TARGET,
  GET_MEMBER,
  CREATE_CAMPAIGN_TARGET,
  DELETE_CAMPAIGN_TARGET,
} from "redux/constants/CampaignTarget"
import CampaignTargetService from "services/CampaignTargetService"

export function* getListCampaignTargetAPI() {
  yield takeEvery(GET_LIST_CAMPAIGN_TARGET, function* ({ payload }) {
    try {
      const result = yield call(
        CampaignTargetService.getListCampaignTarget,
        payload
      )
      if (result.alert) {
        setNotification({
          type: "error",
          message: `Failed to fetch campaign target list`,
        })
      } else {
        result.type = "LIST"
        yield put(resultCampaignTarget(result))
      }
    } catch (err) {
      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Failed to list campaign target",
          description: err.message,
        })
      }
    }
  })
}

export function* getMemberAPI() {
  yield takeEvery(GET_MEMBER, function* ({ payload }) {
    try {
      const result = yield call(CampaignTargetService.getMember, payload)
      if (result.alert) {
        setNotification({
          type: "error",
          message: `Failed to fetch campaign target`,
        })
      } else {
        result.type = "MEMBER LIST"
        yield put(resultCampaignTarget(result))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: error.message,
        description: `Failed to fetch campaign target list`,
      })
    }
  })
}

export function* createCampaigTargetAPI() {
  yield takeEvery(CREATE_CAMPAIGN_TARGET, function* ({ payload }) {
    try {
      const createResult = yield call(
        CampaignTargetService.createCampaignTarget,
        payload
      )
      if (createResult.alert) {
        setNotification({
          type: "error",
          message: `Failed to create campaign target`,
        })
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: `Failed to create campaign target`,
      })
    }
  })
}

export function* deleteCampaignTargetAPI() {
  yield takeEvery(DELETE_CAMPAIGN_TARGET, function* ({ payload }) {
    try {
      const deleteResult = yield call(
        CampaignTargetService.deleteCampaignTarget,
        payload
      )
      if (deleteResult.alert) {
        setNotification({
          type: "error",
          message: `Delete Campaign Target ${payload} was failed!`,
        })
      } else {
        deleteResult.type = "DELETE"
        deleteResult.TargetListId = payload
        yield put(resultCampaignTarget(deleteResult))
        setNotification({
          type: "error",
          message: `Campaign Target ${payload} was successfully deleted`,
        })
      }
    } catch (err) {
      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: `Delete Campaign Target ${payload} was failed!`,
          description: err.message,
        })
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getListCampaignTargetAPI),
    fork(getMemberAPI),
    fork(createCampaigTargetAPI),
    fork(deleteCampaignTargetAPI),
  ])
}
