import {
  SET_COMMON_LOADING,
  DELETE_MEDIA,
  GET_COMMON_DATA,
  GET_COMMON_RESULT,
  GET_LIST_MEDIA,
  SET_LIST_MEDIA,
  UPLOAD_IMAGE_MEDIAS_INTERNAL,
  CREATE_FIELD_TRANSLATION,
  GET_CONTACT_LOG,
  SET_CONTACT_LOG,
  GET_POSTCODE,
  SET_POSTCODE,
} from "../constants/Common"

export const setCommonLoading = (data) => {
  return {
    type: SET_COMMON_LOADING,
    payload: data,
  }
}

export const getCommonData = (data) => ({
  type: GET_COMMON_DATA,
  payload: data,
})

export const getCommonResult = (data) => ({
  type: GET_COMMON_RESULT,
  payload: data,
})

export const uploadImageMediasInternal = (data) => ({
  type: UPLOAD_IMAGE_MEDIAS_INTERNAL,
  payload: data,
})

export const getMedias = (data) => ({
  type: GET_LIST_MEDIA,
  payload: data,
})

export const setMedias = (data) => ({
  type: SET_LIST_MEDIA,
  payload: data,
})

export const deleteMedia = (id) => ({
  type: DELETE_MEDIA,
  payload: id,
})

export const createFieldTranslation = (data) => ({
  type: CREATE_FIELD_TRANSLATION,
  payload: data,
})

export const getContactLog = (data) => ({
  type: GET_CONTACT_LOG,
  payload: data,
})

export const setContactLog = (data) => ({
  type: SET_CONTACT_LOG,
  payload: data,
})

export const getPostcode = (data) => ({
  type: GET_POSTCODE,
  payload: data,
})

export const setPostcode = (data) => ({
  type: SET_POSTCODE,
  payload: data,
})
