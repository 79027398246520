import axios from "axios"
import { API_BASE_URL } from "configs/AppConfig"

const host = API_BASE_URL
const FeedbackOptionService = {}

FeedbackOptionService.createFeedbackOption = function (data) {
  return axios({
    url: host + `/feedback/option/add`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

FeedbackOptionService.getListFeedbackOption = function (data) {
  const searchQuery = {}
  if (!(data.QuestionId === "")) {
    searchQuery.QuestionId = data.QuestionId
  }
  if (!(data.Status === "" || data.Status === "All")) {
    searchQuery.Status = data.Status
  }


  return axios({
    url: host + `/feedback/option/find-all`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ? data.rows : 1000,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchQuery ?? {}),
    },
  })
}

FeedbackOptionService.updateFeedbackOption = function (data) {
  return axios({
    url: host + `/feedback/option/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

FeedbackOptionService.deleteFeedbackOption = function (data) {
  return axios({
    url: host + `/feedback/option/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      OptionId: data?.OptionId,
    },
  })
}

export default FeedbackOptionService
