import { all, fork, takeEvery, call, put } from "@redux-saga/core/effects"
import PointRateService from "services/PointRateService"
import {
  CREATE_POINT_RATE,
  GET_POINT_RATE_LIST,
  GET_POINT_RATE,
  UPDATE_POINT_RATE,
  DELETE_POINT_RATE,
  CREATE_POINT_RATE_DETAIL,
  UPDATE_POINT_RATE_DETAIL,
  DELETE_POINT_RATE_DETAIL,
} from "redux/constants/PointRates"
import {
  resultPointRate,
  setPointRate,
  getPointRateList,
  alertPointRate,
} from "../actions/PointRate"
import { isArray } from "lodash"
import setNotification from "../../components/shared-components/Notification"
import { createLoginActivity } from "redux/actions/Auth"

export function* createPointRateAPI() {
  yield takeEvery(CREATE_POINT_RATE, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const newPointRate = yield call(PointRateService.createPointRate, payload)
      const pointRate = yield call(PointRateService.getPointRate, {
        RateId: newPointRate.data.RateId,
        access_token: auth_token,
      })
      if (pointRate.alert) {
        yield put(alertPointRate({ success: false, message: pointRate.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Add Point Rate in Point Rate. RateId - ${newPointRate.data.RateId}`
          )
        )

        pointRate.type = "CREATE"
        yield put(resultPointRate(pointRate))
        yield put(
          alertPointRate({
            success: true,
            message: "Point Rate details have been saved successfully",
          })
        )
      }
    } catch (err) {
      yield put(
        alertPointRate({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data?.message,
        })
      )
    }
  })
}

export function* listPointRatesAPI() {
  yield takeEvery(GET_POINT_RATE_LIST, function* ({ payload }) {
    try {
      const rates = yield call(PointRateService.listPointRates, payload)
      if (rates) {
        rates.type = "LIST"
        yield put(resultPointRate(rates))
      }
    } catch (err) {
      setNotification({
        type: "error",
        message: "List Failed",
        description: "Point rates failed to be listed.",
      })
    }
  })
}

export function* getPointRateAPI() {
  yield takeEvery(GET_POINT_RATE, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const pointRate = yield call(PointRateService.getPointRate, payload)
      if (pointRate.alert) {
        yield put(alertPointRate(pointRate.alert))
      } else {
        yield put(setPointRate(pointRate.data))
      }
    } catch (err) {
      yield put(alertPointRate(err))
    }
  })
}

export function* updatePointRateAPI() {
  yield takeEvery(UPDATE_POINT_RATE, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const newPointRate = yield call(PointRateService.updatePointRate, payload)
      const pointRate = yield call(PointRateService.getPointRate, {
        RateId: newPointRate.data.RateId,
        access_token: auth_token,
      })
      if (pointRate.alert) {
        yield put(alertPointRate({ success: false, message: pointRate.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Update Point Rate in Point Rate. RateId - ${newPointRate.data.RateId}`
          )
        )

        yield put(setPointRate(pointRate.data))
        yield put(
          alertPointRate({
            success: true,
            message: "Point Rate details have been saved successfully",
          })
        )
      }
    } catch (err) {
      yield put(
        alertPointRate({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data?.message,
        })
      )
    }
  })
}

export function* deletePointRateAPI() {
  yield takeEvery(DELETE_POINT_RATE, function* ({ payload }) {
    try {
      const rates = yield call(PointRateService.deletePointRate, payload)
      if (rates) {
        yield put(
          createLoginActivity(
            `Delete Point Rate in Point Rate. RateId - ${payload}`
          )
        )

        yield put(getPointRateList({}))
        setNotification({
          type: "success",
          message: "Delete Successful",
          description: "Point rate was deleted sucessfully.",
        })
      }
    } catch (err) {
      setNotification({
        type: "error",
        message: "Delete Failed",
        description: "Point rate delete failed.",
      })
    }
  })
}

export function* createPointRateDetailAPI() {
  yield takeEvery(CREATE_POINT_RATE_DETAIL, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const newPointRateDetail = yield call(
        PointRateService.createPointRateDetail,
        payload
      )
      const pointRateDetail = yield call(PointRateService.getPointRate, {
        RateId: newPointRateDetail.data.RateId,
        access_token: auth_token,
      })
      if (pointRateDetail.alert) {
        yield put(
          alertPointRate({ success: false, message: pointRateDetail.alert })
        )
      } else {
        yield put(setPointRate(pointRateDetail.data))
        yield put(
          alertPointRate({
            success: true,
            message: "A new point rate detail has been added",
          })
        )
      }
    } catch (err) {
      yield put(
        alertPointRate({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data?.message,
        })
      )
    }
  })
}

export function* updatePointRateDetailAPI() {
  yield takeEvery(UPDATE_POINT_RATE_DETAIL, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      yield call(PointRateService.updatePointRateDetail, payload)
      const pointRateDetail = yield call(PointRateService.getPointRate, {
        RateId: payload.RateId,
        access_token: auth_token,
      })
      if (pointRateDetail.alert) {
        yield put(
          alertPointRate({ success: false, message: pointRateDetail.alert })
        )
      } else {
        yield put(setPointRate(pointRateDetail.data))
        yield put(
          alertPointRate({
            success: true,
            message: "Point rate detail has been successfully updated",
          })
        )
      }
    } catch (err) {
      yield put(
        alertPointRate({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data?.message,
        })
      )
    }
  })
}

export function* deletePointRateDetailAPI() {
  yield takeEvery(DELETE_POINT_RATE_DETAIL, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      yield call(PointRateService.deletePointRateDetail, payload)
      const pointRate = yield call(PointRateService.getPointRate, {
        RateId: payload.RateId,
        access_token: auth_token,
      })
      if (pointRate.alert) {
        yield put(alertPointRate({ success: false, message: pointRate.alert }))
      } else {
        yield put(setPointRate(pointRate.data))
        yield put(
          alertPointRate({
            success: true,
            message: "Point rate detail has been deleted successfully",
          })
        )
      }
    } catch (err) {
      yield put(
        alertPointRate({
          success: false,
          message: isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0] ?? "An error occured."
            : err?.response?.data?.message ?? "An error occured.",
        })
      )
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listPointRatesAPI),
    fork(createPointRateAPI),
    fork(getPointRateAPI),
    fork(updatePointRateAPI),
    fork(deletePointRateAPI),
    fork(createPointRateDetailAPI),
    fork(updatePointRateDetailAPI),
    fork(deletePointRateDetailAPI),
  ])
}
