import {
  CLEAR_REWARD_INVENTORY,
  DELETE_REWARD_INVENTORY,
  RESULT_REWARD_INVENTORY,
  REMOVE_REWARD_INVENTORY_ALERT,
} from "redux/constants/RewardInventory"

export const deleteRewardInventory = (data) => {
  return {
    type: DELETE_REWARD_INVENTORY,
    payload: data,
  }
}

export const resultRewardInventory = (data) => {
  return {
    type: RESULT_REWARD_INVENTORY,
    payload: data,
  }
}

export const clearRewardInventory = (data) => {
  return {
    type: CLEAR_REWARD_INVENTORY,
    payload: data,
  }
}

export const removeRewardInventoryAlert = () => {
  return {
    type: REMOVE_REWARD_INVENTORY_ALERT,
  }
}
