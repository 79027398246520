import {
  SET_CUSTOMER_TASK_LOADING,
  GET_LIST_CUSTOMER_TASK,
  CREATE_CUSTOMER_TASK,
  GET_RESULT_CUSTOMER_TASK,
  GET_CUSTOMER_TASK,
  UPDATE_CUSTOMER_TASK,
  DELETE_CUSTOMER_TASK,
  RESULT_CUSTOMER_TASK,
  ALERT_CUSTOMER_TASK,
  REMOVE_CUSTOMER_TASK_ALERT,
  UPDATE_CUSTOMER_TASK_AND_PROGRESS_LOGS,
} from "redux/constants/CustomerTask"

export const setCustomerTaskLoading = (data) => {
  return {
    type: SET_CUSTOMER_TASK_LOADING,
    payload: data,
  }
}

export const createCustomerTask = (data) => {
  return {
    type: CREATE_CUSTOMER_TASK,
    payload: data,
  }
}

export const getListCustomerTask = (data) => {
  return {
    type: GET_LIST_CUSTOMER_TASK,
    payload: data,
  }
}

export const getResultCustomerTask = (data) => {
  return {
    type: GET_RESULT_CUSTOMER_TASK,
    payload: data,
  }
}

export const getCustomerTask = (data) => {
  return {
    type: GET_CUSTOMER_TASK,
    payload: data,
  }
}

export const updateCustomerTask = (data) => {
  return {
    type: UPDATE_CUSTOMER_TASK,
    payload: data,
  }
}

export const updateCustomerTaskAndProgressLogs = (data) => {
  return {
    type: UPDATE_CUSTOMER_TASK_AND_PROGRESS_LOGS,
    payload: data,
  }
}

export const deleteCustomerTask = (data) => {
  return {
    type: DELETE_CUSTOMER_TASK,
    payload: data,
  }
}

export const resultCustomerTask = (data) => {
  return {
    type: RESULT_CUSTOMER_TASK,
    payload: data,
  }
}

export const alertCustomerTask = (alert) => {
  return {
    type: ALERT_CUSTOMER_TASK,
    payload: alert,
  }
}

export const removeCustomerTaskAlert = () => {
  return {
    type: REMOVE_CUSTOMER_TASK_ALERT,
  }
}
