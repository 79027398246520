import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button, Form } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"
import { createCustomerActivityType } from "redux/actions/CustomerActivityType"
import { CreateUpdateActivityTypeModal } from "../components"

export const ActivityTypeAddButton = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState({
    ActivityCode: "",
    Description: "",
    AutoSyncYN: "Y",
    Status: "Active",
  })

  const [form] = Form.useForm()

  useEffect(() => {
    if (props.alert?.success === true) {
      resetForm()
    }
  }, [props.alert])

  const resetForm = async () => {
    setData({
      ActivityCode: "",
      Description: "",
      AutoSyncYN: "Y",
      Status: "Active",
    })
    setTimeout(() => {
      setShowModal(false)
    }, 1000)
  }

  const saveGroup = () => {
    props.createCustomerActivityType(data)
  }

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        type="primary"
        icon={<PlusCircleOutlined />}
        block
      >
        Add Activity Type
      </Button>
      <CreateUpdateActivityTypeModal
        form={form}
        title="Create New Activity Type"
        visible={showModal}
        setShowModal={setShowModal}
        data={data}
        setData={setData}
        submitHandler={saveGroup}
      />
    </>
  )
}

const mapStateToProps = ({ customerActivityType }) => {
  const { group, alert } = customerActivityType
  return { group, alert }
}

const mapDispatchToProps = {
  createCustomerActivityType,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTypeAddButton)
