import {
  SET_IS_LOADING,
  GET_TRANSACTION_SUMMARY,
  GET_TRANSACTION_SUMMARY_LIST,
  GET_CUSTOMER_TRANSACTION_REPORT_LIST,
  CREATE_TRANSACTION_SUMMARY,
  UPDATE_TRANSACTION_SUMMARY,
  RESULT_TRANSACTION_SUMMARY,
  REMOVE_TRANSACTION_SUMMARY,
  ALERT_TRANSACTION_SUMMARY,
  REMOVE_TRANSACTION_SUMMARY_ALERT,
  REMOVE_SELECTED_TRANSACTION_SUMMARY,
  GET_TRANSACTION_DETAIL,
  GET_TRANSACTION_DETAIL_LIST,
  CREATE_TRANSACTION_DETAIL,
  UPDATE_TRANSACTION_DETAIL,
  RESULT_TRANSACTION_DETAIL,
  ALERT_TRANSACTION_DETAIL,
  REMOVE_TRANSACTION_DETAIL_ALERT,
} from "redux/constants/TransactionHistory"

export const getTransactionSummaryList = (data) => {
  return {
    type: GET_TRANSACTION_SUMMARY_LIST,
    payload: data,
  }
}

export const getCustomerTransactionReportList = (data) => {
  return {
    type: GET_CUSTOMER_TRANSACTION_REPORT_LIST,
    payload: data,
  }
}

export const getTransactionSummary = (data) => {
  return {
    type: GET_TRANSACTION_SUMMARY,
    payload: data,
  }
}

export const createTransactionSummary = (data) => {
  return {
    type: CREATE_TRANSACTION_SUMMARY,
    payload: data,
  }
}

export const updateTransactionSummary = (data) => {
  return {
    type: UPDATE_TRANSACTION_SUMMARY,
    payload: data,
  }
}

export const resultTransactionSummary = (data) => {
  return {
    type: RESULT_TRANSACTION_SUMMARY,
    payload: data,
  }
}

export const removeTransactionSummary = () => {
  return {
    type: REMOVE_TRANSACTION_SUMMARY,
  }
}

export const alertTransactionSummary = (alert) => {
  return {
    type: ALERT_TRANSACTION_SUMMARY,
    payload: alert,
  }
}

export const removeTransactionSummaryAlert = () => {
  return {
    type: REMOVE_TRANSACTION_SUMMARY_ALERT,
  }
}

export const removeSelectedTransactionSummary = () => {
  return {
    type: REMOVE_SELECTED_TRANSACTION_SUMMARY,
  }
}

export const getTransactionDetailList = (data) => {
  return {
    type: GET_TRANSACTION_DETAIL_LIST,
    payload: data,
  }
}

export const getTransactionDetail = (data) => {
  return {
    type: GET_TRANSACTION_DETAIL,
    payload: data,
  }
}

export const createTransactionDetail = (data) => {
  return {
    type: CREATE_TRANSACTION_DETAIL,
    payload: data,
  }
}

export const updateTransactionDetail = (data) => {
  return {
    type: UPDATE_TRANSACTION_DETAIL,
    payload: data,
  }
}

export const resultTransactionDetail = (data) => {
  return {
    type: RESULT_TRANSACTION_DETAIL,
    payload: data,
  }
}

export const alertTransactionDetail = (alert) => {
  return {
    type: ALERT_TRANSACTION_DETAIL,
    payload: alert,
  }
}

export const removeTransactionDetailAlert = () => {
  return {
    type: REMOVE_TRANSACTION_DETAIL_ALERT,
  }
}

export const setIsLoading = (isLoading) => {
  return {
    type: SET_IS_LOADING,
    payload: isLoading,
  }
}
