import React, { useEffect } from "react"
import { Modal, Form, Input, Row, Col, Button, Select } from "antd"

const FeedbackModal = ({
  title,
  visible,
  setShowModal,
  data,
  setData,
  onSubmit,
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(data)
  }, [data, form])

  const onCancelHandler = () => {
    setShowModal(false)
    clearFields()
  }

  const clearFields = () => {
    setData({})
    setTimeout(() => {
      form.resetFields()
    }, 1000)
  }

  const submitHandler = () => {
    onSubmit()
    clearFields()
    setShowModal(false)
  }

  const pullLeft = {
    labelAlign: "left",
    wrapperCol: { span: 20 },
    labelCol: { span: 6 },
  }

  const styleFormLabel = {
    width: 100,
  }

  return (
    <Modal
      title={title ?? "Feedback"}
      visible={visible}
      okText={"Save"}
      onCancel={onCancelHandler}
      destroyOnClose
      footer={null}
    >
      <Form
        {...pullLeft}
        name="formFeedback"
        initialValues={data}
        onFinish={submitHandler}
        autoComplete={"off"}
        form={form}
      >
        <Form.Item
          label={<label style={styleFormLabel}>Name</label>}
          name="Name"
          onChange={(e) => setData({ ...data, Name: e.target.value })}
          required
          rules={[
            {
              required: true,
              message: "Please input your feedback name",
            },
            () => ({
              validator(_, value) {
                if (value && value.length >= 3 && value.length <= 100) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error("Name must be between 3 and 100 characters length")
                )
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<label style={styleFormLabel}>Code</label>}
          name="Code"
          onChange={(e) => setData({ ...data, Code: e.target.value })}
          required
          rules={[
            {
              required: true,
              message: "Please input your feedback code",
            },
            () => ({
              validator(_, value) {
                if (value && value.length >= 3 && value.length <= 100) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error("Code must be between 3 and 100 characters length")
                )
              },
            }),
          ]}
        >
          <Input style={{ textTransform: "uppercase" }} />
        </Form.Item>

        <Form.Item
          label={<label style={styleFormLabel}>Description</label>}
          name="Description"
          onChange={(e) => setData({ ...data, Description: e.target.value })}
          required
          rules={[
            {
              required: true,
              message: "Please input your feedback description",
            },
            () => ({
              validator(_, value) {
                if (value && value.length >= 3 && value.length <= 1000) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error(
                    "Description must be between 3 and 1000 characters length"
                  )
                )
              },
            }),
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label={<label style={styleFormLabel}>Type</label>}
          name="Type"
          required
          rules={[
            {
              required: true,
              message: "Please select the feedback type",
            },
          ]}
        >
          <Select
            value={data.Type}
            onChange={(value) => setData({ ...data, Type: value })}
          >
            <Select.Option value={"Feedback"}>Feedback</Select.Option>
            <Select.Option value={"Survey"}>Survey</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={<label style={styleFormLabel}>Display Option</label>}
          name="DisplayOption"
          required
          rules={[
            {
              required: true,
              message: "Please select the feedback display option",
            },
          ]}
        >
          <Select
            value={data.DisplayOption}
            onChange={(value) => setData({ ...data, DisplayOption: value })}
          >
            <Select.Option value={"All in one page"}>
              All in one page
            </Select.Option>
            <Select.Option value={"One question per page"}>
              One question per page
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={<label style={styleFormLabel}>Status</label>}
          name="Status"
          required
          rules={[
            {
              required: true,
              message: "Please select the feedback status",
            },
          ]}
        >
          <Select
            value={data.Status}
            onChange={(value) => setData({ ...data, Status: value })}
          >
            <Select.Option value={"Active"}>Active</Select.Option>
            <Select.Option value={"Inactive"}>Inactive</Select.Option>
          </Select>
        </Form.Item>

        <Row gutter={{ md: 10 }} justify={"end"}>
          <Col>
            <Button onClick={onCancelHandler}>Cancel</Button>
          </Col>

          <Col>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default FeedbackModal
