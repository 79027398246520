import {
  GET_LIST_CUSTOMER_TYPE,
  RESULT_CUSTOMER_TYPE,
} from "redux/constants/CustomerType"

export const getListCustomerType = (data) => {
  return {
    type: GET_LIST_CUSTOMER_TYPE,
    payload: data,
  }
}

export const resultCustomerType = (data) => {
  return {
    type: RESULT_CUSTOMER_TYPE,
    payload: data,
  }
}
