import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const ProntoSyncVoucherService = {}

ProntoSyncVoucherService.getAvailableVoucher = function ({
  voucherType,
  voucherPrefix,
}) {
  return fetch({
    url: "/pronto-sync/vouchers/available",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      VoucherType: voucherType,
      VoucherPrefix: voucherPrefix,
    },
  })
}

ProntoSyncVoucherService.updateVoucher = function ({
  VoucherNo,
  SecurityCode,
  data,
}) {
  return fetch({
    url: "pronto-sync/vouchers/update",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { ...data, VoucherNo, SecurityCode },
  })
}

export default ProntoSyncVoucherService
