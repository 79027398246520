import {
  GET_LIST_CARD_CATEGORY,
  CREATE_CARD_CATEGORY,
  GET_RESULT_CARD_CATEGORY,
  GET_CARD_CATEGORY,
  UPDATE_CARD_CATEGORY,
  DELETE_CARD_CATEGORY,
  RESULT_CARD_CATEGORY,
  ALERT_CARD_CATEGORY,
  REMOVE_CARD_CATEGORY_ALERT,
  SET_CARD_CATEGORY,
  CREATE_CARD_LEVEL,
  UPDATE_CARD_LEVEL,
  DELETE_CARD_LEVEL,
} from "redux/constants/MemberCardCategory"

export const setCardCategory = (data) => {
  return {
    type: SET_CARD_CATEGORY,
    payload: data,
  }
}

export const createCardCategory = (data) => {
  return {
    type: CREATE_CARD_CATEGORY,
    payload: data,
  }
}

export const getListCardCategory = (data) => {
  return {
    type: GET_LIST_CARD_CATEGORY,
    payload: data,
  }
}

export const getResultCardCategory = (data) => {
  return {
    type: GET_RESULT_CARD_CATEGORY,
    payload: data,
  }
}

export const getCardCategory = (data) => {
  return {
    type: GET_CARD_CATEGORY,
    payload: data,
  }
}

export const updateCardCategory = (data) => {
  const payload = {
    CardCategoryId: data.CardCategoryId,
    Code: data.Code,
    Description: data.Description,
    Status: data.Status,
    LevelSequence: data.LevelSequence,
    Name: data.Name,
    CardGroupId: data.CardGroupId,
    Color: data.Color,
  }
  return {
    type: UPDATE_CARD_CATEGORY,
    payload: payload,
  }
}

export const deleteCardCategory = (data) => {
  return {
    type: DELETE_CARD_CATEGORY,
    payload: data,
  }
}

export const resultCardCategory = (data) => {
  return {
    type: RESULT_CARD_CATEGORY,
    payload: data,
  }
}

export const alertCardCategory = (alert) => {
  return {
    type: ALERT_CARD_CATEGORY,
    payload: alert,
  }
}

export const removeCardCategoryAlert = () => {
  return {
    type: REMOVE_CARD_CATEGORY_ALERT,
  }
}

export const createCardLevel = (data) => {
  return {
    type: CREATE_CARD_LEVEL,
    payload: data,
  }
}

export const updateCardLevel = (data) => {
  return {
    type: UPDATE_CARD_LEVEL,
    payload: data,
  }
}

export const deleteCardLevel = (data) => {
  return {
    type: DELETE_CARD_LEVEL,
    payload: data,
  }
}
