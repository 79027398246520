import {
  GET_CUSTOMER_ACTIVITY_TYPE,
  GET_LIST_CUSTOMER_ACTIVITY_TYPE,
  GET_RESULT_CUSTOMER_ACTIVITY_TYPE,
  GET_LIST_CUSTOMER_ACTIVITY_TYPE_CODE,
  SET_CUSTOMER_ACTIVITY_TYPE,
  CREATE_CUSTOMER_ACTIVITY_TYPE,
  UPDATE_CUSTOMER_ACTIVITY_TYPE,
  DELETE_CUSTOMER_ACTIVITY_TYPE,
  SET_ALERT_ACTIVITY_TYPE,
} from "redux/constants/CustomerActivityType"

export const getListCustomerActivityType = (data) => {
  return {
    type: GET_LIST_CUSTOMER_ACTIVITY_TYPE,
    payload: data,
  }
}

export const getListCustomerActivityTypeCode = (data) => {
  return {
    type: GET_LIST_CUSTOMER_ACTIVITY_TYPE_CODE,
    payload: data,
  }
}

export const getCustomerActivityType = (data) => {
  return {
    type: GET_CUSTOMER_ACTIVITY_TYPE,
    payload: data,
  }
}

export const getResultCustomerActivityType = (data) => {
  return {
    type: GET_RESULT_CUSTOMER_ACTIVITY_TYPE,
    payload: data,
  }
}

export const setCustomerActivityType = (data) => {
  return {
    type: SET_CUSTOMER_ACTIVITY_TYPE,
    payload: data,
  }
}

export const createCustomerActivityType = (data) => {
  return {
    type: CREATE_CUSTOMER_ACTIVITY_TYPE,
    payload: data,
  }
}

export const updateCustomerActivityType = (data) => {
  return {
    type: UPDATE_CUSTOMER_ACTIVITY_TYPE,
    payload: data,
  }
}

export const deleteCustomerActivityType = (data) => {
  return {
    type: DELETE_CUSTOMER_ACTIVITY_TYPE,
    payload: data,
  }
}

export const setAlertActivityType = (data) => {
  return {
    type: SET_ALERT_ACTIVITY_TYPE,
    payload: data,
  }
}
