import { SET_LIST_STOCK_GROUP, SET_SETTINGS } from "redux/constants/Setting"

const initialState = {
  stockGroups: [],
  settings: [],
}

const Setting = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LIST_STOCK_GROUP:
      return { ...state, stockGroups: payload.data }
    case SET_SETTINGS:
      return { ...state, settings: payload.data }
    default:
      return state
  }
}

export default Setting
