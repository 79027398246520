import {
  CREATE_FEEDBACK_RESPONSE,
  DELETE_FEEDBACK_RESPONSE,
  GET_LIST_FEEDBACK_RESPONSE,
  SET_CREATE_FEEDBACK_RESPONSE,
  SET_DELETE_FEEDBACK_RESPONSE,
  SET_LIST_FEEDBACK_RESPONSE,
  SET_UPDATE_FEEDBACK_RESPONSE,
  UPDATE_FEEDBACK_RESPONSE,
  GET_FEEDBACK_RESPONSE_DETAIL,
  SET_FEEDBACK_RESPONSE_DETAIL,
  UNMOUNT_FEEDBACK_RESPONSE_DETAIL,
} from "redux/constants/FeedbackResponse"

export const createFeedbackResponse = (data) => {
  return {
    type: CREATE_FEEDBACK_RESPONSE,
    payload: data,
  }
}

export const setCreateFeedbackResponse = (data) => {
  return {
    type: SET_CREATE_FEEDBACK_RESPONSE,
    payload: data,
  }
}

export const getListFeedbackResponse = (data) => {
  return {
    type: GET_LIST_FEEDBACK_RESPONSE,
    payload: data,
  }
}

export const setListFeedbackResponse = (data) => {
  return {
    type: SET_LIST_FEEDBACK_RESPONSE,
    payload: data,
  }
}

export const updateFeedbackResponse = (data) => {
  return {
    type: UPDATE_FEEDBACK_RESPONSE,
    payload: data,
  }
}

export const setUpdateFeedbackResponse = (data) => {
  return {
    type: SET_UPDATE_FEEDBACK_RESPONSE,
    payload: data,
  }
}

export const deleteFeedbackResponse = (data) => {
  return {
    type: DELETE_FEEDBACK_RESPONSE,
    payload: data,
  }
}

export const setDeleteFeedbackResponse = (data) => {
  return {
    type: SET_DELETE_FEEDBACK_RESPONSE,
    payload: data,
  }
}

export const getFeedbackResponseDetail = (data) => {
  return {
    type: GET_FEEDBACK_RESPONSE_DETAIL,
    payload: data,
  }
}

export const setFeedbackResponseDetail = (data) => {
  return {
    type: SET_FEEDBACK_RESPONSE_DETAIL,
    payload: data,
  }
}

export const unmountFeedbackResponseDetail = () => {
  return {
    type: UNMOUNT_FEEDBACK_RESPONSE_DETAIL,
  }
}
