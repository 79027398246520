import React, { useEffect, useState } from "react"
import Greetings from "./components/Greetings"
import TasksToDo from "./components/TasksToDo"
import TotalCount from "./components/TotalCount"
import { Row, Col, notification } from "antd"
import { connect } from "react-redux"
import { getOutletList } from "redux/actions/SSOData"
import {
  getListCustomerTask,
  updateCustomerTaskAndProgressLogs,
  removeCustomerTaskAlert,
} from "redux/actions/CustomerTask"
import { getListCustomer, removeCustomerAlert } from "redux/actions/Customer"
import { getTransactionSummaryList } from "redux/actions/TransactionHistory"
import { getCustomerAnalysisReport } from "redux/actions/Reporting"
import {
  createTaskProgress,
  updateTaskProgress,
  removeTaskProgressAlert,
} from "redux/actions/TaskProgress"
import moment from "moment"
import TotalSalesByCategory from "./components/TotalSalesByCategory"
import TotalSales from "./components/TotalSales"
import Utils from "utils"

const Dashboard = ({
  outLetList,
  getOutletList,
  customerTaskAlert,
  customerTaskData,
  customerTaskCount,
  getListCustomerTask,
  updateCustomerTaskAndProgressLogs,
  removeCustomerTaskAlert,
  getCustomerAnalysisReport,
  totalActiveMember,
  totalNewMember,
  totalSalesYesterday,
  totalTransaction,
  totalSelfRegistered,
  totalManualRegistered,
  createTaskProgress,
  updateTaskProgress,
  removeTaskProgressAlert,
  taskProgressAlert,
  customerAlert,
  totalSales,
  totalSalesByGender,
  totalSalesByRace,
  totalSalesByAge,
}) => {
  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"))
  const {
    department: { code: departmentCode },
    building: { code: buildingCode, id: buildingId },
    staff: {
      preferred_name: preferredName,
      staff_type: { name: staffType },
    },
  } = userInfo
  const isOutletStaff = staffType === "Outlet Staff"

  const categories = [
    { label: "Gender", value: "Gender" },
    { label: "Race", value: "Ethnicity" },
    { label: "Age Group", value: "Age" },
  ]

  const [formattedOutletList, setFormattedOutletList] = useState([])
  const [search, setSearch] = useState({})
  const [data, setData] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [updatedCustomerTask, setUpdatedCustomerTask] = useState({})
  const [updatedTaskProgressLog, setUpdatedTaskProgressLog] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedTask, setSelectedTask] = useState({})

  const INITVALUE_NEWPROGRESSLOG = {
    ContactType: "Email",
    Description: "",
    FollowUpDate: moment(),
    Status: "Active",
  }
  const [newProgressLog, setNewProgressLog] = useState(INITVALUE_NEWPROGRESSLOG)
  const [isProgressLogModalVisible, setIsProgressLogModalVisible] =
    useState(false)
  const [validateNewProgressLog, setValidateNewProgressLog] = useState({})
  const [isAddingNewProgressLog, setIsAddingNewProgressLog] = useState(false)

  const CUSTOMERTASK_DEFAULTSEARCH = {
    pages: 1,
    rows: 9999,
    queries: {
      Status: ["To Do", "In Progress"],
    },
  }
  if (userInfo.building?.code !== "HQ") {
    CUSTOMERTASK_DEFAULTSEARCH.queries.SSOBuildingId = buildingId
  }

  const openNotification = (type, alert) => {
    notification[type]({
      message: alert.success ? "Success" : "Error",
      description: alert.message,
      style: alert.success
        ? { backgroundColor: "#e6fff1", border: "1px solid #7cf7bc" }
        : { backgroundColor: "#efc5c2", border: "1px solid #ad5d59" },
    })
  }

  useEffect(() => {
    getOutletList({
      search: {
        Status: "active",
        building_type_id: [1, 4, 5],
      },
    })

    getCustomerAnalysisReport({
      OutletCode: [buildingCode],
      DateFrom: moment().subtract(1, "w").format("YYYY-MM-DD"),
      DateTo: moment().format("YYYY-MM-DD"),
    })

    getListCustomerTask(CUSTOMERTASK_DEFAULTSEARCH)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isSearching) {
      getCustomerAnalysisReport({
        OutletCode: search?.OutletCode ? [search.OutletCode] : "",
        DateFrom: search?.UpdatedAt[0].format("YYYY-MM-DD"),
        DateTo: search?.UpdatedAt[1].format("YYYY-MM-DD"),
      })
      setIsSearching(false)
      setSearch({
        ...search,
        // UpdatedAt: "",
      })
    }
    //eslint-disable-next-line
  }, [isSearching])

  useEffect(() => {
    if (isSaving) {
      updateCustomerTaskAndProgressLogs({
        currentSelectedTask: selectedTask,
        updatedCustomerTaskData: updatedCustomerTask,
        updatedTaskProgressLogsData: updatedTaskProgressLog,
        userId: userInfo?.id,
      })
      setIsSaving(false)
    }
    //eslint-disable-next-line
  }, [selectedTask, isSaving])

  useEffect(() => {
    if (isAddingNewProgressLog) {
      if (newProgressLog.ProgressLogId) {
        updateTaskProgress(newProgressLog, false)
      } else {
        createTaskProgress(
          {
            ...newProgressLog,
            TaskId: selectedTask.TaskId,
            isDashboardTask: true,
          },
          false
        )
      }
      setIsProgressLogModalVisible(false)
      setNewProgressLog(INITVALUE_NEWPROGRESSLOG)
    }
    //eslint-disable-next-line
  }, [isAddingNewProgressLog])

  useEffect(() => {
    if (customerTaskAlert?.status) {
      openNotification(
        customerTaskAlert.success ? "success" : "error",
        customerTaskAlert
      )
      setIsModalVisible(false)
      setIsSaving(false)
      setTimeout(() => {
        removeCustomerTaskAlert()
      }, 1000)
    }
    if (customerAlert?.status) {
      openNotification(
        customerAlert.success ? "success" : "error",
        customerAlert
      )
      setTimeout(() => {
        removeCustomerAlert()
      }, 1000)
    }
    if (taskProgressAlert?.status) {
      openNotification(
        taskProgressAlert.success ? "success" : "error",
        taskProgressAlert
      )
      getListCustomerTask(CUSTOMERTASK_DEFAULTSEARCH)
      // setIsModalVisible(false)
      setIsProgressLogModalVisible(false)
      setIsAddingNewProgressLog(false)
      setTimeout(() => {
        removeTaskProgressAlert()
      }, 1000)
    }
    //eslint-disable-next-line
  }, [customerTaskAlert, customerAlert, taskProgressAlert])

  useEffect(() => {
    setData(customerTaskData)
  }, [customerTaskData])

  useEffect(() => {
    if (outLetList) {
      setFormattedOutletList(
        outLetList
          .sort((a, b) => a.code.localeCompare(b.code))
          .map((o) => {
            return { ...o, label: `${o.code} - ${o.name}` }
          })
      )
    } else {
      setFormattedOutletList([])
    }
    //eslint-disable-next-line
  }, [outLetList])

  const generateTotalSalesByCategory = () => {
    const components = []
    categories.forEach((category, index) => {
      components.push(
        <TotalSalesByCategory
          key={index}
          category={category.label}
          data={
            index === 0
              ? totalSalesByGender
              : index === 1
              ? totalSalesByRace
              : index === 2
              ? totalSalesByAge
              : null
          }
        />
      )
    })
    return components
  }

  return (
    <>
      <Greetings
        departmentCode={departmentCode}
        buildingCode={buildingCode}
        preferredName={preferredName}
        isOutletStaff={isOutletStaff}
        outletList={formattedOutletList}
        search={search}
        setSearch={setSearch}
        setIsSearching={setIsSearching}
      ></Greetings>
      <Row gutter={[16, { xs: 8, sm: 8, md: 8, lg: 8 }]} className="mt-4">
        <Col xs={24} sm={16}>
          <TasksToDo
            data={data}
            outletList={formattedOutletList}
            updatedCustomerTask={updatedCustomerTask}
            setUpdatedCustomerTask={setUpdatedCustomerTask}
            updatedTaskProgressLog={updatedTaskProgressLog}
            setUpdatedTaskProgressLog={setUpdatedTaskProgressLog}
            setIsSaving={setIsSaving}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            rowsPerPage={5}
            totalCount={customerTaskCount}
            newProgressLog={newProgressLog}
            setNewProgressLog={setNewProgressLog}
            validateNewProgressLog={validateNewProgressLog}
            setValidateNewProgressLog={setValidateNewProgressLog}
            isProgressLogModalVisible={isProgressLogModalVisible}
            setIsProgressLogModalVisible={setIsProgressLogModalVisible}
            setIsAddingNewProgressLog={setIsAddingNewProgressLog}
            selectedRecord={selectedTask}
            setSelectedRecord={setSelectedTask}
          ></TasksToDo>
          {Utils.checkUserPrivilege("View Sales Information") && (
            <TotalSales
              categoryList={categories}
              data={totalSales}
            ></TotalSales>
          )}
        </Col>
        <Col xs={24} sm={8}>
          <TotalCount
            totalActiveMember={totalActiveMember}
            totalNewMember={totalNewMember}
            totalSalesYesterday={totalSalesYesterday}
            totalTransaction={totalTransaction}
            totalSelfRegistered={totalSelfRegistered}
            totalManualRegistered={totalManualRegistered}
          />
          {Utils.checkUserPrivilege("View Sales Information") &&
            generateTotalSalesByCategory()}
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = ({
  SSOData,
  customerTask,
  taskProgress,
  customer,
  transactionHistory,
  reporting,
}) => {
  const { outLetList } = SSOData
  const {
    group: { rows: customerTaskData, count: customerTaskCount },
    alert: customerTaskAlert,
  } = customerTask

  const { alert: taskProgressAlert } = taskProgress

  const {
    group: { rows: customerData },
    alert: customerAlert,
  } = customer

  const {
    summary: { rows: transactionSummaryData },
  } = transactionHistory

  const {
    totalActiveMember,
    totalNewMember,
    totalSalesYesterday,
    totalTransaction,
    totalSelfRegistered,
    totalManualRegistered,
    totalSales,
    totalSalesByGender,
    totalSalesByRace,
    totalSalesByAge,
  } = reporting

  return {
    outLetList,
    customerTaskData,
    customerTaskCount,
    customerTaskAlert,
    taskProgressAlert,
    customerData,
    customerAlert,
    transactionSummaryData,
    totalActiveMember,
    totalNewMember,
    totalSalesYesterday,
    totalTransaction,
    totalSelfRegistered,
    totalManualRegistered,
    totalSales,
    totalSalesByGender,
    totalSalesByRace,
    totalSalesByAge,
  }
}

export default connect(mapStateToProps, {
  getOutletList,
  getListCustomerTask,
  updateCustomerTaskAndProgressLogs,
  removeCustomerTaskAlert,
  getListCustomer,
  getTransactionSummaryList,
  getCustomerAnalysisReport,
  createTaskProgress,
  updateTaskProgress,
  removeTaskProgressAlert,
})(Dashboard)
