import axios from "axios"
import { API_BASE_URL } from "configs/AppConfig"

//test
//const host = 'http://localhost:4000'; https://crm-api-test.tomei.com.my/member/
const host = API_BASE_URL

const MemberGroupService = {}

MemberGroupService.createMemberGroup = function (data) {
  return axios({
    url: host + "/member/card-groups/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: data,
  })
}

MemberGroupService.getListMemberGroup = function (data) {
  let searchquery = {}
  if (!(data.Status === "" || data.Status === "All"))
    searchquery.Status = data.Status
  if (!(data.Name === "" || data.Name === "All")) searchquery.Name = data.Name

  return axios({
    url: host + "/member/card-groups/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ?? 20,
      Page: data.page ?? 1,
      Search: JSON.stringify(searchquery ?? "{}"),
    },
  })
}

MemberGroupService.getMemberGroup = function (data) {
  return axios({
    url: host + `/member/card-groups/find-one`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { CardGroupId: data.GroupId },
  })
}

MemberGroupService.updateMemberGroup = function (data) {
  return axios({
    url: host + `/member/card-groups/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: { ...data, CardGroupId: data.GroupId },
    method: "post",
  })
}

MemberGroupService.deleteMemberGroup = function (data) {
  return axios({
    url: host + `/member/card-groups/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { CardGroupId: data.GroupId },
  })
}

export default MemberGroupService
