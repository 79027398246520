import axios from "axios"
import moment from "moment"
import { API_BASE_URL } from "configs/AppConfig"

const host = API_BASE_URL

const VoucherService = {}

VoucherService.createVouchers = function (data) {
  const file = new FormData()

  file.append("file", data.fileList[0].originFileObj)

  return axios({
    url: host + "/pronto-sync/vouchers/batch",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: file,
  })
}

VoucherService.getListVoucher = function (data) {
  let searchquery = {}

  searchquery.Status = data.Status
  searchquery.VoucherNo = data.VoucherNo

  return axios({
    url: host + "/pronto-sync/vouchers/find-all",
    data: {
      Rows: data.rows ?? 10,
      Page: data.page ?? 1,
      Search: JSON.stringify(searchquery ?? {}),
    },
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
  })
}

VoucherService.updateVoucher = function (data) {
  return axios({
    url: host + `/pronto-sync/vouchers/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

VoucherService.updateVoucherBatch = function (data) {
  const payload = {
    ...data,
    ExpiryDate: moment(data.ExpiryDate).format("YYYY-MM-DD")
  }

  return axios({
    url: host + `/pronto-sync/vouchers/update/batch`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: payload,
  })
}

VoucherService.deleteVoucher = function (data) {
  return axios({
    url: host + `/pronto-sync/vouchers/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      VoucherNo: data?.VoucherNo,
      SecurityCode: data?.SecurityCode,
    },
  })
}

export default VoucherService
