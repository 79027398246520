import axios from "axios"
import { API_BASE_URL } from "configs/AppConfig"

const host = API_BASE_URL
const FeedbackQuestionService = {}

FeedbackQuestionService.createFeedbackQuestion = function (data) {
  return axios({
    url: host + `/feedback/question/add`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

FeedbackQuestionService.getListFeedbackQuestion = function (data) {
  const searchQuery = {}
  if (!(data.FeedbackId === "")) {
    searchQuery.FeedbackId = data.FeedbackId
  }
  if (!(data.LanguageCode === "" || data.LanguageCode === "All")) {
    searchQuery.LanguageCode = data.LanguageCode
  }
  if (!(data.Status === "" || data.Status === "All")) {
    searchQuery.Status = data.Status
  }

  return axios({
    url: host + `/feedback/question/find-all`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ? data.rows : 1000,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchQuery ?? {}),
    },
  })
}

FeedbackQuestionService.getOneFeedbackQuestion = function (data) {
  return axios({
    url: host + "/feedback/question/find-one",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      QuestionId: data?.QuestionId,
    },
  })
}

FeedbackQuestionService.updateFeedbackQuestion = function (data) {
  return axios({
    url: host + `/feedback/question/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

FeedbackQuestionService.deleteFeedbackQuestion = function (data) {
  return axios({
    url: host + `/feedback/question/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      QuestionId: data?.QuestionId,
    },
  })
}

export default FeedbackQuestionService
