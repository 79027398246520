import {
  GET_LIST_MEMBER_GROUP,
  CREATE_MEMBER_GROUP,
  GET_RESULT_MEMBER_GROUP,
  GET_MEMBER_GROUP,
  UPDATE_MEMBER_GROUP,
  DELETE_MEMBER_GROUP,
  RESULT_MEMBER_GROUP,
  ALERT_MEMBER_GROUP,
  REMOVE_GROUP_MEMBER_ALERT,
} from "redux/constants/MemberGroup"

export const createMemberGroup = (data) => {
  return {
    type: CREATE_MEMBER_GROUP,
    payload: data,
  }
}

export const getListMemberGroup = (data) => {
  return {
    type: GET_LIST_MEMBER_GROUP,
    payload: data,
  }
}

export const getResultMemberGroup = (data) => {
  return {
    type: GET_RESULT_MEMBER_GROUP,
    payload: data,
  }
}

export const getMemberGroup = (data) => {
  return {
    type: GET_MEMBER_GROUP,
    payload: data,
  }
}

export const updateMemberGroup = (data) => {
  const payload = {
    GroupId: data.GroupId,
    Name: data.Name,
    Description: data.Description,
    Status: data.Status,
  }
  return {
    type: UPDATE_MEMBER_GROUP,
    payload: payload,
  }
}

export const deleteMemberGroup = (data) => {
  return {
    type: DELETE_MEMBER_GROUP,
    payload: data,
  }
}

export const resultMemberGroup = (data) => {
  return {
    type: RESULT_MEMBER_GROUP,
    payload: data,
  }
}

export const alertMemberGroup = (alert) => {
  return {
    type: ALERT_MEMBER_GROUP,
    payload: alert,
  }
}

export const removeGroupMemberAlert = () => {
  return {
    type: REMOVE_GROUP_MEMBER_ALERT,
  }
}
