import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  GET_LIST_INACTIVE_MEMBERS,
  GET_LIST_MEMBER_ID_TYPE,
  UPDATE_INACTIVE_MEMBER,
} from "redux/constants/MemberArchiveInactiveMember"
import {
  setIsInactiveMembersLoading,
  getResultInactiveMembers,
} from "redux/actions/MemberArchiveInactiveMember"
import MemberArchiveInactiveMemberServices from "services/MemberArchiveInactiveMember"
import setNotification from "../../components/shared-components/Notification"
import { createLoginActivity } from "redux/actions/Auth"

export function* getListInactiveMembersAPI() {
  yield takeEvery(GET_LIST_INACTIVE_MEMBERS, function* ({ payload }) {
    try {
      yield put(setIsInactiveMembersLoading(true))

      const listInactiveMember = yield call(
        MemberArchiveInactiveMemberServices.getListInactiveMembers,
        payload
      )

      if (listInactiveMember.alert) {
        setNotification({
          type: "error",
          message: "List Failed",
          description: "Members failed to be listed.",
        })
      } else {
        if (listInactiveMember.data?.rows?.length < 1) {
          yield put(setIsInactiveMembersLoading(false))
          setNotification({
            type: "error",
            message: "Data Not Found",
          })
        } else {
          listInactiveMember.type = "LIST"
          yield put(getResultInactiveMembers(listInactiveMember))
        }
      }
    } catch (error) {
      yield put(setIsInactiveMembersLoading(false))
      if (error?.response?.data) {
        const { statusCode, message } = error.response.data
        switch (statusCode) {
          case 400:
            setNotification({
              type: "error",
              message: message,
            })
            break
          default:
            setNotification({
              type: "error",
              message: message,
            })
            break
        }
      } else {
        setNotification({
          type: "error",
          message: error.message,
        })
      }
    }
  })
}

export function* getListMemberIdTypeAPI() {
  yield takeEvery(GET_LIST_MEMBER_ID_TYPE, function* () {
    try {
      yield put(setIsInactiveMembersLoading(true))

      const group = yield call(
        MemberArchiveInactiveMemberServices.getListMemberIdType
      )
      if (group.type) {
        setNotification({
          type: "error",
          message: "Update Failed",
          description: "An error occurred",
        })
      } else {
        group.type = "LIST_MEMBER_ID_TYPE"
        yield put(getResultInactiveMembers(group))
      }
    } catch (error) {
      yield put(setIsInactiveMembersLoading(false))
      setNotification({
        type: "error",
        message: "Update Failed",
        description: "An error occurred",
      })
    }
  })
}

export function* updateMemberAPI() {
  yield takeEvery(UPDATE_INACTIVE_MEMBER, function* ({ payload }) {
    try {
      const updateResult = yield call(
        MemberArchiveInactiveMemberServices.updateInactiveMember,
        payload
      )
      if (updateResult.alert) {
        setNotification({
          type: "error",
          message: "Update Failed",
          description: "An error occurred",
        })
      } else {
        yield put(
          createLoginActivity(
            "Archive In-active Member in Archive In-active Member"
          )
        )

        updateResult.type = "UPDATE"
        updateResult.MembershipNo = payload?.MembershipNo
        yield put(getResultInactiveMembers(updateResult))
      }
    } catch (error) {
      if (error?.response?.data) {
        const { statusCode, message } = error.response.data
        switch (statusCode) {
          case 400:
            setNotification({
              type: "error",
              message,
            })
            break
          default:
            setNotification({
              type: "error",
              message: message,
            })
            break
        }
      } else {
        setNotification({
          type: "error",
          message: error.message,
        })
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getListInactiveMembersAPI),
    fork(updateMemberAPI),
    fork(getListMemberIdTypeAPI),
  ])
}
