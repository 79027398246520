import setNotification from "components/shared-components/Notification"
import { isArray } from "lodash"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { resultRewardInventory } from "redux/actions/RewardInventory"
import { resultReward } from "redux/actions/Reward"
import {
  REWARD_INVENTORY_ALERT,
  DELETE_REWARD_INVENTORY,
} from "redux/constants/RewardInventory"
import { SET_REWARD } from "redux/constants/Reward"
import RewardInventoryService from "services/RewardInventoryService"
import RewardService from "services/RewardService"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* deleteRewardInventoryAPI() {
  yield takeEvery(DELETE_REWARD_INVENTORY, function* ({ payload }) {
    try {
      let data = {}
      for (let i = 0; i < payload?.length; i++) {
        yield call(RewardInventoryService.deleteRewardInventory, payload[i])
      }
      data.type = REWARD_INVENTORY_ALERT
      yield put(resultRewardInventory(data))
      console.log(payload)

      if (payload?.length > 0) {
        const RewardId = payload[0].RewardId
        let reward = yield call(RewardService.getReward, RewardId)
        reward.type = SET_REWARD
        yield put(resultReward(reward))
      }
    } catch (err) {
      setError(
        "Delete Reward Inventory Failed",
        isArray(err?.response?.data?.message)
          ? err?.response?.data?.message[0]
          : err?.response?.data?.message ?? "Something went wrong"
      )
    }
  })
}

export default function* rootSaga() {
  yield all([fork(deleteRewardInventoryAPI)])
}
