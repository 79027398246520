import React from "react"
import { Card, Typography, Row, Col } from "antd"
import Chart from "react-apexcharts"
import "../index.css"

const { Title } = Typography

const TotalSalesByCategory = ({ category, chartSize = 200, data }) => {
  const series = data.rows ? data.rows.map((d) => d.Total) : []

  const options = {
    labels: data.rows ? data.rows.map((d) => `${d.Value} - ${d.Total}`) : [],
    chart: {
      type: "donut",
      width: chartSize,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "16px",
              fontFamily: "Helvetica, Arial, sans-serif",
              color: "#455560",
              offsetY: 5,
            },
            total: {
              show: true,
              label: `Total Sales by ${category}`,
              color: "#455560",
              formatter: function (w) {
                return w.globals.seriesTotals
                  .reduce((a, b) => {
                    return a + b
                  }, 0)
                  .toLocaleString()
              },
            },
          },
        },
      },
    },
    legend: {
      show: true,
      itemMargin: {
        vertical: 5,
      },
    },
    tooltip: {
      y: {
        title: {
          formatter: function (val) {
            return val.split(" - ")[0]
          },
        },
      },
    },
  }

  return (
    <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
      <Col xs={24}>
        <Card className="total-sales-by-category-card">
          <Title level={4}>Total Sales by {category}</Title>
          <Chart
            options={options}
            series={series}
            type="donut"
            height={chartSize}
          />
        </Card>
      </Col>
    </Row>
  )
}

export default TotalSalesByCategory
