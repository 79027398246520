import {
  SET_SEND_EMAIL_LOADING,
  SET_VALIDATE_OTP_LOADING,
  SET_UPDATE_EMAIL_LOADING,
} from "redux/constants/CustomerEmailVerification"

const initialState = {
  isSendEmailLoading: false,
  isValidateOtpLoading: false,
  isUpdateEmailLoading: false,
}

const CustomerEmailVerification = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SEND_EMAIL_LOADING:
      return {
        ...state,
        isSendEmailLoading: payload,
      }
    case SET_VALIDATE_OTP_LOADING:
      return {
        ...state,
        isValidateOtpLoading: payload,
      }
    case SET_UPDATE_EMAIL_LOADING:
      return {
        ...state,
        isUpdateEmailLoading: payload,
      }
    default:
      return state
  }
}

export default CustomerEmailVerification
