import axios from "axios"
import { API_BASE_URL } from "configs/AppConfig"

const host = API_BASE_URL

const TaskProgressService = {}

TaskProgressService.createTaskProgress = function (data) {
  return axios({
    url: host + "/task/progress-logs/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: data,
  })
}

TaskProgressService.getListTaskProgress = function (data) {
  return axios({
    url: host + "/task/progress-logs/find-all",
    data: {
      Rows: data.rows,
      Pages: data.pages,
      Search: JSON.stringify(data.queries ?? {}),
    },
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
  })
}

TaskProgressService.getTaskProgress = function (data) {
  return axios({
    url: host + `/task/progress-logs/find-one`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { ProgressLogId: data.id },
  })
}

TaskProgressService.updateTaskProgress = function (data) {
  return axios({
    url: host + `/task/progress-logs/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: { ...data, ProgressLogId: data.ProgressLogId },
    method: "post",
  })
}

TaskProgressService.deleteTaskProgress = function (data) {
  return axios({
    url: host + `/task/progress-logs/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { ProgressLogId: data.ProgressLogId },
  })
}

export default TaskProgressService
