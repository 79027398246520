import {
  RESULT_POINT_ADJUSTMENT,
  RESULT_MEMBER_DETAIL,
  SET_LIST_POINT_ADJUSTMENT_LOADING,
} from "../constants/PointSummary"

const initialState = {
  isLoading: false,
  group: [],
  alert: {},
  member: {
    memberDetail: null,
    memberCardDetail: null,
    pointExpiry: null,
    pointExpiryDetail: null,
    membershipLevelDetail: {},
    customerEligibleReward: [],
  },
}

const PointSummary = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIST_POINT_ADJUSTMENT_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case RESULT_POINT_ADJUSTMENT:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          group: action?.payload?.data,
          isLoading: false,
        }
      }
      break
    case RESULT_MEMBER_DETAIL:
      if (action.payload.type === "POINT") {
        const cardDetail = []
        action.payload?.data?.CardLevel?.forEach((el) => {
          if (el.Status === "Active") {
            cardDetail.push(el)
          }
        })
        return {
          ...state,
          member: {
            ...state.member,
            pointExpiry: cardDetail?.length > 0 ? cardDetail[0] : [],
          },
        }
      } else if (action.payload.type === "POINT EXPIRY DETAIL") {
        return {
          ...state,
          member: {
            ...state.member,
            pointExpiryDetail: action?.payload?.data,
          },
        }
      } else if (action.payload.type === "MEMBER") {
        return {
          ...state,
          member: {
            ...state.member,
            memberDetail:
              action.payload?.data?.length > 0 ? action.payload.data[0] : null,
          },
        }
      } else if (action.payload.type === "MEMBER CARD LEVEL") {
        return {
          ...state,
          member: {
            ...state.member,
            memberCardDetail: action.payload?.data?.rows?.[0],
          },
        }
      } else if (action.payload.type === "MEMBERSHIP LEVEL") {
        return {
          ...state,
          member: {
            ...state.member,
            membershipLevelDetail:
              action.payload?.data?.rows?.[0]?.MembershipLevel?.[0],
          },
        }
      } else if (action.payload.type === "ELIGIBLE REWARD") {
        return {
          ...state,
          member: {
            ...state.member,
            customerEligibleReward: action.payload?.data?.rows,
          },
        }
      }
      break

    default:
      return state
  }
}

export default PointSummary
