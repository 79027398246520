import axios from "axios"
import { API_BASE_URL } from "configs/AppConfig"

const host = API_BASE_URL

const CustomerTypeService = {}

CustomerTypeService.getListCustomerType = function (data) {
  return axios({
    url: host + "/customer-types/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
  })
}

export default CustomerTypeService
