import {
  GET_RESULT_PRODUCT_GROUP,
  SET_ALERT_PRODUCT_GROUP,
  SET_PRODUCT_GROUP,
  SET_PRODUCT_TYPE,
} from "redux/constants/ProductGroup"

const initialState = {
  group: [],
  productTypes: [],
  codeList: [],
  alert: {},
}
const productGroup = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_TYPE:
      let { data } = action.payload
      return {
        ...state,
        productTypes: data.rows,
      }
    case GET_RESULT_PRODUCT_GROUP:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          group: action.payload.data,
        }
      } else if (action.payload.type === "CODE LIST") {
        return {
          ...state,
          codeList: action.payload.data,
        }
      } else if (action.payload.type === "DETAIL") {
        return {
          ...state,
          detail: action.payload.data,
        }
      } else if (action.payload.type === "CREATE") {
        if (state.codeList?.rows?.length > 0) {
          return {
            ...state,
            codeList: {
              count: state.codeList.count + 1,
              rows: [action.payload.data, ...state.codeList.rows],
            },
          }
        } else {
          return {
            ...state,
            codeList: {
              count: 1,
              rows: [action.payload.data],
            },
          }
        }
      } else if (action.payload.type === "UPDATE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: state.group.rows.map((groupParam) => {
              if (groupParam.ProductCode === action.payload.data.ProductCode) {
                return {
                  ...groupParam,
                  ProductCode: action.payload.data.ProductCode,
                  ProductName: action.payload.data.ProductName,
                  ProductType: action.payload.data.ProductType,
                  Status: action.payload.data.Status,
                }
              } else {
                return groupParam
              }
            }),
          },
        }
      } else if (action.payload.type === "DELETE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: state.group.rows.filter(
              (groupParam) =>
                groupParam.ProductCode !== action.payload.data.ProductCode
            ),
          },
        }
      }
      break
    case SET_PRODUCT_GROUP:
      return {
        ...state,
        group: action.payload,
      }
    case SET_ALERT_PRODUCT_GROUP:
      return {
        ...state,
        alert: action.payload,
      }
    default:
      return state
  }
}

export default productGroup
