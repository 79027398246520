export const SET_AUTH_LOADING = "SET_AUTH_LOADING"
export const SIGNIN = "SIGNIN"
export const SSO_SIGNIN = "SSO_SIGNIN"
export const AUTHENTICATED = "AUTHENTICATED"
export const SIGNOUT = "SIGNOUT"
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS"
export const SIGNUP = "SIGNUP"
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS"
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE"
export const HIDE_AUTH_MESSAGE = "HIDE_AUTH_MESSAGE"
export const AUTH_TOKEN = "auth_token"
export const CLIENT_IP_ADDRESS = 'client_ip_address'
export const REFRESH_TOKEN = "refresh_token"
export const LOGIN_DATE_TIME = "login_date_time"
export const UNIQUE_SESSION_ID = "unique_session_id"
export const COMMON_TOKEN = "cc-token"
export const SSO_TOKEN = "s-token"
export const USER_SSO_TOKEN = "user_s-token"
export const SIGNIN_WITH_GOOGLE = "SIGNIN_WITH_GOOGLE"
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED =
  "SIGNIN_WITH_GOOGLE_AUTHENTICATED"
export const SIGNIN_WITH_FACEBOOK = "SIGNIN_WITH_FACEBOOK"
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED =
  "SIGNIN_WITH_FACEBOOK_AUTHENTICATED"
export const UNAUTHENTICATED = "UNAUTHENTICATED"

export const CUSTOMER_SIGNIN = "CUSTOMER_SIGNIN"
export const CUSTOMER_AUTHENTICATED = "CUSTOMER_AUTHENTICATED"
export const CUSTOMER_TOKEN = "c-token"

export const SYSTEM_SIGNIN = "SYSTEM_SIGNIN"
export const COMMON_SYSTEM_SIGNIN = "COMMON_SYSTEM_SIGNIN"
export const SYSTEM_AUTHENTICATED = "SYSTEM_AUTHENTICATED"

export const GET_ROLES_PRIVILEGES = "GET_ROLES_PRIVILEGES"
export const SET_ROLES_PRIVILEGES = "SET_ROLES_PRIVILEGES"
export const ROLES = "roles"
export const PRIVILEGES = "privileges"
export const LOGOUT = "LOGOUT"

export const CHANGE_SSO_PASSWORD = "CHANGE_SSO_PASSWORD"
export const REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD"

export const GET_LOGIN_ACTIVITIES = "GET_LOGIN_ACTIVITIES"
export const SET_LOGIN_ACTIVITIES = "SET_LOGIN_ACTIVITIES"

export const CREATE_LOGIN_ACTIVITY = "CREATE_LOGIN_ACTIVITY"
