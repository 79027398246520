import {
  SET_IS_POINT_EXPIRY_REWARD_LOADING,
  CREATE_POINT_EXPIRY_REWARD,
  CREATE_MEMBER_REWARD_TO_POINT_EXPIRY,
  GET_LIST_POINT_EXPIRY_REWARD_ELIGIBLE,
  SET_CREATE_POINT_EXPIRY_REWARD,
  SET_CREATE_MEMBER_REWARD_TO_POINT_EXPIRY,
  SET_LIST_POINT_EXPIRY_REWARD_ELIGIBLE,
} from "redux/constants/PointExpiryReward"

export const setIsPointExpiryRewardLoading = (payload) => {
  return {
    type: SET_IS_POINT_EXPIRY_REWARD_LOADING,
    payload,
  }
}

export const createPointExpiryReward = (data) => {
  return {
    type: CREATE_POINT_EXPIRY_REWARD,
    payload: data,
  }
}

export const setCreatePointExpiryReward = (data) => {
  return {
    type: SET_CREATE_POINT_EXPIRY_REWARD,
    payload: data,
  }
}

export const createMemberPointToPointExpiry = (data) => {
  return {
    type: CREATE_MEMBER_REWARD_TO_POINT_EXPIRY,
    payload: data,
  }
}

export const setCreateMemberPointToPointExpiry = (data) => {
  return {
    type: SET_CREATE_MEMBER_REWARD_TO_POINT_EXPIRY,
    payload: data,
  }
}

export const getListPointExpiryRewardEligible = (data) => {
  return {
    type: GET_LIST_POINT_EXPIRY_REWARD_ELIGIBLE,
    payload: data,
  }
}

export const setListPointExpiryRewardEligible = (data) => {
  return {
    type: SET_LIST_POINT_EXPIRY_REWARD_ELIGIBLE,
    payload: data,
  }
}
