import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  GET_LIST_POINT_EXPIRY,
  GET_LIST_CUSTOMER_POINT_EXPIRIES,
  UPDATE_CUSTOMER_POINT_EXPIRY,
} from "../constants/PointExpiry"
import {
  setIsPointExpiryLoading,
  resultPointExpiry,
  unmountPointExpiry,
} from "../actions/PointExpiry"
import PointExpiryService from "services/PointExpiryService"
import setNotification from "../../components/shared-components/Notification"
import { createLoginActivity } from "redux/actions/Auth"

export function* getListPointExpiryAPI() {
  yield takeEvery(GET_LIST_POINT_EXPIRY, function* ({ payload }) {
    try {
      yield put(setIsPointExpiryLoading(true))

      const group = yield call(PointExpiryService.getListPointExpiry, payload)
      if (group.alert) {
        setNotification({
          type: "error",
          message: "List Failed",
          description: "Point Expiry failed to be listed.",
        })
      } else {
        group.type = "LIST"
        yield put(resultPointExpiry(group))
      }
    } catch (error) {
      yield put(setIsPointExpiryLoading(false))
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: message,
        })
      } else {
        setNotification({
          type: "error",
          message: error.message,
        })
      }
    }
  })
}

export function* getCustomerPointExpiriesAPI() {
  yield takeEvery(GET_LIST_CUSTOMER_POINT_EXPIRIES, function* ({ payload }) {
    try {
      yield put(setIsPointExpiryLoading(true))

      const group = yield call(
        PointExpiryService.getListCustomerPointExpiries,
        payload
      )
      if (group.alert) {
        setNotification({
          type: "error",
          message: "List Failed",
          description: "Customer Point Expiry failed to be listed.",
        })
      } else {
        group.type = "CUSTOMER POINT EXPIRIES"
        yield put(resultPointExpiry(group))
      }
    } catch (error) {
      yield put(setIsPointExpiryLoading(false))
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: message,
        })
      } else {
        setNotification({
          type: "error",
          message: error.message,
        })
      }
    }
  })
}

export function* updateCustomerPointExpiryAPI() {
  yield takeEvery(UPDATE_CUSTOMER_POINT_EXPIRY, function* ({ payload }) {
    try {
      yield put(setIsPointExpiryLoading(true))

      const group = yield call(
        PointExpiryService.updateCustomerPointExpiry,
        payload
      )
      if (group.alert) {
        setNotification({
          type: "error",
          message: `Failed to update member ${payload?.MembershipNo}`,
        })
      } else {
        yield put(
          createLoginActivity(
            "Update Point Expiry in Point Expiry"
          )
        )

        setNotification({
          type: "success",
          message: group?.data?.message,
        })
        yield put(unmountPointExpiry())
      }
    } catch (error) {
      yield put(setIsPointExpiryLoading(false))
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: message,
        })
      } else {
        setNotification({
          type: "error",
          message: error.message,
        })
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getListPointExpiryAPI),
    fork(getCustomerPointExpiriesAPI),
    fork(updateCustomerPointExpiryAPI),
  ])
}
