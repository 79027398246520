export const CREATE_CUSTOMER_SELF_SERVICE = "CREATE_CUSTOMER_SELF_SERVICE"
export const UPDATE_CUSTOMER_SELF_SERVICE = "UPDATE_CUSTOMER_SELF_SERVICE"
export const SHOW_CREATE_CUSTOMER = "SHOW_CREATE_CUSTOMER"
export const SHOW_UPDATE_CUSTOMER = "SHOW_UPDATE_CUSTOMER"
export const GET_CUSTOMER_PROFILE = "GET_CUSTOMER_PROFILE"
export const SET_CUSTOMER_PROFILE = "SET_CUSTOMER_PROFILE"
export const GET_LIST_PASSCODE = "GET_LIST_PASSCODE"
export const VALIDATE_PASSCODE = "VALIDATE_PASSCODE"
export const SET_PASSCODE_ERROR = "SET_PASSCODE_ERROR"
export const GENERATE_PASSCODE = "GENERATE_PASSCODE"
export const GET_CARD_LEVEL_DETAIL_SELF_SERVICE =
  "GET_CARD_LEVEL_DETAIL_SELF_SERVICE"
export const VERIFY_REFERRER_SELF_SERVICE = "VERIFY_REFERRER_SELF_SERVICE"
