import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  CREATE_CAMPAIGN,
  GET_LIST_CAMPAIGN,
  GET_CAMPAIGN,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN,
} from "redux/constants/Campaign"
import { createLoginActivity } from "redux/actions/Auth"
import CampaignService from "services/CampaignService"
import { resultCampaign, getListCampaign } from "redux/actions/Campaign"
import setNotification from "components/shared-components/Notification"

export function* createCampaignAPI() {
  yield takeEvery(CREATE_CAMPAIGN, function* ({ payload }) {
    try {
      const campaign = yield call(CampaignService.createCampaign, payload)
      if (campaign.alert) {
      } else {
        yield put(
          createLoginActivity(
            `Add Campaign in Campaign. CampaignCode - ${campaign?.data?.CampaignCode}`
          )
        )
        campaign.type = "CREATE"
        yield put(getListCampaign({ Status: "Active" }))
        payload.callback(campaign?.data)
      }
    } catch (err) {
      if (err?.response?.data) {
        const { message, error } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* listCampaignAPI() {
  yield takeEvery(GET_LIST_CAMPAIGN, function* ({ payload }) {
    try {
      const campaign = yield call(CampaignService.getListCampaign, payload)
      if (campaign.alert) {
        setNotification({
          type: "error",
          message: "Listing Failed",
          description: "An error occurred",
        })
      } else {
        campaign.type = "LIST"
        yield put(resultCampaign(campaign))
      }
    } catch (err) {
      if (err?.response?.data) {
        const { message, error } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* getCampaignAPI() {
  yield takeEvery(GET_CAMPAIGN, function* ({ payload }) {
    try {
      const campaignDetail = yield call(CampaignService.getCampaign, payload)
      if (campaignDetail.alert) {
        setNotification({
          type: "error",
          message: "Campaign Detail Failed to load",
        })
      } else {
        campaignDetail.type = "DETAIL"
        yield put(resultCampaign(campaignDetail))
      }
    } catch (err) {
      if (err?.response?.data) {
        const { message, error } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* updateCampaignAPI() {
  yield takeEvery(UPDATE_CAMPAIGN, function* ({ payload }) {
    try {
      const groupUpdate = yield call(CampaignService.updateCampaign, {
        campaignCode: payload?.CampaignCode,
        data: payload,
      })
      if (groupUpdate.alert) {
        setNotification({
          type: "error",
          message: "Update Failed",
        })
      } else {
        yield put(
          createLoginActivity(
            `Update Campaign in Campaign. CampaignCode - ${payload?.CampaignCode}`
          )
        )
        groupUpdate.type = "UPDATE"
        yield put(getListCampaign({ page: payload?.page, rows: payload?.rows }))
        yield put(resultCampaign(groupUpdate))
        setNotification({
          type: "success",
          message: "Update Success",
          description: "Campaign has been successfully updated",
        })
      }
    } catch (err) {
      if (err?.response?.data) {
        const { message, error } = err.response.data
        setNotification({
          type: "success",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* deleteCampaignAPI() {
  yield takeEvery(DELETE_CAMPAIGN, function* ({ payload }) {
    try {
      const result = yield call(
        CampaignService.deleteCampaign,
        payload?.CampaignCode
      )
      if (result.alert) {
        setNotification({
          type: "error",
          message: "Delete Failed",
        })
      } else {
        yield put(
          createLoginActivity(
            `Delete Campaign in Campaign. CampaignCode - ${payload?.CampaignCode}`
          )
        )
        result.type = "DELETE"
        yield put(resultCampaign(result))
        yield put(getListCampaign())
        setNotification({
          type: "success",
          message: "Campaign Data has been deleted successfully",
        })
      }
    } catch (err) {
      if (err?.response?.data) {
        const { message, error } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(createCampaignAPI),
    fork(listCampaignAPI),
    fork(getCampaignAPI),
    fork(updateCampaignAPI),
    fork(deleteCampaignAPI),
  ])
}
