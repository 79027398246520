import {
  SET_IS_POINT_EXPIRY_LOADING,
  RESULT_POINT_EXPIRY,
  SET_LIST_POINT_EXPIRY,
  UNMOUNT_POINT_EXPIRY,
} from "redux/constants/PointExpiry"

const initialState = {
  isLoading: false,
  pointExpiryList: {
    count: 0,
    rows: [],
  },
  customerPointExpiries: [],
  alert: {},
  rate: null,
}

const PointExpiry = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_POINT_EXPIRY_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case RESULT_POINT_EXPIRY:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          pointExpiryList: action.payload.data,
          isLoading: false,
        }
      } else if (action.payload.type === "CUSTOMER POINT EXPIRIES") {
        return {
          ...state,
          customerPointExpiries: action.payload.data,
          isLoading: false,
        }
      }
      break
    case SET_LIST_POINT_EXPIRY:
      return {
        ...state,
        pointExpiryList: null,
      }

    case UNMOUNT_POINT_EXPIRY:
      return {
        ...state,
        pointExpiryList: {
          count: 0,
          rows: [],
        },
        isLoading: false,
      }

    default:
      return state
  }
}

export default PointExpiry
