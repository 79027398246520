import {
  GET_LIST_CAMPAIGN_TARGET,
  CREATE_CAMPAIGN_TARGET,
  RESULT_CAMPAIGN_TARGET,
  GET_MEMBER,
  SET_MEMBER,
  DELETE_CAMPAIGN_TARGET,
  SET_CAMPAIGN_TARGET,
} from "redux/constants/CampaignTarget"

export const getListCampaignTarget = (data) => {
  return {
    type: GET_LIST_CAMPAIGN_TARGET,
    payload: data,
  }
}

export const createCampaignTarget = (data) => {
  return {
    type: CREATE_CAMPAIGN_TARGET,
    payload: data,
  }
}

export const deleteCampaignTarget = (targetId) => {
  return {
    type: DELETE_CAMPAIGN_TARGET,
    payload: targetId,
  }
}

export const resultCampaignTarget = (data) => {
  return {
    type: RESULT_CAMPAIGN_TARGET,
    payload: data,
  }
}

export const getMember = (membershipNo) => {
  return {
    type: GET_MEMBER,
    payload: membershipNo,
  }
}

export const setMember = (data) => {
  return {
    type: SET_MEMBER,
    payload: data,
  }
}

export const setCampaignTarget = (data) => {
  return {
    type: SET_CAMPAIGN_TARGET,
    payload: data,
  }
}
