import setNotification from "components/shared-components/Notification"
import { isArray } from "lodash"
import moment from "moment"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  setIsCancelled,
  setIsLoading,
  setReward,
  setRewardImages,
} from "redux/actions/CancelRedeemReward"
import {
  GET_REWARD,
  ON_CANCEL_REDEEMED_REWARD,
} from "redux/constants/CancelRedeemReward"
import CommonService from "services/CommonService"
import CustomerRewardService from "services/CustomerRewardService"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* getRewardAPI() {
  yield takeEvery(GET_REWARD, function* ({ payload }) {
    try {
      const { data } = yield call(
        CustomerRewardService.getCustomerReward,
        payload.ReferenceCode
      )

      data.MembershipNo =
        payload.MemberId === "MembershipNo"
          ? payload.MemberIdValue
          : data.Customer.CustomerMemberships[0].MembershipNo

      const isActive = data.Status === "Active"

      if (!isActive) {
        setError(
          `The reward is ${data.Status}, cancellation is not allowed if the record Status is not Active`
        )

        return
      }

      const isMembership = data.Customer.CustomerMemberships.some(
        ({ MembershipNo }) => MembershipNo === payload.MemberIdValue
      )
      const isIdType = payload.MemberIdValue === data.Customer.IdNo
      const isContactNo = payload.MemberIdValue === data.Customer.ContactNo
      const isEmail = payload.MemberIdValue === data.Customer.Email

      const isMatched =
        payload.MemberId === "MembershipNo"
          ? isMembership
          : payload.MemberId === "IdNo"
            ? isIdType
            : payload.MemberId === "ContactNo"
              ? isContactNo
              : payload.MemberId === "Email"
                ? isEmail
                : false

      if (!isMatched) {
        setError(
          "Get Reward Failed",
          "Member Details did not match with rewards record"
        )
        return
      }

      /** Get Reward Images */
      const { data: images } = yield call(CommonService.getMedias, {
        ObjectId: data.Reward?.RewardId,
        ObjectType: "RewardId",
      })

      if (images.rows?.length) yield put(setRewardImages(images.rows))
      /** End Get Reward Images */

      yield put(setReward(data))
    } catch (err) {
      setError("Get Reward Failed", err.response.data.message ?? "")
    }
  })
}

export function* onCancelRedeemRewardAPI() {
  yield takeEvery(ON_CANCEL_REDEEMED_REWARD, function* ({ payload }) {
    yield put(setIsLoading(true))

    try {
      const { ReferenceCode, Remarks } = payload

      const userInfo = JSON.parse(sessionStorage.getItem("userinfo"))

      if (userInfo) {
        const dataCancel = {
          CancelledById: userInfo.id + "",
          CancelledOutletCode: userInfo.building.code + "",
          CancelledDate: moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss"),
          Status: "Cancelled",
          Remarks,
        }

        yield call(CustomerRewardService.updateCustomerReward, {
          ref_code: ReferenceCode,
          data: dataCancel,
        })

        yield put(
          createLoginActivity(`Cancel Redeem Rewards. ReferenceCode - ${ReferenceCode}`)
        )

        setNotification({
          type: "success",
          message: "Cancel Reward Success",
          description: "",
        })
        yield put(setIsCancelled(true))
      } else {
        setError("Cancel Reward Failed")
      }
    } catch (err) {
      setError(
        "Cancel Reward Failed",
        isArray(err.response.data?.message)
          ? err.response.data?.message[0]
          : err.response.data.message ?? ""
      )
    }

    yield put(setIsLoading(false))
  })
}

export default function* rootSaga() {
  yield all([fork(getRewardAPI), fork(onCancelRedeemRewardAPI)])
}
