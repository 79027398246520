import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  CREATE_POINT_STRUCTURE,
  GET_LIST_POINT_STRUCTURE,
  GET_POINT_STRUCTURE,
  UPDATE_POINT_STRUCTURE,
  DELETE_POINT_STRUCTURE,
} from "../constants/PointStructure"
import {
  resultPointStructure,
  alertPointStructure,
} from "../actions/PointStructure"
import PointStructureService from "services/PointStructureService"
import { isArray } from "lodash"
import moment from "moment"
import { createLoginActivity } from "redux/actions/Auth"

//generator
export function* createPointStructureAPI() {
  yield takeEvery(CREATE_POINT_STRUCTURE, function* ({ payload }) {
    const { group: payloadGroup, groupDetails: payloadGroupDetails } = payload
    let auth_token = sessionStorage.getItem("auth_token")
    payloadGroup.map((item) => (item.access_token = auth_token))
    payloadGroupDetails.access_token = auth_token
    try {
      const group = yield call(
        PointStructureService.createPointStructure,
        payloadGroupDetails
      )
      if (group.alert) {
        yield put(alertPointStructure({ success: false, message: group.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Add Point Structure in Point Structure. StructureId - ${group.data.StructureId}`
          )
        )

        yield put(
          alertPointStructure({
            success: true,
            message: "A new point structure Details has been created",
          })
        )

        yield all(
          payloadGroup.map((payload) =>
            call(
              PointStructureService.createPointStructureDetails,
              payload,
              group.data.StructureId
            )
          )
        )
      }
    } catch (err) {
      yield put(
        alertPointStructure({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* listPointStructureAPI() {
  yield takeEvery(GET_LIST_POINT_STRUCTURE, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(
        PointStructureService.getListPointStructure,
        payload
      )
      if (group.alert) {
      } else {
        group.type = "LIST"
        yield put(resultPointStructure(group))
      }
    } catch (err) {}
  })
}

export function* getPointStructureAPI() {
  yield takeEvery(GET_POINT_STRUCTURE, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(PointStructureService.getPointStructure, payload)
      if (group.alert) {
        yield put(alertPointStructure(group.alert))
      } else {
        group.type = "UPDATE"
        yield put(resultPointStructure(group.data))
      }
    } catch (err) {
      yield put(alertPointStructure(err))
    }
  })
}

export function* updatePointStructureAPI() {
  yield takeEvery(UPDATE_POINT_STRUCTURE, function* ({ payload }) {
    const { group: payloadGroup, groupDetails: payloadGroupDetails } = payload
    let auth_token = sessionStorage.getItem("auth_token")
    payloadGroup.map((item) => (item.access_token = auth_token))
    payloadGroupDetails.access_token = auth_token
    try {
      const group = yield call(
        PointStructureService.updatePointStructure,
        payloadGroupDetails
      )
      if (group.alert) {
        yield put(alertPointStructure({ success: false, message: group.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Update Point Structure in Point Structure. StructureId - ${group.data.StructureId}`
          )
        )

        yield put(
          alertPointStructure({
            success: true,
            message: "Point structure Details has been updated",
          })
        )
        // eslint-disable-next-line array-callback-return
        let listAPI = payloadGroup.map((DetailsPayload) => {
          DetailsPayload.StructureId = group.data.StructureId.toString()
          if (
            DetailsPayload.StructureDetailId &&
            DetailsPayload.Status !== "Expired"
          ) {
            if (DetailsPayload.Status === "Active") {
              delete DetailsPayload.EffectiveFrom
              DetailsPayload.EffectiveTo ?? delete DetailsPayload.EffectiveTo
            }
            if (DetailsPayload.Status === "Inactive") {
              if (
                DetailsPayload.EffectiveTo == null ||
                moment(DetailsPayload.EffectiveTo).isBefore(moment())
              ) {
                delete DetailsPayload.EffectiveTo
              }
            }
            return call(
              PointStructureService.updatePointStructureDetails,
              DetailsPayload
            )
          }

          if (!DetailsPayload.StructureDetailId) {
            return call(
              PointStructureService.createPointStructureDetails,
              DetailsPayload,
              group.data.StructureId
            )
          }
        })
        yield all([...listAPI])
      }
    } catch (err) {
      yield put(
        alertPointStructure({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* deletePointStructureAPI() {
  yield takeEvery(DELETE_POINT_STRUCTURE, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(
        PointStructureService.deletePointStructure,
        payload
      )
      if (group.alert) {
        yield put(alertPointStructure({ success: false, message: group.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Delete Point Structure in Point Structure. StructureId - ${payload.StructureId}`
          )
        )

        group.type = "DELETE"
        group.data = payload
        yield put(resultPointStructure(group))
        yield put(
          alertPointStructure({
            success: true,
            message:
              "Point structure has been deleted/inactivated successfully",
          })
        )
      }
    } catch (err) {
      yield put(alertPointStructure({ success: false, message: err }))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listPointStructureAPI),
    fork(createPointStructureAPI),
    fork(getPointStructureAPI),
    fork(updatePointStructureAPI),
    fork(deletePointStructureAPI),
  ])
}
