import {
  RESULT_CARD_CATEGORY,
  ALERT_CARD_CATEGORY,
  REMOVE_CARD_CATEGORY_ALERT,
  SET_CARD_CATEGORY,
} from "redux/constants/MemberCardCategory"

const initialState = {
  group: [],
  alert: {},
  category: null,
}

const cardCategory = (state = initialState, action) => {
  switch (action.type) {
    case SET_CARD_CATEGORY:
      return {
        ...state,
        category: action.payload,
      }
    case RESULT_CARD_CATEGORY:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          group: action.payload.data,
        }
      } else if (action.payload.type === "CREATE") {
        return {
          ...state,
          group: {
            ...state.group,
            count: state.group.count + 1,
            rows: [{ ...action.payload.data }, ...state.group.rows],
          },
          category: action.payload.data,
        }
      } else if (action.payload.type === "UPDATE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: [
              ...state.group.rows.map((groupParam) => {
                if (
                  groupParam.CardCategoryId ===
                  action.payload.data.CardCategoryId
                ) {
                  return {
                    ...groupParam,
                    Name: action.payload.data.Name,
                    Description: action.payload.data.Description,
                    Status: action.payload.data.Status,
                    Code: action.payload.data.Code,
                    Color: action.payload.data.Color,
                  }
                } else {
                  return groupParam
                }
              }),
            ],
          },
          category: action.payload.data,
        }
      } else if (action.payload.type === "DELETE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: [
              ...state.group.rows.map((groupParam) => {
                if (
                  groupParam.CardCategoryId ===
                  action.payload.data.CardCategoryId
                ) {
                  return {
                    ...groupParam,
                    Status: "Deleted",
                  }
                } else {
                  return groupParam
                }
              }),
            ],
          },
          category: action.payload.data,
        }
      }
      break
    case ALERT_CARD_CATEGORY:
      return {
        ...state,
        alert: {
          status: true,
          success: action.payload.success,
          message: action.payload.message,
        },
      }
    case REMOVE_CARD_CATEGORY_ALERT:
      return {
        ...state,
        alert: {
          status: false,
        },
      }
    default:
      return state
  }
}

export default cardCategory
