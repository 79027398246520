import {
  SET_CREATE_FEEDBACK_FEEDBACK,
  SET_DELETE_FEEDBACK_FEEDBACK,
  SET_LIST_FEEDBACK_FEEDBACK,
  SET_FEEDBACK_FEEDBACK_DETAIL,
  SET_UPDATE_FEEDBACK_FEEDBACK,
} from "redux/constants/Feedback"
import {
  SET_FEEDBACK_QUESTION_FETCH,
  SET_FEEDBACK_QUESTION_LOADING,
  SET_CREATE_FEEDBACK_QUESTION,
  SET_DELETE_FEEDBACK_QUESTION,
  SET_LIST_FEEDBACK_QUESTION,
  SET_FEEDBACK_QUESTION_DETAIL,
  SET_UPDATE_FEEDBACK_QUESTION,
  UNMOUNT_FEEDBACK_QUESTION_DETAIL,
} from "redux/constants/FeedbackQuestion"
import {
  SET_CREATE_FEEDBACK_OPTION,
  SET_DELETE_FEEDBACK_OPTION,
  SET_LIST_FEEDBACK_OPTION,
  SET_UPDATE_FEEDBACK_OPTION,
  UNMOUNT_LIST_FEEDBACK_OPTION,
} from "redux/constants/FeedbackOption"

const initialState = {
  feedbackList: {
    count: 0,
    rows: [],
  },
  feedbackQuestionList: {
    isQuestionFetched: false,
    isQuestionLoading: false,
    count: 0,
    rows: [],
  },
  feedbackOptionList: {
    count: 0,
    rows: [],
  },
  feedbackDetail: {},
  feedbackQuestionDetail: {},
}

const feedback = (state = initialState, action) => {
  switch (action.type) {
    /*Feedback Feedback*/
    case SET_LIST_FEEDBACK_FEEDBACK:
      return {
        ...state,
        feedbackList: action.payload,
      }

    case SET_FEEDBACK_FEEDBACK_DETAIL:
      return {
        ...state,
        feedbackDetail: action.payload,
      }

    case SET_CREATE_FEEDBACK_FEEDBACK:
      return {
        ...state,
        feedbackList: {
          count: state.feedbackList.count + 1,
          rows: [action.payload, ...state.feedbackList.rows],
        },
      }

    case SET_UPDATE_FEEDBACK_FEEDBACK:
      return {
        ...state,
        feedbackList: {
          count: state.feedbackList.count,
          rows: state.feedbackList.rows.map((feedback) =>
            feedback.FeedbackId === action.payload.FeedbackId
              ? action.payload
              : feedback
          ),
        },
      }

    case SET_DELETE_FEEDBACK_FEEDBACK:
      return {
        ...state,
        feedbackList: {
          count: state.feedbackList.count,
          rows: state.feedbackList.rows.filter(
            (feedback) => feedback.FeedbackId !== action.payload.FeedbackId
          ),
        },
      }

    /* Feedback Question */
    case SET_FEEDBACK_QUESTION_FETCH: {
      return {
        ...state,
        feedbackQuestionList: {
          isQuestionFetched: action.payload,
          ...state.feedbackQuestionList,
        }
      }
    }

    case SET_FEEDBACK_QUESTION_LOADING: {
      return {
        ...state,
        feedbackQuestionList: {
          isQuestionLoading: action.payload,
          ...state.feedbackQuestionList,
        }
      }
    }

    case SET_LIST_FEEDBACK_QUESTION:
      return {
        ...state,
        feedbackQuestionList: {
          isQuestionFetched: true,
          isQuestionLoading: false,
          ...action.payload
        },
      }

    case SET_FEEDBACK_QUESTION_DETAIL:
      return {
        ...state,
        feedbackQuestionDetail: action.payload,
      }

    case SET_CREATE_FEEDBACK_QUESTION:
      return {
        ...state,
        feedbackQuestionList: {
          count: state.feedbackQuestionList.count + 1,
          rows: [action.payload, ...state.feedbackQuestionList.rows],
        },
      }

    case SET_UPDATE_FEEDBACK_QUESTION:
      return {
        ...state,
        feedbackQuestionList: {
          count: state.feedbackQuestionList.count,
          rows: state.feedbackQuestionList.rows.map((feedback) =>
            feedback.QuestionId === action.payload.QuestionId
              ? action.payload
              : feedback
          ),
        },
      }

    case SET_DELETE_FEEDBACK_QUESTION:
      return {
        ...state,
        feedbackQuestionList: {
          count: state.feedbackQuestionList.count,
          rows: state.feedbackQuestionList.rows.filter(
            (feedback) => feedback.QuestionId !== action.payload.QuestionId
          ),
        },
      }

    case UNMOUNT_FEEDBACK_QUESTION_DETAIL:
      return {
        ...state,
        feedbackQuestionDetail: {},
      }

    /* Feedback Option */
    case SET_LIST_FEEDBACK_OPTION:
      return {
        ...state,
        feedbackOptionList: action.payload,
      }

    case SET_CREATE_FEEDBACK_OPTION:
      return {
        ...state,
        feedbackOptionList: {
          count: state.feedbackOptionList.count + 1,
          rows: [action.payload, ...state.feedbackOptionList.rows],
        },
      }

    case SET_UPDATE_FEEDBACK_OPTION:
      return {
        ...state,
        feedbackOptionList: {
          count: state.feedbackOptionList.count,
          rows: state.feedbackOptionList.rows.map((feedback) =>
            feedback.OptionId === action.payload.OptionId
              ? action.payload
              : feedback
          ),
        },
      }

    case SET_DELETE_FEEDBACK_OPTION:
      return {
        ...state,
        feedbackOptionList: {
          count: state.feedbackOptionList.count,
          rows: state.feedbackOptionList.rows.filter(
            (feedback) => feedback.OptionId !== action.payload.OptionId
          ),
        },
      }

    case UNMOUNT_LIST_FEEDBACK_OPTION:
      return {
        ...state,
        feedbackOptionList: {
          count: 0,
          rows: [],
        },
      }

    default:
      return state
  }
}

export default feedback
