import {
  SET_CUSTOMER_ACTIVITY_LOADING,
  RESULT_CUSTOMER_ACTIVITY,
  SET_CUSTOMER_ACTIVITY_LIST,
} from "redux/constants/CustomerActivity"

const initialState = {
  isLoading: false,
  group: { count: 0, rows: [] },
  codeList: [],
  alert: {},
}

const customerActivity = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_ACTIVITY_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case RESULT_CUSTOMER_ACTIVITY:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          group: action.payload.data,
          isLoading: false,
        }
      } else if (action.payload.type === "CREATE") {
        if (state.codeList?.rows?.length > 0) {
          return {
            ...state,
            codeList: {
              count: state.codeList.count + 1,
              rows: [action.payload.data, ...state.codeList.rows],
            },
            isLoading: false,
          }
        } else {
          return {
            ...state,
            codeList: {
              count: 1,
              rows: [action.payload.data],
            },
            isLoading: false,
          }
        }
      }
      break

    case SET_CUSTOMER_ACTIVITY_LIST:
      return {
        ...state,
        group: action.payload,
        isLoading: false,
      }

    default:
      return state
  }
}

export default customerActivity
