import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  CREATE_TRANSACTION_SUMMARY,
  GET_TRANSACTION_SUMMARY,
  GET_TRANSACTION_SUMMARY_LIST,
  GET_CUSTOMER_TRANSACTION_REPORT_LIST,
  UPDATE_TRANSACTION_SUMMARY,
  CREATE_TRANSACTION_DETAIL,
  GET_TRANSACTION_DETAIL,
  GET_TRANSACTION_DETAIL_LIST,
  UPDATE_TRANSACTION_DETAIL,
} from "../constants/TransactionHistory"
import {
  resultTransactionSummary,
  alertTransactionSummary,
  resultTransactionDetail,
  alertTransactionDetail,
  setIsLoading,
} from "../actions/TransactionHistory"
import TransactionHistoryService from "services/TransactionHistoryService"
import { isArray } from "lodash"
import { createLoginActivity } from "redux/actions/Auth"

export function* createTransactionSummaryAPI() {
  yield takeEvery(CREATE_TRANSACTION_SUMMARY, function* ({ payload }) {
    try {
      const transaction = yield call(
        TransactionHistoryService.createTransactionSummary,
        payload
      )
      if (transaction.summaryAlert) {
        yield put(
          alertTransactionSummary({
            success: false,
            message: transaction.summaryAlert,
          })
        )
      } else {
        yield put(
          alertTransactionSummary({
            success: true,
            message: "Transaction summary has been saved successfully.",
          })
        )
      }
    } catch (err) {
      yield put(
        alertTransactionSummary({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* getTransactionSummaryListAPI() {
  yield takeEvery(GET_TRANSACTION_SUMMARY_LIST, function* ({ payload }) {
    try {
      yield put(setIsLoading({ summaryList: true }))

      const transaction = yield call(
        TransactionHistoryService.getTransactionSummaryList,
        payload
      )
      if (transaction.summaryAlert) {
        yield put(alertTransactionSummary(transaction.summaryAlert))
      } else {
        transaction.type = "LIST"
        yield put(resultTransactionSummary(transaction))
      }
    } catch (err) {
      yield put(setIsLoading({}))
      yield put(
        alertTransactionSummary({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* getCustomerTransactionReportList() {
  yield takeEvery(
    GET_CUSTOMER_TRANSACTION_REPORT_LIST,
    function* ({ payload }) {
      try {
        yield put(setIsLoading({ customerTransactionReportList: true }))

        const transaction = yield call(
          TransactionHistoryService.getCustomerTransactionReportList,
          payload
        )

        if (transaction.summaryAlert) {
          yield put(alertTransactionSummary(transaction.summaryAlert))
        } else {
          transaction.type = "CUSTOMER_TRANSACTION_LIST"
          yield put(resultTransactionSummary(transaction))
        }
      } catch (err) {
        yield put(setIsLoading({}))
        yield put(
          alertTransactionSummary({
            success: false,
            message: isArray(err.response.data?.message)
              ? err.response.data?.message[0]
              : err.response.data.message,
          })
        )
      }
    }
  )
}

export function* getTransactionSummaryAPI() {
  yield takeEvery(GET_TRANSACTION_SUMMARY, function* ({ payload }) {
    try {
      yield put(setIsLoading({ selectedSummary: true }))

      const transaction = yield call(
        TransactionHistoryService.getTransactionSummary,
        payload
      )
      if (transaction.summaryAlert) {
        yield put(alertTransactionSummary(transaction.summaryAlert))
      } else {
        transaction.type = "RECORD"
        yield put(resultTransactionSummary(transaction))
      }
    } catch (err) {
      yield put(
        alertTransactionSummary({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* updateTransactionSummaryAPI() {
  yield takeEvery(UPDATE_TRANSACTION_SUMMARY, function* ({ payload }) {
    try {
      yield put(setIsLoading({ updateSummary: true }))

      const transaction = yield call(
        TransactionHistoryService.updateTransactionSummary,
        payload
      )

      yield put(setIsLoading({}))
      if (transaction.summaryAlert) {
        yield put(
          alertTransactionSummary({
            success: false,
            message: transaction.summaryAlert,
          })
        )
      } else {
        yield put(setIsLoading({}))

        if (payload.isCustomerTransaction) {
          yield put(
            createLoginActivity(
              `Update Transaction Summary in Customer Transaction. SalesOrder - ${transaction.data.SalesOrder}`
            )
          )

          yield put(
            createLoginActivity(
              `Update Transaction Detail in Customer Transaction. SalesOrder - ${transaction.data.SalesOrder}`
            )
          )
        } else {
          yield put(
            createLoginActivity(
              `Updated member transaction summary from View Customer. SalesOrder - ${transaction.data.SalesOrder}`
            )
          )

          yield put(
            createLoginActivity(
              `Updated member transaction detail from View Customer. SalesOrder - ${transaction.data.SalesOrder}`
            )
          )
        }

        yield put(
          alertTransactionSummary({
            success: true,
            message: "Transaction summary has been saved successfully.",
          })
        )
      }
    } catch (err) {
      yield put(setIsLoading({}))

      yield put(
        alertTransactionSummary({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* createTransactionDetailAPI() {
  yield takeEvery(CREATE_TRANSACTION_DETAIL, function* ({ payload }) {
    try {
      const transaction = yield call(
        TransactionHistoryService.createTransactionDetail,
        payload
      )
      if (transaction.detailAlert) {
        yield put(
          alertTransactionDetail({
            success: false,
            message: transaction.detailAlert,
          })
        )
      } else {
        yield put(
          alertTransactionDetail({
            success: true,
            message: "Transaction detail has been saved successfully.",
          })
        )
      }
    } catch (err) {
      yield put(
        alertTransactionDetail({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* getTransactionDetailListAPI() {
  yield takeEvery(GET_TRANSACTION_DETAIL_LIST, function* ({ payload }) {
    try {
      yield put(setIsLoading({ detail: true }))

      const transaction = yield call(
        TransactionHistoryService.getTransactionDetailList,
        payload
      )
      if (transaction.detailAlert) {
        // yield put(alertTransactionDetail(transaction.detailAlert))
      } else {
        transaction.type = "LIST"
        yield put(resultTransactionDetail(transaction))
      }
    } catch (err) {
      yield put(setIsLoading({}))
      yield put(
        alertTransactionDetail({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* getTransactionDetailAPI() {
  yield takeEvery(GET_TRANSACTION_DETAIL, function* ({ payload }) {
    try {
      yield put(setIsLoading({ selectedDetail: true }))

      const transaction = yield call(
        TransactionHistoryService.getTransactionDetail,
        payload
      )
      if (transaction.detailAlert) {
        // yield put(alertTransactionDetail(transaction.detailAlert))
      } else {
        transaction.type = "RECORD"
        yield put(resultTransactionDetail(transaction))
      }
    } catch (err) {
      yield put(
        alertTransactionDetail({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* updateTransactionDetailAPI() {
  yield takeEvery(UPDATE_TRANSACTION_DETAIL, function* ({ payload }) {
    try {
      yield put(setIsLoading({ updateDetail: true }))

      const transaction = yield call(
        TransactionHistoryService.updateTransactionDetail,
        payload
      )
      if (transaction.detailAlert) {
        yield put(
          alertTransactionDetail({
            success: false,
            message: transaction.detailAlert,
          })
        )
      } else {
        yield put(
          alertTransactionDetail({
            success: true,
            message: "Transaction detail has been saved successfully.",
          })
        )
      }
    } catch (err) {
      yield put(setIsLoading({}))

      yield put(
        alertTransactionDetail({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(createTransactionSummaryAPI),
    fork(getTransactionSummaryListAPI),
    fork(getTransactionSummaryAPI),
    fork(getCustomerTransactionReportList),
    fork(updateTransactionSummaryAPI),
    fork(createTransactionDetailAPI),
    fork(getTransactionDetailListAPI),
    fork(getTransactionDetailAPI),
    fork(updateTransactionDetailAPI),
  ])
}
