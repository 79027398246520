export const SET_FEEDBACK_QUESTION_FETCH = "SET_FEEDBACK_QUESTION_FETCH"
export const SET_FEEDBACK_QUESTION_LOADING = "SET_FEEDBACK_QUESTION_LOADING"
export const CREATE_FEEDBACK_QUESTION = "CREATE_FEEDBACK_QUESTION"
export const SET_CREATE_FEEDBACK_QUESTION = "SET_CREATE_FEEDBACK_QUESTION"
export const GET_LIST_FEEDBACK_QUESTION = "GET_LIST_FEEDBACK_QUESTION"
export const SET_LIST_FEEDBACK_QUESTION = "SET_LIST_FEEDBACK_QUESTION"
export const GET_FEEDBACK_QUESTION = "GET_FEEDBACK_QUESTION"
export const UPDATE_FEEDBACK_QUESTION = "UPDATE_FEEDBACK_QUESTION"
export const SET_UPDATE_FEEDBACK_QUESTION = "SET_UPDATE_FEEDBACK_QUESTION"
export const DELETE_FEEDBACK_QUESTION = "DELETE_FEEDBACK_QUESTION"
export const SET_DELETE_FEEDBACK_QUESTION = "SET_DELETE_FEEDBACK_QUESTION"
export const GET_FEEDBACK_QUESTION_DETAIL = "GET_FEEDBACK_QUESTION_DETAIL"
export const SET_FEEDBACK_QUESTION_DETAIL = "SET_FEEDBACK_QUESTION_DETAIL"
export const UNMOUNT_FEEDBACK_QUESTION_DETAIL =
  "UNMOUNT_FEEDBACK_QUESTION_DETAIL"
