import {
  CLEAR_REWARD_OBJECT,
  RESULT_REWARD_OBJECT,
  SET_LIST_REWARD_OBJECT,
} from "redux/constants/RewardObject"

const initialState = {
  rows: 100,
  page: 1,
  rewardObjects: [],
  rewardObject: {},
  count: 0,
  alert: {},
}

const RewardObject = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESULT_REWARD_OBJECT:
      if (payload.type === SET_LIST_REWARD_OBJECT) {
        return {
          ...state,
          rewardObjects: payload.data.rows,
          rewardObject: {
            ...state.rewardObject,
            [payload.ObjectType]: payload.data.rows,
          },
          count: payload.data.count,
          alert: {},
        }
      }
      break
    case CLEAR_REWARD_OBJECT:
      return {
        ...state,
        rewardObject: {},
        rewardObjects: [],
      }

    default:
      return state
  }
}

export default RewardObject
