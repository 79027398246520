import {
  RESULT_CUSTOMER_TAG,
  ALERT_CUSTOMER_TAG,
  REMOVE_CUSTOMER_TAG_ALERT,
} from "redux/constants/CustomerTag"

const initialState = {
  group: [],
  alert: {},
}

const customerTag = (state = initialState, action) => {
  switch (action.type) {
    case RESULT_CUSTOMER_TAG:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          group: action.payload.data,
        }
      } else if (action.payload.type === "CREATE") {
        return {
          ...state,
          group: {
            ...state.group,
            count: state.group.count + 1,
            rows: [action.payload.data, ...state.group.rows],
          },
        }
      } else if (action.payload.type === "UPDATE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: [
              ...state.group.rows.map((groupParam) => {
                if (groupParam.TagId === action.payload.data.TagId) {
                  return {
                    ...groupParam,
                    ...action.payload.data,
                  }
                } else {
                  return groupParam
                }
              }),
            ],
          },
        }
      } else if (action.payload.type === "DELETE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: [
              ...state.group.rows.map((groupParam) => {
                if (groupParam.TagId === action.payload.data.TagId) {
                  return {
                    ...groupParam,
                    Status: "Deleted",
                  }
                } else {
                  return groupParam
                }
              }),
            ],
          },
        }
      }
      break

    case ALERT_CUSTOMER_TAG:
      return {
        ...state,
        alert: {
          status: true,
          success: action.payload.success,
          message: action.payload.message,
        },
      }

    case REMOVE_CUSTOMER_TAG_ALERT:
      return {
        ...state,
        alert: {
          status: false,
        },
      }

    default:
      return state
  }
}

export default customerTag
