import {
  GET_LIST_CUSTOMER,
  CREATE_CUSTOMER,
  GET_RESULT_CUSTOMER,
  GET_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  RESULT_CUSTOMER,
  ALERT_CUSTOMER,
  REMOVE_CUSTOMER_ALERT,
  SET_CURRENT_CUSTOMER,
  CLEAR_CURRENT_CUSTOMER,
  GET_CURRENT_CUSTOMER,
  CREATE_CUSTOMER_SELF_SERVICE,
  SET_CUSTOMER_MEMBERSHIP_DETAILS,
  CLEAR_CUSTOMER_MEMBERSHIP_DETAILS,
  GET_CUSTOMER_MEMBERSHIP_DETAILS,
  GET_CUSTOMER_MEMBERSHIP_MERGE_DETAILS,
  SET_CUSTOMER_MEMBERSHIP_MERGE_DETAILS,
  CUSTOMER_LOGIN,
  UPDATE_CUSTOMER_LOGIN,
  SET_CONFIRM_UPGRADE,
  UPGRADE_TO_PREMIUM,
  SET_CUSTOMER_LOADING,
  GET_CARD_LEVEL_DETAIL,
  VERIFY_REFERRER,
  MERGE_PREMIUM_CUSTOMER_MEMBERSHIP,
} from "redux/constants/Customer"

export const initializeCustomerLoading = () => {
  return {
    type: SET_CUSTOMER_LOADING,
  }
}

export const createCustomer = (data) => {
  return {
    type: CREATE_CUSTOMER,
    payload: data,
  }
}

// TODO: Move functions to SelfService
export const createCustomerSelfService = (data) => {
  return {
    type: CREATE_CUSTOMER_SELF_SERVICE,
    payload: data,
  }
}
// End TODO

export const getListCustomer = (data) => {
  return {
    type: GET_LIST_CUSTOMER,
    payload: data,
  }
}

export const getResultCustomer = (data) => {
  return {
    type: GET_RESULT_CUSTOMER,
    payload: data,
  }
}

export const getCustomer = (data) => {
  return {
    type: GET_CUSTOMER,
    payload: data,
  }
}

export const updateCustomer = (data) => {
  return {
    type: UPDATE_CUSTOMER,
    payload: data,
  }
}

export const deleteCustomer = (data) => {
  return {
    type: DELETE_CUSTOMER,
    payload: data,
  }
}

export const resultCustomer = (data) => {
  return {
    type: RESULT_CUSTOMER,
    payload: data,
  }
}

export const alertCustomer = (alert) => {
  return {
    type: ALERT_CUSTOMER,
    payload: alert,
  }
}

export const removeCustomerAlert = () => {
  return {
    type: REMOVE_CUSTOMER_ALERT,
  }
}

export const getCurrentCustomer = (data) => {
  return {
    type: GET_CURRENT_CUSTOMER,
    payload: data,
  }
}

export const setCurrentCustomer = (data) => {
  return {
    type: SET_CURRENT_CUSTOMER,
    payload: data,
  }
}

export const clearCurrentCustomer = (data) => {
  return {
    type: CLEAR_CURRENT_CUSTOMER,
    payload: data,
  }
}

export const getCustomerMembershipDetails = (data) => {
  return {
    type: GET_CUSTOMER_MEMBERSHIP_DETAILS,
    payload: data,
  }
}

export const setCustomerMembershipDetails = (data) => {
  return {
    type: SET_CUSTOMER_MEMBERSHIP_DETAILS,
    payload: data,
  }
}

export const getCustomerMembershipMergeDetails = (data) => {
  return {
    type: GET_CUSTOMER_MEMBERSHIP_MERGE_DETAILS,
    payload: data,
  }
}

export const setCustomerMembershipMergeDetails = (data) => {
  return {
    type: SET_CUSTOMER_MEMBERSHIP_MERGE_DETAILS,
    payload: data,
  }
}

export const clearCustomerMembershipDetails = (data) => {
  return {
    type: CLEAR_CUSTOMER_MEMBERSHIP_DETAILS,
    payload: data,
  }
}

export const customerLogin = () => {
  return {
    type: CUSTOMER_LOGIN,
  }
}

export const updateCustomerLogin = (data) => {
  return {
    type: UPDATE_CUSTOMER_LOGIN,
    payload: data,
  }
}

export const setConfirmUpgrade = (data) => {
  return {
    type: SET_CONFIRM_UPGRADE,
    payload: data,
  }
}

export const upgradeToPremium = (data) => {
  return {
    type: UPGRADE_TO_PREMIUM,
    payload: data,
  }
}

export const getCardLevelDetail = (data) => {
  return {
    type: GET_CARD_LEVEL_DETAIL,
    payload: data,
  }
}

export const verifyReferrer = (data) => {
  return {
    type: VERIFY_REFERRER,
    payload: data,
  }
}

export const mergePremiumCustomerMembership = (data) => {
  return {
    type: MERGE_PREMIUM_CUSTOMER_MEMBERSHIP,
    payload: data,
  }
}
