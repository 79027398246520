import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const PointSummaryService = {}

PointSummaryService.createPointHistory = function (data) {
  return fetch({
    url: "/point/adjustments/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

PointSummaryService.getListPointAdjustments = function (data) {
  let search = {}
  if (data?.TransactionType) {
    search.TransactionType = data.TransactionType
  }
  if (data?.InvoiceNo) {
    search.InvoiceNo = data.InvoiceNo
  }
  if (data?.MembershipNo) {
    search.MembershipNo = data.MembershipNo
  }

  const payload = {
    Rows: data?.rows ? data.rows : 10,
    Page: data?.page ? data.page : 1,
    Search: JSON.stringify(search),
    TransactionStartDate: data?.TransactionStartDate
      ? data.TransactionStartDate
      : "",
    TransactionEndDate: data?.TransactionEndDate ? data.TransactionEndDate : "",
  }

  return fetch({
    url: "/point/adjustments/find-all",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

PointSummaryService.getPointExpiry = async function (data) {
  return fetch({
    url: `/member/card-categories/find-one`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { CardCategoryId: data?.MembershipLevel[0]?.CardCategoryId },
  })
}

PointSummaryService.getPointExpiryDetail = function (data) {
  const payload = {
    ...data,
  }

  return fetch({
    url: `/reporting/point-summary-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      ...payload,
      Status: 'Active'
    },
  })
}

PointSummaryService.getMemberBalance = function (data) {
  return fetch({
    url: `/customer/memberships/balances`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      MembershipNo: data?.MembershipNo,
      Search: JSON.stringify({
        BalanceType: "Jewel Club Points",
      }),
    },
  })
}

PointSummaryService.getMemberLevel = function (data) {
  return fetch({
    url: `/member/card-levels/find-one`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { id: data },
  })
}

PointSummaryService.getMembershipLevelDetail = function (data) {
  return fetch({
    url: `/customer/memberships/find-all`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: Number.MAX_SAFE_INTEGER,
      Page: 1,
      Search: JSON.stringify({
        MembershipNo: data?.MembershipNo,
      }),
    },
  })
}

PointSummaryService.getMemberCardLevelDetail = function (data) {
  return fetch({
    url: `/member/card-levels/find-all`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: Number.MAX_SAFE_INTEGER,
      Page: 1,
      Search: JSON.stringify({
        CardCategoryId: data?.CardCategoryId,
        Status: "Active",
      }),
    },
  })
}

PointSummaryService.getCustomerEligibleReward = function (data) {
  return fetch({
    url: `/reward/customer-rewards/eligible`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      MembershipNo: data?.MembershipNo,
    },
  })
}

export default PointSummaryService
