import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  DELETE_MEDIA,
  GET_COMMON_DATA,
  GET_LIST_MEDIA,
  UPLOAD_IMAGE_MEDIAS_INTERNAL,
  CREATE_FIELD_TRANSLATION,
  GET_CONTACT_LOG,
  GET_POSTCODE,
} from "../constants/Common"
import {
  setCommonLoading,
  getCommonResult,
  setContactLog,
  setMedias,
  setPostcode,
} from "../actions/Common"
import CommonService from "services/CommonService"
import setNotification from "components/shared-components/Notification"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* listCommonAPI() {
  yield takeEvery(GET_COMMON_DATA, function* ({ payload }) {
    try {
      yield put(setCommonLoading(true))
      const group = yield call(CommonService.getCommonData, payload)
      if (!group.alert) {
        group.type = payload.replaceAll(" ", "")
        yield put(getCommonResult(group))
      }
    } catch (err) {
      yield put(setCommonLoading(false))
    }
  })
}

export function* uploadImageMediasInternalAPI() {
  yield takeEvery(UPLOAD_IMAGE_MEDIAS_INTERNAL, function* ({ payload }) {
    try {
      const group = yield call(CommonService.uploadImageMediasInternal, payload)
      if (!group.alert) {
        payload.callback(group)
      }
    } catch (err) { }
  })
}

export function* listMediaAPI() {
  yield takeEvery(GET_LIST_MEDIA, function* ({ payload }) {
    try {
      const { data } = yield call(CommonService.getMedias, payload)
      yield put(setMedias(data))
    } catch (err) {
      setError("Listing media failed.", err?.response?.data?.message ?? "")
    }
  })
}

export function* deleteMediaAPI() {
  yield takeEvery(DELETE_MEDIA, function* ({ payload }) {
    try {
      yield call(CommonService.deleteMedia, payload)
    } catch (err) {
      setError("Delete media failed.", err.response.data.message ?? "")
    }
  })
}

export function* createFieldTranslationAPI() {
  yield takeEvery(CREATE_FIELD_TRANSLATION, function* ({ payload }) {
    try {
      yield call(CommonService.createFieldTranslation, payload)
    } catch (err) {
      setError(
        "Create Field Translation failed.",
        err.response.data.message ?? ""
      )
    }
  })
}

export function* listContactLogAPI() {
  yield takeEvery(GET_CONTACT_LOG, function* ({ payload }) {
    try {
      yield put(setCommonLoading(true))
      const { data } = yield call(CommonService.getContactLog, payload)
      yield put(setContactLog(data))
    } catch (err) {
      yield put(setCommonLoading(false))
      setError("Get Contact Log failed.", err.response.data.message ?? "")
    }
  })
}

export function* listPostcodeAPI() {
  yield takeEvery(GET_POSTCODE, function* ({ payload }) {
    try {
      yield put(setCommonLoading(true))
      const { data } = yield call(CommonService.getPostcode, payload)
      yield put(setPostcode(data))
    } catch (err) {
      yield put(setCommonLoading(false))
      setError("Get Postcode failed.", err.response.data.message ?? "")
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listCommonAPI),
    fork(uploadImageMediasInternalAPI),
    fork(listMediaAPI),
    fork(deleteMediaAPI),
    fork(createFieldTranslationAPI),
    fork(listContactLogAPI),
    fork(listPostcodeAPI),
  ])
}
