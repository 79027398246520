import {
  GET_LIST_REFERRER,
  GET_RESULT_REFERRER,
  SET_REFERRER,
} from "redux/constants/MemberReferrerListing"

export const getListReferrer = (data) => {
  return {
    type: GET_LIST_REFERRER,
    payload: data,
  }
}

export const getResultReferrer = (data) => {
  return {
    type: GET_RESULT_REFERRER,
    payload: data,
  }
}

export const setReferrer = (data) => {
  return {
    type: SET_REFERRER,
    payload: data,
  }
}
