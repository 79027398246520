import {
  GET_MEMBERSHIP_LEVELS,
  GET_MEMBERSHIPS_FOR_DOWNGRADE,
  GET_MEMBERSHIP_LEVEL_BY_MEMBER,
  CREATE_MEMBERSHIP_LEVEL,
  UPDATE_MEMBERSHIP_LEVEL,
  APPROVE_MEMBERSHIP_LEVEL,
  REJECT_MEMBERSHIP_LEVEL,
  DELETE_MEMBERSHIP_LEVEL,
  RESULT_MEMBERSHIP_LEVEL,
  ALERT_MEMBERSHIP_LEVEL,
  REMOVE_MEMBERSHIP_LEVEL_ALERT,
} from "redux/constants/MembershipLevel"

export const getMembershipLevels = (data) => {
  return {
    type: GET_MEMBERSHIP_LEVELS,
    payload: data,
  }
}

export const getMembershipsForDowngrade = (data) => {
  return {
    type: GET_MEMBERSHIPS_FOR_DOWNGRADE,
    payload: data,
  }
}

export const getMembershipLevelByMember = (data) => {
  return {
    type: GET_MEMBERSHIP_LEVEL_BY_MEMBER,
    payload: data,
  }
}

export const createMembershipLevel = (data) => {
  return {
    type: CREATE_MEMBERSHIP_LEVEL,
    payload: data,
  }
}

export const deleteMembershipLevel = (data) => {
  return {
    type: DELETE_MEMBERSHIP_LEVEL,
    payload: data,
  }
}

export const updateMembershipLevel = (data) => {
  return {
    type: UPDATE_MEMBERSHIP_LEVEL,
    payload: data,
  }
}

export const approveMembershipLevel = (data) => {
  return {
    type: APPROVE_MEMBERSHIP_LEVEL,
    payload: data,
  }
}

export const rejectMembershipLevel = (data) => {
  return {
    type: REJECT_MEMBERSHIP_LEVEL,
    payload: data,
  }
}

export const resultMembershipLevel = (data) => {
  return {
    type: RESULT_MEMBERSHIP_LEVEL,
    payload: data,
  }
}

export const alertMembershipLevel = (alert) => {
  return {
    type: ALERT_MEMBERSHIP_LEVEL,
    payload: alert,
  }
}

export const removeMembershipLevelAlert = () => {
  return {
    type: REMOVE_MEMBERSHIP_LEVEL_ALERT,
  }
}
