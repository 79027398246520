import axios from "axios"
import { API_BASE_URL } from "configs/AppConfig"

const host = API_BASE_URL
const ProductGroupService = {}

ProductGroupService.getListProductGroups = function (data) {
  const searchquery = {}
  if (!(data.Status === "" || data.Status === "All"))
    searchquery.Status = data.Status
  if (!(data.ProductCode === "" || data.ProductCode === "All"))
    searchquery.ProductCode = data.ProductCode
  if (!(data.ProductName === "" || data.ProductName === "All"))
    searchquery.ProductName = data.ProductName
  if (!(data.ProductType === "" || data.ProductType === "All"))
    searchquery.ProductType = data.ProductType

  return axios({
    url: host + "/product-groups/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ? data.rows : 1000,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchquery ?? {}),
    },
  })
}

ProductGroupService.getProductGroup = function (data) {
  return axios({
    url: host + `/product-groups/find-one`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { ProductCode: data.code },
  })
}

ProductGroupService.createProductGroup = function (data) {
  return axios({
    url: host + `/product-groups/add`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

ProductGroupService.updateProductGroup = function (data) {
  return axios({
    url: host + `/product-groups/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

ProductGroupService.deleteProductGroup = function (data) {
  return axios({
    url: host + `/product-groups/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      ProductCode: data?.ProductCode,
    },
  })
}
export default ProductGroupService
