import {
  CREATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  GET_CAMPAIGN,
  GET_LIST_CAMPAIGN,
  RESULT_CAMPAIGN,
  SET_CAMPAIGN_LIST,
  UPDATE_CAMPAIGN,
  SET_PAGE_ROW,
  SET_CAMPAIGN_DETAIL,
} from "redux/constants/Campaign"

export const createCampaign = (data) => {
  return {
    type: CREATE_CAMPAIGN,
    payload: data,
  }
}

export const getListCampaign = (data) => {
  return {
    type: GET_LIST_CAMPAIGN,
    payload: data,
  }
}

export const resultCampaign = (data) => {
  return {
    type: RESULT_CAMPAIGN,
    payload: data,
  }
}

export const getCampaign = (data) => {
  return {
    type: GET_CAMPAIGN,
    payload: data,
  }
}

export const updateCampaign = (data) => {
  return {
    type: UPDATE_CAMPAIGN,
    payload: data,
  }
}

export const deleteCampaign = (data) => {
  return {
    type: DELETE_CAMPAIGN,
    payload: data,
  }
}

export const setCampaignList = (data) => {
  return {
    type: SET_CAMPAIGN_LIST,
    payload: data,
  }
}

export const setPageRow = (data) => {
  return {
    type: SET_PAGE_ROW,
    payload: data,
  }
}

export const setCampaignDetail = (data) => {
  return {
    type: SET_CAMPAIGN_DETAIL,
    payload: data,
  }
}
