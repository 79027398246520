import React from "react"
import { Modal, Result, Button } from "antd"

function ActivityTypeDeleteModal(props) {
  const onSave = () => {
    props.deleteActivityType()
    props.setShowDeleteModal(false)
  }

  const onCancel = () => {
    props.setShowDeleteModal(false)
    props.setDeleteData({
      GroupId: "",
    })
  }

  return (
    <div>
      <Modal
        visible={props.visible}
        onOk={() => onSave()}
        okText="Confirm Delete"
        onCancel={() => onCancel()}
        destroyOnClose={true}
        footer={null}
      >
        <Result status="warning" />
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "23px",
            color: "black",
            margin: "-50px 50px 20px 50px",
            textAlign: "center",
          }}
        >
          Are you sure you want to delete this Activity Type?
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-10px",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <Button type="primary" onClick={() => onSave()}>
              Confirm Delete
            </Button>
          </div>
          <div>
            <Button onClick={() => onCancel()}>Cancel</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ActivityTypeDeleteModal
