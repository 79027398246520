import fetch from "auth/APIFetchInterceptor"

const ReferrerService = {}

ReferrerService.getListReferrer = function (data) {
  let searchquery = {}

  if (data.ReferrerNo) searchquery.ReferrerNo = data.ReferrerNo
  if (data.ReferrerIdNo) searchquery.ReferrerIdNo = data.ReferrerIdNo
  if (data.MembershipNo) searchquery.MembershipNo = data.MembershipNo
  if (data.IdNo) searchquery.IdNo = data.IdNo
  if (data.InvoiceNo) searchquery.InvoiceNo = data.InvoiceNo
  if (data.RegisteredOutletCode)
    searchquery.RegisteredOutletCode = data.RegisteredOutletCode
  if (data.PointAwardYN) searchquery.PointAwardYN = data.PointAwardYN

  return fetch({
    url: "/customer/referrer-listing/find-all",
    data: {
      Rows: data.rows ? data.rows : 10,
      Page: data.page,
      Search: JSON.stringify(searchquery ?? {}),
    },
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
  })
}

export default ReferrerService
