import React from "react"
import { Switch, Route } from "react-router-dom"
import SelfServiceView from "views/self-service-views"

export const SelfServiceLayout = () => {
  return (
    <div className="auth-container">
      <Switch>
        <Route path="/self-service" component={SelfServiceView} />
      </Switch>
    </div>
  )
}

export default SelfServiceLayout
