import fetch from "auth/APIFetchInterceptor"
import { AUTH_TOKEN } from "redux/constants/Auth"

const RedeemRewardService = {}

RedeemRewardService.searchCustomerMembership = function (search) {
  return fetch({
    url: "/customer/memberships/find-all",
    method: "post",
    data: {
      Page: 1,
      Rows: 1,
      Search: JSON.stringify(search ?? {}),
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

RedeemRewardService.pointRedemption = function (data) {
  return fetch({
    url: "/point/redemption",
    method: "post",
    data,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

export default RedeemRewardService
