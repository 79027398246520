import moment from "moment"
import React, { useEffect } from "react"
import { Row, Col, Table, Button } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { createLoginActivity } from "redux/actions/Auth"
import { useHistory } from "react-router-dom"

const backgroundURL = "/img/others/img-17.jpg"
const backgroundStyle = {
  backgroundImage: `url(${backgroundURL})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  paddingTop: "10px",
}

const LogoutPageView = () => {
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.theme.currentTheme)
  const { loading, loginActivities } = useSelector((state) => state.auth)
  const history = useHistory()

  useEffect(() => {
    dispatch(createLoginActivity("Logout", true))
    //eslint-disable-next-line
  }, [dispatch])

  const columns = [
    {
      key: "ActivityDateTime",
      title: "Date, Time",
      dataIndex: "ActivityDateTime",
      render: (value) => moment(value).format("DD/MM/YYYY hh:mm:ss a"),
      sortDirections: ["ascend", "descend"],
      width: 200,
    },
    {
      key: "ActivityDescription",
      title: "Activity Description",
      dataIndex: "ActivityDescription",
      sortDirections: ["ascend", "descend"],
    },
  ]

  return (
    <div className={`h-100 ${theme === "light" ? "bg-white" : ""}`}>
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={16} xl={16}>
                <h3>Thank you for using CRM</h3>
                <h5>
                  You have logged out at:{" "}
                  {moment().format("DD/MM/YYYY hh:mm:ss a")}
                </h5>
                <div className="mt-4">
                  <Table
                    columns={columns}
                    dataSource={loginActivities.rows?.map((activity) => ({
                      ...activity,
                      key: activity.LoginActivityId,
                    }))}
                    pagination={{
                      responsive: true,
                      pageSize: 5,
                    }}
                    bordered
                    loading={loading}
                  />
                </div>
                <Button
                  type="primary"
                  block
                  onClick={() => history.push("/auth/login")}
                >
                  Login
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={0} sm={0} md={0} lg={8}>
          <div
            className="d-flex flex-column justify-content-between h-100 px-4"
            style={backgroundStyle}
          >
            <div className="text-right">
              <img src="/img/tomei-white-logo.png" alt="logo" />
            </div>
            <Row className="justify-content-center">
              <Col lg={24} md={0}>
                <img
                  className="img-fluid mb-3"
                  src="/img/others/MainImage.png"
                  alt=""
                  style={{ width: "50%" }}
                />

                <h1 className="text-white" style={{ lineHeight: "1" }}>
                  Tomei Customer Relationship Management System
                </h1>
                <p
                  className="text-white"
                  style={{
                    lineHeight: "1.4",
                    fontWeight: 300,
                    fontSize: "15px",
                  }}
                >
                  CRM System is a process in which a business or organization
                  interactions with customers, typically using data analysis to
                  study large amounts of information.
                </p>
              </Col>
            </Row>
            <div className="d-flex justify-content-end pb-4">
              <div>
                <a
                  className="text-white"
                  href="/#"
                  onClick={(e) => e.preventDefault()}
                >
                  Term & Conditions
                </a>
                <span className="mx-2 text-white"> | </span>
                <a
                  className="text-white"
                  href="/#"
                  onClick={(e) => e.preventDefault()}
                >
                  Privacy & Policy
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default LogoutPageView
