import setNotification from "components/shared-components/Notification"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { getResultReferrer } from "redux/actions/MemberReferrerListing"
import { GET_LIST_REFERRER } from "redux/constants/MemberReferrerListing"
import ReferrerService from "services/MemberReferrerListingService"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* getListReferrerAPI() {
  yield takeEvery(GET_LIST_REFERRER, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(ReferrerService.getListReferrer, payload)
      if (group.alert) {
      } else {
        group.type = "LIST"
        yield put(getResultReferrer(group))
      }
    } catch (err) {
      setError("Listing Referrer Failed", err.response.data.message ?? "")
    }
  })
}

export default function* rootSaga() {
  yield all([fork(getListReferrerAPI)])
}
