import {
  SET_CUSTOMER_ADDRESS_LOADING,
  SET_CREATE_CUSTOMER_ADDRESS,
  SET_CUSTOMER_ADDRESS_LIST,
  SET_CUSTOMER_ADDRESS_DETAIL,
  SET_UPDATE_CUSTOMER_ADDRESS,
  SET_DELETE_CUSTOMER_ADDRESS
} from 'redux/constants/CustomerAddress'

const initialState = {
  isLoading: false,
  addressList: {
    count: 0,
    rows: []
  },
  addressDetail: {}
}

const CustomerAddress = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_ADDRESS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }

    case SET_CUSTOMER_ADDRESS_LIST:
      return {
        ...state,
        addressList: action.payload,
        isLoading: false,
      }

    case SET_CUSTOMER_ADDRESS_DETAIL:
      return {
        ...state,
        addressDetail: action.payload,
        isLoading: false,
      }

    case SET_CREATE_CUSTOMER_ADDRESS:
      return {
        ...state,
        addressList: {
          count: state.addressList.count + 1,
          rows: [action.payload, ...state.addressList.rows],
        },
        isLoading: false,
      }

    case SET_UPDATE_CUSTOMER_ADDRESS:
      return {
        ...state,
        addressList: {
          count: state.addressList.count,
          rows: state.addressList.rows.map((address) =>
            address.AddressId === action.payload.AddressId
              ? action.payload
              : address
          ),
        },
        isLoading: false,
      }

    case SET_DELETE_CUSTOMER_ADDRESS:
      return {
        ...state,
        addressList: {
          count: state.addressList.count - 1,
          rows: state.addressList.rows.filter(
            (address) => address.AddressId !== action.payload.AddressId
          ),
        },
        isLoading: false,
      }

    default:
      return state
  }
}

export default CustomerAddress