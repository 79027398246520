import axios from "axios"
import { API_BASE_URL } from "configs/AppConfig"

//test
//const host = 'http://localhost:4000'; https://crm-api-test.tomei.com.my/member/
const host = API_BASE_URL

const TaskGroupService = {}

TaskGroupService.createTaskGroup = function (data) {
  return axios({
    url: host + "/task/groups/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: data,
  })
}

TaskGroupService.getListTaskGroup = function (data) {
  let searchquery = {}
  if (!(data.Status === "" || data.Status === "All"))
    searchquery.Status = data.Status
  if (!(data.Code === "" || data.Code === "All")) searchquery.Code = data.Code
  if (!(data.Description === "" || data.Description === "All"))
    searchquery.Description = data.Description

  const payload = {
    Rows: data.rows ? data.rows : 20,
    Page: data.page ? data.page : 1,
    Search: JSON.stringify(searchquery),
  }

  return axios({
    url: host + "/task/groups/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: payload,
  })
}

TaskGroupService.getTaskGroup = function (data) {
  return axios({
    url: `/task/groups/find-one`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { code: data.GroupId },
  })
}

TaskGroupService.updateTaskGroup = function (data) {
  const payload = { ...data, code: data.Code }

  if (payload.Code) delete payload.Code
  if (payload.access_token) delete payload.access_token

  return axios({
    url: host + `/task/groups/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: payload,
    method: "post",
  })
}

TaskGroupService.deleteTaskGroup = function (data) {
  return axios({
    url: host + `/task/groups/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { code: data.Code },
  })
}

export default TaskGroupService
