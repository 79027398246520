import React from "react"
import { Card, Typography, Row, Col } from "antd"
import Chart from "react-apexcharts"
import "../index.css"
import moment from "moment"

const { Title } = Typography

const TotalSales = ({ categoryList, data }) => {
  data.rows = data.rows.filter((d) => d.Type === "Ethnicity")

  const uniqueTransactionDates = [
    ...new Set(
      data.rows.map((d) => moment(d.TransactionDate).format("DD-MMM-YY"))
    ),
  ].sort((a, b) => moment(a, "DD-MMM-YY").diff(moment(b, "DD-MMM-YY")))

  const dataFormatter = (data, uniqueTransactionDates) => {
    const hashmap = {}

    data.forEach((item) => {
      if (!hashmap[item.Value]) {
        hashmap[item.Value] = []
      }

      hashmap[item.Value].push({
        x: moment(item.TransactionDate).format("DD-MMM-YY"),
        y: item.Sales,
      })
    })

    const formattedData = Object.entries(hashmap).map((value) => {
      const dataPoints = value[1]
      const accumulatedDataPoints = []

      uniqueTransactionDates.forEach((date) => {
        const matchingDataPoints = dataPoints.filter(
          (dataPoint) => dataPoint.x === date
        )

        if (matchingDataPoints.length > 0) {
          const total = matchingDataPoints.reduce((sum, dataPoint) => {
            return sum + dataPoint.y
          }, 0)

          const average = total / matchingDataPoints.length

          accumulatedDataPoints.push({
            x: date,
            y: Math.floor(average),
          })
        } else {
          accumulatedDataPoints.push({
            x: date,
            y: null,
          })
        }
      })

      return {
        name: value[0],
        data: accumulatedDataPoints,
      }
    })

    return formattedData
  }

  const series = dataFormatter(
    data.rows ? data.rows : [],
    uniqueTransactionDates
  )

  const options = {
    chart: {
      height: 350,
      width: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 5, 3],
      curve: "straight",
      dashArray: [0, 8, 5],
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      type: "category",
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  }

  return (
    <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
      <Col xs={24}>
        <Card className="total-sales-card">
          <Title level={4}>Total Sales</Title>
          <Chart options={options} series={series} height={300} />
        </Card>
      </Col>
    </Row>
  )
}

export default TotalSales
