import {
  GET_CLAIM_REWARD,
  RESET_CLAIM_REWARD_ALERT,
  RESULT_CLAIM_REWARD,
  RESET_CLAIM_REWARD,
  ON_CLAIM_REWARD,
} from "redux/constants/ClaimReward"
import { SET_IS_LOADING } from "redux/constants/TransactionHistory"

export const getClaimReward = (data) => {
  return {
    type: GET_CLAIM_REWARD,
    payload: data,
  }
}

export const claimReward = (data) => {
  return {
    type: ON_CLAIM_REWARD,
    payload: data,
  }
}

export const resetClaimRewardAlert = () => {
  return {
    type: RESET_CLAIM_REWARD_ALERT,
  }
}

export const resetClaimReward = () => {
  return {
    type: RESET_CLAIM_REWARD,
  }
}

export const resultClaimReward = (data) => {
  return {
    type: RESULT_CLAIM_REWARD,
    payload: data,
  }
}

export const setIsLoading = (isLoading) => {
  return {
    type: SET_IS_LOADING,
    payload: isLoading,
  }
}
