import {
  SET_CUSTOMER_ADDRESS_LOADING,
  CREATE_CUSTOMER_ADDRESS,
  SET_CREATE_CUSTOMER_ADDRESS,
  GET_CUSTOMER_ADDRESS_LIST,
  SET_CUSTOMER_ADDRESS_LIST,
  GET_CUSTOMER_ADDRESS_DETAIL,
  SET_CUSTOMER_ADDRESS_DETAIL,
  UPDATE_CUSTOMER_ADDRESS,
  SET_UPDATE_CUSTOMER_ADDRESS,
  DELETE_CUSTOMER_ADDRESS,
  SET_DELETE_CUSTOMER_ADDRESS,
} from '../constants/CustomerAddress'

export const setCustomerAddressLoading = (data) => {
  return {
    type: SET_CUSTOMER_ADDRESS_LOADING,
    payload: data
  }
}

export const createCustomerAddress = (data) => {
  return {
    type: CREATE_CUSTOMER_ADDRESS,
    payload: data,
  }
}

export const setCreateCustomerAddress = (data) => {
  return {
    type: SET_CREATE_CUSTOMER_ADDRESS,
    payload: data,
  }
}

export const getCustomerAddressList = (data) => {
  return {
    type: GET_CUSTOMER_ADDRESS_LIST,
    payload: data,
  }
}

export const setCustomerAddressList = (data) => {
  return {
    type: SET_CUSTOMER_ADDRESS_LIST,
    payload: data,
  }
}

export const getCustomerAddressDetail = (data) => {
  return {
    type: GET_CUSTOMER_ADDRESS_DETAIL,
    payload: data,
  }
}

export const setCustomerAddressDetail = (data) => {
  return {
    type: SET_CUSTOMER_ADDRESS_DETAIL,
    payload: data,
  }
}

export const updateCustomerAddress = (data, isUpdatingPrimaryAddress = false) => {
  return {
    type: UPDATE_CUSTOMER_ADDRESS,
    payload: data,
    isUpdatingPrimaryAddress
  }
}

export const setUpdateCustomerAddress = (data) => {
  return {
    type: SET_UPDATE_CUSTOMER_ADDRESS,
    payload: data,
  }
}

export const deleteCustomerAddress = (data) => {
  return {
    type: DELETE_CUSTOMER_ADDRESS,
    payload: data,
  }
}

export const setDeleteCustomerAddress = (data) => {
  return {
    type: SET_DELETE_CUSTOMER_ADDRESS,
    payload: data,
  }
}