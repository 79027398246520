import React, { useEffect } from "react"
import { Modal, Form, Input, Select, Row, Col, Button } from "antd"

function ProductGroupModal(props) {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(props.data)
  }, [props?.data, form])

  const onCancel = async () => {
    await clearFields()
    props.setShowModal(false)
  }

  const clearFields = async () => {
    await props.setData({})
    setTimeout(() => {
      form.resetFields()
    }, 1000)
  }

  const submitHandler = async (e) => {
    props.submitHandler()
    await clearFields()
  }

  const pullLeft = {
    labelAlign: "left",
    wrapperCol: { span: 20 },
    labelCol: { span: 6 },
  }

  const styleFormLabel = {
    width: 100,
  }

  return (
    <div>
      <Modal
        title={props.title ? props.title : "Product Group"}
        visible={props.visible}
        okText="Save"
        onCancel={() => onCancel()}
        destroyOnClose={true}
        footer={null}
      >
        <Form
          {...pullLeft}
          name="formGroup"
          initialValues={props.data}
          onFinish={submitHandler}
          autoComplete={"off"}
          form={form}
        >
          <Form.Item
            label={<label style={styleFormLabel}>Product Code</label>}
            name="ProductCode"
            onChange={(e) =>
              props.setData({ ...props.data, ProductCode: e.target.value })
            }
            rules={
              !props.data?.CreatedById
                ? [
                    {
                      required: true,
                      message: "Please input your product code!",
                    },
                    {
                      pattern: /^[A-Z0-9_]+$/,
                      message: "Please use capital letter without space",
                    },
                  ]
                : null
            }
          >
            <Input
              disabled={props.title === "Edit" ? true : false}
              onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
            />
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Product Name</label>}
            name="ProductName"
            onChange={(e) =>
              props.setData({ ...props.data, ProductName: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Please input your product name!",
              },
              {
                max: 100,
                message: "Product name cannot more than 100 character",
              },
            ]}
          >
            <Input
              onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
            />
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Product Type</label>}
            name="ProductType"
          >
            <Select
              value={props.data.ProductType}
              onChange={(value) =>
                props.setData({ ...props.data, ProductType: value })
              }
            >
              {props?.productTypes?.map((productType) => (
                <Select.Option value={productType.Name}>
                  {productType.Name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={<label style={styleFormLabel}>Status</label>}
            name="Status"
          >
            <Select
              value={props.data.Status ? props.data.Status : "Active"}
              onChange={(value) =>
                props.setData({ ...props.data, Status: value })
              }
            >
              <Select.Option value={"Active"}>Active</Select.Option>
              <Select.Option value={"Inactive"}>Inactive</Select.Option>
            </Select>
          </Form.Item>

          <Row gutter={{ md: 10 }} justify={"end"}>
            <Col>
              <Button onClick={onCancel}>Cancel</Button>
            </Col>

            <Col>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

export default ProductGroupModal
