import {
  GET_POINT_RATE_LIST,
  GET_POINT_RATE,
  CREATE_POINT_RATE,
  UPDATE_POINT_RATE,
  DELETE_POINT_RATE,
  SET_POINT_RATE,
  RESULT_POINT_RATE,
  ALERT_POINT_RATE,
  REMOVE_POINT_RATE_ALERT,
  GET_POINT_RATE_DETAL_BY_RATE_STRUCTURE_LIST,
  GET_POINT_RATE_DETAIL_LIST,
  GET_POINT_RATE_DETAIL,
  EXPIRE_POINT_RATE_DETAIL,
  CREATE_POINT_RATE_DETAIL,
  UPDATE_POINT_RATE_DETAIL,
  DELETE_POINT_RATE_DETAIL,
  SET_POINT_RATE_DETAIL,
  ALERT_POINT_RATE_DETAIL,
  REMOVE_POINT_RATE_ALERT_DETAIL,
} from "redux/constants/PointRates"

export const getPointRateList = (data) => {
  return {
    type: GET_POINT_RATE_LIST,
    payload: data,
  }
}

export const getPointRate = (data) => {
  return {
    type: GET_POINT_RATE,
    payload: data,
  }
}

export const createPointRate = (data) => {
  return {
    type: CREATE_POINT_RATE,
    payload: data,
  }
}

export const updatePointRate = (data) => {
  return {
    type: UPDATE_POINT_RATE,
    payload: data,
  }
}

export const deletePointRate = (data) => {
  return {
    type: DELETE_POINT_RATE,
    payload: data,
  }
}

export const setPointRate = (data) => {
  return {
    type: SET_POINT_RATE,
    payload: data,
  }
}

export const resultPointRate = (data) => {
  return {
    type: RESULT_POINT_RATE,
    payload: data,
  }
}

export const alertPointRate = (alert) => {
  return {
    type: ALERT_POINT_RATE,
    payload: alert,
  }
}

export const removePointRateAlert = () => {
  return {
    type: REMOVE_POINT_RATE_ALERT,
  }
}

export const getPointRateDetailByRateStructureList = (data) => {
  return {
    type: GET_POINT_RATE_DETAL_BY_RATE_STRUCTURE_LIST,
    payload: data,
  }
}

export const getPointRateDetailList = (data) => {
  return {
    type: GET_POINT_RATE_DETAIL_LIST,
    payload: data,
  }
}

export const getPointRateDetail = (data) => {
  return {
    type: GET_POINT_RATE_DETAIL,
    payload: data,
  }
}

export const expirePointRateDetail = (data) => {
  return {
    type: EXPIRE_POINT_RATE_DETAIL,
    payload: data,
  }
}

export const createPointRateDetail = (data) => {
  return {
    type: CREATE_POINT_RATE_DETAIL,
    payload: data,
  }
}

export const updatePointRateDetail = (data) => {
  return {
    type: UPDATE_POINT_RATE_DETAIL,
    payload: data,
  }
}

export const deletePointRateDetail = (data) => {
  return {
    type: DELETE_POINT_RATE_DETAIL,
    payload: data,
  }
}

export const setPointRateDetail = (data) => {
  return {
    type: SET_POINT_RATE_DETAIL,
    payload: data,
  }
}

export const alertPointRateDetail = (alert) => {
  return {
    type: ALERT_POINT_RATE_DETAIL,
    payload: alert,
  }
}

export const removePointRateDetailAlert = () => {
  return {
    type: REMOVE_POINT_RATE_ALERT_DETAIL,
  }
}
