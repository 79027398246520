import setNotification from "components/shared-components/Notification"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import {
  setIsCustomerAnalysisReportLoading,
  resetCustomerAnalysisReport,
  setCustomerAnalysisReport,
} from "redux/actions/CustomerAnalysisReport"
import { GET_CUSTOMER_ANALYSIS_REPORT_DATA } from "redux/constants/CustomerAnalysisReport"
import CustomerAnalysisReportService from "services/CustomerAnalysisReportService"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* getCustomerAnalysisReportAPI() {
  yield takeEvery(GET_CUSTOMER_ANALYSIS_REPORT_DATA, function* ({ payload }) {
    try {
      yield put(setIsCustomerAnalysisReportLoading(true))
      const { data } = yield call(
        CustomerAnalysisReportService.getCustomerAnalysisReport,
        payload
      )

      yield put(setCustomerAnalysisReport(data))

      if (!data.rows?.length) {
        setError("Empty Data", "List not found.")
      }
    } catch (err) {
      yield put(setIsCustomerAnalysisReportLoading(false))
      yield put(resetCustomerAnalysisReport())

      setError(
        "Generating Customer Analysis Report Failed",
        err?.response?.data?.message ?? "Something went wrong."
      )
    }
  })
}

export default function* rootSaga() {
  yield all([fork(getCustomerAnalysisReportAPI)])
}
