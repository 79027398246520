import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  CREATE_FEEDBACK_QUESTION,
  DELETE_FEEDBACK_QUESTION,
  GET_LIST_FEEDBACK_QUESTION,
  GET_FEEDBACK_QUESTION_DETAIL,
  UPDATE_FEEDBACK_QUESTION,
} from "redux/constants/FeedbackQuestion"
import FeedbackQuestionService from "services/FeedbackQuestionService"
import setNotification from "components/shared-components/Notification"
import {
  setFeedbackQuestionLoading,
  setListFeedbackQuestion,
  setFeedbackQuestionDetail,
  setCreateFeedbackQuestion,
  setUpdateFeedbackQuestion,
  setDeleteFeedbackQuestion,
} from "redux/actions/FeedbackQuestion"
import FeedbackOptionService from "services/FeedbackOptionService"

export function* createFeedbackQuestion() {
  yield takeEvery(CREATE_FEEDBACK_QUESTION, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedbackQuestion = yield call(
        FeedbackQuestionService.createFeedbackQuestion,
        payload
      )
      if (feedbackQuestion.alert) {
        setNotification({
          type: "error",
          message: "Create Question Failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Add Feedback Question in Feedback Question. QuestionId - ${feedbackQuestion.data?.QuestionId}`
          )
        )

        yield put(setCreateFeedbackQuestion(feedbackQuestion.data))
        setNotification({
          type: "success",
          message: "Create Question Success",
        })

        /* Creating Feedback Options */
        payload.optionsData.map((option) => (option.access_token = auth_token))
        yield all(
          payload.optionsData.map((option) =>
            call(FeedbackOptionService.createFeedbackOption, {
              ...option,
              Status: "Active",
              QuestionId: feedbackQuestion.data.QuestionId,
            })
          )
        )

        // Redirect to Feedback Question listing after finished
        payload.callback()
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Create Question Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Create Question Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* getAllFeedbackQuestion() {
  yield takeEvery(GET_LIST_FEEDBACK_QUESTION, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    yield put(setFeedbackQuestionLoading(true))
    try {
      const feedbackQuestion = yield call(
        FeedbackQuestionService.getListFeedbackQuestion,
        payload
      )
      if (feedbackQuestion.alert) {
        setNotification({
          type: "error",
          message: "Get Question Failed",
          description: "An error occured",
        })
      } else {
        yield put(setListFeedbackQuestion(feedbackQuestion.data))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Get Question Failed",
        description: error.response?.data?.message ?? "An error occured",
      })
    }
  })
}

export function* getOneFeedbackQuestion() {
  yield takeEvery(GET_FEEDBACK_QUESTION_DETAIL, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedbackQuestion = yield call(
        FeedbackQuestionService.getOneFeedbackQuestion,
        payload
      )

      if (feedbackQuestion.alert) {
        setNotification({
          type: "error",
          message: "Get Question Failed",
          description: "An error occured",
        })
      } else {
        yield put(setFeedbackQuestionDetail(feedbackQuestion.data))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Get Question Failed",
        description: error.response?.data?.message ?? "An error occured",
      })
    }
  })
}

export function* updateFeedbackQuestion() {
  yield takeEvery(UPDATE_FEEDBACK_QUESTION, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedbackQuestion = yield call(
        FeedbackQuestionService.updateFeedbackQuestion,
        payload
      )
      if (feedbackQuestion.alert) {
        setNotification({
          type: "error",
          message: "Update Question failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Update Feedback Question in Feedback Question. QuestionId - ${feedbackQuestion.data?.QuestionId}`
          )
        )

        yield put(setUpdateFeedbackQuestion(feedbackQuestion.data))
        setNotification({
          type: "success",
          message: "Question successfully updated",
        })

        /* Update and Manage Feedback Option  */
        // Attach authentication token to each option in payload and existing options
        payload.optionsData.map((option) => (option.access_token = auth_token))
        feedbackQuestion.data.FeedbackOptions.map(
          (option) => (option.access_token = auth_token)
        )

        // Step 1: Update or Create Feedback Options
        yield all(
          payload.optionsData.map((option) => {
            if (option.OptionId) {
              // If OptionId exists, update the Feedback Option
              return call(FeedbackOptionService.updateFeedbackOption, option)
            } else {
              // If no OptionId, create a new Feedback Option
              return call(FeedbackOptionService.createFeedbackOption, {
                ...option,
                Status: "Active",
                QuestionId: feedbackQuestion.data.QuestionId,
              })
            }
          })
        )

        // Step 2: Identify Feedback Options to Delete
        const optionsToDelete = feedbackQuestion.data.FeedbackOptions.filter(
          (existingOption) =>
            !payload.optionsData.some(
              (option) => option.OptionId === existingOption.OptionId
            )
        )

        // Step 3: Delete Feedback Options that are no longer needed
        for (const optionToDelete of optionsToDelete) {
          yield call(FeedbackOptionService.deleteFeedbackOption, optionToDelete)
        }

        // Step 4: Redirect to Feedback Question listing after finished
        payload.callback()
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Update Question Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Update Question Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* deleteFeedbackQuestion() {
  yield takeEvery(DELETE_FEEDBACK_QUESTION, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedbackQuestion = yield call(
        FeedbackQuestionService.deleteFeedbackQuestion,
        payload
      )
      if (feedbackQuestion.alert) {
        setNotification({
          type: "error",
          message: "Delete Question failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Delete Feedback Question in Feedback Question. QuestionId - ${payload.QuestionId}`
          )
        )

        yield put(setDeleteFeedbackQuestion(payload))
        setNotification({
          type: "success",
          message: "Question successfully deleted",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Delete Question Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Delete Question Failed",
          description: error.message,
        })
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(createFeedbackQuestion),
    fork(getAllFeedbackQuestion),
    fork(getOneFeedbackQuestion),
    fork(updateFeedbackQuestion),
    fork(deleteFeedbackQuestion),
  ])
}
