import setNotification from "components/shared-components/Notification"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import {
  CREATE_CAMPAIGN_OBJECT,
  DELETE_CAMPAIGN_OBJECT,
  GET_LIST_CAMPAIGN_OBJECT,
} from "redux/constants/CampaignObject"
import { resultCampaignObject } from "redux/actions/CampaignObject"
import CampaignObjectService from "services/CampaignObjectService"

export function* createCampaignObjectAPI() {
  yield takeEvery(CREATE_CAMPAIGN_OBJECT, function* ({ payload }) {
    try {
      const campaignObject = yield call(
        CampaignObjectService.createCampaignObject,
        payload
      )
      if (campaignObject.alert) {
        payload.callback(true)
      }
    } catch (error) {
      payload.callback(true)
      setNotification({
        type: "error",
        message: "Create campaign object failed",
        description: error.message,
      })
    }
  })
}

export function* getListCampaignObjectAPI() {
  yield takeEvery(GET_LIST_CAMPAIGN_OBJECT, function* ({ payload }) {
    try {
      const group = yield call(
        CampaignObjectService.getListCampaignObject,
        payload
      )
      if (group.alert) {
        setNotification({
          type: "error",
          message: "Get list campaign object failed to load",
        })
      } else {
        group.type = "LIST"
        yield put(resultCampaignObject(group))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Get list campaign object failed to load",
        description: error.message,
      })
    }
  })
}

export function* deleteCampaignObjectAPI() {
  yield takeEvery(DELETE_CAMPAIGN_OBJECT, function* ({ payload }) {
    try {
      const result = yield call(
        CampaignObjectService.deleteCampaignObject,
        payload
      )
      if (result.alert) {
        setNotification({
          type: "error",
          message: "Delete campaign has failed",
        })
      } else {
        result.type = "DELETE"
        result.ObjectCode = payload.ObjectCode
        yield put(resultCampaignObject(result))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Delete campaign has failed",
        description: error.message,
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(createCampaignObjectAPI),
    fork(getListCampaignObjectAPI),
    fork(deleteCampaignObjectAPI),
  ])
}
