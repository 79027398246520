import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  CREATE_CARD_CATEGORY,
  GET_LIST_CARD_CATEGORY,
  GET_CARD_CATEGORY,
  UPDATE_CARD_CATEGORY,
  DELETE_CARD_CATEGORY,
  CREATE_CARD_LEVEL,
  UPDATE_CARD_LEVEL,
  DELETE_CARD_LEVEL,
} from "../constants/MemberCardCategory"
import {
  resultCardCategory,
  setCardCategory,
  alertCardCategory,
} from "../actions/MemberCardCategory"
import CardCategoryService from "services/MemberCardCategoryService"
import { isArray } from "lodash"
import { createLoginActivity } from "redux/actions/Auth"

//generator
export function* createCardCategoryAPI() {
  yield takeEvery(CREATE_CARD_CATEGORY, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const CreateGroup = yield call(
        CardCategoryService.createCardCategory,
        payload
      )
      const group = yield call(CardCategoryService.getCardCategory, {
        CardCategoryId: CreateGroup.data.CardCategoryId,
        access_token: auth_token,
      })
      if (group.alert) {
        yield put(alertCardCategory({ success: false, message: group.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Add Member Card Category in Member Card Category. CardCategoryId - ${group.data.CardCategoryId}`
          )
        )

        group.type = "CREATE"
        yield put(resultCardCategory(group))
        yield put(
          alertCardCategory({
            success: true,
            message: "A new Member Card Category has been added",
          })
        )
      }
    } catch (err) {
      yield put(
        alertCardCategory({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data?.message,
        })
      )
    }
  })
}

export function* listCardCategoryAPI() {
  yield takeEvery(GET_LIST_CARD_CATEGORY, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(CardCategoryService.getListCardCategory, payload)
      if (group.alert) {
      } else {
        group.type = "LIST"
        yield put(resultCardCategory(group))
      }
    } catch (err) {}
  })
}

export function* getCardCategoryAPI() {
  yield takeEvery(GET_CARD_CATEGORY, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(CardCategoryService.getCardCategory, payload)
      if (group.alert) {
        yield put(alertCardCategory(group.alert))
      } else {
        yield put(setCardCategory(group.data))
      }
    } catch (err) {
      yield put(alertCardCategory(err))
    }
  })
}

export function* updateCardCategoryAPI() {
  yield takeEvery(UPDATE_CARD_CATEGORY, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const CreateGroup = yield call(
        CardCategoryService.updateCardCategory,
        payload
      )
      const group = yield call(CardCategoryService.getCardCategory, {
        CardCategoryId: CreateGroup.data.CardCategoryId,
        access_token: auth_token,
      })
      if (group.alert) {
        yield put(alertCardCategory({ success: false, message: group.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Update Member Card Category in Member Card Category. CardCategoryId - ${group.data.CardCategoryId}`
          )
        )

        yield put(setCardCategory(group.data))
        yield put(
          alertCardCategory({
            success: true,
            message: "Member card category successfully updated",
          })
        )
      }
    } catch (err) {
      yield put(
        alertCardCategory({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data?.message,
        })
      )
    }
  })
}

export function* deleteCardCategoryAPI() {
  yield takeEvery(DELETE_CARD_CATEGORY, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(CardCategoryService.deleteCardCategory, payload)
      if (group.alert) {
        yield put(alertCardCategory({ success: false, message: group.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Delete Member Card Category in Member Card Category. CardCategoryId - ${payload.CardCategoryId}`
          )
        )

        group.type = "DELETE"
        group.data = payload
        yield put(resultCardCategory(group))
        yield put(
          alertCardCategory({
            success: true,
            message:
              "Member card category has been deleted/deactivated successfully",
          })
        )
      }
    } catch (err) {
      yield put(
        alertCardCategory({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data?.message,
        })
      )
    }
  })
}

export function* createCardLevelAPI() {
  yield takeEvery(CREATE_CARD_LEVEL, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const CreateCardLevel = yield call(
        CardCategoryService.createCardLevel,
        payload
      )
      const group = yield call(CardCategoryService.getCardCategory, {
        CardCategoryId: CreateCardLevel.data.CardCategoryId,
        access_token: auth_token,
      })
      if (group.alert) {
        yield put(alertCardCategory({ success: false, message: group.alert }))
      } else {
        yield put(setCardCategory(group.data))
        yield put(
          alertCardCategory({
            success: true,
            message: "A new Member Card Level has been added",
          })
        )
      }
    } catch (err) {
      yield put(
        alertCardCategory({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data?.message,
        })
      )
    }
  })
}

export function* updateCardLevelAPI() {
  yield takeEvery(UPDATE_CARD_LEVEL, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      yield call(CardCategoryService.updateCardLevel, payload)
      const group = yield call(CardCategoryService.getCardCategory, {
        CardCategoryId: payload.CardCategoryId,
        access_token: auth_token,
      })
      if (group.alert) {
        yield put(alertCardCategory({ success: false, message: group.alert }))
      } else {
        yield put(setCardCategory(group.data))
        yield put(
          alertCardCategory({
            success: true,
            message: "Member card level successfully updated",
          })
        )
      }
    } catch (err) {
      yield put(
        alertCardCategory({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data?.message,
        })
      )
    }
  })
}

export function* deleteCardLevelAPI() {
  yield takeEvery(DELETE_CARD_LEVEL, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      yield call(CardCategoryService.deleteCardCardLevel, payload)
      const group = yield call(CardCategoryService.getCardCategory, {
        CardCategoryId: payload.CardCategoryId,
        access_token: auth_token,
      })
      if (group.alert) {
        yield put(alertCardCategory({ success: false, message: group.alert }))
      } else {
        yield put(setCardCategory(group.data))
        yield put(
          alertCardCategory({
            success: true,
            message: "Member card level has been deleted successfully",
          })
        )
      }
    } catch (err) {
      yield put(
        alertCardCategory({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data?.message,
        })
      )
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listCardCategoryAPI),
    fork(createCardCategoryAPI),
    fork(getCardCategoryAPI),
    fork(updateCardCategoryAPI),
    fork(deleteCardCategoryAPI),
    fork(createCardLevelAPI),
    fork(updateCardLevelAPI),
    fork(deleteCardLevelAPI),
  ])
}
