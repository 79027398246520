import { all } from "redux-saga/effects"
import Auth from "./Auth"
import MemberGroup from "./MemberGroup"
import MemberCardCategory from "./MemberCardCategory"
import MemberInactiveMember from "./MemberArchiveInactiveMember"
import MemberReferrerListing from "./MemberReferrerListing"
import Customer from "./Customer"
import TaskGroup from "./TaskGroup"
import PointStructure from "./PointStructure"
import Common from "./Common"
import SSOData from "./SSOData"
import Memberships from "./Memberships"
import PointRate from "./PointRate"
import PointSummary from "./PointSummary"
import ProfileUpdatedHistory from "./ProfileUpdatedHistory"
import PointAdjustment from "./PointAdjustment"
import PointExpiry from "./PointExpiry"
import MembershipLevel from "./MembershipLevel"
import CustomerAddress from "./CustomerAddress"
import TransactionHistory from "./TransactionHistory"
import CustomerTask from "./CustomerTask"
import CustomerType from "./CustomerType"
import TaskProgress from "./TaskProgress"
import SelfService from "./SelfService"
import Reward from "./Reward"
import Setting from "./Setting"
import RewardObject from "./RewardObject"
import ContactTemplate from "./ContactTemplate"
import Campaign from "./Campaign"
import CampaignObject from "./CampaignObject"
import CampaignTarget from "./CampaignTarget"
import CustomerReward from "./CustomerReward"
import Reporting from "./Reporting"
import RewardInventory from "./RewardInventory"
import ClaimReward from "./ClaimReward"
import RedeemReward from "./RedeemReward"
import CustomerActivityType from "./CustomerActivityType"
import ProductGroup from "./ProductGroup"
import CollectReward from "./CollectReward"
import CustomField from "./CustomField"
import CustomerTag from "./CustomerTag"
import CustomerActivity from "./CustomerActivity"
import CustomerAnalysisReport from "./CustomerAnalysisReport"
import Voucher from "./Voucher"
import CancelRedeemReward from "./CancelRedeemReward"
import CancelCollectReward from "./CancelCollectReward"
import customerEmailVerification from "./CustomerEmailVerification"
import CustomerTransaction from './CustomerTransaction'
import Feedback from "./Feedback"
import FeedbackQuestion from "./FeedbackQuestion"
import FeedbackOption from "./FeedbackOption"
import FeedbackResponse from "./FeedbackResponse"
import PointExpiryReward from "./PointExpiryReward"
import ReferrerListing from "./ReferrerListing"

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    MemberGroup(),
    MemberCardCategory(),
    MemberReferrerListing(),
    Customer(),
    TaskGroup(),
    PointStructure(),
    Common(),
    SSOData(),
    Memberships(),
    PointRate(),
    ProfileUpdatedHistory(),
    PointAdjustment(),
    PointExpiry(),
    MembershipLevel(),
    CustomerAddress(),
    TransactionHistory(),
    CustomerTask(),
    CustomerType(),
    TaskProgress(),
    SelfService(),
    MemberInactiveMember(),
    PointSummary(),
    Reward(),
    Setting(),
    RewardObject(),
    ContactTemplate(),
    Campaign(),
    CampaignObject(),
    CampaignTarget(),
    CustomerReward(),
    Reporting(),
    RewardInventory(),
    ClaimReward(),
    RedeemReward(),
    CustomerActivityType(),
    CollectReward(),
    CustomField(),
    CustomerTag(),
    CustomerActivity(),
    CustomerAnalysisReport(),
    Voucher(),
    ProductGroup(),
    CancelRedeemReward(),
    CancelCollectReward(),
    customerEmailVerification(),
    CustomerTransaction(),
    Feedback(),
    FeedbackQuestion(),
    FeedbackOption(),
    FeedbackResponse(),
    PointExpiryReward(),
    ReferrerListing(),
  ])
}
