export const GET_LIST_PRODUCT_GROUP = "GET_LIST_PRODUCT_GROUP"
export const GET_LIST_PRODUCT_TYPE = "GET_LIST_PRODUCT_TYPE"
export const GET_LIST_PRODUCT_GROUP_CODE = "GET_LIST_PRODUCT_GROUP_CODE"
export const GET_RESULT_PRODUCT_GROUP = "GET_RESULT_PRODUCT_GROUP"
export const GET_PRODUCT_GROUP = "GET_PRODUCT_GROUP"
export const SET_PRODUCT_GROUP = "SET_PRODUCT_GROUP"
export const SET_PRODUCT_TYPE = "SET_PRODUCT_TYPE"
export const CREATE_PRODUCT_GROUP = "CREATE_PRODUCT_GROUP"
export const UPDATE_PRODUCT_GROUP = "UPDATE_PRODUCT_GROUP"
export const DELETE_PRODUCT_GROUP = "DELETE_PRODUCT_GROUP"
export const SET_ALERT_PRODUCT_GROUP = "SET_ALERT_PRODUCT_GROUP"
