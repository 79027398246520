import setNotification from "components/shared-components/Notification"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import {
  setIsReportingLoading,
  setMemberList,
  setCustomerAnalysisReport,
  setCustomerRewardStatusReport,
  setCustomerPerformanceReport,
  setMemberListPremium,
  setProductSalesAnalysisReport,
  setMemberSalesAnalysisReport,
  setMemberProfileAmendmentReport,
  setCustomerTransactionReport,
  setNonMemberTransactionReport,
  setRepeatCustomerAnalysisReport,
  setPremiumGiftRedemptionListing,
  setInactiveReactiveMemberListing,
  setMemberCategoryAnalysisReport,
  setAuditTrailReport,
} from "redux/actions/Reporting"
import {
  GET_ALL_MEMBER_LIST,
  GET_MEMBER_LIST,
  GET_CUSTOMER_ANALYSIS_REPORT,
  GET_CUSTOMER_REWARD_STATUS_REPORT,
  GET_CUSTOMER_PERFORMANCE_REPORT,
  GET_MEMBER_LIST_PREMIUM,
  GET_PRODUCT_SALES_ANALYSIS_REPORT,
  GET_MEMBER_SALES_ANALYSIS_REPORT,
  GET_MEMBER_PROFILE_AMENDMENT_REPORT,
  GET_CUSTOMER_TRANSACTION_REPORT,
  GET_NON_MEMBER_TRANSACTION_REPORT,
  GET_REPEAT_CUSTOMER_ANALYSIS_REPORT,
  GET_PREMIUM_GIFT_REDEMPTION_LISTING,
  GET_INACTIVE_REACTIVE_MEMBER_LISTING,
  GET_MEMBER_CATEGORY_ANALYSIS_REPORT,
  GET_AUDIT_TRAIL_REPORT,
} from "redux/constants/Reporting"
import ReportService from "services/ReportingService"

// fetch all member list helper function
function* fetchMemberList(payload, memberType, actionCreator) {
  const memberGroup = yield call(ReportService.getMemberList, {
    ...payload,
    MemberType: memberType,
  })

  if (memberGroup?.data?.rows?.length) {
    memberGroup.type = "LIST"
    memberGroup.isGeneratingReport = false
    yield put(actionCreator(memberGroup))
    return true
  }

  return false
}

export function* listAllMemberListReportAPI() {
  yield takeEvery(GET_ALL_MEMBER_LIST, function* ({ payload }) {
    try {
      yield put(setIsReportingLoading(true))

      payload.FullName = payload.FullName?.toUpperCase()
      payload.IdNo = payload.IdNo?.toUpperCase()

      const nonPremiumDataFound = yield call(
        fetchMemberList,
        payload,
        "Non-Premium Member",
        setMemberList
      )
      const premiumDataFound = yield call(
        fetchMemberList,
        payload,
        "Premium Member",
        setMemberListPremium
      )

      yield put(setIsReportingLoading(false))

      if (!nonPremiumDataFound && !premiumDataFound) {
        setNotification({
          type: "warning",
          message: "No Customer Found",
        })
      }
    } catch (err) {
      yield put(setIsReportingLoading(false))

      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* listMemberListReportAPI() {
  yield takeEvery(
    GET_MEMBER_LIST,
    function* ({ payload, isGeneratingReport, isGeneratingExcelReport }) {
      try {
        yield put(setIsReportingLoading(true))

        const group = yield call(ReportService.getMemberList, {
          ...payload,
          isGeneratingReport,
        })
        if (group.alert) {
        } else {
          if (group?.data?.rows?.length < 1) {
            setNotification({
              type: "warning",
              message: "Non-Premium Customer Not Found",
            })
            yield put(setIsReportingLoading(false))
          } else {
            group.type = "LIST"
            group.isGeneratingReport = isGeneratingReport
            group.isGeneratingExcelReport = isGeneratingExcelReport
            yield put(setMemberList(group))
          }
        }
      } catch (err) {
        yield put(setIsReportingLoading(false))

        if (err?.response?.data) {
          const { error, message } = err.response.data
          setNotification({
            type: "error",
            message: error,
            description: message,
          })
        } else {
          setNotification({
            type: "error",
            message: err.message,
          })
        }
      }
    }
  )
}

export function* listMemberListPremiumReportAPI() {
  yield takeEvery(
    GET_MEMBER_LIST_PREMIUM,
    function* ({
      payload,
      isGeneratingReport,
      isFeedbackFetch,
      isGeneratingExcelReport,
    }) {
      try {
        yield put(setIsReportingLoading(true))

        const group = yield call(ReportService.getMemberList, {
          ...payload,
          isGeneratingReport,
        })
        if (group.alert) {
        } else {
          if (group?.data?.rows?.length < 1) {
            if (!isFeedbackFetch) {
              setNotification({
                type: "warning",
                message: "Premium Customer Not Found",
              })
            }
            yield put(setIsReportingLoading(false))
          } else {
            group.type = "LIST"
            group.isGeneratingReport = isGeneratingReport
            group.isGeneratingExcelReport = isGeneratingExcelReport
            yield put(setMemberListPremium(group))
          }
        }
      } catch (err) {
        yield put(setIsReportingLoading(false))

        if (err?.response?.data) {
          const { error, message } = err.response.data
          setNotification({
            type: "error",
            message: error,
            description: message,
          })
        } else {
          setNotification({
            type: "error",
            message: err.message,
          })
        }
      }
    }
  )
}

export function* listCustomerPerformanceReportAPI() {
  yield takeEvery(
    GET_CUSTOMER_PERFORMANCE_REPORT,
    function* ({ payload, isGeneratingReport }) {
      try {
        yield put(setIsReportingLoading(true))

        const group = yield call(ReportService.getCustomerPerformanceReport, {
          ...payload,
          isGeneratingReport,
        })

        if (group.alert) {
        } else {
          if (group?.data?.rows?.length < 1) {
            setNotification({
              type: "error",
              message: "Data Not Found",
            })
            yield put(setIsReportingLoading(false))
          } else {
            group.type = "LIST"
            yield put(setCustomerPerformanceReport(group))
          }
        }
      } catch (err) {
        yield put(setIsReportingLoading(false))

        if (err?.response?.data) {
          const { error, message } = err.response.data
          setNotification({
            type: "error",
            message: error,
            description: message,
          })
        } else {
          setNotification({
            type: "error",
            message: err.message,
          })
        }
      }
    }
  )
}

export function* listCustomerRewardStatusReportAPI() {
  yield takeEvery(GET_CUSTOMER_REWARD_STATUS_REPORT, function* ({ payload }) {
    try {
      yield put(setIsReportingLoading(true))

      const group = yield call(
        ReportService.getCustomerRewardStatusReport,
        payload
      )

      if (group.alert) {
      } else {
        if (group?.data?.rows?.length < 1) {
          setNotification({
            type: "error",
            message: "Data Not Found",
          })
          yield put(setIsReportingLoading(false))
        } else {
          group.type = "LIST"
          yield put(setCustomerRewardStatusReport(group))
        }
      }
    } catch (err) {
      yield put(setIsReportingLoading(false))

      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* getCustomerAnalysisReportAPI() {
  yield takeEvery(GET_CUSTOMER_ANALYSIS_REPORT, function* ({ payload }) {
    try {
      yield put(setIsReportingLoading(true))

      const result = yield call(
        ReportService.getCustomerAnalysisReport,
        payload
      )
      if (result.alert) {
        setNotification({
          type: "error",
          message: result.alert,
        })
        yield put(setIsReportingLoading(false))
      } else {
        yield put(setCustomerAnalysisReport(result))
      }
    } catch (err) {
      yield put(setIsReportingLoading(false))

      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* getProductSalesAnalysisReportAPI() {
  yield takeEvery(GET_PRODUCT_SALES_ANALYSIS_REPORT, function* ({ payload }) {
    try {
      yield put(setIsReportingLoading(true))

      const result = yield call(
        ReportService.getProductSalesAnalysisReport,
        payload
      )
      if (result.alert) {
        setNotification({
          type: "error",
          message: result.alert,
        })
        yield put(setIsReportingLoading(false))
      } else {
        yield put(setProductSalesAnalysisReport(result))
      }
    } catch (err) {
      yield put(setIsReportingLoading(false))

      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* getMemberSalesAnalysisReportAPI() {
  yield takeEvery(GET_MEMBER_SALES_ANALYSIS_REPORT, function* ({ payload }) {
    try {
      yield put(setIsReportingLoading(true))

      const result = yield call(
        ReportService.getMemberSalesAnalysisReport,
        payload
      )
      if (result.alert) {
        setNotification({
          type: "error",
          message: result.alert,
        })
        yield put(setIsReportingLoading(false))
      } else {
        yield put(setMemberSalesAnalysisReport(result))
      }
    } catch (err) {
      yield put(setIsReportingLoading(false))

      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* getMemberProfileAmendmentReportAPI() {
  yield takeEvery(GET_MEMBER_PROFILE_AMENDMENT_REPORT, function* ({ payload }) {
    try {
      yield put(setIsReportingLoading(true))

      const result = yield call(
        ReportService.getMemberProfileAmendmentReport,
        payload
      )

      if (result?.data?.rows?.length < 1) {
        setNotification({
          type: "error",
          message: "Data Not Found",
        })
      }

      if (result.alert) {
        setNotification({
          type: "error",
          message: result.alert,
        })
      } else {
        yield put(setMemberProfileAmendmentReport(result))
      }
    } catch (err) {
      yield put(setIsReportingLoading(false))

      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* getCustomerTransactionReportAPI() {
  yield takeEvery(GET_CUSTOMER_TRANSACTION_REPORT, function* ({ payload }) {
    try {
      yield put(setIsReportingLoading(true))

      const result = yield call(
        ReportService.getCustomerTransactionReport,
        payload
      )

      if (result?.data?.rows?.length < 1) {
        setNotification({
          type: "error",
          message: "Data Not Found",
        })
      }

      if (result.alert) {
        setNotification({
          type: "error",
          message: result.alert,
        })
      } else {
        yield put(setCustomerTransactionReport(result))
      }
    } catch (err) {
      yield put(setIsReportingLoading(false))

      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* getNonMemberTransactionReportAPI() {
  yield takeEvery(GET_NON_MEMBER_TRANSACTION_REPORT, function* ({ payload }) {
    try {
      yield put(setIsReportingLoading(true))

      const result = yield call(
        ReportService.getNonMemberTransactionReport,
        payload
      )

      if (result?.data?.rows?.length < 1) {
        setNotification({
          type: "error",
          message: "Data Not Found",
        })
      }

      if (result.alert) {
        setNotification({
          type: "error",
          message: result.alert,
        })
      } else {
        yield put(setNonMemberTransactionReport(result))
      }
    } catch (err) {
      yield put(setIsReportingLoading(false))

      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* getRepeatCustomerAnalysisReportAPI() {
  yield takeEvery(GET_REPEAT_CUSTOMER_ANALYSIS_REPORT, function* ({ payload }) {
    try {
      yield put(setIsReportingLoading(true))

      const result = yield call(
        ReportService.getRepeatCustomerAnalysisReport,
        payload
      )

      if (result?.data?.rows?.length < 1) {
        setNotification({
          type: "error",
          message: "Data Not Found",
        })
      }

      if (result.alert) {
        setNotification({
          type: "error",
          message: result.alert,
        })
      } else {
        yield put(setRepeatCustomerAnalysisReport(result))
      }
    } catch (err) {
      yield put(setIsReportingLoading(false))

      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* getPremiumGiftRedemptionListingAPI() {
  yield takeEvery(GET_PREMIUM_GIFT_REDEMPTION_LISTING, function* ({ payload }) {
    try {
      yield put(setIsReportingLoading(true))

      const result = yield call(
        ReportService.getPremiumGiftRedemptionListing,
        payload
      )

      if (result?.data?.rows?.length < 1) {
        setNotification({
          type: "error",
          message: "Data Not Found",
        })
      }

      if (result.alert) {
        setNotification({
          type: "error",
          message: result.alert,
        })
      } else {
        yield put(setPremiumGiftRedemptionListing(result))
      }
    } catch (err) {
      yield put(setIsReportingLoading(false))

      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* getInactiveReactiveMemberListingAPI() {
  yield takeEvery(
    GET_INACTIVE_REACTIVE_MEMBER_LISTING,
    function* ({ payload, isGeneratingExcelReport }) {
      try {
        yield put(setIsReportingLoading(true))

        let result

        if (payload.ReportType === "Inactive Member") {
          result = yield call(
            ReportService.getInactiveMemberReportListing,
            payload
          )
        } else if (payload.ReportType === "Reactive Member") {
          result = yield call(
            ReportService.getReactiveMemberReportListing,
            payload
          )
        }

        if (result?.data?.rows?.length < 1) {
          setNotification({
            type: "error",
            message: "Data Not Found",
          })
        }

        if (result.alert) {
          setNotification({
            type: "error",
            message: result.alert,
          })
        } else {
          result.isGeneratingExcelReport = isGeneratingExcelReport
          yield put(setInactiveReactiveMemberListing(result))
        }
      } catch (err) {
        yield put(setIsReportingLoading(false))

        if (err?.response?.data) {
          const { error, message } = err.response.data
          setNotification({
            type: "error",
            message: error,
            description: message,
          })
        } else {
          setNotification({
            type: "error",
            message: err.message,
          })
        }
      }
    }
  )
}

export function* getMemberCategoryAnalysisReportAPI() {
  yield takeEvery(GET_MEMBER_CATEGORY_ANALYSIS_REPORT, function* ({ payload }) {
    try {
      yield put(setIsReportingLoading(true))

      const result = yield call(
        ReportService.getMemberCategoryAnalysisReport,
        payload
      )

      if (result?.data?.rows?.length < 1) {
        setNotification({
          type: "error",
          message: "Data Not Found",
        })
      }

      if (result.alert) {
        setNotification({
          type: "error",
          message: result.alert,
        })
      } else {
        yield put(setMemberCategoryAnalysisReport(result))
      }
    } catch (err) {
      yield put(setIsReportingLoading(false))

      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export function* getAuditTrailReportAPI() {
  yield takeEvery(GET_AUDIT_TRAIL_REPORT, function* ({ payload }) {
    try {
      yield put(setIsReportingLoading(true))

      const result = yield call(ReportService.getAuditTrailReport, payload)

      if (result?.data?.rows?.length < 1) {
        setNotification({
          type: "error",
          message: "Data Not Found",
        })
      }

      if (result.alert) {
        setNotification({
          type: "error",
          message: result.alert,
        })
      } else {
        yield put(setAuditTrailReport(result))
      }
    } catch (err) {
      yield put(setIsReportingLoading(false))

      if (err?.response?.data) {
        const { error, message } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listMemberListReportAPI),
    fork(listMemberListPremiumReportAPI),
    fork(listAllMemberListReportAPI),
    fork(getCustomerAnalysisReportAPI),
    fork(listCustomerRewardStatusReportAPI),
    fork(listCustomerPerformanceReportAPI),
    fork(getProductSalesAnalysisReportAPI),
    fork(getMemberSalesAnalysisReportAPI),
    fork(getMemberProfileAmendmentReportAPI),
    fork(getCustomerTransactionReportAPI),
    fork(getNonMemberTransactionReportAPI),
    fork(getRepeatCustomerAnalysisReportAPI),
    fork(getPremiumGiftRedemptionListingAPI),
    fork(getInactiveReactiveMemberListingAPI),
    fork(getMemberCategoryAnalysisReportAPI),
    fork(getAuditTrailReportAPI),
  ])
}
