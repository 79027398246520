import React from "react"
import { Button } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"

function PoinRateAddButton() {
  let history = useHistory()

  const handleOnClick = () => {
    history.push(`/points-management/point-rate/add`, {
      mode: "ADD",
      title: "Add",
    })
  }

  return (
    <>
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        block
        onClick={handleOnClick}
      >
        Add Point Rate
      </Button>
    </>
  )
}

export default PoinRateAddButton
