import {
  SET_CRM_CUSTOM_FIELD_LIST,
  SET_CUSTOM_FIELD_LIST,
} from "redux/constants/CustomField"

const initialState = {
  listCustomField: [],
  listCustomFieldCount: 0,
  customField: null,

  crmCustomFields: [], // update
}

const CustomField = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CUSTOM_FIELD_LIST:
      return {
        ...state,
        listCustomField: payload.rows.sort(
          (a, b) => a.SequenceNo - b.SequenceNo
        ),
        listCustomFieldCount: payload.rows.length === 0 ? 0 : payload.count,
      }

    case SET_CRM_CUSTOM_FIELD_LIST:
      return {
        ...state,
        crmCustomFields: payload.rows,
      }

    default:
      return state
  }
}

export default CustomField
