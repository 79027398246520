import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  GET_PRODUCT_GROUP,
  GET_LIST_PRODUCT_GROUP,
  GET_LIST_PRODUCT_GROUP_CODE,
  CREATE_PRODUCT_GROUP,
  UPDATE_PRODUCT_GROUP,
  DELETE_PRODUCT_GROUP,
  GET_LIST_PRODUCT_TYPE,
} from "../constants/ProductGroup"
import {
  getResultProductGroup,
  setAlertProductGroup,
  setProductType,
} from "../actions/ProductGroup"
import ProductGroupService from "services/ProductGroupService"
import setNotification from "components/shared-components/Notification"
import CommonService from "services/CommonService"
import { createLoginActivity } from "redux/actions/Auth"

export function* listProductGroupAPI() {
  yield takeEvery(GET_LIST_PRODUCT_GROUP, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token

    try {
      const group = yield call(
        ProductGroupService.getListProductGroups,
        payload
      )
      if (group.alert) {
        setNotification({
          type: "error",
          message: "Listing Failed",
          description: "An error occured",
        })
      } else {
        group.type = "LIST"
        yield put(getResultProductGroup(group))
      }
    } catch (err) {
      setNotification({
        type: "error",
        message: "Listing Failed",
        description: "An error occured",
      })
    }
  })
}

export function* listProductType() {
  yield takeEvery(GET_LIST_PRODUCT_TYPE, function* () {
    try {
      const group = yield call(CommonService.getCommonData, "Product Type")
      if (!group.alert) {
        yield put(setProductType(group))
      }
    } catch (err) {
      setNotification({
        type: "error",
        message: "Failed to get product type",
        description: "An error occured",
      })
    }
  })
}

export function* getListProductGroupCode() {
  yield takeEvery(GET_LIST_PRODUCT_GROUP_CODE, function* () {
    const payload = { page: 1, rows: Number.MAX_SAFE_INTEGER }
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(
        ProductGroupService.getListProductGroups,
        payload
      )
      if (group.alert) {
        setNotification({
          type: "error",
          message: "Listing Failed",
          description: "An error occured",
        })
      } else {
        group.type = "CODE LIST"
        yield put(getResultProductGroup(group))
      }
    } catch (err) {
      setNotification({
        type: "error",
        message: "Listing Failed",
        description: "An error occured",
      })
    }
  })
}

export function* getProductGroup() {
  yield takeEvery(GET_PRODUCT_GROUP, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(ProductGroupService.getProductGroup, payload)
      if (group.alert) {
        setNotification({
          type: "error",
          message: "Listing Failed",
          description: "An error occured",
        })
      } else {
        group.type = "DETAIL"
        yield put(getResultProductGroup(group))
      }
    } catch (err) {
      setNotification({
        type: "error",
        message: "Listing Failed",
        description: "An error occured",
      })
    }
  })
}

export function* createProductGroup() {
  yield takeEvery(CREATE_PRODUCT_GROUP, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(ProductGroupService.createProductGroup, payload)
      if (group.alert) {
        setNotification({
          type: "error",
          message: "Create Product Group Failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Add Product Group in Product Group. ProductCode - ${group.data.ProductCode}`
          )
        )

        group.type = "CREATE"
        yield put(getResultProductGroup(group))
        yield put(setAlertProductGroup({ success: true }))
        setNotification({
          type: "success",
          message: "Create Product Group Success",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Create Product Group Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Create Product Group Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* updateProductGroup() {
  yield takeEvery(UPDATE_PRODUCT_GROUP, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(ProductGroupService.updateProductGroup, payload)

      if (group.alert) {
        setNotification({
          type: "error",
          message: "Update Product Group failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Update Product Group in Product Group. ProductCode - ${group.data.ProductCode}`
          )
        )

        const updateData = {
          type: "UPDATE",
          data: { ...payload },
        }
        yield put(getResultProductGroup(updateData))
        setNotification({
          type: "success",
          message: "Product Group successfully updated",
        })
      }
    } catch (err) {
      if (err?.response?.data) {
        const { message } = err.response.data
        setNotification({
          type: "error",
          message: "Update Product Group Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Update Product Group Failed",
          description: err.message,
        })
      }
    }
  })
}

export function* deleteProductGroup() {
  yield takeEvery(DELETE_PRODUCT_GROUP, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(ProductGroupService.deleteProductGroup, payload)
      if (group.alert) {
        setNotification({
          type: "error",
          message: "Delete Product Group Failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Delete Product Group in Product Group. ProductCode - ${payload.ProductCode}`
          )
        )

        const deleteData = {
          type: "DELETE",
          data: { ...payload },
        }
        yield put(getResultProductGroup(deleteData))
        setNotification({
          type: "error",
          message: "Product Group successfully deleted",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Delete Product Group Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Delete Product Group Failed",
          description: error.message,
        })
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listProductGroupAPI),
    fork(getListProductGroupCode),
    fork(getProductGroup),
    fork(createProductGroup),
    fork(updateProductGroup),
    fork(deleteProductGroup),
    fork(listProductType),
  ])
}
