import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const CampaignObjectService = {}

CampaignObjectService.createCampaignObject = function (data) {
  return fetch({
    url: `/campaign/objects/add`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

CampaignObjectService.getListCampaignObject = function (data) {
  const searchquery = {}
  if (data?.CampaignCode) searchquery.CampaignCode = data.CampaignCode

  return fetch({
    url: `/campaign/objects/find-all`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: data?.rows || Number.MAX_SAFE_INTEGER,
      Page: 1,
      Search: JSON.stringify(searchquery ?? {}),
    },
  })
}

CampaignObjectService.deleteCampaignObject = function (data) {
  return fetch({
    url: `/campaign/objects/delete`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { CampaignObjectId: data?.ObjectId },
  })
}

export default CampaignObjectService
