export const GET_LIST_CUSTOMER_ACTIVITY_TYPE = "GET_LIST_CUSTOMER_ACTIVITY_TYPE"
export const GET_LIST_CUSTOMER_ACTIVITY_TYPE_CODE =
  "GET_LIST_CUSTOMER_ACTIVITY_TYPE_CODE"
export const GET_RESULT_CUSTOMER_ACTIVITY_TYPE =
  "GET_RESULT_CUSTOMER_ACTIVITY_TYPE"
export const GET_CUSTOMER_ACTIVITY_TYPE = "GET_CUSTOMER_ACTIVITY_TYPE"
export const SET_CUSTOMER_ACTIVITY_TYPE = "SET_CUSTOMER_ACTIVITY_TYPE"
export const CREATE_CUSTOMER_ACTIVITY_TYPE = "CREATE_CUSTOMER_ACTIVITY_TYPE"
export const UPDATE_CUSTOMER_ACTIVITY_TYPE = "UPDATE_CUSTOMER_ACTIVITY_TYPE"
export const DELETE_CUSTOMER_ACTIVITY_TYPE = "DELETE_CUSTOMER_ACTIVITY_TYPE"
export const SET_ALERT_ACTIVITY_TYPE = "SET_ALERT_ACTIVITY_TYPE"
