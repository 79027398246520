import {
  REMOVE_CUSTOMER_ALERT,
  RESULT_CUSTOMER,
  ALERT_CUSTOMER,
  SET_CURRENT_CUSTOMER,
  CLEAR_CURRENT_CUSTOMER,
  SET_CUSTOMER_MEMBERSHIP_DETAILS,
  SET_CUSTOMER_MEMBERSHIP_MERGE_DETAILS,
  CLEAR_CUSTOMER_MEMBERSHIP_DETAILS,
  SET_CONFIRM_UPGRADE,
  SET_CUSTOMER_LOADING,
} from "redux/constants/Customer"

const initialState = {
  isLoading: false,
  group: {
    count: 0,
    rows: [],
  },
  current: {},
  alert: {},
  detail: {},
  memberIdDetails: {},
  isConfirmUpgrade: false,
  upgradeData: null,
}
const customer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_LOADING:
      return {
        ...state,
        isLoading: true,
      }

    case RESULT_CUSTOMER:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          group: action.payload.data,
        }
      } else if (action.payload.type === "DETAIL") {
        return {
          ...state,
          isLoading: false,
          detail: action.payload.data,
        }
      } else if (action.payload.type === "CREATE") {
        return {
          ...state,
          isLoading: false,
          group: {
            ...state.group,
            count: state.group.count + 1,
            rows: [...state.group.rows, action.payload.data],
          },
        }
      } else if (action.payload.type === "UPDATE") {
        let isPremiumMember = Boolean(state.current?.MembershipNo)

        let updatedCurrent = isPremiumMember
          ? {
              ...state.current,
              Customer: {
                ...state.current.Customer,
                ...action.payload.data,
              },
            }
          : {
              ...state.current,
              ...action.payload.data,
            }

        return {
          ...state,
          current: updatedCurrent,
        }
      } else if (action.payload.type === "DELETE") {
        return {
          ...state,
          group: {
            ...state.group,
            rows: [
              ...state.group.rows.map((groupParam) => {
                if (groupParam.CustomerId === action.payload.data.CustomerId) {
                  return {
                    ...groupParam,
                    Status: "Deleted",
                  }
                } else {
                  return groupParam
                }
              }),
            ],
          },
        }
      }
      break

    case SET_CURRENT_CUSTOMER:
      return {
        ...state,
        current: action.payload,
      }

    case CLEAR_CURRENT_CUSTOMER:
      return {
        ...state,
        current: {},
        detail: {},
      }

    case SET_CUSTOMER_MEMBERSHIP_DETAILS:
      return {
        ...state,
        memberIdDetails: action.payload,
      }

    case SET_CUSTOMER_MEMBERSHIP_MERGE_DETAILS:
      return {
        ...state,
        memberIdDetails: action.payload,
      }

    case CLEAR_CUSTOMER_MEMBERSHIP_DETAILS:
      return {
        ...state,
        memberIdDetails: {},
      }

    case ALERT_CUSTOMER:
      return {
        ...state,
        isLoading: false,
        alert: {
          status: true,
          success: action.payload.success,
          message: action.payload.message,
        },
      }
    case REMOVE_CUSTOMER_ALERT:
      return {
        ...state,
        alert: {
          status: false,
        },
      }

    case SET_CONFIRM_UPGRADE:
      return {
        ...state,
        isConfirmUpgrade: action.payload.isConfirmUpgrade,
        upgradeData: action.payload.upgradeData,
      }
    default:
      return state
  }
}

export default customer
