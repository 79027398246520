import axios from "axios"
import { API_BASE_URL, COMMON_API_BASE_URL } from "configs/AppConfig"
import { AUTH_TOKEN } from "redux/constants/Auth"

const CustomFieldService = {}

const commonFetch = axios.create({
  baseURL: COMMON_API_BASE_URL,
  timeout: 60000,
})

const apiFetch = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
})

CustomFieldService.getCustomFieldList = function (params) {
  const data = {
    Page: params.page ?? 1,
    Rows: params.rows ?? 10000,
    Search: JSON.stringify(params.search ?? {}),
  }

  return commonFetch({
    url: "/custom-fields/find-all",
    method: "post",
    data,
  })
}

CustomFieldService.getListItems = function (id) {
  return commonFetch({
    url: `/lists/${id}/items`,
    method: "get",
  })
}

CustomFieldService.addCommonCustomField = async function (data) {
  const payload = { ...data }

  if (data.FieldListId && data.FieldListId.length === 0)
    delete payload.FieldListId
  if (data.SequenceNo) payload.SequenceNo = Number(data.SequenceNo)

  const headers = {
    Authorization: `Bearer ${payload.access_token}`,
  }

  delete payload.access_token

  return commonFetch({
    url: "/custom-fields/add",
    method: "post",
    data: payload,
    headers,
  })
}

CustomFieldService.updateCommonCustomField = function (data) {
  const payload = { ...data }

  const headers = {
    Authorization: `Bearer ${payload.access_token}`,
  }

  delete payload.access_token

  return commonFetch({
    url: "/custom-fields/update",
    method: "post",
    data,
    headers,
  })
}

CustomFieldService.deleteCommonCustomField = function ({
  CustomFieldCode,
  access_token,
}) {
  return commonFetch({
    url: "/custom-fields/delete",
    method: "post",
    data: { CustomFieldCode },
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
}

CustomFieldService.addCrmCustomField = function (data) {
  const accessToken = data.access_token || sessionStorage.getItem(AUTH_TOKEN)

  return apiFetch({
    url: "/custom-fields/add",
    method: "post",
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

CustomFieldService.updateCrmCustomField = function ({
  CustomFieldId,
  ...data
}) {
  return apiFetch({
    url: `/custom-fields/update`,
    method: "post",
    data: { ...data, CustomFieldId },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

CustomFieldService.getCrmCustomField = function (params) {
  const payload = {
    ...params,
    Page: params.page ?? 1,
    Rows: params.rows ?? 10,
    Search: JSON.stringify(params.search ?? "{}"),
  }

  return apiFetch({
    url: `/custom-fields/find-all`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}
export default CustomFieldService
