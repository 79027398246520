export const SET_IS_MEMBERSHIP_LOADING = "SET_IS_MEMBERSHIP_LOADING"
export const GET_LIST_MEMBERSHIPS = "GET_LIST_MEMBERSHIPS"
export const GET_LIST_MEMBER_POINT_STATEMENT = "GET_LIST_MEMBER_POINT_STATEMENT"
export const GET_LIST_POINT_SUMMARY_REPORT = "GET_LIST_POINT_SUMMARY_REPORT"
export const CREATE_MEMBERSHIPS = "CREATE_MEMBERSHIPS"
export const SEARCH_LIST_MEMBERSHIPS = "SEARCH_LIST_MEMBERSHIPS"
export const GET_RESULT_MEMBERSHIPS = "GET_RESULT_MEMBERSHIPS"
export const GET_MEMBERSHIPS = "GET_MEMBERSHIPS"
export const UPDATE_MEMBERSHIPS = "UPDATE_MEMBERSHIPS"
export const DELETE_MEMBERSHIPS = "DELETE_MEMBERSHIPS"
export const RESULT_MEMBERSHIPS = "RESULT_MEMBERSHIPS"
export const ALERT_MEMBERSHIPS = "ALERT_MEMBERSHIPS"
export const REMOVE_MEMBERSHIPS_ALERT = "REMOVE_MEMBERSHIPS_ALERT"
