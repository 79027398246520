import {
  RESET_CLAIM_REWARD_ALERT,
  RESULT_CLAIM_REWARD,
  SET_CLAIM_REWARD,
  RESET_CLAIM_REWARD,
  SET_ON_CLAIM_REWARD,
} from "redux/constants/ClaimReward"
import { SET_IS_LOADING } from "redux/constants/TransactionHistory"

const initialState = {
  rows: 10, // constant
  page: 1,
  rewards: [],
  count: 0,
  reward: {},
  alert: {},
  isLoading: false,
  isClaimed: false,
  isSucceed: false,
}

const ClaimReward = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESULT_CLAIM_REWARD:
      if (payload.type === SET_CLAIM_REWARD) {
        return {
          ...state,
          reward: payload.data,
          alert: { status: true, success: true },
          isLoading: false,
          isClaimed: payload.data.Status === "Claimed",
        }
      } else if (payload.type === SET_ON_CLAIM_REWARD) {
        return {
          ...state,
          isClaimed: true,
          isSucceed: true,
          alert: { status: true, success: true },
          isLoading: false,
          reward: payload.data,
        }
      }
      break

    case RESET_CLAIM_REWARD_ALERT:
      return {
        ...state,
        alert: {},
        isLoading: false,
      }

    case RESET_CLAIM_REWARD:
      return {
        ...state,
        alert: {},
        reward: {},
        isLoading: false,
        isClaimed: false,
        isSucceed: false,
      }

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }

    default:
      return state
  }
}

export default ClaimReward
