import {
  CREATE_CAMPAIGN_OBJECT,
  DELETE_CAMPAIGN_OBJECT,
  GET_LIST_CAMPAIGN_OBJECT,
  RESULT_CAMPAIGN_OBJECT,
  SET_CAMPAIGN_OBJECT,
} from "redux/constants/CampaignObject"

export const createCampaignObject = (data) => {
  return {
    type: CREATE_CAMPAIGN_OBJECT,
    payload: data,
  }
}

export const getListCampaignObject = (data) => {
  return {
    type: GET_LIST_CAMPAIGN_OBJECT,
    payload: data,
  }
}

export const resultCampaignObject = (data) => {
  return {
    type: RESULT_CAMPAIGN_OBJECT,
    payload: data,
  }
}

export const deleteCampaignObject = (objectId) => {
  return {
    type: DELETE_CAMPAIGN_OBJECT,
    payload: objectId,
  }
}

export const setCampaignObject = (data) => {
  return {
    type: SET_CAMPAIGN_OBJECT,
    payload: data,
  }
}
