const {
  SET_REFERRER,
  GET_RESULT_REFERRER,
  ALERT_REFERRER,
  REMOVE_ALERT_REFERRER,
} = require("redux/constants/MemberReferrerListing")

const initailState = {
  count: 0,
  group: [],
  alert: {},
}

const referrer = (state = initailState, action) => {
  switch (action.type) {
    case SET_REFERRER:
      return {
        ...state,
        group: action.payload,
        count: action.payload.length,
      }

    case GET_RESULT_REFERRER:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          group: action.payload.data.rows,
          count: action.payload.data.count,
        }
      }
      break

    case ALERT_REFERRER:
      return {
        ...state,
        alert: {
          status: true,
          success: action.payload.success,
          message: action.payload.message,
        },
      }

    case REMOVE_ALERT_REFERRER:
      return {
        ...state,
        alert: {
          status: false,
        },
      }

    default:
      return state
  }
}

export default referrer
