import {
  RESET_REWARD_ALERT,
  RESULT_REWARD,
  SET_CREATE_REWARD,
  SET_DELETE_REWARD,
  SET_LIST_REWARD,
  SET_PAGE_REWARD,
  SET_REWARD,
} from "redux/constants/Reward"

const initialState = {
  rows: 10, // constant
  page: 1,
  rewards: [],
  count: 0,
  reward: {},
  alert: {},
}

const Reward = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESULT_REWARD:
      if (payload.type === SET_LIST_REWARD) {
        return {
          ...state,
          rewards: payload.data.rows,
          count: payload.data.count,
          alert: {},
        }
      } else if (payload.type === SET_REWARD) {
        return {
          ...state,
          reward: payload.data,
        }
      } else if (payload.type === SET_DELETE_REWARD) {
        const updatedRewards = state.rewards.filter(
          (reward) => reward.RewardId !== payload.id
        )
        return {
          ...state,
          rewards: updatedRewards,
        }
      } else if (payload.type === SET_CREATE_REWARD) {
        return {
          ...state,
          alert: { status: true, success: true },
        }
      }
      break

    case SET_PAGE_REWARD:
      return { ...state, page: payload }

    case RESET_REWARD_ALERT:
      return {
        ...state,
        alert: {},
      }

    default:
      return state
  }
}

export default Reward
