export const GET_POINT_RATE_LIST = "GET_POINT_RATE_LIST"
export const GET_POINT_RATE = "GET_POINT_RATE"
export const CREATE_POINT_RATE = "CREATE_POINT_RATE"
export const UPDATE_POINT_RATE = "UPDATE_POINT_RATE"
export const DELETE_POINT_RATE = "DELETE_POINT_RATE"
export const SET_POINT_RATE = "SET_POINT_RATE"
export const ALERT_POINT_RATE = "ALERT_POINT_RATE"
export const REMOVE_POINT_RATE_ALERT = "REMOVE_POINT_RATE_ALERT"
export const RESULT_POINT_RATE = "RESULT_POINT_RATE"

export const GET_POINT_RATE_DETAL_BY_RATE_STRUCTURE_LIST =
  "GET_POINT_RATE_DETAL_BY_RATE_STRUCTURE_LIST"
export const GET_POINT_RATE_DETAIL_LIST = "GET_POINT_RATE_DETAIL_LIST"
export const GET_POINT_RATE_DETAIL = "GET_POINT_RATE_DETAIL"
export const EXPIRE_POINT_RATE_DETAIL = "EXPIRE_POINT_RATE_DETAIL"
export const CREATE_POINT_RATE_DETAIL = "CREATE_POINT_RATE_DETAIL"
export const UPDATE_POINT_RATE_DETAIL = "UPDATE_POINT_RATE_DETAIL"
export const DELETE_POINT_RATE_DETAIL = "DELETE_POINT_RATE_DETAIL"
export const SET_POINT_RATE_DETAIL = "SET_POINT_RATE_DETAIL"
export const ALERT_POINT_RATE_DETAIL = "ALERT_POINT_RATE_DETAIL"
export const REMOVE_POINT_RATE_ALERT_DETAIL = "REMOVE_POINT_RATE_ALERT_DETAIL"
