import React from "react"
import { Switch, Route } from "react-router-dom"
import LogoutPageView from "views/logout-views"

export const LogoutLayout = () => {
  return (
    <div className="auth-container">
      <Switch>
        <Route path="/logout" component={LogoutPageView} />
      </Switch>
    </div>
  )
}

export default LogoutLayout
