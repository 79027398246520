export const GET_MEMBERSHIP_LEVELS = "GET_MEMBERSHIP_LEVELS"
export const GET_MEMBERSHIPS_FOR_DOWNGRADE = "GET_MEMBERSHIPS_FOR_DOWNGRADE"
export const GET_MEMBERSHIP_LEVEL_BY_MEMBER = "GET_MEMBERSHIP_LEVEL_BY_MEMBER"
export const CREATE_MEMBERSHIP_LEVEL = "CREATE_MEMBERSHIP_LEVEL"
export const UPDATE_MEMBERSHIP_LEVEL = "UPDATE_MEMBERSHIP_LEVEL"
export const APPROVE_MEMBERSHIP_LEVEL = "APPROVE_MEMBERSHIP_LEVEL"
export const REJECT_MEMBERSHIP_LEVEL = "REJECT_MEMBERSHIP_LEVEL"
export const DELETE_MEMBERSHIP_LEVEL = "DELETE_MEMBERSHIP_LEVEL"
export const RESULT_MEMBERSHIP_LEVEL = "RESULT_MEMBERSHIP_LEVEL"
export const ALERT_MEMBERSHIP_LEVEL = "ALERT_MEMBERSHIP_LEVEL"
export const REMOVE_MEMBERSHIP_LEVEL_ALERT = "REMOVE_MEMBERSHIP_LEVEL_ALERT"
