export const GET_LIST_REWARD = "GET_LIST_REWARD"
export const GET_REWARD = "GET_REWARD"
export const DELETE_REWARD = "DELETE_REWARD"
export const CREATE_REWARD = "CREATE_REWARD"
export const UPDATE_REWARD = "UPDATE_REWARD"
export const RESULT_REWARD = "RESULT_REWARD"

export const SET_LIST_REWARD = "SET_LIST_REWARD"
export const SET_REWARD = "SET_REWARD"
export const SET_DELETE_REWARD = "SET_DELETE_REWARD"
export const SET_CREATE_REWARD = "SET_CREATE_REWARD"
export const SET_UPDATE_REWARD = "SET_UPDATE_REWARD"

export const SET_PAGE_REWARD = "SET_PAGE_REWARD"
export const RESET_REWARD_ALERT = "RESET_REWARD_ALERT"
