import React from "react"
import { Button } from "antd"
import { Link } from "react-router-dom"
import { PlusCircleOutlined } from "@ant-design/icons"

export const PointStructureAddButton = (props) => {
  return (
    <>
      <Link to="/points-management/point-structure/add">
        <Button type="primary" icon={<PlusCircleOutlined />} block>
          Add Point Structure
        </Button>
      </Link>
    </>
  )
}

export default PointStructureAddButton
