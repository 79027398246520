import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const CampaignTargetService = {}

CampaignTargetService.getListCampaignTarget = function (data) {
  const searchquery = {}
  if (data?.CampaignCode) searchquery.CampaignCode = data.CampaignCode

  return fetch({
    url: "/campaign/target-lists/find-all",
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: data?.rows || 20,
      Page: data?.page || 1,
      Search: JSON.stringify(searchquery ?? {}),
    },
  })
}

CampaignTargetService.getMember = function (params) {
  return fetch({
    url: `/customer/memberships/find-all`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: 10,
      Page: 1,
      Search: JSON.stringify(params ?? {}),
    },
  })
}

CampaignTargetService.createCampaignTarget = function (payload) {
  return fetch({
    url: `/campaign/target-lists/add`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

CampaignTargetService.deleteCampaignTarget = function (targetId) {
  return fetch({
    url: `/campaign/target-lists/delete`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { TargetListId: targetId },
  })
}

export default CampaignTargetService
